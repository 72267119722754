import { data } from '../../../constants';
import { DiamondSizeCard } from '../../Cards';
import FormElement from './FormElement';

type Props = {
  selectedSize?: string;
  onChange: (e: string) => void;
};
const DiamondSizeChooser = ({ selectedSize, onChange }: Props) => (
  <FormElement className="request-form-section__inner" title="What is the size of the largest diamond?">
    <div className="request-form-sizes">
      {data.DIAMOND_SIZES.map(d => (
        <DiamondSizeCard
          title={d.title || ''}
          hide={!d.title}
          id={d.id}
          hideDiamond={!d.isDiamondCard}
          isSelected={selectedSize === d.title}
          onClick={onChange}
        />
      ))}
    </div>
  </FormElement>
);

export default DiamondSizeChooser;
