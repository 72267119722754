import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

type Props = {
  leftHeaderText: string,
  imageSrc: string,
  title: string,
  content: string
};

const InfoTile = (props: Props) => (
  <Tile>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row justify="space-between" align="middle" style={{ width: '100%' }}>
          <Col>
            <NumberHeader>{props.leftHeaderText}</NumberHeader>
          </Col>
          <Col>
            <img alt="" src={props.imageSrc} />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Title style={{ width: '100%' }}>{props.title}</Title>
      </Col>
      <Col span={24}>
        <Text>{props.content}</Text>
      </Col>
    </Row>
  </Tile>
);

const Tile = styled.div`
    background-color: #FFFFFF;
    padding: 20px;
    box-shadow: -20px 30px 30px rgba(158, 177, 189, 0.1);
    width: 298px;
    height: 258px;

    @media screen and (max-width: 826px){
      width: 100%;

    }
`;

const NumberHeader = styled.span`
    font-family: Cambon;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 52px;
    color: #8FA1AC;
`;

const Title = styled.span`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: #31393E;
`;

const Text = styled.span`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #4F6470;
`;

export default InfoTile;
