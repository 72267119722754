import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toLower, startCase, upperFirst } from 'lodash';
import dayjs from 'dayjs';
import { text, data } from '../../constants';
import { searchTree } from '../../helpers/tree';
import ingot from '../../assets/images/gold-ingot.png';
import Package from '../../assets/images/package.svg';
import {
  Badge,
} from '../common';

type Props = {
  mode: Mode,
  updatedAt: Date,
  name: string,
  isPackage: boolean,
  status: string
};

const InfoCard: React.FC<Props> = ({
  mode, name, updatedAt, status, isPackage,
}: Props) => {
  const [cover, setCover] = useState('');

  useEffect(() => {
    const onCoverGet = () => {
      const { imgCover } = searchTree(data.CATEGORIES, name);
      return imgCover || ingot;
    };
    if (isPackage)
      return setCover(Package);
    setCover(onCoverGet());
  }, [name, isPackage]);

  return (
    <ItemCard>
      <ItemInfo>
        <CaptionText>{startCase(toLower(mode))}</CaptionText>
        <CaptionText>{`Updated:  ${dayjs(updatedAt).format('MM.DD.YYYY h:mm A')}`}</CaptionText>
        <CardTitle>{upperFirst(name)}</CardTitle>
        <Badge backgroundColor="#E8F1F7" color="#8FA1AC" width="80%" textAlignCenter>
          {status}
        </Badge>
      </ItemInfo>
      <CardImage src={cover} alt="" />
    </ItemCard>
  );
};

const ItemCard = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 20px 25px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 20px auto;

  @media (min-width: 400px) {
    width: 100%;
    margin: 50px 20px 30px;
  }

  @media (min-width: 1100px) {
    margin: 50px 20px 30px;
  }

  @media (min-width: 1460px) {
    max-width: 500px;
  }
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CaptionText = styled.span`
  ${text.caption}
  color: #8FA1AC;
`;

const CardTitle = styled.span`
  ${text['title-20']}
  color: #4F6470;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CardImage = styled.img`
  width: 120px;
  height: 100px;
`;

export default React.memo(InfoCard);
