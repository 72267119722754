import { Component } from 'react';
import styled from 'styled-components';
import { message as aMessage } from 'antd';
import { api } from '../api';
import { interceptors } from '../utils';

const { interceptPostError } = interceptors;

type Props = {};
type State = {
  isLoading: boolean;
  contactEmail: string;
};
export default class ContactUsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      contactEmail: '',
    };
  }

  componentDidMount() {
    this.fetchLink();
  }

  render() {
    const { isLoading, contactEmail } = this.state;
    return (
      <main className="main">
        <div className="section section-contact">
          <div className="container">
            <div className="row no-gutters">
              <div
                className="col-lg-6 section-contact__content"
                data-aos={window.innerWidth > 1000 ? 'fade-down' : ''}
                data-aos-delay={0}
                data-aos-duration={1200}
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                data-aos-anchor-="#header"
              >
                <div className="questions-box questions-box--mb questions-box--simple">
                  <h1
                    style={{ color: '#4f6470' }}
                    className="questions-box__title questions-box__title--mb"
                  >
                    Contact Us
                  </h1>
                  <div className="questions-box__details">
                    <div className="questions-box__detail">
                      <div className="row">
                        <div className="col-2">
                          <span>Call:</span>
                        </div>
                        <div className="col-10">
                          <a className="questions-box__link" href="tel:8562352463">
                            <span>856 235 2463</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="questions-box__detail">
                      <div className="row">
                        <div className="col-2">
                          <span>Mail:</span>
                        </div>
                        <div className="col-10">
                          <a
                            className="questions-box__link questions-box__link--with-icon"
                            href={`mailto:${contactEmail}`}
                          >
                            <span>{contactEmail}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="questions-box questions-box--simple questions-box--mb-mobile">
                  <h2
                    style={{ color: '#4f6470' }}
                    className="questions-box__title questions-box__title--mb"
                  >
                    Or find us here
                  </h2>
                  <div className="questions-box__details">
                    <div className="questions-box__detail questions-box__detail--multiline m-0">
                      <a
                        className="questions-box__link questions-box__link--with-icon"
                        href="https://g.page/EandBPawn?share"
                      >
                        <span>
                          110 W Camden Ave,
                          <br />
                          Moorestown, NJ 08057
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos={window.innerWidth > 1000 ? 'fade-up' : ''}
                data-aos-delay={0}
                data-aos-duration={1200}
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                data-aos-anchor-="#header"
              >
                <div className="card">
                  <div className="card__body">
                    <form
                      className="js-contact-form"
                      onSubmit={this.handleSubmit}
                    >
                      <input
                        className="form-control card__form-control"
                        name="name"
                        type="text"
                        placeholder="Your Full Name*"
                        required
                        disabled={isLoading}
                      />
                      <input
                        className="form-control card__form-control"
                        name="email"
                        type="email"
                        placeholder="Your Email*"
                        required
                        disabled={isLoading}
                      />
                      <input
                        className="form-control card__form-control"
                        name="subject"
                        type="text"
                        placeholder="Subject"
                        disabled={isLoading}
                      />
                      <textarea
                        className="form-control form-control--textarea"
                        name="message"
                        placeholder="Your Message"
                        required
                        defaultValue=""
                        disabled={isLoading}
                      />
                      <div className="card__form-action row">
                        <div className="col-xl-6">
                          <p className="card__form-caption">
                            Please expect at least 24
                            <br />
                            hours before receiving a reply
                          </p>
                        </div>
                        <div className="col-xl-6">
                          <SubmitInput
                            type="submit"
                            value={isLoading ? 'SENDING...' : 'SEND MESSAGE'}
                            className="btn btn--secondary btn--lg w-100 text-center"
                            disabled={isLoading}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal micromodal-slide" id="modal-send-success" aria-hidden="true">
          <div className="modal__overlay" tabIndex={-1} data-micromodal-close>
            <div className="modal__container" role="dialog" aria-modal="true" aria-label="message status">
              <div className="modal__content">
                <p className="modal__title">Message has been successfully sent. </p>
              </div>
              <div className="modal__footer">
                <button type="button" className="btn btn--secondary" data-micromodal-close>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }

  fetchLink = () => {
    api
      .get('/configuration/contact-email')
      .then(res => res.data)
      .then(({ content }) => this.setState({ contactEmail: content }))
      .catch(() => this.setState({ contactEmail: '' }));
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const {
      target: {
        name, email, subject, message,
      },
    } = e;

    api
      .post('/notification/send-email', {
        name: name.value,
        email: email.value,
        subject: subject.value,
        message: message.value,
      })
      .then(() => {
        aMessage.success('The e-mail was sent successfully');
        e.target.reset();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({ isLoading: false }));
  };
}

const SubmitInput = styled.input`
  outline: none;
`;
