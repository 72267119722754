import { Component } from 'react';
import {
  Row, Modal, Space,
} from 'antd';
import styled from 'styled-components';
import { PlaidLink } from 'react-plaid-link';
import { Button, Input, Spinner } from '../common';
import { api } from '../../api';
import { colors, text } from '../../constants';
import { interceptors } from '../../utils';
import { CustomModal } from '../../custom';

type Props = {
  visible: boolean;
  onCancel: () => void;
  transaction: Loan;
  onPay: () => void;
  onBankConnection: () => void;
};

type State = {
  amount: number;
  isInputVisible: boolean;
  bankToken: boolean;
  linkToken: string;
  isLoading: boolean;
  minimalLoanValue: number,
  rate: number,
};

class PayModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      amount: 0,
      isInputVisible: false,
      linkToken: '',
      bankToken: false,
      isLoading: false,
      minimalLoanValue: 0,
      rate: 0,
    };
  }

  componentDidMount() {
    this.createLinkToken();
    this.getForDate();
  }

  render() {
    const { onCancel, visible, transaction } = this.props;
    const {
      amount, isInputVisible, linkToken, isLoading, bankToken, rate, minimalLoanValue,
    } = this.state;

    if (isLoading)
      return (
        <StyledModal onCancel={onCancel} visible={visible} footer={null}>
          <Spinner />
        </StyledModal>
      );

    return (
      <StyledModal onCancel={onCancel} visible={visible} footer={null}>
        {isLoading && (!bankToken || !linkToken) ? (
          <Spinner />
        ) : (
          <>
            {!bankToken ? (
              <Row>
                <StyledPlaidLink
                  token={linkToken}
                  className="plaid-link"
                  onSuccess={this.onSuccess}
                >
                  Connect a bank account
                </StyledPlaidLink>
              </Row>
            ) : (
              <>
                <Row>
                  <Button.SecondaryButton
                    className="pay-button"
                    width="100%"
                    disabled={!rate}
                    onClick={() => this.handlePay(rate)}
                  >
                    Pay interests $
                    {rate}
                  </Button.SecondaryButton>
                </Row>
                <Row>
                  <Button.SecondaryButton
                    className="pay-button"
                    width="100%"
                    onClick={() => this.handleAmountInputVisibility(!isInputVisible)}
                  >
                    Pay interests + amount
                  </Button.SecondaryButton>
                  {isInputVisible && (
                    <Space className="pay-input-container" direction="horizontal">
                      <Input.CurrencyInputNumber
                        min={+minimalLoanValue + +rate}
                        max={+transaction.loanAmount + +rate}
                        value={amount}
                        onChange={e => this.handleAmountChange(e)}
                      />
                      <Button.SecondaryButton onClick={() => this.handlePay(amount)}>
                        Pay
                      </Button.SecondaryButton>
                    </Space>
                  )}
                </Row>
                <Row>
                  <Button.SecondaryButton
                    className="pay-button"
                    width="100%"
                    onClick={() => this.handlePay(+transaction.loanAmount + +rate)}
                  >
                    Redeem the item
                  </Button.SecondaryButton>
                </Row>
              </>
            )}

          </>
        )}
      </StyledModal>
    );
  }

  handlePay = async (amount?: number) => {
    const {
      transaction: { id },
      onPay,
    } = this.props;

    api
      .post('/payments/pay', { amount, id })
      .then(() => {
        onPay();
        CustomModal.displayCustomSuccess({
          title: 'Payment success',
        });
      })
      .catch(error => {
        CustomModal.displayCustomWarning({
          title: 'Payment failed',
          content: error.response?.data.message,
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  onSuccess = (token: any, metadata: any) => {
    this.setState({ isLoading: true });
    api
      .post('/plaid/create-item', { publicToken: token, ...metadata })
      .then(res => res.data)
      .then(() => this.props.onBankConnection())
      .catch(interceptors.interceptPostError)
      .finally(() => this.setState({ isLoading: false }));
  };

  getForDate = () => {
    const {
      transaction: { id },
    } = this.props;
    this.setState({ isLoading: true });
    api
      .get(`/loans/${id}/get-for-date`)
      .then(res => res.data)
      .then(({ minimalLoanValue, rate }) => this.setState({
        minimalLoanValue,
        rate,
        amount: +minimalLoanValue + +rate,
      }))
      .finally(() => this.setState({ isLoading: false }));
  };

  handleAmountInputVisibility = (state: boolean) => this.setState({ isInputVisible: state });

  handleAmountChange = (amount: number) => this.setState({ amount });

  createLinkToken = () => {
    this.setState({ isLoading: true });
    api
      .post('/plaid/create-link-token')
      .then(res => res.data)
      .then(res => {
        const { linkToken, bankToken } = res;
        this.setState({ linkToken, bankToken });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  createItem = async () => api.post('/plaid/create-item', {});
}

export default PayModal;

const StyledPlaidLink = styled(PlaidLink)`
  background-color: ${colors.button.primary} !important;
  color: ${colors.background['widget-white']} !important;
  border: 1px solid ${colors.button.primary} !important;
  padding: 0px 10px !important;
  ${text['buttom-caps-14']}
  font-size: 11px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.5px !important;
  cursor: pointer !important;
  outline: none !important;

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    &:not([disabled]) {
      border: 1px solid ${colors.button.primary};
      background-color: ${colors.background['widget-white']};
      color: ${colors.button.primary};
    }
  }

  @media (min-width: 1024px) {
    padding: 0 5px;
  }

  @media (mim-width: 1440px) {
    padding: 0 10px;
    font-size: 12px;
  }

  @media (min-width: 1700px) {
    font-size: 16px;
  }
`;

const StyledModal = styled(Modal)`
  display: grid;
  justify-content: center;

  .pay-button {
    width: 462px;
  }

  .pay-input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    width: 100%;
    margin-bottom: 5%;
  }

  @media only screen and (max-width: 462px) {
    .currency-input,
    .pay-button {
      width: 80% !important;
    }
  }

  .ant-modal-content {
    padding: 10px;
  }

  .ant-modal-body {
    .ant-row {
      margin-top: 5px;
      margin-bottom: 5%;
    }
  }
`;
