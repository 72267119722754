import dayjs from 'dayjs';

type Props = {
  quote: Quote;
};

const DetailsSection = (props: Props) => {
  const {
    quote: {
      customId, context: {
        submission: {
          description,
          generalInfo: {
            diamondSize, transactionType, originalPrice, sizeDescription,
            categories, denomination, grantingCompany, isGraded, mintMark,
            originType, year, metal, metalType, properType, designerName,
            materialType, condition, hasBoxAndPapers, packingTags, itemName,
          },
        },
      }, updatedAt,
    },
  } = props;

  const showCategories = categories?.map((item: any, index: number): JSX.Element => (
    <>
      {`${item}${index === categories.length - 1 ? '' : ' / '} `}
    </>
  ));

  const showPackingTags = packingTags?.map((item: any, index: number): JSX.Element => (
    <>
      {`${item}${index === categories.length - 1 ? '' : ' , '} `}
    </>
  ));

  return (
    <div className="item-details__right">
      <div className="attachments-card item-details__info attachments-card--white">
        <div className="attachments-card__pretitle">
          {customId}
          <span> / </span>
          {transactionType}
          <span> / </span>
          {dayjs(updatedAt).format('MM.DD.YYYY h:mm A')}
        </div>
        <div className="attachments-card__pretitle">
          {showCategories}
          {metal && (
            `Metal / ${metal}`
          )}
        </div>
        <div className="attachments-card__title" />
        <div className="attachments-card__description">
          {diamondSize && (
            <div className="attachments-card__section">
              Size of the largest diamond:
              <span>{` ${diamondSize}`}</span>
            </div>
          )}
          {metalType && (
            <div className="attachments-card__info">
              Metal type:
              <span>
                {` ${metalType}`}
              </span>
            </div>
          )}
          {properType && (
            <div className="attachments-card__info">
              Proper type:
              <span>
                {` ${properType}`}
              </span>
            </div>
          )}
          {designerName && (
            <div className="attachments-card__info">
              Designer name:
              <span>
                {` ${designerName}`}
              </span>
            </div>
          )}
          {materialType && (
            <div className="attachments-card__info">
              Material type:
              <span>
                {` ${materialType}`}
              </span>
            </div>
          )}
          {hasBoxAndPapers && (
            <div className="attachments-card__info">
              Has box and papers:
              <span>
                {` ${hasBoxAndPapers ? 'Yes' : 'No'}`}
              </span>
            </div>
          )}
          {packingTags && (
            <div className="attachments-card__info">
              Packing tags:
              <span> </span>
              <span>
                {showPackingTags}
              </span>
            </div>
          )}
          {condition && (
            <div className="attachments-card__info">
              Condition:
              <span>
                {` ${condition}`}
              </span>
            </div>
          )}
          {itemName && (
            <div className="attachments-card__info">
              Item name:
              <span>
                {` ${itemName}`}
              </span>
            </div>
          )}
          {originalPrice && (
            <div className="attachments-card__info">
              Original price:
              <span>
                {` $${originalPrice}`}
              </span>
            </div>
          )}
          {denomination && (
            <div className="attachments-card__info">
              Denomination:
              <span>
                {` ${denomination}`}
              </span>
            </div>
          )}
          {grantingCompany && (
            <div className="attachments-card__info">
              Granting Company:
              <span>
                {` ${grantingCompany}`}
              </span>
            </div>
          )}
          {isGraded && (
            <div className="attachments-card__info">
              Is graded:
              <span>
                Yes
              </span>
            </div>
          )}
          {mintMark && (
            <div className="attachments-card__info">
              Mint mark:
              <span>
                {` ${mintMark}`}
              </span>
            </div>
          )}
          {originType && (
            <div className="attachments-card__info">
              Origin type:
              <span>
                {` ${originType}`}
              </span>
            </div>
          )}
          {year && (
            <div className="attachments-card__info">
              Year:
              <span>
                {` ${year}`}
              </span>
            </div>
          )}
        </div>
        <div className="attachments-card__section">
          {sizeDescription && (
            <div className="attachments-card__info attachments-card__info--vertical">
              Additional diamonds information:
              <span>{sizeDescription}</span>
            </div>
          )}
        </div>
        <div className="attachments-card__section">
          {description && (
            <div className="attachments-card__info attachments-card__info--vertical">
              Description:
              <span>
                {description}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsSection;
