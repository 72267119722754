import styled from 'styled-components';
import { Image, Row } from 'antd';
import tableNoContentMessage from '../../assets/images/tableNoContentMessage.png';
import { colors } from '../../constants';

const TableNoContentMessage = () => (
  <StyledDiv>
    <Row>No results were found...</Row>
    <Row>Try another name or number</Row>
    <Row><Image src={tableNoContentMessage} /></Row>
  </StyledDiv>
);

const StyledDiv = styled.div`
float: left;
padding: 24px;

.ant-row {
    font-size: 20px;
    color: ${colors.text.body};
}

.ant-image {
    padding-top: 20%;
}
`;

export default TableNoContentMessage;
