/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
type Props = {
  quoteValue: number;
  isVisibleRejectSendPreliminaryOffer: boolean;
  typeCallback: string;
  onSendPreliminaryOffer: () => void;
  onRejectRequest: () => void;
  onAcceptRequest: () => void;
  onCancel: () => void;
};

const RejectSendPreliminaryOffer = ({
  quoteValue, isVisibleRejectSendPreliminaryOffer, typeCallback, onSendPreliminaryOffer, onRejectRequest, onAcceptRequest, onCancel,
}: Props) => (
  <>
    {
      typeCallback === 'ACCEPT' && (
        <div className={`popup ${isVisibleRejectSendPreliminaryOffer && 'popup--shown'}`}>
          <div className="popup__wrap">
            <a className="popup__close" onClick={onCancel} />
            <div className="popup__inner">
              <div className="popup__header">
                <div className="popup__title popup__title--small">Your offer is:</div>
              </div>
              <div className="popup__content">
                <div className="popup__price popup__price--small">
                  $
                  {quoteValue.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
              <div className="popup__footer">
                <div className="popup__buttons">
                  <a className="popup__btn btn btn--dark" onClick={onSendPreliminaryOffer}>Confirm & Send</a>
                  <a className="popup__btn btn btn--bordered btn--bordered-dark" onClick={onCancel}>Back</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    {
      (typeCallback === 'REJECT' || typeCallback === 'REJECT_ITEMS') && (
        <div className={`popup ${isVisibleRejectSendPreliminaryOffer && 'popup--shown'} marks-wrap marks-wrap--red`}>
          <div className="popup__wrap">
            <a className="popup__close" onClick={onCancel} />
            <div className="popup__inner">
              <div className="popup__header">
                <div className="popup__text popup__text--big">
                  This
                  {typeCallback === 'REJECT' ? ' request ' : ' items '}
                  will be
                  <mark>rejected. </mark>
                  <br />
                  Are you sure you
                  want to proceed?
                </div>
              </div>
              <div className="popup__content">
                <div className="popup__image popup__image--red">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 14.6666C5.30237 14.6682 2.86966 13.0439 1.83711 10.5517C0.804556 8.05946 1.37569 5.19063 3.284 3.28391C4.96886 1.59904 7.42461 0.941026 9.72617 1.55773C12.0277 2.17443 13.8255 3.97216 14.4422 6.27373C15.0589 8.5753 14.4009 11.031 12.716 12.7159C11.4676 13.9699 9.76944 14.6723 8 14.6666ZM2.66666 8.11457C2.69821 11.0487 5.09407 13.4063 8.02836 13.3906C10.9626 13.3747 13.333 10.9916 13.333 8.05724C13.333 5.12291 10.9626 2.73975 8.02836 2.7239C5.09407 2.70821 2.69821 5.06575 2.66666 7.99991V8.11457ZM6.27266 10.6666L5.33333 9.72657L7.06 7.99991L5.33333 6.27324L6.27333 5.33324L8 7.05991L9.72666 5.33324L10.6667 6.27324L8.94 7.99991L10.6667 9.72657L9.72733 10.6666L8 8.93991L6.27333 10.6666H6.27266Z"
                    />
                  </svg>
                </div>
              </div>
              <div className="popup__footer">
                <div className="popup__buttons popup__buttons--horizontal">
                  <a className="popup__btn btn btn--bordered btn--bordered-dark  popup__btn--static-width" onClick={onCancel}>
                    Go Back
                  </a>
                  <a className="popup__btn btn btn--red popup__btn--static-width" onClick={onRejectRequest}>Yes, Reject</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    {
      typeCallback === 'ACCEPT_OFFER' && (
        <div className={`popup ${isVisibleRejectSendPreliminaryOffer && 'popup--shown'} marks-wrap marks-wrap--green`}>
          <div className="popup__wrap">
            <a className="popup__close" onClick={onCancel} />
            <div className="popup__inner">
              <div className="popup__header">
                <div className="popup__text popup__text--big">
                  This request will be
                  <mark>accepted.</mark>
                  <br />
                  Are you sure you
                  want to proceed?
                </div>
              </div>
              <div className="popup__content">
                <div className="popup__image popup__image--green">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.98931 13.3333H7.99998C10.9445 13.3303 13.3295 10.9417 13.328 7.99725C13.3265 5.05278 10.9391 2.66659 7.99465 2.66659C5.05017 2.66659 2.66279 5.05278 2.66131 7.99725C2.65984 10.9417 5.04484 13.3303 7.98931 13.3333ZM6.66665 11.3333L3.99998 8.66659L4.93998 7.72659L6.66665 9.44659L11.06 5.05325L12 5.99992L6.66665 11.3333Z"
                    />
                  </svg>
                </div>
              </div>
              <div className="popup__footer">
                <div className="popup__buttons popup__buttons--horizontal">
                  <a
                    className="
                    popup__btn
                    btn btn--bordered btn--bordered-dark
                    popup__btn--static-width
                  "
                    onClick={onCancel}
                  >
                    Go Back
                  </a>
                  <a className="popup__btn btn btn--green popup__btn--static-width" onClick={onAcceptRequest}>
                    Yes, Accept
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </>
);

export default RejectSendPreliminaryOffer;
