import React from 'react';
import { Modal } from 'antd';
import ShippingForm from './ShippingForm';

type Props = {
  isVisible: boolean,
  onVisibleChange: (state: boolean) => void;
  details: ShippingLabel[];
  onFinish(shhipment: ShipmentDetails): void
};

const ConfirmDeliveryAddressModal: React.FC<Props> = ({
  isVisible, onVisibleChange, onFinish, details,
}: Props) => (
  <Modal title="Confirm delivery address" visible={isVisible} onCancel={() => onVisibleChange(false)} footer={null}>
    <ShippingForm onFinish={onFinish} values={details[0].shipmentDetails} />
  </Modal>
);

export default ConfirmDeliveryAddressModal;
