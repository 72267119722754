/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Modal,
} from 'antd';
import styled from 'styled-components';
import { quoteStore } from '../../store';
import Tick from '../../assets/images/tick.svg';
import { SecondaryButton, OutlineButton } from '../common/Button';

type Props = {
  fileList?: any[]
  isVisible: boolean;
  handleVisibileChange?: (state: boolean) => void;
  onClose?: () => void;
  onConfirm?: () => void;
};

const NewSummaryModal = ({
  isVisible,
  onConfirm,
  handleVisibileChange,
  // fileList,
  onClose,
}: Props) => {
  const quotes = quoteStore.getState();
  // const files = [...quotes.quotes.map(q => q.attachments && [...q.attachments]), fileList] || [];

  const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  let items: any[] = [];

  const moreThanOneItem = quotes.quotes.length > 0;

  if (moreThanOneItem) {
    const quotesArray = quotes.quotes.map(quote => quote.generalInfo.categories[quote.generalInfo.categories.length - 1]);
    items = [quotes.generalInfo.categories[quotes.generalInfo.categories.length - 1], ...quotesArray];
  }

  const groupBy = function (xs: any[], key: string | number) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const arrayOfItems = Object.values(groupBy(items, 'length'));

  const showItems = arrayOfItems.map((item: any, index: number): JSX.Element => (
    <>
      {`${item.length} ${capitalizeFirstLetter(item[0])}${index === arrayOfItems.length - 1 ? '.' : ','} `}
    </>
  ));

  return (
    <StyledModal
      onCancel={() => handleVisibileChange && handleVisibileChange(false)}
      visible={isVisible}
      footer={null}
    >
      <Title>Nice Work!</Title>
      <Subtitle>
        {`You have chosen to ${quotes?.generalInfo?.transactionType} your item(s):`}
        <br />
        {moreThanOneItem && showItems}
      </Subtitle>
      <img src={Tick} alt="" width="51" height="51" />
      <p>
        Before we proceed,
        <br />
        would you like to add another item?
      </p>
      <SecondaryButton onClick={onConfirm}>Finish &amp; Add Payment Method</SecondaryButton>
      <OutlineButton onClick={onClose} className="no-attachment-modal">
        Add New Item
      </OutlineButton>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 60px;
        display: flex;
        flex-direction: column;

        img {
            margin: 45px auto 40px auto;
        }

        .no-attachment-modal {
            margin-top: 10px;
        }

        p {
          font-size: 16px;
          text-align: center;
          margin-bottom: 32px;
        }
    }
`;

const Title = styled.div`
  color: #A38E31;
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  text-align: center;
`;

const Subtitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  text-align: center;
`;
export default NewSummaryModal;
