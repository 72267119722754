/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import EMAIL_NOTIFICATIONS_STAGES from '../../../constants/emailNotificationsStages';

const { AV_3A_ACCEPT, AV_3A_REJECT, AV_3A_COUNTER } = EMAIL_NOTIFICATIONS_STAGES;

type Props = {
  quoteValue: number;
  actualQuoteValue: number;
  previousAdminOffer?: number;
  onChangeValue: (e: any) => void;
  onFinalOffer: (e: any) => void;
  onSubmit: (typeNotification: string, emailNotification: string) => void;
};

const AdminCounterOfferFromUser = ({
  quoteValue, actualQuoteValue, previousAdminOffer, onChangeValue, onFinalOffer, onSubmit,
}: Props) => (
  <div className="dashboard-columns__right">
    <div className="dashboard-infocard">
      <div className="popup__header">
        <div className="popup__title">Counter offer from User</div>
        <div className="popup__text">
          Previous E&B offer:
          $
          {previousAdminOffer?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="popup__content">
        <div className="popup__price popup__price--small">
          $
          {quoteValue?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="popup__footer">
        <div
          className="
          popup__buttons
          popup__buttons--wide
          popup__buttons--horizontal
          popup__buttons--stretch
        "
        >
          <a className="popup__btn btn btn--green" onClick={() => onSubmit('ACCEPT_OFFER', AV_3A_ACCEPT)}>Accept</a>
          <a className="popup__btn btn btn--red" onClick={() => onSubmit('REJECT', AV_3A_REJECT)}>Reject</a>
        </div>
        <form
          className="popup-counteroffer dashboard-infocard__counteroffer"
        >
          <div className="form-input popup-counteroffer__input">
            <input type="number" placeholder="$0" value={actualQuoteValue} onChange={onChangeValue} />
          </div>
          <a
            className="
            popup-counteroffer__submit
            btn btn--dark
            popup__btn
          "
            onClick={() => onSubmit('ACCEPT', AV_3A_COUNTER)}
          >
            Send a Counter Offer
          </a>
        </form>
        <label className="checkbox checkbox--small">
          <input type="checkbox" name="checkbox" onChange={onFinalOffer} /><span>Final offer</span>
        </label>
      </div>
    </div>
  </div>
);

export default AdminCounterOfferFromUser;
