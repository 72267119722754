import styled from 'styled-components';
import FormElement from './FormElement';
import FormCardBase from '../../Cards/FormCardBase';

type Props = {
  type: string;
  onTypeChange: (value: string) => void;
  data: string[];
};

const TypeChooser = ({ type, onTypeChange, data }: Props) => (
  <FormElement title="Please select proper type?">
    <TypeContainer className="type-chooser-container">
      {data.map(t => (
        <FormCardBase onClick={onTypeChange} isSelected={t === type} type={t}>
          <>{t}</>
        </FormCardBase>
      ))}
    </TypeContainer>
  </FormElement>
);

const TypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: inherit;
`;

export default TypeChooser;
