type Props = {
  shippingLabel: ShippingLabel | undefined;
  context: QuoteContext | undefined;
};

const ShippingBackInfo = ({ shippingLabel, context }: Props): JSX.Element => {
  const sentByFedexBox = shippingLabel?.shipmentDetails.boxSize !== 'YOUR_PACKAGING';
  return (
    <>
      {
        !sentByFedexBox && (
          <div className="dashboard-status dashboard-status--error">
            <div className="dashboard-status__title">
              This item was sent via shipping label
            </div>
            <div className="dashboard-status__text dashboard-status__text--wide">
              Please note that this item was originally sent via shipping label
              (and not Fedex box).
            </div>
          </div>
        )
      }
      <div className="tracking-info">
        <div className="tracking-info__body">
          <div className="tracking-info__title">Tracking Information:</div>
          <div className="tracking-info__unit">
            {'Address: '}
            <span>
              {`${shippingLabel?.shipmentDetails.addressLine} / ${shippingLabel?.shipmentDetails.city} / ${shippingLabel?.shipmentDetails.postalCode}`}
            </span>
          </div>
          {
            context?.shippingBackTrackingNumber && (
              <div className="tracking-info__unit">
                {'Tracking number: '}
                <span>
                  {context?.shippingBackTrackingNumber}
                </span>
              </div>
            )
          }
          <div className="tracking-info__unit">
            {'Client name: '}
            <span>
              {`${shippingLabel?.shipmentDetails.firstName} ${shippingLabel?.shipmentDetails.lastName}`}
            </span>
          </div>
          <div className="tracking-info__unit">
            {'Phone number: '}
            <span>
              {shippingLabel?.shipmentDetails.phone}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingBackInfo;
