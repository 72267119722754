type Props = {
  interestData?: InterestData;
  mode?: string;
  quoteValue: number;
};

const AdminAgreementHasBeenSentSigned = ({ interestData, mode, quoteValue }: Props) => (
  <div className="dashboard-columns__right">
    <div className="dashboard-infocard marks-wrap marks-wrap--green">
      <div className="popup__header">
        <div className="popup__title">
          Agreement has been
          <mark>
            {
              mode === 'PAWN' ? <div>Signed</div> : 'Sent'
            }
          </mark>
        </div>
      </div>
      <div className="popup__content">
        <div className="attachments-card__info">
          Rate:
          <span>
            {` ${((interestData?.montlyPercentageRate || 0) * 100).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
            % per month (
            {((interestData?.yearlyPercentageRate || 0) * 100).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            % APR)
          </span>
        </div>
        <div className="attachments-card__info">
          Total interest:
          <span>
            <span> $</span>
            {(quoteValue * (interestData?.montlyPercentageRate || 0) * 3).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
        <div
          className="popup__price popup__price--small popup__price--green"
        >
          $
          {quoteValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="popup__footer">
        <div className="popup__text">
          {
            mode === 'PAWN' ? 'Waiting for user authorization' : ' Waiting for user to sign agreement'
          }
        </div>
      </div>
    </div>
  </div>
);

export default AdminAgreementHasBeenSentSigned;
