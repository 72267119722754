import { Component } from 'react';
import {
  withRouter,
  Route,
  RouteComponentProps,
  Switch,
  Redirect,
} from 'react-router-dom';
import PaymentInformation from './views/PaymentInformation';
import GlobalFonts from './GlobalFonts';
import {
  actionTypes, quoteStore, userStore, optionStore,
} from './store';

import {
  AboutView,
  ActionHistoryView,
  AdminRequestDetailsView,
  AgreementTemplateView,
  AgreementsView,
  AttachmentsView,
  CancelView,
  CategoriesView,
  ContactUsView,
  DescriptionView,
  FaqEditView,
  FaqView,
  GeneralInfoView,
  HomeView,
  NewRequestView,
  NotificationsView,
  ProfileView,
  RequestsView,
  ConfigurationView,
  SuccessView,
  TransactionDetailsView,
  TransactionPaymentView,
  TransactionsView,
  WhatsNextView,
  DictionaryView,
  CommentsAdminView,
  ResetPasswordSetNewView,
  RequestDetailsView,
} from './views';

import {
  common, PrivateRoute, ProtectedRoute,
  ScrollToTop,
  Sider as CustomSider,
  Menu,
} from './components';

import { api } from './api';
import { data } from './constants';

const {
  Content,
  Footer,
  Layout,
  Navbar,
  Spinner,
} = common;

const { AdminMenu, UserMenu } = Menu;

type State = {
  user: User;
  generalInfo: any,
  isLoading: boolean,
  isSiderVisible: boolean,
  isMobileSiderDisplayed: boolean,
  isVisibleShowLoginForm: boolean,
};

type Props = RouteComponentProps;
class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: data.USER_DEFAULT,
      generalInfo: null,
      isLoading: true,
      isSiderVisible: true,
      isMobileSiderDisplayed: false,
      isVisibleShowLoginForm: false,
    };

    userStore.subscribe(() => {
      const user = userStore.getState();
      this.setState({
        user,
      });
      if (user.id)
        this.authSmartUpp(user);
    });

    optionStore.subscribe(() => this.setState({ isSiderVisible: optionStore.getState().isNavbarVisible }));

    quoteStore.subscribe(() => this.setState({ generalInfo: quoteStore.getState() }));
  }

  componentDidUpdate(_: Props, prevState: State) {
    if (prevState.user.id !== this.state.user.id) {
      this.getCurrentLoggedUser();
    }
  }

  componentDidMount() {
    this.getCurrentLoggedUser();
  }

  render() {
    const {
      user, isLoading, isMobileSiderDisplayed, isVisibleShowLoginForm,
    } = this.state;
    const isAuth = this.isUserLoggedIn(user);
    const menu = this.handleDisplayMenu(user);

    if (isLoading) {
      return (
        <Layout>
          <Content>
            <Spinner />
          </Content>
        </Layout>
      );
    }

    return (
      <>
        <Layout>
          <GlobalFonts />
          <>
            <Navbar
              {...this.props}
              isLogged={isAuth && !this.shouldDisplaySidebar()}
              displayToggler={isAuth && this.shouldDisplaySidebar()}
              shouldBeTranspaarent={this.shouldNavBeTransparent()}
              handleLogout={this.handleLogout}
              onSwitchChange={this.onSwitchChange}
              showLoginForm={isVisibleShowLoginForm}
              onCloseLogginForm={() => this.setState({ isVisibleShowLoginForm: false })}
            />
            {isAuth && this.shouldDisplaySidebar() && <CustomSider collapsed={!isMobileSiderDisplayed}>{menu}</CustomSider>}
            {user.id && window.location.hash === '#/' && <Redirect from="/" to={{ pathname: '/requests/all' }} />}
            <ScrollToTop>
              <Switch>
                <ProtectedRoute
                  path="/request-details/:id"
                  component={user.role === 'admin'
                    ? AdminRequestDetailsView
                    : RequestDetailsView}
                  roles={['admin', 'user']}
                />
                <ProtectedRoute path="/agreements" component={AgreementsView} roles={['admin']} />
                <ProtectedRoute path="/notifications" component={NotificationsView} roles={['admin']} />
                <PrivateRoute path="/transactions/details/:id">
                  <TransactionDetailsView {...this.props} />
                </PrivateRoute>
                <PrivateRoute path="/transactions/payment/:id">
                  <TransactionPaymentView {...this.props} />
                </PrivateRoute>
                <PrivateRoute path="/transactions/:category">
                  <TransactionsView {...this.props} />
                </PrivateRoute>
                <PrivateRoute path="/requests/:filter">
                  <RequestsView {...this.props} />
                </PrivateRoute>
                <PrivateRoute path="/action-history">
                  <ActionHistoryView {...this.props} />
                </PrivateRoute>
                <PrivateRoute path="/profile/info">
                  <ProfileView {...this.props} />
                </PrivateRoute>
                <ProtectedRoute path="/profile/faq" component={FaqEditView} roles={['admin']} />
                <ProtectedRoute path="/configurations/shipment" component={DictionaryView} roles={['admin']} />
                <ProtectedRoute
                  path="/configuration/agreement-template"
                  component={AgreementTemplateView}
                  roles={['admin']}
                />
                <ProtectedRoute path="/new-request" component={NewRequestView} roles={['user']} />
                <Route path="/payment-information" render={() => <PaymentInformation {...this.props} />} />
                <Route path="/general-information/:category" component={GeneralInfoView} />
                <Route path="/description/:category?/:subcategory?" component={DescriptionView} />
                <Route path="/attachments/:category" component={AttachmentsView} />
                <Route path="/categories/:category?/:subcategory?" component={CategoriesView} />
                <Route path="/contact-us" component={ContactUsView} />
                <Route path="/what-next" component={WhatsNextView} />
                <Route path="/about" component={AboutView} />
                <Route path="/faq" component={FaqView} />
                <Route path="/remind-password/:id" component={ResetPasswordSetNewView} />
                <ProtectedRoute
                  path="/configuration/store-contact-us"
                  component={ConfigurationView}
                  roles={['admin']}
                />
                <ProtectedRoute
                  path="/configuration/comments"
                  component={CommentsAdminView}
                  roles={['admin']}
                />
                <Route path="/success" component={SuccessView} />
                <Route path="/cancel" component={CancelView} />
                <Route
                  path="/:id"
                  render={props => <HomeView onSuccessChangePassword={() => this.setState({ isVisibleShowLoginForm: true })} {...props} />}
                />
                <Route path="/" component={HomeView} />
              </Switch>
            </ScrollToTop>
          </>
        </Layout>
        {(!isAuth || !this.shouldDisplaySidebar()) && <Footer {...this.props} handleLogout={this.handleLogout} />}
      </>
    );
  }

  authSmartUpp = ({
    id, firstName, lastName, email,
  }: User) => {
    window.smartsupp('name', `${firstName} ${lastName}`);
    window.smartsupp('email', email);
    window.smartsupp('User_ID', id);
  };

  handleDisplayMenu = ({ role }: User) => {
    switch (role) {
      case 'admin':
        return (
          <AdminMenu />
        );
      case 'user':
        return (
          <UserMenu />
        );
      default:
        return <></>;
    }
  };

  handleLogout = () => {
    this.setState({ isLoading: true });
    api
      .post('/logout')
      .then(() => {
        userStore.dispatch({ type: actionTypes.LOG_OUT, user: data.USER_DEFAULT });
        quoteStore.dispatch({ type: actionTypes.CLEAR_ITEMS });
        this.props.history.push('/');
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  getCurrentLoggedUser = () => {
    api
      .post('/user')
      .then(res => res.data)
      .then((user: User) => {
        userStore.dispatch({ type: actionTypes.ADD_USER, user });
      })
      .catch(() => { })
      .finally(() => this.setState({ isLoading: false }));
  };

  shouldDisplayNavbarComponent = () => {
    const locationsWithCustomNavbar = [
      '/',
      '/faq',
      '/contact-us',
      '/about',
      '/configurations/shipment',
    ];

    let shouldDisplay = false;

    const currentLocation = this.props.location.pathname;

    locationsWithCustomNavbar.forEach(location => {
      if (this.props.location.pathname === location
        || location === currentLocation.substring(0, currentLocation.lastIndexOf('/'))) {
        shouldDisplay = true;
      }
    });

    return !shouldDisplay;
  };

  shouldNavBeTransparent = () => {
    const locationsWithSidebar = [
      '/',
      '/about',
    ];

    let shouldDisplay = false;

    const currentLocation = this.props.location.pathname;

    locationsWithSidebar.forEach(location => {
      if (this.props.location.pathname === location
        || location === currentLocation.substring(0, currentLocation.lastIndexOf('/'))) {
        shouldDisplay = true;
      }
    });

    return shouldDisplay;
  };

  shouldDisplaySidebar = () => {
    const locationsWithSidebar = [
      '/profile',
      '/new-request',
      '/requests/all',
      '/requests/open',
      '/requests/open',
      '/requests/admin-action-required',
      '/requests/user-action-required',
      '/requests/accepted',
      '/requests/negotiations',
      '/requests/closed',
      '/requests/rejected',
      '/action-history',
      '/transactions/default',
      '/transactions/shipped-back',
      '/transactions/details',
      '/request-details',
      '/transactions/payment',
      '/agreements',
      '/notifications',
      '/configuration',
      '/agreement-template',
      '/configurations/shipment',
    ];

    let shouldDisplay = false;

    const currentLocation = this.props.location.pathname;

    locationsWithSidebar.forEach(location => {
      if (this.props.location.pathname === location
        || location === currentLocation.substring(0, currentLocation.lastIndexOf('/'))) {
        shouldDisplay = true;
      }
    });

    return shouldDisplay;
  };

  isUserLoggedIn = (user: User) => user.id !== '';

  onSwitchChange = (checked: boolean) => {
    this.setState({ isMobileSiderDisplayed: checked });
  };
}

export default withRouter(App);
