type Props = {
  quoteValue: number;
};

const AdminSaleHasBeenMade = ({ quoteValue }: Props) => (
  <div className="dashboard-columns__right">
    <div className="dashboard-infocard marks-wrap marks-wrap--green">
      <div className="popup__header">
        <div className="popup__title">
          Sale has been
          <mark>Made</mark>
        </div>
      </div>
      <div className="popup__content">
        <div
          className="popup__price popup__price--small popup__price--green"
        >
          $
          {quoteValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="popup__footer">
        <div className="popup__text">
          Waiting for user to sign agreement
        </div>
      </div>
    </div>
  </div>
);

export default AdminSaleHasBeenMade;
