import React, { Component } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import { TransactionTypeChooser } from '../Choosers';
import { ControlledFormInput, CurrencyInputNumber } from '../../common/Input';
import FormElement from '../Choosers/FormElement';
import { RedirectToDescriptionSection } from '../RedirectTo';
import { actionTypes, quoteStore } from '../../../store';

type Props = { subCategory: string } & RouteComponentProps & WithCategoriesProps;

type State = {
  transactionType?: TransactionType;
  designerName: string;
  materialType: string;
  originalPrice: number;
  groupType?: TransactionType;
};

class DesignerForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      transactionType: undefined,
      designerName: '',
      materialType: '',
      originalPrice: 0,
    };
  }

  componentDidMount() {
    this.handleValidPackageType();
  }

  render() {
    const {
      originalPrice,
      materialType,
      designerName,
      transactionType,
      groupType,
    } = this.state;

    const store = quoteStore.getState();

    return (
      <>
        <Container className="container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TransactionTypeChooser
                selectedType={groupType || transactionType}
                quoteArray={store.quotes}
                onTransactionTypeChange={this.handleTransactionTypeChange}
              />
              <FormElementContainer>
                <FormElement title="Who is the Designer?">
                  <ControlledFormInput
                    value={designerName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleDesignerNameChange(e.target.value)}
                    placeholder="Designer Name"
                  />
                </FormElement>
                <FormElement title="What type of metal/material is the item made of?">
                  <ControlledFormInput
                    value={materialType}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleMaterialTypeChange(e.target.value)}
                    placeholder="Silver, Gold, Platinium"
                  />
                </FormElement>
                <FormElement
                  className="jewelry-designer-original-price-element"
                  title="What is the original price of the item? (optional)"
                  styleChildren={{ maxWidth: '90%' }}
                >
                  <CurrencyInputNumber
                    value={originalPrice}
                    onChange={e => this.handleOriginalPriceChange(e)}
                  />
                </FormElement>
              </FormElementContainer>
            </Col>
          </Row>
        </Container>
        <RedirectToDescriptionSection
          handleValidateForm={this.handleValidateForm}
          onClick={this.handleFinish}
          category="jewelry/designer"
        />
      </>
    );
  }

  handleValidPackageType = () => {
    const { quotes } = quoteStore.getState();
    if (quotes.length === 0)
      return;
    const type = quotes[0].generalInfo.transactionType;
    this.setState({ groupType: type, transactionType: type });
  };

  handleValidateForm = (): boolean => {
    const {
      transactionType, designerName, materialType,
    } = this.state;

    if (!transactionType) {
      message.warn('Transaction type not selected');
      return false;
    }

    if (!designerName) {
      message.warn('Designer name cannot be empty');
      return false;
    }

    if (!materialType) {
      message.warn('Material type cannot be empty');
      return false;
    }
    return true;
  };

  handleFinish = () => {
    if (!this.handleValidateForm()) return;

    const {
      designerName, materialType, originalPrice, transactionType, groupType,
    } = this.state;
    const { categories } = this.props;
    quoteStore.dispatch({
      type: actionTypes.ADD_GENERAL_INFO,
      generalInfo: {
        categories, designerName, materialType, originalPrice, transactionType: groupType || transactionType,
      },
    });
  };

  handleOriginalPriceChange = (e: number) => this.setState({ originalPrice: e });

  handleDesignerNameChange = (e: string) => this.setState({ designerName: e });

  handleMaterialTypeChange = (e: string) => this.setState({ materialType: e });

  handleTransactionTypeChange = (transactionType: TransactionType) => {
    this.setState(prevState => {
      if (prevState.transactionType === transactionType) {
        return { transactionType: undefined };
      }
      return {
        transactionType,
      };
    });
  };
}
const Container = styled.div`
    background-color: #ffffff;
    .currency-input {
        width: 100%;
        margin-top: 20px;
    }

    .form-element {
        width: 100%;
        h2 {
            width: 80%;
        }
    }
    .ant-input {
        width: 100%;
    }
    .flatware-original-price-element {
        margin-bottom: 180px;
    }
`;

const FormElementContainer = styled.div`
    width: max-content;
    margin-bottom: 180px;
`;

export default DesignerForm;
