import { Modal } from 'antd';
import styled from 'styled-components';
import { EmailTemplateTypes } from '../../../constants/mocks';
import { SecondaryButton } from '../../common/Button';

type Props = {
  onDelete: (id: string) => void;
  onCancel: () => void;
  emailTemplate: EmailTemplate;
  visible: boolean
};

const EmailTemplateCreateModal = ({
  emailTemplate, visible, onDelete, onCancel,
}: Props) => (
  <Modal
    title="Delete Email Template"
    visible={visible}
    footer={[
      <SecondaryButton key="ok" onClick={() => onDelete(emailTemplate.id)}>
        Delete
      </SecondaryButton>,
      <SecondaryButton key="back" onClick={onCancel}>
        Cancel
      </SecondaryButton>,
    ]}
    onCancel={onCancel}
  >
    <StyledSpan>Are you sure you want to delete following email template?</StyledSpan>
    <p style={{ marginTop: '5%' }}>
      <StyledSpan>Email type:</StyledSpan>
      {EmailTemplateTypes.find(f => f.value === emailTemplate.type)?.text}
    </p>
    <p style={{ marginTop: '5%' }}>
      <StyledSpan>Email subject:</StyledSpan>
      {emailTemplate.subject}
    </p>
    <p style={{ marginTop: '5%' }}>
      <StyledSpan>Email title:</StyledSpan>
      {emailTemplate.title}
    </p>
    <p style={{ marginTop: '5%' }}>
      <StyledSpan>Email content:</StyledSpan>
      {emailTemplate.content}
    </p>
  </Modal>
);

const StyledSpan = styled.span`
  font-weight: bold;
  margin-right: 1%
`;

export default EmailTemplateCreateModal;
