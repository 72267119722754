import styled, { css } from 'styled-components';

const ButtonCaps14 = css`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 40px;
    text-transform: uppercase;
`;

const ButtonCaps24 = css`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
`;

const Title = css`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
`;

const TitleDiv = styled.div`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    color: #526B7C;
`;

const Text = css`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #8fa1ac;
`;

const Title20 = css`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #4f6470;
`;

const Title20Div = styled.div`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #31393E;
`;

const Title40 = css`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 63px;
    color: #31393e;
`;

const Title2Aeonik = css`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
`;

const Tab = css`
    font-family: 'Aeonik', sans-serif;
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: 500;
`;

const Caption = css`
    font-family: 'Aeonik', sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
`;

const MenuItem = css`
    font-family: 'Aeonik', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.4px;
    text-align: left;
`;

const Body = css`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
`;

const Body20 = css`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
`;

const TableThead = css`
    font-family: 'Aeonik', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
`;

const text = {
  'buttom-caps-14': ButtonCaps14,
  'buttom-caps-24': ButtonCaps24,
  title: Title,
  titleDiv: TitleDiv,
  body: Body,
  'body-20': Body20,
  'title-20-Aeonik': Title2Aeonik,
  'title-20': Title20,
  title20Div: Title20Div,
  'title-40': Title40,
  caption: Caption,
  tab: Tab,
  'menu-item': MenuItem,
  'table-thead': TableThead,
  text: Text,
};

export default text;
