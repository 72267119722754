/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { interceptors } from 'utils';
import { CustomModal } from '../../../custom';
import { api } from '../../../api';
import ShippingForm from './ShippingForm';
import fonts from '../../../constants/fonts';

const { mainFont } = fonts.fonts;

type Props = {
  isVisible: boolean;
  onVisibleChange: (state: boolean) => void;
  requestId: string;
  onFinish: () => void;
};

const CreateShippingLabelModal: React.FC<Props> = ({
  isVisible, onVisibleChange, requestId, onFinish,
}: Props) => {
  const [isSubmittingShippingLabel, setIsSubmittingShippingLabel] = useState(false);

  const handleFinish = (values: any) => {
    setIsSubmittingShippingLabel(true);

    api.post('/shipment/create', {
      values,
      requestId,
    }).then(res => res.data)
      .then((linkToLabel: string) => {
        window.open(linkToLabel, '_blank')?.focus();
        onFinish();
        setIsSubmittingShippingLabel(false);
        onVisibleChange(false);
      })
      .catch((error: AxiosError) => {
        setIsSubmittingShippingLabel(false);
        if (error?.response?.status === 503) {
          CustomModal.displayCustomWarning({
            title: error.response?.data.message,
            content: error.response?.data.data,
          });
        } else {
          interceptors.interceptPostErrorMessages(error.response?.data.errorMessages);
        }
      });
  };

  return (
    <div className={`popup ${isVisible && 'popup--shown'}`} style={{ fontFamily: mainFont }}>
      <div className="popup__wrap item-details">
        <div className="item-details__header">
          <div className="item-details__title">Provide your shipping details</div>
          <div className="popup__close item-details__close" onClick={() => onVisibleChange(false)} />
        </div>
        <ShippingForm onFinish={handleFinish} isSubmittingShippingLabel={isSubmittingShippingLabel} />
      </div>
    </div>
  );
};

export default CreateShippingLabelModal;
