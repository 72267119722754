/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Form } from 'antd';
import Spinner from '../../../common/Spinner';

type Props = {
  isVisible: boolean;
  isAddingTrackingBackNumber: boolean;
  onCancel: () => void;
  onSubmit: (values: TrackingBackNumber) => void;
};

const AdminItemReceivedShippingBackModal = ({
  isVisible, isAddingTrackingBackNumber, onCancel, onSubmit,
}: Props) => (
  <Form
    className="request-payment-form__body popup__input-wrap"
    onFinish={onSubmit}
  >
    <div className={`popup ${isVisible && 'popup--shown'} marks-wrap marks-wrap--green`}>
      <div className="popup__wrap">
        <a className="popup__close" onClick={onCancel} />
        <div className="popup__inner">
          <div className="popup__header">
            <div className="popup__text popup__text--big">
              This shipment will be
              <mark>confirmed</mark>
              .
              <br />
              Please provide tracking number.
            </div>
          </div>
          <div className="popup__content">
            <div className="request-payment-form-unit">
              <div className="request-payment-form-unit__body">
                <Form.Item
                  name="trackingBackNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Tracking number is required',
                    },
                  ]}
                >
                  <input
                    className="request-payment-form-unit__input"
                    type="text"
                    placeholder="Tracking number"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="popup__footer">
            <div className="popup__buttons popup__buttons--horizontal">
              <a
                className="
                  popup__btn
                  btn btn--bordered btn--bordered-dark
                  popup__btn--static-width
                "
                onClick={onCancel}
              >
                Go Back
              </a>
              <button className="popup__btn btn btn--green popup__btn--static-width" type="submit">
                {
                  isAddingTrackingBackNumber
                    ? <Spinner loaderSize={{ width: 20, height: 20 }} tip={' '} style={{ width: 50, marginTop: 20 }} />
                    : '  Yes, Accept'
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
);

export default AdminItemReceivedShippingBackModal;
