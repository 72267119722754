import styled from 'styled-components';
import FormElement from './FormElement';
import SizeCard from './SizeCard';

type Props = {
  currentSizes: SizeType;
  handleSumChange: (key: string, type: 'INCEREMENT' | 'DECREMENT') => void;
};

const SizeChooser = ({ currentSizes, handleSumChange }: Props) => {
  const displaySizeCards = () => Object.entries(currentSizes).map(([key, value]) => (
    <SizeCard size={key} amount={value} handleSumChange={handleSumChange} />
  ));

  return (
    <Container>
      <FormElement title="What is the size?">{displaySizeCards()}</FormElement>
    </Container>
  );
};

const Container = styled.div`
    margin-bottom: 112px !important;
`;

export default SizeChooser;
