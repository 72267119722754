/* eslint-disable max-len */
import fonts from '../../../../constants/fonts';

const { mainFont } = fonts.fonts;

type Props = {
  quoteValue: number;
};

const ReceivedItemCounterOfferByUser = ({ quoteValue }: Props) => (
  <div style={{ fontFamily: mainFont }}>
    <div className="dashboard-columns">
      <div className="dashboard-columns__left">
        <div className="dashboard-card dashboard-card--green dashboard-card--huge-text">
          <div className="dashboard-card__title">Thank you!</div>
          <div className="dashboard-card__text">Thank you for sending your counter offer, our team will review and let you know if we accept your offer.</div>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 36.6668C18.1591 36.6668 16.6667 35.1744 16.6667 33.3335H23.3334C23.3334 35.1744 21.841 36.6668 20 36.6668ZM33.3334 31.6668H6.66669V28.3335L10 26.6668V17.5002C10 11.7302 12.3684 7.9885 16.6667 6.96683V3.3335H21.6667C20.5799 4.77339 19.9946 6.52953 20 8.3335C20 8.75246 20.0312 9.17084 20.0934 9.58516H20C17.9664 9.45345 16.005 10.3629 14.7917 12.0002C13.7543 13.6411 13.2452 15.5608 13.3334 17.5002V28.3335H26.6667V17.5002C26.6667 17.1485 26.655 16.8102 26.6317 16.5002C27.7341 16.7279 28.871 16.7319 29.975 16.5118C29.9934 16.8635 30 17.1985 30 17.5118V26.6668L33.3334 28.3335V31.6668ZM28.3334 13.3335C27.3263 13.3347 26.3426 13.0308 25.5117 12.4618C23.548 11.1195 22.8018 8.5795 23.7275 6.38834C24.6531 4.19717 26.9943 2.96147 29.3257 3.43356C31.657 3.90565 33.3331 5.95483 33.3334 8.3335C33.3334 11.0949 31.0948 13.3335 28.3334 13.3335Z" />
          </svg>
        </div>
        <div className="dashboard-card dashboard-card--smaller-text">
          <div className="dashboard-card__title">What’s Next?</div>
          <div className="dashboard-card__text">Please wait while our experts review your counter offer. We will notify you once we have an update.</div>
        </div>
      </div>
      <div className="dashboard-columns__right">
        <div className="dashboard-infocard marks-wrap marks-wrap--accent">
          <div className="popup__header">
            <div className="popup__title">Thank you for sending your counter offer!</div>
          </div>
          <div className="popup__content">
            <div className="popup__image popup__image--green">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18C32.9909 26.2805 26.2805 32.9909 18 33ZM17.976 30H18C24.6251 29.9934 29.9913 24.6191 29.988 17.994C29.9847 11.3689 24.6131 6.00001 17.988 6.00001C11.3629 6.00001 5.99131 11.3689 5.988 17.994C5.98469 24.6191 11.3509 29.9934 17.976 30ZM15 25.5L9 19.5L11.115 17.385L15 21.255L24.885 11.37L27 13.5L15 25.5Z" />
              </svg>
            </div>
          </div>
          <div className="popup__footer">
            <div className="popup__title">
              Offer Value:
              <br />
              <mark>
                $
                {quoteValue.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </mark>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ReceivedItemCounterOfferByUser;
