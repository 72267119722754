import styled from 'styled-components';
import { MetalCard } from '../../Cards';
import FormElement from './FormElement';
import { data } from '../../../constants';

type Props = {
  metal: string;
  onMetalChange: (value: string) => void;
  title?: string;
};

const MetalTypeChooser = ({ metal, onMetalChange, title }: Props) => (
  <FormElement title={title || 'What type of metal is it?'}>
    <MetalCards className="metal-cards">
      {data.METAL_NAMES.map(m => (
        <MetalCard
          isSelected={metal === m.name}
          title={m.name}
          onClick={onMetalChange}
          color={m.color}
          hasCircle={m.hasCover}
        />
      ))}
    </MetalCards>
  </FormElement>
);

const MetalCards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: inherit;
`;

export default MetalTypeChooser;
