import * as actionTypes from './actionTypes';

type State = {
  isNavbarVisible: boolean
};

type OptionAction = {
  type: string;
  isNavbarVisible: boolean
};

const reducer = (state: State = { isNavbarVisible: true }, action: OptionAction): State => {
  switch (action.type) {
    case actionTypes.CHANGE_NAVBAR_VISIBILITY:
      return {
        ...state,
        isNavbarVisible: action.isNavbarVisible,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
