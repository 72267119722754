/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import dayjs from 'dayjs';

type Props = {
  updatedAt: Date;
  context?: QuoteContext;
  onConfirm: () => void;
};

const AdminLoanClosed = ({ updatedAt, context, onConfirm }: Props) => (
  <div className="dashboard-columns__right">
    <div className="dashboard-infocard">
      <div className="popup__header">
        <div className="popup__title">Loan Closed</div>
        {
          context?.shippingBackTrackingNumber && (
            <div className="popup__title">Item(s) sent back</div>
          )
        }
        <div className="popup__text">
          {'Date of closure: '}
          {dayjs(updatedAt).format('MM-DD-YYYY')}
        </div>
      </div>
      <div className="popup__content">
        <div className="popup__image popup__image--green">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.98931 13.3333H7.99998C10.9445 13.3303 13.3295 10.9417 13.328 7.99725C13.3265 5.05278 10.9391 2.66659 7.99465 2.66659C5.05017 2.66659 2.66279 5.05278 2.66131 7.99725C2.65984 10.9417 5.04484 13.3303 7.98931 13.3333ZM6.66665 11.3333L3.99998 8.66659L4.93998 7.72659L6.66665 9.44659L11.06 5.05325L12 5.99992L6.66665 11.3333Z"
            />
          </svg>
        </div>
      </div>
      <div className="popup__footer">
        <div className="popup__buttons popup__buttons--wide">
          {
            !context?.shippingBackTrackingNumber && (
              <a className="popup__btn btn btn--dark" onClick={onConfirm}>
                CONFIRM SHIPPMENT
              </a>
            )
          }
        </div>
      </div>
    </div>
  </div>
);

export default AdminLoanClosed;
