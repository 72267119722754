/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import FormElement from '../GeneralInfo/Choosers/FormElement';

type Props = {
  title: string;
  values: [string, string];
  value: boolean;
  onChange: (value: boolean) => void;
};

const Switch = ({
  title, values, value, onChange,
}: Props) => (
  <>
    <FormElement title={title}>
      <div className="request-form-sizes">
        <label className="request-form-label" style={{ width: 150 }}>
          <input type="radio" name="other" onClick={() => onChange(true)} checked={value} />
          <span className="request-form-label__body request-form-other">{values[0]}</span>
        </label>
        <label className="request-form-label" style={{ width: 150 }}>
          <input type="radio" name="other" onClick={() => onChange(false)} checked={!value} />
          <span className="request-form-label__body request-form-other">{values[1]}</span>
        </label>
      </div>
    </FormElement>
  </>
);

export default Switch;
