/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import EMAIL_NOTIFICATIONS_STAGES from '../../../constants/emailNotificationsStages';

const { AV_1A_ACCEPT, AV_1B_REJECT } = EMAIL_NOTIFICATIONS_STAGES;

type Props = {
  value: number;
  onChangeValue: (e: any) => void;
  onFinalOffer: (e: any) => void;
  onSubmit: (typeNotification: string, emailNotifocation: string) => void;
};

const AdminRequestSent = ({
  value, onChangeValue, onFinalOffer, onSubmit,
}: Props) => (
  <div className="dashboard-columns__right">
    <div className="dashboard-infocard">
      <div className="popup__header">
        <div className="popup__title">Make Preliminary Offer</div>
      </div>
      <div className="popup__content">
        <div className="form-input">
          <input type="number" placeholder="$0" value={value} onChange={onChangeValue} />
          <div className="form-input__footer">
            <label className="checkbox checkbox--small">
              <input type="checkbox" name="checkbox" onChange={onFinalOffer} />
              <span>Final offer</span>
            </label>
          </div>
        </div>
      </div>
      <div className="popup__footer">
        <div className="popup__buttons popup__buttons--wide">
          <a className="popup__btn btn btn--dark" onClick={() => onSubmit('ACCEPT', AV_1A_ACCEPT)}>Send preliminary Offer</a>
          <a className="popup__btn btn btn--red" onClick={() => onSubmit('REJECT', AV_1B_REJECT)}>Reject Request</a>
        </div>
      </div>
    </div>
  </div>
);

export default AdminRequestSent;
