import React from 'react';
import dayjs from 'dayjs';
import Table from './common/Table';
import { Button } from './common';

const { ActionButton } = Button;

type Props = {
  dataSource?: any[];
  callback: (id: String) => void;
  customColumns?: any[];
};

const ResourceTable = ({ dataSource, callback, customColumns }: Props) => {
  const columns = [
    { title: 'Number', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Items', dataIndex: 'items', key: 'items' },
    {
      title: 'Last proposal',
      dataIndex: 'currentValue',
      key: 'currentValue',
      render: (value: string) => (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(value))}
        </>
      ),
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updated_at',
      render: (date: Date) => <>{dayjs(date).format('MM.DD.YYYY h:mm A')}</>,
    },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Admin action', dataIndex: 'action', key: 'action' },
    {
      title: '',
      key: 'actions',
      render: (record: any) => (
        <ActionButton
          onClick={() => callback(record.id)}
        >
          Details
        </ActionButton>
      ),
    },
  ];

  return <Table dataSource={dataSource || []} columns={customColumns || columns} />;
};

export default ResourceTable;
