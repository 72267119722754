import styled from 'styled-components';
import { upperFirst } from 'lodash';
import FormCardBase from './FormCardBase';

type Props = {
  title: string;
  onClick: (e: string) => void;
  isSelected: boolean;
  color?: string;
  hasCircle: boolean;
};

const MetalCard = ({
  title, isSelected, onClick, color, hasCircle,
}: Props) => (
  <MetalCardContainer className="metal-container">
    <FormCardBase
      type={title}
      className="metal-size-card"
      isSelected={isSelected}
      onClick={() => onClick(title)}
    >
      <>
        {hasCircle && <Circle color={color} />}
        {' '}
        {upperFirst(title)}
      </>
    </FormCardBase>
  </MetalCardContainer>
);

const MetalCardContainer = styled.div``;

type CircleProps = {
  color?: string;
};

const Circle = styled.div<CircleProps>`
    width: 40px;
    height: 40px;
    background: ${props => props.color};
    background-blend-mode: lighten, normal;
    box-shadow: inset 4px -6px 24px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
`;

export default MetalCard;
