import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { runMain } from '../assets/js/main';

export default function AboutView() {
  useEffect(() => {
    runMain();
  }, []);

  return (
    <main className="main">
      <div className="page-descr page-descr--pb">
        <div className="section section-descr-page">
          <div className="container">
            <div className="mobile-container">
              <h1
                className="section__title section-descr-page__title section__title--lg page-descr__title page-descr__title--static"
                data-aos="fade-down"
                data-aos-delay={300}
                data-aos-duration={800}
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                data-aos-anchor-placement="center-bottom"
              >
                <span>About</span>
              </h1>
              <p
                className="
                  section__title section__title--lg
                  section-descr-page__title page-descr__title page-descr__title--top
                  page-descr__title--like-static page-descr__title--right-image
                "
                data-aos="fade-down"
                data-aos-delay={300}
                data-aos-duration={800}
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                data-aos-anchor-placement="center-bottom"
              >
                <span>About</span>
              </p>
            </div>
          </div>
          <div className="row page-descr__back-row no-gutters">
            <div
              className="col-lg-6 offset-lg-6"
              data-aos="fade-down"
              data-aos-delay={0}
              data-aos-duration={800}
              data-aos-easing="ease-in-out"
              data-aos-once="true"
              data-aos-mirror="true"
              data-aos-anchor="#header"
            >
              <div className="page-descr__bg-image-wrap page-descr__bg-image-wrap--right">
                <picture>
                  <source type="image/webp" media="(min-width: 1900px)" srcSet="images/chess/about-lg@2x.webp" />
                  <source type="image/jpg" media="(min-width: 1900px)" srcSet="images/chess/about-lg@2x.jpg" />
                  <source type="image/webp" media="(max-width: 600px)" srcSet="images/chess/about-lg.webp" />
                  <source type="image/jpg" media="(max-width: 600px)" srcSet="images/chess/about-lg.jpg" />
                  <source type="image/webp" srcSet="images/chess/about-lg.webp, images/chess/about-lg@2x.webp 2x" />
                  <img
                    className="page-descr__bg-image"
                    alt="work"
                    src="images/chess/about-lg.jpg"
                    srcSet="images/chess/about-lg.jpg, images/chess/about-lg@2x.jpg 2x"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-8">
              <div className="row">
                <div className="col-lg-11">
                  <div className="mobile-container">
                    <div
                      className="page-descr__content page-descr__content--page"
                      data-aos="fade-up"
                      data-aos-delay={150}
                      data-aos-duration={800}
                      data-aos-easing="ease-in-out"
                      data-aos-once="true"
                      data-aos-mirror="true"
                      data-aos-anchor="#header"
                    >
                      <p className="page-descr__subtitle page-descr__subtitle--lg">Serving South Jersey since 1973</p>
                      <div className="page-descr__text">
                        <p>
                          We buy, pawn, and sell a wide variety of items, including jewelry, coins,
                          electronics, musical instruments, tools, and more. No matter what you bring to E&amp;B,
                          you can rest assured that it will be in good hands. We have close to 50 years of experience
                          in the industry, and understand how important our customers’ items are. That’s why we strive
                          to provide expert appraisals quickly, so you can be sure your items will be handled with care,
                          while getting the money you need today.
                        </p>
                        <p>
                          We extend loans, buy and sell items, and even offer repair services. To learn more
                          about our services, visit our&nbsp;
                          <Link to="/faq">FAQ</Link>
                          &nbsp;
                          page or get in touch with one of our representatives today.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="page-descr__line page-descr__line--top"
          src="images/line-1-gold-reverse.svg"
          alt="line"
          data-aos="line-clip-patch"
          data-aos-delay="000"
          data-aos-duration={1500}
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          data-aos-anchor-placement="center-bottom"
        />
        <img
          className="page-descr__line"
          src="images/line-2-gold-reverse.svg"
          alt="line"
          data-aos="line-clip-patch"
          data-aos-delay={500}
          data-aos-duration={1500}
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          data-aos-anchor-placement="center-bottom"
        />
      </div>
      <div className="section section-story">
        <img
          className="section-story__line section-story__line--top"
          src="images/line-1-gold-story.svg"
          alt="line"
          data-aos="line-clip-patch-sm"
          data-aos-delay="000"
          data-aos-duration={1500}
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          data-aos-anchor-placement="center-bottom"
        />
        <img
          className="section-story__line"
          src="images/line-2-gold-story.svg"
          alt="line"
          data-aos="line-clip-patch-sm"
          data-aos-delay={500}
          data-aos-duration={1500}
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          data-aos-anchor-placement="center-bottom"
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="mobile-container">
                <h2 style={{ color: '#4f6470' }} className="section__title section-story__title">
                  Read our
                  <br />
                  short &#160;
                  <span className="gradient-color">story</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mobile-container">
                <div
                  className="section-story__text section-story__text--mt"
                  data-aos="fade-up"
                  data-aos-delay={150}
                  data-aos-duration={800}
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="center-bottom"
                >
                  <p>
                    We’re a family owned business that was founded on one principle—our
                    customers come first. Over the years, we’ve built long-lasting, trustworthy
                    relationships with our clients, and have continued to provide friendly
                    support to our community through our loan and purchasing services.
                    At E&amp;B Co., you’ll always feel at home—whether you’re in-store or online.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 section-story__col-image">
              <picture
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-duration={800}
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                data-aos-mirror="true"
                data-aos-anchor-placement="center-bottom"
              >
                <source type="image/webp" media="(max-width: 600px)" srcSet="images/chess/ring-inspection.webp" />
                <source type="image/jpg" media="(max-width: 600px)" srcSet="images/chess/ring-inspection.jpg" />
                <source
                  type="image/webp"
                  srcSet="images/chess/ring-inspection.webp, images/chess/ring-inspection@2x.webp 2x"
                />
                <img
                  className="section-story__image"
                  alt="ring-inspection"
                  src="images/chess/ring-inspection.jpg"
                  srcSet="images/chess/ring-inspection.jpg, images/chess/ring-inspection@2x.jpg 2x"
                />
              </picture>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="mobile-container">
                <div
                  className="section-story__text"
                  data-aos="fade-up"
                  data-aos-delay={150}
                  data-aos-duration={800}
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="center-bottom"
                >
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-cta-box">
        <div className="container">
          <div
            className="box box--fixed-height-lg box--gold"
            data-aos="zoom-in"
            data-aos-delay={0}
            data-aos-duration={800}
            data-aos-easing="ease-in-out"
            data-aos-once="true"
            data-aos-mirror="true"
            data-aos-anchor-placement="center-bottom"
          >
            <div className="box__body text-center box__body--center-content">
              <div className="box__body-in box__body-in--px">
                <p className="box__title box__title--lg box__title--mb-xl">Have an item to sell?</p>
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-md-8 col-sm-10">
                    <p className="box__text box__text--mb-lg box__text--lg">
                      Our experts will appraise and evaluate your items and offer a buy price and a 90-day loan price.
                    </p>
                  </div>
                </div>
                <Link className="btn btn--secondary btn--lg btn--px-xl" to="/categories">
                  get an instant offer
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
