type Props = {
  payment: ConfirmPayment | undefined;
};

const PaymentInformation = ({ payment }: Props): JSX.Element => (
  <div className="tracking-info">
    <div className="tracking-info__body">
      <div className="tracking-info__title">Payment Information:</div>
      {
        payment?.type === 'paypal'
          ? (
            <>
              <div className="tracking-info__unit">
                Paypal:
                <span>
                  {` ${payment.email}`}
                </span>
              </div>
              <div className="tracking-info__unit">
                {payment?.paypalTransactionId ? `Paypal Transaction ID: ${payment?.paypalTransactionId}` : '-'}
              </div>
              <div className="tracking-info__unit">-</div>
              <div className="tracking-info__unit">-</div>
            </>
          ) : (
            <>
              <div className="tracking-info__unit">
                Check payable to:
                <span>
                  {` ${payment?.payableTo}`}
                </span>
              </div>
              <div className="tracking-info__unit">
                First address:
                <span>
                  {` ${payment?.firstAddress}`}
                </span>
              </div>
              {
                payment?.secondAddress && (
                  <div className="tracking-info__unit">
                    Second address:
                    <span>
                      {` ${payment.secondAddress}`}
                    </span>
                  </div>
                )
              }
              <div className="tracking-info__unit">
                State:
                <span>
                  {` ${payment?.stateOfProvinceCode}`}
                </span>
              </div>
              <div className="tracking-info__unit">
                City:
                <span>
                  {` ${payment?.city}`}
                </span>
              </div>
              {
                payment?.phoneNumber && (
                  <div className="tracking-info__unit">
                    Phone:
                    <span>
                      {` ${payment?.phoneNumber}`}
                    </span>
                  </div>
                )
              }
              {
                payment?.checkNo && (
                  <div className="tracking-info__unit">
                    Check No.:
                    <span>
                      {` ${payment.checkNo}`}
                    </span>
                  </div>
                )
              }
            </>
          )
      }
    </div>
  </div>
);

export default PaymentInformation;
