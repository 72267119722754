/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { upperFirst } from 'lodash';
import { runMain } from '../../assets/js/main';

type Props = {
  title: string;
  imgCover: string;
  quote?: boolean;
  hasSubcategory?: boolean;
  id: string;
  disabled?: boolean;
};

const CategoryCard = ({
  title, imgCover, hasSubcategory, id,
}: Props) => {
  const history = useHistory();

  useEffect(() => {
    runMain();
  }, []);

  const getPathNames = () => history.location.pathname.split('/');

  const getCategories = () => {
    const pathnames = getPathNames();
    if (pathnames.find(c => c === id)) {
      const temp = [...pathnames];
      temp.pop();
      return temp.slice(1).join('/');
    }
    return `${pathnames.slice(1).join('/')}/${id.replace(' ', '').toLowerCase()}`;
  };

  const onClick = () => {
    const categories = getCategories();
    const urlCategories = categories.replace('categories', '').replace(' ', '');

    if (!hasSubcategory) {
      return history.push(`/general-information${urlCategories}`);
    }
    return history.push(`/categories${urlCategories}`);
  };

  return (
    <div key={title} className="col-lg-4 col-sm-6" style={{ cursor: 'pointer', fontFamily: 'Poppins' }}>
      <div
        className="image-box image-box--mb"
        data-aos="fade-up"
        data-aos-delay={0}
        data-aos-duration={800}
        data-aos-easing="ease-in-out"
        data-aos-once="true"
        data-aos-mirror="true"
        data-aos-anchor-placement="center-bottom"
      >
        <picture>
          <source type="image/webp" srcSet={imgCover} />
          <img
            className="image-box__thumbnail"
            alt={upperFirst(title)}
            src={imgCover}
            srcSet={imgCover}
          />
        </picture>
        <div className="image-box__body">
          <p className="request-categories-unit__title">{upperFirst(title)}</p>
          <div className="request-categories-unit__btn" onClick={onClick}>
            <svg
              width="25"
              height="13"
              viewBox="0 0 25 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.2257 4.98735L0 4.98735V6.98735L20.2531 6.98735L16.7645 10.5699L18.1598 12.0028L23.3061 6.71782L24.0038 6.00138L23.3061 5.28494L18.1598 0L16.7645 1.43288L20.2257 4.98735Z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
