import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash';
import { Pagination } from 'antd';
import { Input, Button, Spinner } from '../components/common';
import ResourceTable from '../components/ResourceTable';
import { api } from '../api';

const { SearchInput } = Input;
const { ActionButton } = Button;

type Props = {} & RouteComponentProps;

type State = {
  actions: Action[],
  filter: string,
  current: number,
  limit: number,
  total: number,
  isLoading: boolean,
};

export default class ActionHistoryView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      actions: [],
      filter: '',
      current: 1,
      limit: 5,
      total: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchActions();
  }

  render() {
    const {
      actions,
      filter,
      limit,
      current,
      isLoading,
      total,
    } = this.state;

    if (isLoading)
      <Spinner />;

    return (
      <body className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <form>
              <Filter>
                <SearchInput
                  value={filter}
                  onChange={e => this.handleFilterChange(e)}
                  placeholder="Search by or number"
                />
                <ActionButton
                  type="submit"
                  className="search-button"
                  onClick={() => this.fetchActions()}
                >
                  Search
                </ActionButton>
              </Filter>
            </form>
            <ResourceTable
              customColumns={this.customColumns}
              dataSource={actions}
              callback={this.navigateToDetailsView}
            />
            <CPagination
              showSizeChanger
              onShowSizeChange={this.onShowSizeChange}
              onChange={this.onChange}
              current={current}
              pageSizeOptions={['2', '5', '10', '20']}
              pageSize={limit}
              total={total}
            />
          </div>
        </div>
      </body>
    );
  }

  onShowSizeChange = (_: number, limit: number) => this.setState({ limit }, () => this.fetchActions());

  onChange = (page: number) => this.setState({ current: page }, () => this.fetchActions());

  customColumns = [
    {
      title: 'Number', key: 'id', dataIndex: 'customId',
    },
    {
      title: 'Name', dataIndex: 'name', key: 'name', render: (name: string) => <>{upperFirst(name)}</>,
    },
    {
      title: 'Items', dataIndex: 'items', key: 'items', render: () => <>1</>,
    },
    {
      title: 'Last proposal',
      key: 'currentValue',
      render: (record: any) => (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(record.context.quoteValue || 0))}
        </>
      ),
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updated_at',
      render: (date: Date) => <>{dayjs(date).format('MM.DD.YYYY h:mm A')}</>,
    },
    {
      title: 'Status', key: 'status', render: (record: Action) => <>{record.context.status}</>,
    },
    {
      title: 'Admin action',
      key: 'action',
      render: (record: Action) => (
        <>
          {
            record.context.isAdminAction
              ? 'Required'
              : 'Not required'
          }
        </>
      ),
    },
    {
      title: '',
      key: 'actions',
      render: (record: Action) => (
        <ActionButton
          onClick={() => this.navigateToDetailsView(record.groupId)}
        >
          Details
        </ActionButton>
      ),
    },
  ];

  fetchActions = () => {
    const { filter, limit, current } = this.state;
    const offset = (current - 1) * (limit || 0);
    this.setState({ isLoading: true });
    api
      .get('/action-history', { params: { filter, limit, offset } })
      .then(res => res.data)
      .then(({ actions, total }) => this.setState({ actions, total }))
      .catch(() => { })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleFilterChange = (e: string) => this.setState({ filter: e });

  navigateToDetailsView = (id: String): void => this.props.history.push(`/request-details/${id}`);
}

const CPagination = styled(Pagination)`
  margin-bottom: 6%;
  margin-top: 1%;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .search-button {
    height: 60px;
    margin-left: 1%;
  }
`;
