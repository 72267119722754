import styled from 'styled-components';
import { colors } from '../../constants';

type Props = {
  message: string
};

const ErrorMessage = ({ message }: Props) => (
  <StyledSpan>
    {message}
  </StyledSpan>
);

const StyledSpan = styled.span`
    color: ${colors.text.red};
    font-weight: bold;
    font-size: larger;
`;

export default ErrorMessage;
