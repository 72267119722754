import { Link, useHistory } from 'react-router-dom';
import { Modal, Button, Space } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { quoteStore, actionTypes } from '../../store';

type Props = {
  isVisible: boolean;
  handleVisibileChange: (state: boolean) => void;
  onClose?: () => void;
  onConfirm?: () => void;
  metalOption: SelectOptions,
};

const PackageWarningModal = ({ isVisible, metalOption, handleVisibileChange }: Props) => {
  const history = useHistory();

  const removePackage = () => {
    quoteStore.dispatch({ type: actionTypes.CLEAR_ITEMS });
    return history.push(`/general-information/bullion/${metalOption}`);
  };

  return (
    <StyledModal
      title={<WarningOutlined />}
      visible={isVisible}
      onCancel={() => handleVisibileChange(false)}
      footer={(
        <Space direction="horizontal">
          <Button onClick={() => removePackage()}>Remove package</Button>
          <Link to="/categories">
            <Button type="primary">Redirect to categories</Button>
          </Link>
        </Space>
)}
    >
      Bullion is not allowed to be an element of a package. You can either remove package or add item from categories
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-title {
    color: #faad14;
  }
`;
export default PackageWarningModal;
