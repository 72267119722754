import { Redirect, Route, RouteProps } from 'react-router';
import { userStore } from '../store';

type Props = {
  roles: Role[],
} & RouteProps;

const ProtectedRoute = ({ component: Component, roles }: Props) => (
  <Route render={props => {
    const currentUser = userStore.getState();
    if (!currentUser.id || !Component)
      return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    if (roles && roles.indexOf(currentUser.role) === -1)
      return <Redirect to={{ pathname: '/' }} />;
    return <Component {...props} />;
  }}
  />
);

export default ProtectedRoute;
