import { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, Pagination } from 'antd';
import {
  Button, Input, Title, Spinner,
} from '../components/common';
import { api } from '../api';
import { Faq } from '../components';

const { SecondaryButton } = Button;
const { ControlledFormTextArea } = Input;

type Props = {};

type State = {
  faq: Faq[],
  current: number,
  limit: number,
  total: number,
  isLoading: boolean,
  isCreatingFaq: boolean,
  newFaqPanel: {
    title: string,
    content: string,
  },
};

class FaqEditView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      current: 1,
      limit: 5,
      total: 0,
      faq: [],
      isLoading: true,
      isCreatingFaq: false,
      newFaqPanel: {
        title: '',
        content: '',
      },
    };
  }

  componentDidMount() {
    this.fetchFaqs();
  }

  render() {
    const {
      faq,
      isCreatingFaq,
      newFaqPanel,
      total,
      current,
      limit,
      isLoading,
    } = this.state;

    if (isLoading)
      return <Spinner />;

    return (
      <>
        <body className="body--dashboard">
          <div className="main main-dashboard">
            <div className="dashboard">
              <Container className="faq-edit-view-container">
                <Row gutter={[32, 40]}>
                  <Col>
                    <Title title="Frequently asked questions" />
                  </Col>
                </Row>
                <Row gutter={[32, 40]}>
                  <Col>
                    <SecondaryButton
                      onClick={() => this.onFaqAdd()}
                    >
                      Add New
                    </SecondaryButton>
                  </Col>
                </Row>
                <Row gutter={[32, 40]}>
                  <Col span={24}>
                    {faq.map((f: Faq) => (
                      <Faq
                        size={total}
                        faq={f}
                        onFaqChange={this.onFaqChange}
                        onFaqDelete={this.onFaqDelete}
                      />
                    ))}
                  </Col>
                </Row>
                {
                  isCreatingFaq
                  && (
                    <>
                      <Row gutter={[32, 32]}>
                        <Col xs={23} sm={12}>
                          <ControlledFormTextArea
                            maxLength={300}
                            placeholder="Title"
                            value={newFaqPanel.title}
                            onChange={e => this.onNewTitleChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[32, 32]}>
                        <Col xs={23} sm={12}>
                          <ControlledFormTextArea
                            maxLength={300}
                            placeholder="Content"
                            value={newFaqPanel.content}
                            onChange={e => this.onNewContentChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[32, 32]}>
                        <Col xs={23} sm={7}>
                          <SecondaryButton
                            onClick={() => this.onFaqPanelSave()}
                          >
                            Save
                          </SecondaryButton>
                        </Col>
                      </Row>
                    </>
                  )
                }
                <Row gutter={[32, 40]} style={{ paddingLeft: '15px' }}>
                  <CPagination
                    showSizeChanger
                    onShowSizeChange={this.onShowSizeChange}
                    onChange={this.onChange}
                    current={current}
                    pageSizeOptions={['2', '5', '10', '20']}
                    pageSize={limit}
                    total={total}
                  />
                </Row>
              </Container>
            </div>
          </div>
        </body>
      </>
    );
  }

  onShowSizeChange = (_: number, limit: number) => this.setState({ limit }, () => this.fetchFaqs());

  onChange = (page: number) => this.setState({ current: page }, () => this.fetchFaqs());

  onFaqPanelSave = () => {
    const { newFaqPanel } = this.state;
    this.setState({ isLoading: true });
    api
      .post('/faq', { ...newFaqPanel })
      .then(() => {
        this.fetchFaqs();
        this.setState({ isCreatingFaq: false, newFaqPanel: { content: '', title: '' } });
      })
      .catch(() => { })
      .finally(() => { });
  };

  onNewContentChange = (e: string) => this.setState(prevState => ({
    newFaqPanel: { ...prevState.newFaqPanel, content: e },
  }));

  onNewTitleChange = (e: string) => this.setState(prevState => ({
    newFaqPanel: { ...prevState.newFaqPanel, title: e },
  }));

  onFaqAdd = () => this.setState(prevState => ({
    isCreatingFaq: !prevState.isCreatingFaq,
  }));

  onFaqChange = (faq: Faq) => {
    api.patch('/faq', { ...faq }).then(() => this.fetchFaqs());
  };

  onFaqDelete = (id: string) => {
    api.delete(`/faq/${id}`).then(() => this.fetchFaqs());
  };

  fetchFaqs = () => {
    const { current, limit } = this.state;
    const offset = (current - 1) * (limit || 0);
    this.setState({ isLoading: true });
    api
      .get('/faq', { params: { offset, limit } })
      .then(res => res.data)
      .then(({ faq, size }: { faq: any[], size: number }) => {
        this.setState({
          faq,
          total: size,
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  };
}

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 0px 15px 15px 15px;

  .ant-row {
    margin-top: 1%;
  }

  .ant-collapse-header {
    background-color: #99ADBA;
    color: #FFFFFF !important;
    font-family: Cambon;
    font-weight: 600;
    letter-spacing: 1.3px;
  }

  .ant-collapse-content {
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
`;

const CPagination = styled(Pagination)`
  margin-bottom: 6%;
  margin-top: 1%;
`;

export default FaqEditView;
