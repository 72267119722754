const searchTree = (element: CategoryTree<string>, matchingTitle: string): CategoryTree<string> => {
  const stack: CategoryTree<string>[] = [];
  let node: CategoryTree<string>;
  stack.push(element);
  while (stack.length > 0) {
    node = stack.pop() || { name: '', treeLevel: 0 };
    if (node.name === matchingTitle) {
      return node;
    }
    if (node.children) {
      stack.push(...node.children);
    }
  }
  return { name: '', treeLevel: 0 };
};

export { searchTree };
