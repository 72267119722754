import FormElement from './FormElement';
import OriginButton from './OriginButton';
import { ControlledFormInput } from '../../common/Input';

type Props = {
  selectedOriginType: OriginCountryType;
  originCountry: string;
  onOriginTypeChange: (originType: OriginCountryType) => void;
  onOriginCountryChange: (originCountry: string) => void;
  originText?: string;
};

const OriginChooser = ({
  selectedOriginType,
  onOriginTypeChange,
  originCountry,
  onOriginCountryChange,
  originText,
}: Props) => {
  const onChange = (e: React.ChangeEvent<any>) => {
    const event = e as React.ChangeEvent<HTMLInputElement>;
    onOriginCountryChange(event.target.value);
  };

  return (
    <>
      <FormElement title={originText || 'What is the origin?'}>
        <div className="request-form-section__body">
          <div className="request-form-section__inner">
            <div className="request-form-sizes">
              <OriginButton
                type="US"
                onOriginTypeChange={onOriginTypeChange}
                selectedOriginCountry={selectedOriginType}
              />
              <OriginButton
                type="Foreign"
                onOriginTypeChange={onOriginTypeChange}
                selectedOriginCountry={selectedOriginType}
              />
            </div>
          </div>
        </div>
      </FormElement>
      {selectedOriginType === 'Foreign' && (
        <FormElement title="What is the origin country?">
          <ControlledFormInput onChange={e => onChange(e)} value={originCountry} />
        </FormElement>
      )}
    </>
  );
};

export default OriginChooser;
