import * as actionTypes from './actionTypes';

type QuoteReducer = {
  generalInfo: any,
  description: string,
  attachments: any[],
  quotes: QuoteModel[],
};

const initialState: QuoteReducer = {
  generalInfo: null,
  description: '',
  attachments: [],
  quotes: [],
};

type QuoteAction = {
  type: string;
} & QuoteModel;

const reducer = (state: QuoteReducer = initialState, action: QuoteAction): QuoteReducer => {
  const {
    attachments, description, generalInfo, quotes,
  } = state;
  switch (action.type) {
    case actionTypes.ADD_GENERAL_INFO:
      return {
        ...state,
        generalInfo: action.generalInfo || null,
      };
    case actionTypes.ADD_DESCRIPTION:
      return {
        ...state,
        description: action.description || '',
      };
    case actionTypes.ADD_ATTACHMENTS:
      return {
        ...state,
        attachments: action.attachments || [],
      };
    case actionTypes.CLEAR_ITEMS:
      return {
        quotes: [],
        attachments: [],
        description: '',
        generalInfo: null,
      };
    case actionTypes.ADD_NEXT_ITEM:
      return {
        ...state,
        quotes: [...quotes, { attachments, description, generalInfo }],
        attachments: [],
        description: '',
        generalInfo: null,
      };
    default:
      return state;
  }
};

export default reducer;
