import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from '../../common';
import FormElement from '../Choosers/FormElement';
import { RedirectToDescriptionSection } from '../RedirectTo';
import { actionTypes, quoteStore } from '../../../store';
import { TransactionTypeChooser } from '../Choosers';

const { ControlledFormInput, CurrencyInputNumber } = Input;

type Props = {
  subCategory: string;
} & RouteComponentProps & WithCategoriesProps;

type State = {
  itemName: string;
  originalPrice: number;
  transactionType?: TransactionType;
  groupType?: TransactionType;
};

class OtherForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      itemName: '',
      originalPrice: 0,
      transactionType: undefined,
      groupType: undefined,
    };
  }

  componentDidMount() {
    this.handleValidPackageType();
  }

  render() {
    const {
      itemName, originalPrice, transactionType, groupType,
    } = this.state;

    const store = quoteStore.getState();

    return (
      <>
        <Container className="container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FormElementContainer className="form-element-container">
                <TransactionTypeChooser
                  mode="other"
                  selectedType={groupType || transactionType}
                  quoteArray={store.quotes}
                  onTransactionTypeChange={this.handleTransactionTypeChange}
                />
                <FormElement title="What is the item?">
                  <ControlledFormInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleItemNameChange(e.target.value)}
                    value={itemName}
                    placeholder="Item Name"
                  />
                </FormElement>
                <FormElement
                  className="flatware-original-price-element"
                  title="What is the original price of the item? (optional)"
                >
                  <CurrencyInputNumber
                    onChange={e => this.handleOriginalPriceChange(e)}
                    value={originalPrice}
                  />
                </FormElement>
              </FormElementContainer>
            </Col>
          </Row>
        </Container>
        <RedirectToDescriptionSection
          handleValidateForm={this.handleValidateForm}
          onClick={this.handleFinish}
          category="other"
        />
      </>
    );
  }

  handleValidPackageType = () => {
    const { quotes } = quoteStore.getState();
    if (quotes.length === 0)
      return;
    const type = quotes[0].generalInfo.transactionType;
    this.setState({ groupType: type, transactionType: type });
  };

  handleValidateForm = (): boolean => {
    const {
      itemName, transactionType,
    } = this.state;

    if (!itemName) {
      message.warn('Item name cannot be empty');
      return false;
    }

    if (!transactionType) {
      message.warn('Transaction type not selected');
      return false;
    }

    return true;
  };

  handleFinish = () => {
    const {
      itemName, originalPrice, transactionType, groupType,
    } = this.state;
    const { categories } = this.props;
    quoteStore.dispatch({
      type: actionTypes.ADD_GENERAL_INFO,
      generalInfo: {
        categories,
        itemName,
        originalPrice,
        transactionType: groupType || transactionType,
      },
    });
  };

  handleTransactionTypeChange = (transactionType: TransactionType) => {
    this.setState(prevState => {
      if (prevState.transactionType === transactionType) {
        return { transactionType: undefined };
      }
      return {
        transactionType,
      };
    });
  };

  handleOriginalPriceChange = (e: number) => this.setState({ originalPrice: e });

  handleItemNameChange = (name: string) => this.setState({ itemName: name });
}

const Container = styled.div`
  background-color: #ffffff;
  .currency-input {
    width: 100%;
    margin-top: 20px;
  }

  .form-element {
    width: 100%;
  }
  .ant-input {
    width: 100%;
  }
  .flatware-original-price-element {
    margin-bottom: 180px;
  }
`;

const FormElementContainer = styled.div`
  width: 100vw;
`;

export default OtherForm;
