import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from '../../constants';

type Props = {
  title: string,
  description: string
};

const Steper = (props: Props) => (
  <StyledSteper>
    <u>{props.title}</u>
    &nbsp; &nbsp;
    <RightOutlined />
    &nbsp; &nbsp;
    <span className="steper-description">
      {props.description}
    </span>
  </StyledSteper>
);

export default Steper;

const StyledSteper = styled.p`
    padding: 20px;
    color: ${colors.text.body};
    margin-bottom: -40px;
    font-size: 16px;
    font-weight: 499;
    margin-top: 10px;

    .steper-description {
        color: ${colors.text.light};
    }
`;
