/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
type Props = {
  isVisible: boolean;
  paymentSuccess: boolean;
  onGoBack: () => void;
};

const PaymentAction = ({ isVisible, paymentSuccess, onGoBack }: Props) => (
  <div style={{ fontFamily: 'Poppins' }}>
    {
      paymentSuccess
        ? (
          <div className={`popup ${isVisible && 'popup--shown'} marks-wrap marks-wrap--green`}>
            <div className="popup__wrap">
              <span className="popup__close" onClick={onGoBack} />
              <div className="popup__inner">
                <div className="popup__header">
                  <div className="popup__text popup__text--big">
                    Your payment was successful.
                  </div>
                </div>
                <div className="popup__content">
                  <div className="popup__image popup__image--green">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18C32.9909 26.2805 26.2805 32.9909 18 33ZM17.976 30H18C24.6251 29.9934 29.9913 24.6191 29.988 17.994C29.9847 11.3689 24.6131 6.00001 17.988 6.00001C11.3629 6.00001 5.99131 11.3689 5.988 17.994C5.98469 24.6191 11.3509 29.9934 17.976 30ZM15 25.5L9 19.5L11.115 17.385L15 21.255L24.885 11.37L27 13.5L15 25.5Z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="popup__footer">
                  <div className="popup__buttons popup__buttons--horizontal">
                    <span
                      className="
                    popup__btn
                    btn btn--bordered btn--bordered-dark
                    popup__btn--static-width
                  "
                      onClick={onGoBack}
                    >
                      Go Back
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`popup ${isVisible && 'popup--shown'} marks-wrap marks-wrap--red`}>
            <div className="popup__wrap">
              <span className="popup__close" onClick={onGoBack} />
              <div className="popup__inner">
                <div className="popup__header">
                  <div className="popup__text popup__text--big">
                    The payment
                    <mark>was not successful.</mark>
                    <br />
                    Please try again.
                  </div>
                </div>
                <div className="popup__content">
                  <div className="popup__image popup__image--red">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 14.6666C5.30237 14.6682 2.86966 13.0439 1.83711 10.5517C0.804556 8.05946 1.37569 5.19063 3.284 3.28391C4.96886 1.59904 7.42461 0.941026 9.72617 1.55773C12.0277 2.17443 13.8255 3.97216 14.4422 6.27373C15.0589 8.5753 14.4009 11.031 12.716 12.7159C11.4676 13.9699 9.76944 14.6723 8 14.6666ZM2.66666 8.11457C2.69821 11.0487 5.09407 13.4063 8.02836 13.3906C10.9626 13.3747 13.333 10.9916 13.333 8.05724C13.333 5.12291 10.9626 2.73975 8.02836 2.7239C5.09407 2.70821 2.69821 5.06575 2.66666 7.99991V8.11457ZM6.27266 10.6666L5.33333 9.72657L7.06 7.99991L5.33333 6.27324L6.27333 5.33324L8 7.05991L9.72666 5.33324L10.6667 6.27324L8.94 7.99991L10.6667 9.72657L9.72733 10.6666L8 8.93991L6.27333 10.6666H6.27266Z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="popup__footer">
                  <div className="popup__buttons popup__buttons--horizontal">
                    <span
                      className="
                      popup__btn
                      btn btn--bordered btn--bordered-dark
                      popup__btn--static-width
                    "
                      onClick={onGoBack}
                    >
                      Go Back
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
  </div>
);

export default PaymentAction;
