import { Modal } from 'antd';
import styled from 'styled-components';
import Photo from '../../assets/images/photo.svg';
import { SecondaryButton, OutlineButton } from '../common/Button';
import { text } from '../../constants';

type Props = {
  isVisible: boolean;
  onClose?: () => void;
  title?: string;
  onSubmit?: () => void;
};

const NoAttachmentsModal = ({
  isVisible, title, onClose, onSubmit,
}: Props) => (
  <StyledModal footer={null} visible={isVisible} onCancel={onClose}>
    <Text>{title}</Text>
    <img src={Photo} alt="" width="64" height="58" />
    <SecondaryButton onClick={onClose}>Upload Photos</SecondaryButton>
    <OutlineButton onClick={onSubmit} className="no-attachment-modal">
      Submit without Photos
    </OutlineButton>
  </StyledModal>
);

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 60px;
        display: flex;
        flex-direction: column;

        img {
            margin: 60px auto;
        }

        .no-attachment-modal {
            margin-top: 10px;
        }
    }
`;

const Text = styled.p`
    ${text.body}
    text-align: center;
`;

export default NoAttachmentsModal;
