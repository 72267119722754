import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import { FileUpload } from './common/Upload';

type Props = {
  content?: string,
  setContent?: (content: string) => void,
  readonly?: boolean,
  fileList?: any[],
  setFileList?: (fileList: any[]) => void
};

const Editor = ({
  content,
  setContent,
  readonly,
  // fileList,
  // setFileList,
}: Props) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  // const onFileListChange = (fileList: any[]) => {
  //   if (setFileList)
  //     setFileList(fileList);
  // };

  return (
    <div className="my-component">
      <ReactQuill
        theme="snow"
        modules={readonly ? { toolbar: false } : modules}
        value={content}
        onChange={setContent}
        readOnly={!setContent || readonly}
      />
      {/* <FileUpload readonly={!setContent || readonly} fileList={fileList || []} onFileListChange={onFileListChange} /> */}
    </div>
  );
};

export default Editor;
