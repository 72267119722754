/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */

type Props = {
  children: JSX.Element | string;
  title: string;
  type: TransactionType;
  quoteArray?: QuoteModel[];
  onTransactionTypeChange: (transactionType: TransactionType) => void;
};

const TransactionTypeCard = ({
  children, title, type, quoteArray, onTransactionTypeChange,
}: Props) => {
  const isDisabled = (quoteArray && quoteArray?.length > 0) ? quoteArray[0]?.generalInfo?.transactionType !== type : false;
  return (
    <label className="request-form-label">
      <input type="radio" name="type" onClick={() => onTransactionTypeChange(type)} disabled={isDisabled} />
      <span className="request-form-label__body request-form-type">
        <span className="request-form-type__header">
          <span className="request-form-type__title" style={{ color: isDisabled ? 'rgba(0,0,0,0.2)' : '' }}>{title}</span>
          <span className="request-form-type__caption">select</span>
        </span>
        <span className="request-form-type__text" style={{ color: isDisabled ? 'rgba(0,0,0,0.2)' : '' }}>{children}</span>
      </span>
    </label>
  );
};

export default TransactionTypeCard;
