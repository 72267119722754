import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash';
import {
  Button, Spinner, Badge, Steper,
} from '../components/common';
import {
  colors, data, text, date,
} from '../constants';
import { searchTree } from '../helpers/tree';
import ingot from '../assets/images/gold-ingot.png';
import { api } from '../api';

const { SecondaryButton } = Button;

type Props = {
} & RouteComponentProps;

type State = {
  cover: string,
  loan: Loan,
  isLoading: boolean,
};

class TransactionPaymentView extends Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.state = {
      cover: '',
      loan: data.LOAN_DEFUALT,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchTransaction();
  }

  render() {
    const {
      cover,
      isLoading,
      loan: {
        payment,
        startDate,
        quote: { context, name, updatedAt },
      },
    } = this.state;

    if (isLoading)
      return <Spinner />;

    return (
      <div>
        <Steper title="Transactions" description="Payment" />
        <Container>
          <Col style={{ marginRight: '30px' }}>
            <ItemCard>
              <ItemInfo style={{ float: 'left' }}>
                <CaptionText>
                  {context.mode}
                </CaptionText>
                <CaptionText>
                  {`Updated: ${dayjs(updatedAt).format('MM.DD.YYYY h:mm A')}`}
                </CaptionText>
                <CardTitle>{upperFirst(name)}</CardTitle>
                <Badge
                  backgroundColor={colors.background.inputNumber}
                  color={colors.text.green}
                  width="80%"
                  textAlignCenter
                >
                  Request Accepted
                </Badge>
              </ItemInfo>
              <CardImage src={cover} alt="cover" />
            </ItemCard>
            <StyledRow>
              <SecondaryButton onClick={() => this.handleRedirectToTransaction()}>
                View Transaction
              </SecondaryButton>
            </StyledRow>
          </Col>
          <ActionButtonsSection>
            <ActionButtons>
              <CaptionTextMedium>Number: </CaptionTextMedium>
              <CaptionTextMedium>Agreement Date:</CaptionTextMedium>
              <CaptionTextMedium>Payment Type: Manual</CaptionTextMedium>
              <CaptionTextMedium>{`Payment Date: ${dayjs(startDate).format(date.full)}`}</CaptionTextMedium>
              <CaptionTextMedium>
                {`Status: ${upperFirst(payment?.status)}`}
              </CaptionTextMedium>
              <CaptionTextMedium>
                {`Principal Payment: $${Math.max(0, (payment?.value.total || 0) - (payment?.value.interest || 0))}`}
              </CaptionTextMedium>
              <CaptionTextMedium>{`Interest Payment:  $${(payment?.value.interest || 0)}`}</CaptionTextMedium>
            </ActionButtons>
          </ActionButtonsSection>
        </Container>
      </div>
    );
  }

  handleRedirectToTransaction = () => {
    const { loan: { id } } = this.state;
    this.props.history.push(`/transactions/details/${id}`);
  };

  fetchTransaction = () => {
    const { location } = this.props;
    const paths = location.pathname.split('/');
    const id = [...paths].pop();

    api
      .get(`/transactions/${id}`)
      .then(res => res.data)
      .then((res: Loan) => {
        const cover = res.quote.name ? this.findCover(res.quote.name || '') : ingot;
        this.setState({ loan: res, cover });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  findCover = (name: string) => {
    const cover = searchTree(data.CATEGORIES, name);
    return cover.imgCover || ingot;
  };
}

const StyledRow = styled(Row)`
  margin-top: 15px;
  margin-left: 20px;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: 1100px) {
        flex-direction: row;
    }
`;

const ItemCard = styled.div`
    width: 100% !important;
    max-width: 500px;
    max-height: 200px;
    padding: 20px 25px;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;

    @media(min-width: 400px) {
        width: 50%;
        margin: 50px 20px 30px;
    }

    @media (min-width: 1100px) {
        margin: 50px 20px 30px;
    }

    @media (min-width: 1460px) {
        max-width: 500px;
    }
`;

const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const CaptionText = styled.span`
    ${text.caption}
    color: #8FA1AC;
`;
const CaptionTextMedium = styled.span`
    ${text['menu-item']}
    color: #8FA1AC;
`;

const CardTitle = styled.span`
    ${text['title-20']}
    color: #4F6470;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const CardImage = styled.img`
    width: 120px;
    height: 100px;
`;

const ActionButtonsSection = styled.div`
    margin: 20px auto;

    @media (min-width: 1100px) {
        margin: 50px 20px 50px;
    }
`;
const ActionButtons = styled.div`
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    width: 300px !important;
    flex-direction: column;

    @media (min-width: 1440px) {
       width: 150px;
    }
    @media (min-width: 1700px) {
        width: 180px;
    }

    span {
      margin-top: 10px;
    }
`;

export default TransactionPaymentView;
