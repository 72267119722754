/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-boolean-value */
import fonts from '../../constants/fonts';

const { mainFont } = fonts.fonts;

type Props = {
  isBoxRequest: boolean;
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: (value: string) => void;
};

const ConfirmShipItems = ({
  isBoxRequest,
  isVisible,
  onCancel,
  onConfirm,
}: Props) => (
  <div style={{ fontFamily: mainFont }}>
    <div className={`popup ${isVisible && 'popup--shown'}`}>
      <div className="popup__wrap">
        <div className="popup__inner">
          <div className="popup__header">
            <div className="popup__text popup__text--big">
              {
                isBoxRequest
                  ? 'Do you want to request next box?'
                  : 'Did you ship the item(s) to us?'
              }

            </div>
          </div>
          <div className="popup__content">
            <div className="popup__image popup__image--green">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                  <path d="M8.00033 23.2376H5.33366C4.96566 23.2376 4.66699 22.9134 4.66699 22.5138C4.66699 22.1143 4.96566 21.79 5.33366 21.79H8.00033C8.36833 21.79 8.66699 22.1143 8.66699 22.5138C8.66699 22.9134 8.36833 23.2376 8.00033 23.2376Z" />
                  <path d="M30.3331 23.2375H28.6664C28.2984 23.2375 27.9998 22.9132 27.9998 22.5137C27.9998 22.1141 28.2984 21.7899 28.6664 21.7899H29.7798L30.6784 16.59C30.6664 13.9293 28.5731 11.6566 25.9998 11.6566H21.6224L19.5011 21.7899H23.3331C23.7011 21.7899 23.9998 22.1141 23.9998 22.5137C23.9998 22.9132 23.7011 23.2375 23.3331 23.2375H18.6664C18.4638 23.2375 18.2718 23.1376 18.1451 22.9653C18.0184 22.7945 17.9704 22.5687 18.0158 22.3544L20.4398 10.7736C20.5091 10.4421 20.7784 10.209 21.0904 10.209H25.9998C29.3078 10.209 31.9998 13.1317 31.9998 16.7232L30.9878 22.6468C30.9291 22.9899 30.6544 23.2375 30.3331 23.2375Z" />
                  <path d="M26.0003 25.9997C24.163 25.9997 22.667 24.505 22.667 22.6663C22.667 20.8277 24.163 19.333 26.0003 19.333C27.8377 19.333 29.3337 20.8277 29.3337 22.6663C29.3337 24.505 27.8377 25.9997 26.0003 25.9997ZM26.0003 20.6663C24.8977 20.6663 24.0003 21.5637 24.0003 22.6663C24.0003 23.769 24.8977 24.6663 26.0003 24.6663C27.103 24.6663 28.0003 23.769 28.0003 22.6663C28.0003 21.5637 27.103 20.6663 26.0003 20.6663Z" />
                  <path d="M10.8001 26.2282C8.9628 26.2282 7.4668 24.7335 7.4668 22.8949C7.4668 21.0562 8.9628 19.5615 10.8001 19.5615C12.6375 19.5615 14.1335 21.0562 14.1335 22.8949C14.1335 24.7335 12.6375 26.2282 10.8001 26.2282ZM10.8001 20.8949C9.69746 20.8949 8.80013 21.7922 8.80013 22.8949C8.80013 23.9975 9.69746 24.8949 10.8001 24.8949C11.9028 24.8949 12.8001 23.9975 12.8001 22.8949C12.8001 21.7922 11.9028 20.8949 10.8001 20.8949Z" />
                  <path d="M8.66602 10.2093H3.33268C2.96468 10.2093 2.66602 9.88507 2.66602 9.48553C2.66602 9.08598 2.96468 8.76172 3.33268 8.76172H8.66602C9.03402 8.76172 9.33268 9.08598 9.33268 9.48553C9.33268 9.88507 9.03402 10.2093 8.66602 10.2093Z" />
                  <path d="M8.66732 14.5521H2.00065C1.63265 14.5521 1.33398 14.2278 1.33398 13.8283C1.33398 13.4288 1.63265 13.1045 2.00065 13.1045H8.66732C9.03532 13.1045 9.33399 13.4288 9.33399 13.8283C9.33399 14.2278 9.03532 14.5521 8.66732 14.5521Z" />
                  <path d="M8.66667 18.8949H0.666667C0.298667 18.8949 0 18.5706 0 18.1711C0 17.7715 0.298667 17.4473 0.666667 17.4473H8.66667C9.03467 17.4473 9.33333 17.7715 9.33333 18.1711C9.33333 18.5706 9.03467 18.8949 8.66667 18.8949Z" />
                  <path d="M18.6673 23.2375H14.0007C13.6327 23.2375 13.334 22.9133 13.334 22.5137C13.334 22.1142 13.6327 21.7899 14.0007 21.7899H18.1353L21.1647 7.31382H6.00065C5.63265 7.31382 5.33398 6.98956 5.33398 6.59002C5.33398 6.19048 5.63265 5.86621 6.00065 5.86621H22.0007C22.2033 5.86621 22.3953 5.9661 22.522 6.13836C22.6487 6.30918 22.6967 6.53501 22.6513 6.74925L19.318 22.673C19.2487 23.0045 18.978 23.2375 18.6673 23.2375Z" />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="popup__footer">
            <div className="popup__comment">
              {
                isBoxRequest
                  ? 'Please only continue here if you have been waiting more than a week for the fedex prepaid shipping box. '
                  : ' Please only continue here if you have shipped the item(s) to us so that we can expect them.'
              }
            </div>
            <div className="popup__buttons">
              <div className="popup__btn btn btn--dark" onClick={() => onConfirm(isBoxRequest ? '' : 'transport')}>
                {
                  isBoxRequest
                    ? 'Yes, I confirm that I WANT A NEW BOX'
                    : 'Yes, I confirm that I shipped the item(s)'
                }
              </div>
              <div className="popup__btn btn btn--bordered btn--bordered-dark" onClick={onCancel}>
                No, Go back
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ConfirmShipItems;
