import { Modal as AntdModal } from 'antd';
import styled from 'styled-components';
import { text, colors } from '../../constants';

type Props = {
  isVisible: boolean;
  children?: JSX.Element;
  onCancel?: () => void;
  footer?: JSX.Element;
  title?: string;
};

const Modal = ({
  isVisible, footer, title, onCancel, children,
}: Props) => (
  <StyledModal
    style={{ top: 16 }}
    footer={footer || null}
    title={title}
    visible={isVisible}
    onCancel={() => onCancel && onCancel()}
  >
    {children}
  </StyledModal>
);

export default Modal;

const StyledModal = styled(AntdModal)`
    width: 800px !important;

    .ant-modal-header {
        display: flex;
        justify-content: center;
        padding-top: 40px !important;
        border: none;
        color: ${colors.text.dark};
        .ant-modal-title {
            ${text.title}
        }
    }
    .ant-modal-close-x {
        svg {
            height: 19px;
            width: 19px;
        }

        color: ${colors.text.light};
    }

    .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
