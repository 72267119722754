import { message, Modal } from 'antd';
import { Component } from 'react';
import { api } from '../../../api';
import { Spinner } from '../../common';
import { SecondaryButton } from '../../common/Button';
import Editor from '../../Editor';

type Props = {
  onCancel: () => void;
  agreementId?: string;
  agreement?: Agreement;
  readonly?: boolean;
};

type State = {
  agreement?: Agreement;
  isLoading: boolean;
};

class AgreementViewModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount(): void {
    if (this.props.agreement)
      this.setState({ agreement: this.props.agreement, isLoading: false });
    if (!this.props.agreementId)
      return;
    this.fetchAgreement();
  }

  render() {
    const { agreement, isLoading } = this.state;
    const { onCancel, readonly } = this.props;

    return (
      <Modal
        title="Agreement Details"
        visible={true!}
        footer={[
          <SecondaryButton key="back" onClick={onCancel}>
            Close
          </SecondaryButton>,
        ]}
        onCancel={onCancel}
      >
        <span>
          { isLoading
            ? <Spinner />
            : (
              <Editor
                readonly={readonly}
                content={agreement?.content}
                fileList={agreement?.attachments}
              />
            )}
        </span>
      </Modal>
    );
  }

  fetchAgreement = () => {
    const { agreementId } = this.props;
    api
      .get(`/agreement/${agreementId}`)
      .then(res => res.data)
      .then(agreement => this.setState({ agreement }))
      .catch(() => message.error('Error'))
      .finally(() => this.setState({ isLoading: false }));
  };
}

export default AgreementViewModal;
