/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useEffect } from 'react';
import fonts from '../../constants/fonts';

const { mainFont } = fonts.fonts;

type Props = {
  isVisible: boolean;
  isGettingRateMetalValue: boolean;
  setVisibility: (isVisible: boolean) => void;
  amount?: number;
  handleSendOffer: (value: number, isCounterOffer?: boolean) => void;
};

const MetalOfferModal = ({
  isVisible, isGettingRateMetalValue, setVisibility, amount, handleSendOffer,
}: Props) => {
  const [isCounterOffering, setIsCounterOffering] = useState(false);
  const [counterOfferValue, setCounterOfferValue] = useState(0);

  useEffect(() => {
    if (isCounterOffering) {
      setCounterOfferValue(amount || 0);
    }
  }, [isCounterOffering, amount]);

  const handlleCounterOffer = (e: any) => {
    setCounterOfferValue(Number(e.target.value));
  };

  const handleOnCancel = () => {
    setVisibility(false);
    setIsCounterOffering(false);
  };

  return (
    <div className="main" style={{ fontFamily: mainFont }}>
      <div className={`popup ${isVisible && isGettingRateMetalValue && 'popup--shown'}`}>
        <div className="popup__wrap popup__wrap--wide">
          <a className="popup__close" onClick={handleOnCancel} />
          <div className="popup__inner">
            <div className="popup__header">
              <div className="popup__title">
                Wait a moment.
                <br />
                We are reviewing your request and calculating
                an offer
              </div>
            </div>
            <div className="popup__content">
              <div className="popup__loader">..</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`popup ${isVisible && !isGettingRateMetalValue && 'popup--shown'}`}>
        <div className="popup__wrap popup__wrap--wide">
          <a className="popup__close" onClick={handleOnCancel} />
          <div className="popup__inner">
            <div className="popup__header">
              <div className="popup__title popup__title--big">
                Our offer for your item(s):
              </div>
            </div>
            <div className="popup__content">
              <div className="popup__price">
                $
                {amount?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
              <div className="popup__status status status--closed">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016C14.6628 11.6804 11.6804 14.6628 8.00016 14.6668ZM2.66683 8.11483C2.69837 11.049 5.09423 13.4065 8.02852 13.3908C10.9628 13.375 13.3332 10.9918 13.3332 8.05749C13.3332 5.12317 10.9628 2.74001 8.02852 2.72416C5.09423 2.70847 2.69837 5.066 2.66683 8.00016V8.11483ZM9.3335 11.3335H7.3335V8.66683H6.66683V7.3335H8.66683V10.0002H9.3335V11.3335ZM8.66683 6.00016H7.3335V4.66683H8.66683V6.00016Z"
                  />
                </svg>
                <span>Pending Inspection</span>
                <div className="status__popup">
                  The final offer will be determined once we have received your
                  item(s) and our experts have completed the item inspection.
                </div>
              </div>
            </div>
            {
              isCounterOffering
                ? (
                  <div className="popup__footer">
                    <a className="popup__link" onClick={() => setIsCounterOffering(false)}>
                      Accept and Continue with
                      $
                      {amount?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </a>
                    <form className="popup-counteroffer">
                      <div className="form-input popup-counteroffer__input">
                        <input
                          type="number"
                          placeholder="$0"
                          min={0}
                          value={counterOfferValue}
                          onChange={handlleCounterOffer}
                        />
                      </div>
                      <div
                        className="popup-counteroffer__submit btn btn--dark popup__btn"
                        onClick={() => handleSendOffer(counterOfferValue || 0, true)}
                      >
                        Submit a Counter Offer
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="popup__footer">
                    <div className="popup__buttons">
                      <a className="popup__btn btn btn--dark" onClick={() => handleSendOffer(amount || 0, false)}>Accept and Continue</a>
                    </div>
                    <div className="popup__comment">
                      If you are not satisfied with our estimate, you can
                      <a style={{ color: '#61a1cd' }} onClick={() => setIsCounterOffering(true)}> make a counter offer here</a>
                    </div>
                  </div>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetalOfferModal;
