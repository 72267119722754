/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/quotes */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
} from 'antd';
import { userStore } from '../../../store';
import {
  data,
  usaStateCodes,
  shippingServices,
} from '../../../constants';
import { Spinner } from '../../common';
import { BOX_SIZE } from '../../../constants/data';

type Props = {
  isSubmittingShippingLabel?: boolean;
  onFinish: (values: any) => void;
  values?: ShipmentDetails
};

const ShippingForm: React.FC<Props> = ({
  onFinish, isSubmittingShippingLabel,
}: Props) => {
  const [user, setUser] = useState<User>(data.USER_DEFAULT);
  const [formAddress] = Form.useForm();
  const [formPackage] = Form.useForm();
  const [activeTab, setActiveTab] = useState(1);
  const [dataForm, setDataForm] = useState<any>({});
  const [activeBoxSize, setActiveBoxSize] = useState(true);
  const [selectedBoxSize, setSelectedBoxSize] = useState(BOX_SIZE[0].size);
  const [showSelectedBoxSize, setShowSelectedBoxSize] = useState('');
  const [weightPackage, setWeightPackage] = useState('');

  useEffect(() => {
    formAddress.resetFields();
    formPackage.resetFields();
  }, [user]);

  useEffect(() => {
    setUser(userStore.getState());
  }, []);

  useEffect(() => {
    if (!activeBoxSize)
      return setSelectedBoxSize('');
    setSelectedBoxSize(BOX_SIZE[0].size);
  }, [activeBoxSize]);

  const handleFinish = () => {
    onFinish(dataForm);
  };

  const handleSubmit = (values: any) => {
    if (!values) {
      const selectedSize = BOX_SIZE.filter(db => db.size === selectedBoxSize)[0];
      setShowSelectedBoxSize(selectedSize.visualDescription);
      setDataForm({
        ...dataForm,
        ...selectedSize.values,
        boxSize: selectedSize.size,
        accountNumber: '',
        packagingType: '',
      });
    } else {
      setDataForm({
        ...dataForm,
        ...values,
        boxSize: '',
        packagingType: 'YOUR_PACKAGING',
      });
    }

    if (activeTab === 1)
      setActiveTab(2);
    if (activeTab === 2)
      setActiveTab(3);
  };

  const handleActiveBoxSize = (e: any) => {
    setActiveBoxSize(e.target.checked);
  };

  const handleActiveDimensions = (e: any) => {
    setActiveBoxSize(!e.target.checked);
  };

  const handleWeightPackage = (e: any) => {
    setWeightPackage(e.target.value);
  };

  return (
    <>
      <div className="item-details__body item-details__body--one-column">
        <div className="shipping-label-form">
          <div className="request-steps shipping-label-form__steps">
            <span
              className={`${activeTab === 1 && 'request-steps__unit--current'} ${activeTab > 1 && 'request-steps__unit--completed'} request-steps__unit`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.65686 18L15.3137 12.3431L9.65686 6.68629"
                  strokeWidth="2"
                />
              </svg>
              <span>1. Shipping Details</span>
            </span>
            <span
              className={`${activeTab === 2 && 'request-steps__unit--current'} ${activeTab > 2 && 'request-steps__unit--completed'} request-steps__unit`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.65686 18L15.3137 12.3431L9.65686 6.68629"
                  strokeWidth="2"
                />
              </svg>
              <span>2. Shipping BOX</span>
            </span>
            <span
              className={`${activeTab === 3 && 'request-steps__unit--current'} request-steps__unit`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.65686 18L15.3137 12.3431L9.65686 6.68629"
                  strokeWidth="2"
                />
              </svg>
              <span>3. Review &amp; Confirm</span>
            </span>
          </div>
          <div className="shipping-label-form__text">
            We use this information to create your shipping label so that you can send your item(s) to us for free!
          </div>
          {
            activeTab === 1 && (
              <Form id="form1" form={formAddress} layout="horizontal" onFinish={handleSubmit} className="shipping-label-form__body request-payment-form__body">

                <div className="shipping-label-form__columns shipping-label-form__columns--2">
                  <div className="request-payment-form-unit shipping-label-form__unit">
                    <div className="request-payment-form-unit__header">
                      <div className="request-payment-form-unit__title">First Name</div>
                    </div>
                    <div className="request-payment-form-unit__body">
                      <Form.Item name="firstName" rules={[{ required: true, message: '' }]}>
                        <Input placeholder="First Name" className="request-payment-form-unit__title" type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="request-payment-form-unit shipping-label-form__unit">
                    <div className="request-payment-form-unit__header">
                      <div className="request-payment-form-unit__title">Last Name</div>
                    </div>
                    <div className="request-payment-form-unit__body">
                      <Form.Item name="lastName" rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Last Name" className="request-payment-form-unit__title" type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="request-payment-form-unit shipping-label-form__unit">
                    <div className="request-payment-form-unit__header">
                      <div className="request-payment-form-unit__title">Address Line 1</div>
                    </div>
                    <div className="request-payment-form-unit__body">
                      <Form.Item name="addressLine" rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Address Line 1" className="request-payment-form-unit__title" type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="request-payment-form-unit shipping-label-form__unit">
                    <div className="request-payment-form-unit__header">
                      <div className="request-payment-form-unit__title">Address Line 2</div>
                      <div className="request-payment-form-unit__comment">(optional)</div>
                    </div>
                    <div className="request-payment-form-unit__body">
                      <Form.Item name="addressLine2">
                        <Input placeholder="Address Line 2" className="request-payment-form-unit__title" type="text" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="shipping-label-form__columns shipping-label-form__columns--3">
                  <div className="request-payment-form-unit shipping-label-form__unit">
                    <div className="request-payment-form-unit__header">
                      <div className="request-payment-form-unit__title">City</div>
                    </div>
                    <div className="request-payment-form-unit__body">
                      <Form.Item name="city" rules={[{ required: true, message: '' }]}>
                        <Input placeholder="City" className="request-payment-form-unit__title" type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="request-payment-form-unit shipping-label-form__unit">
                    <div className="request-payment-form-unit__header">
                      <div className="request-payment-form-unit__title">State</div>
                    </div>
                    <div className="request-payment-form-unit">
                      <Form.Item name="stateOrProvinceCode" rules={[{ required: true, message: '' }]} initialValue={'AL'}>
                        <select>
                          {Object.entries(usaStateCodes).map(([key, value]) => (
                            <option key={value} value={key} className="request-payment-form-unit__title">{`${key} - ${value}`}</option>
                          ))}
                        </select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="request-payment-form-unit shipping-label-form__unit">
                    <div className="request-payment-form-unit__header">
                      <div className="request-payment-form-unit__title">Zip Code</div>
                    </div>
                    <div className="request-payment-form-unit__body">
                      <Form.Item name="postalCode" rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Zip Code" className="request-payment-form-unit__title" type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="request-payment-form-unit shipping-label-form__unit">
                    <div className="request-payment-form-unit__header">
                      <div className="request-payment-form-unit__title">Phone</div>
                    </div>
                    <div className="request-payment-form-unit__body">
                      <Form.Item name="phone" rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Phone" className="request-payment-form-unit__title" type="number" />
                      </Form.Item>
                    </div>
                  </div>

                </div>
                <div className="shipping-label-form__columns shipping-label-form__columns--2">
                  <div className="request-payment-form-unit shipping-label-form__unit">
                    <div className="request-payment-form-unit__header">
                      <div className="request-payment-form-unit__title">Shipping</div>
                    </div>
                    <div className="request-payment-form-unit__body">
                      <Form.Item name="shippingService" rules={[{ required: true, message: '' }]} initialValue={"FIRST_OVERNIGHT"}>
                        <select>
                          {Object.entries(shippingServices).map(([key, value]) => (
                            <option key={value} value={value} className="request-payment-form-unit__title">{`${key} - ${value}`}</option>
                          ))}
                        </select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }
          {
            activeTab === 2 && (
              <div className="shipping-label-options">
                <div className={`shipping-label-option ${activeBoxSize && 'shipping-label-option--active'}`}>
                  <label className="checkbox shipping-label-option__checkbox">
                    <input type="radio" name="js-shipping-label-option" checked={activeBoxSize} onChange={handleActiveBoxSize} />
                    <span>{`Select a box size. We'll send a prepaid shipping label box right to your house.`}</span>
                  </label>
                  <div className="shipping-label-option__body">
                    <div className="shipping-label-option__sizes">
                      <label className="request-form-label shipping-label-option__unit">
                        <input type="radio" name="size" checked={selectedBoxSize === BOX_SIZE[0].size} onClick={() => setSelectedBoxSize(BOX_SIZE[0].size)} />
                        <span className="request-form-label__body request-form-size">
                          <div className="request-form-size__icon-wrap">
                            <img className="request-form-size__icon" src="/images/shipping-label/box1.svg" alt="diamond" />
                          </div>
                          <span className="request-form-size__title">Small: 12”/4”/2”</span>
                        </span>
                      </label>
                      <label className="request-form-label shipping-label-option__unit">
                        <input type="radio" name="size" checked={selectedBoxSize === BOX_SIZE[1].size} onClick={() => setSelectedBoxSize(BOX_SIZE[1].size)} />
                        <span className="request-form-label__body request-form-size">
                          <div className="request-form-size__icon-wrap">
                            <img className="request-form-size__icon" src="/images/shipping-label/box2.svg" alt="diamond" />
                          </div>
                          <span className="request-form-size__title">Medium: 16”/8”/4”</span>
                        </span>
                      </label>
                      <label className="request-form-label shipping-label-option__unit">
                        <input type="radio" name="size" checked={selectedBoxSize === BOX_SIZE[2].size} onClick={() => setSelectedBoxSize(BOX_SIZE[2].size)} />
                        <span className="request-form-label__body request-form-size">
                          <div className="request-form-size__icon-wrap">
                            <img className="request-form-size__icon" src="/images/shipping-label/box3.svg" alt="diamond" />
                          </div>
                          <span className="request-form-size__title">Large: 20”/12”/10”</span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className={`shipping-label-option ${!activeBoxSize && 'shipping-label-option--active'}`}>
                  <label className="checkbox shipping-label-option__checkbox">
                    <input type="radio" name="js-shipping-label-option" checked={!activeBoxSize} onChange={handleActiveDimensions} />
                    <span>Select if your item(s) weight is over 20 pounds or is too big to fit into one of the above boxes
                      <br />
                      (you will pay for shipping)
                    </span>
                  </label>
                  <div className="shipping-label-option__body">
                    <Form id="form2" form={formPackage} layout="vertical" onFinish={handleSubmit}>
                      <div className="shipping-label-option__dimensions request-payment-form__body">
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">Package Weight</div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="weight"
                              rules={[{ required: true },
                              ]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="number"
                                placeholder="lbs 0"
                                value={weightPackage}
                                min={0}
                                onChange={handleWeightPackage}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">Package Dimensions</div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="height"
                              rules={[{ required: true }]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="number"
                                placeholder="height (inches)"
                                disabled={activeBoxSize}
                                min={0}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title" />
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="width"
                              rules={[{ required: true }]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="number"
                                placeholder="width (inches)"
                                disabled={activeBoxSize}
                                min={0}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title" />
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="length"
                              rules={[{ required: true }]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="number"
                                placeholder="length (inches)"
                                disabled={activeBoxSize}
                                min={0}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">Your fedex account number</div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="accountNumber"
                              rules={[{ required: true, message: 'Your fedex account number is required' }]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="text"
                                placeholder="Fedex account number"
                                disabled={activeBoxSize}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            )
          }
          {
            activeTab === 3 && (
              <>
                <div className="shipping-label-summary">
                  <div className="shipping-label-summary__title">1. Adress</div>
                  <div className="shipping-label-summary__body">
                    <div className="shipping-label-summary__text">
                      {`${dataForm.addressLine} / ${dataForm.city} / ${dataForm.postalCode}`}
                    </div>
                    <button className="shipping-label-summary__change btn btn--bordered btn--bordered-dark btn--small" onClick={() => setActiveTab(1)}>
                      CHANGE
                    </button>
                  </div>
                </div>
                <div className="shipping-label-summary">
                  <div className="shipping-label-summary__title">2. Package Details </div>
                  <div className="shipping-label-summary__body">
                    <div className="shipping-label-summary__text">
                      {
                        activeBoxSize
                          ? <Row>{`${showSelectedBoxSize}`}</Row>
                          : (
                            <>
                              <Row>{`weight: ${dataForm.weight} lbs `}</Row>
                              <Row>{`height: ${dataForm.height} (inches)`}</Row>
                              <Row>{`width: ${dataForm.width} (inches)`}</Row>
                              <Row>{`length: ${dataForm.length} (inches)`}</Row>
                            </>
                          )
                      }
                    </div>
                    <button className="shipping-label-summary__change btn btn--bordered btn--bordered-dark btn--small" onClick={() => setActiveTab(2)}>
                      CHANGE
                    </button>
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div>
      <div className="item-details__footer">
        {activeTab === 1 && (
          <button className="btn btn--bordered btn--bordered-dark btn-create_shipment" form="form1" style={{ backgroundColor: '#fff' }}>
            Next:&nbsp;&nbsp;&nbsp;Package Information
          </button>
        )}
        {activeTab === 2 && (
          activeBoxSize
            ? (
              <>
                <a className="btn btn--bordered btn--bordered-dark" onClick={() => handleSubmit(undefined)}>
                  Next:&nbsp;&nbsp;&nbsp;Package Information
                </a>
              </>
            )
            : (
              <button className="btn btn--bordered btn--bordered-dark btn-create_shipment" form="form2" style={{ backgroundColor: '#fff' }}>
                Next:&nbsp;&nbsp;&nbsp;Package Information
              </button>
            )
        )}
        {activeTab === 3 && (
          <button className="btn btn--dark" form="form1" onClick={handleFinish} disabled={isSubmittingShippingLabel}>
            {
              isSubmittingShippingLabel
                ? <Spinner loaderSize={{ width: 20, height: 20 }} tip={' '} style={{ width: 150 }} />
                : ' Confirm shipping details'
            }
          </button>
        )}
      </div>
    </>
  );
};

export default ShippingForm;
