/* eslint-disable */
"use strict";

window.addEventListener("DOMContentLoaded", function () {
  // initFileUpload();
  initPopups();
  initPaymentOptions();
  initDashboardHeaderListToggle();
  initItemDetailsGallery();
  // initHeaderMobileMenu();
  // initMobileSidebar();
  initShippingLabelOptions();
})

export function runMain2() {
  // initFileUpload();
  initPopups();
  initPaymentOptions();
  initDashboardHeaderListToggle();
  initItemDetailsGallery();
  // initHeaderMobileMenu();
  // initMobileSidebar();
  initShippingLabelOptions();
}

export const initShippingLabelOptions = () => {
  const blocks = document.querySelectorAll('.shipping-label-options');
  blocks.forEach((block) => {
    const options = block.querySelectorAll('.shipping-label-option');
    const activeOptionClass = 'shipping-label-option--active';
    options.forEach((option) => {
      const checkbox = option.querySelector('.shipping-label-option__checkbox');
      checkbox.addEventListener('change', () => {
        options.forEach(optionInner => optionInner.classList.remove(activeOptionClass));
        option.classList.add(activeOptionClass);
      })
    })
  })
}
export const initMobileSidebar = () => {
  const sidebarBlocks = document.querySelectorAll('.sidebar');
  sidebarBlocks.forEach((sidebar) => {
    const sidebarBody = sidebar.querySelector('.sidebar__body');
    const sidebarHeaderButton = document.querySelector('.header__sidebar-btn');
    sidebar.addEventListener('click', () => {
      sidebar.classList.toggle('sidebar--shown');
    })
    sidebarHeaderButton.addEventListener('click', () => {
      sidebar.classList.toggle('sidebar--shown');
    })
    sidebarBody.addEventListener('click', (e) => {
      e.stopPropagation();
    })
  })
}
export const initHeaderMobileMenu = () => {
  const burger = document.querySelector('.header__burger');
  const header = document.querySelector('.header');

  burger.addEventListener('click', () => {
    header.classList.toggle('header--active');
  })
}
export const initItemDetailsGallery = () => {
  const galleries = document.querySelectorAll('.item-details-gallery:not(.item-details-gallery--empty)');
  galleries.forEach((gallery) => {
    const prev = gallery.querySelector('.slider-controls__unit--prev');
    const next = gallery.querySelector('.slider-controls__unit--next');
    const pagination = gallery.querySelector('.item-details-gallery__pagination');
    const units = gallery.querySelectorAll('.item-details-gallery__unit');
    const thumbs = [];
    const thumbActiveClass = 'slider-pagination__unit--active';
    units.forEach((unit, index) => {
      const style = unit.getAttribute('style');
      const thumb = document.createElement('div');
      thumb.classList.add('slider-pagination__unit');
      if (index == 0) thumb.classList.add(thumbActiveClass);
      thumb.setAttribute('style', style);
      thumbs.push(thumb);
      pagination.append(thumb);
    })
    const sliderInstance = new Swiper(gallery, {
      loop: true,
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      on: {
        slideChange: function (swiper) {
          thumbs.forEach((thumb) => thumb.classList.remove(thumbActiveClass));
          thumbs[swiper.realIndex].classList.add(thumbActiveClass);
        },
      },
    })
    thumbs.forEach((thumb, index) => {
      thumb.addEventListener('click', () => {
        sliderInstance.slideToLoop(index);
      })
    })
  })
}
export const initDashboardHeaderListToggle = () => {
  const headers = document.querySelectorAll('.js-dashboard-header--table');
  const activeClass = 'js-dashboard-header--table-shown';
  headers.forEach((header) => {
    const btn = header.querySelector('.js-toggle-dashboard-header-list');
    btn.addEventListener('click', () => {
      header.classList.toggle(activeClass);
    })
  })
  document.addEventListener('click', (e) => {
    const element = e.target;
    const closestSelect = element.closest('.js-dashboard-header--table');
    document.querySelectorAll('.js-dashboard-header--table').forEach((select) => {
      if (select !== closestSelect) {
        select.classList.remove(activeClass);
      }
    })
  })
}
export const initFileUpload = () => {
  Dropzone.autoDiscover = false
  const attachForms = document.querySelectorAll('.attachments-form');
  attachForms.forEach((form) => {
    const addBtn = form.querySelector('.js-attach-btn');
    const template = form.querySelector('.js-attach-template');
    const dropzone = new Dropzone(form, {
      url: "/file/post",
      clickable: addBtn,
      acceptedFiles: ".jpeg,.jpg,.png,.gif",
      previewTemplate: template.innerHTML,
      thumbnailWidth: 500,
      thumbnailHeight: 500,
    });
  })
}
export const initPopups = () => {
  const popups = document.querySelectorAll('.popup');
  const hidePopup = (popup) => {
    popup.classList.remove('popup--shown');
  }
  popups.forEach((popup) => {
    const wrap = popup.querySelector('.popup__wrap');
    const closeButton = popup.querySelector('.popup__close');
    popup.addEventListener('click', () => hidePopup(popup));
    wrap.addEventListener('click', (e) => e.stopPropagation());
    closeButton.addEventListener('click', () => hidePopup(popup));
  })
}
export const initPaymentOptions = () => {
  const paymentOptionsBlocks = document.querySelectorAll('.request-payment__wrap');
  paymentOptionsBlocks.forEach((paymentOptions) => {
    const radioInput = paymentOptions.querySelectorAll('.request-payment-option__header');
    const options = paymentOptions.querySelectorAll('.request-payment-option');
    const activeOptionClass = 'request-payment-option--selected';
    radioInput.forEach((radio) => {
      radio.addEventListener('change', () => {
        options.forEach(option => option.classList.remove(activeOptionClass));
        radio.closest('.request-payment-option').classList.add(activeOptionClass);
      })
    })
  })
}