const RequestLogo = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9632 3.1807H18.9391V1.15664C18.9391 0.520452 18.4186 0 17.7827 0H3.0355C2.39936 0
      1.87891 0.520452 1.87891 1.15664V19.6626C1.87891 20.2988 2.39936 20.8193 3.03554
      20.8193H5.05965V22.8434C5.05965 23.4795 5.57987 24 6.21629 24H16.0473C16.6832 24
      17.583 23.6432 18.046 23.2074L21.277 20.1661C21.7405 19.7304 22.1193 18.853 22.1193
      18.2169L22.1199 4.33743C22.1198 3.7012 21.5993 3.1807 20.9632 3.1807ZM5.05956
      4.33738V19.9518H3.0355C2.8788 19.9518 2.74637 19.8194 2.74637 19.6627V1.15664C2.74637
      0.999936 2.8788 0.867514 3.0355 0.867514H17.7828C17.9392 0.867514 18.0719 0.999936
      18.0719 1.15664V3.18074H6.2162C5.57977 3.18074 5.05956 3.7012 5.05956 4.33738ZM20.6824
      19.5345L17.4514 22.5756C17.3852 22.6378 17.299 22.6988 17.2039 22.7569V20.0964C17.2039
      19.8977 17.284 19.719 17.4161 19.586C17.5494 19.4538 17.7281 19.3735 17.9268
      19.3735H20.8198C20.7749 19.4344 20.729 19.4905 20.6824 19.5345ZM21.2517
      18.2168C21.2517 18.3027 21.2361 18.4022 21.2124 18.5059H17.9267C17.0492 18.5077
      16.3381 19.2187 16.3363 20.0963V23.0974C16.2331 23.1183 16.1337 23.1324 16.0472
      23.1324H6.2162V23.1324C6.05916 23.1324 5.92707 23 5.92707 22.8433V4.33734C5.92707
       4.18063 6.05921 4.04821 6.2162 4.04821H20.9631C21.1198 4.04821 21.2523
       4.18063 21.2523 4.33734L21.2517 18.2168Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M17.7841 7.22888H9.39858C9.15886 7.22888 8.96484 7.4229 8.96484 7.66261C8.96484 7.90233 9.15891
      8.09635 9.39858 8.09635H17.7841C18.0235 8.09635 18.2178 7.90233 18.2178 7.66261C18.2178 7.4229 18.0235
      7.22888 17.7841 7.22888Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M14.025 13.012H9.39858C9.15886 13.012 8.96484 13.206 8.96484 13.4458C8.96484 13.6855 9.15886
       13.8795 9.39858 13.8795H14.025C14.2645 13.8795 14.4585 13.6855 14.4585 13.4458C14.4585 13.206
        14.2645 13.012 14.025 13.012Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M17.7841 10.1204H9.39858C9.15886 10.1204 8.96484 10.3144 8.96484 10.5542C8.96484 10.7939 9.15891
      10.9879 9.39858 10.9879H17.7841C18.0235 10.9879 18.2178 10.7939 18.2178 10.5542C18.2178 10.3144
      18.0235 10.1204 17.7841 10.1204Z"
      fill="url(#paint3_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="11.9994"
        y1="0"
        x2="11.9994"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE8EF" />
        <stop offset="1" stopColor="#99ADBA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="13.5913"
        y1="7.22888"
        x2="13.5913"
        y2="8.09635"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE8EF" />
        <stop offset="1" stopColor="#99ADBA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="11.7117"
        y1="13.012"
        x2="11.7117"
        y2="13.8795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE8EF" />
        <stop offset="1" stopColor="#99ADBA" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="13.5913"
        y1="10.1204"
        x2="13.5913"
        y2="10.9879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DFE8EF" />
        <stop offset="1" stopColor="#99ADBA" />
      </linearGradient>
    </defs>
  </svg>
);

export default RequestLogo;
