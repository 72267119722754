import styled from 'styled-components';

type BadgeProps = {
  backgroundColor?: string;
  color?: string;
  fontSize?: string;
  width?: string;
  textAlignCenter?: boolean;
};

const Badge = styled.div<BadgeProps>`
  background-color: ${props => props.backgroundColor || '#E8F1F7'};
  font-size: ${props => props.fontSize || '12px'};
  color: ${props => props.color || '#8FA1AC'};
  width: ${props => props.width || 'auto'};
  text-align: ${props => {
    if (props.textAlignCenter) {
      return 'center';
    }
    return 'auto';
  }};
  padding: 10px;
  font-family: 'Aeonik', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.2px;
`;

export default Badge;
