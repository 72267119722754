import axios from 'axios';
import { response } from './constants/response';

const baseURL = process.env.REACT_APP_API_URL;
export const api = axios.create({
  baseURL,
});

api.interceptors.request.use(req => {
  const offset = sessionStorage.getItem('offset');
  req.headers.offset = offset;
  return req;
});

api.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response) {
      if (error.response.status === response.UNAUTHORIZED) {
        // Display error about session timeout
      }
    }

    return Promise.reject(error);
  },
);
