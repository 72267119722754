import * as actionTypes from './actionTypes';
import { USER_DEFAULT } from '../constants/data';

const reducer = (state: User = USER_DEFAULT, action: UserAction): User => {
  switch (action.type) {
    case actionTypes.ADD_USER:
      return {
        ...action.user,
      };
    case actionTypes.LOG_OUT:
      return {
        ...USER_DEFAULT,
      };
    default:
      return {
        ...USER_DEFAULT,
      };
  }
  return state;
};

export default reducer;
