type Props = {
  title: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
  styleChildren?: React.CSSProperties
  mode?: string;
};

const FormElement = ({
  title, children, styleChildren, mode,
}: Props) => (
  <div className="request-form-section">
    <div className="request-form-section__title">{title}</div>
    <div
      style={styleChildren}
      className="request-form-section__body"
    >
      <div
        className="request-form-types"
        style={{
          width: mode === 'other'
            ? 1000 : '',
        }}
      >
        {children}
      </div>
    </div>
  </div>
);

export default FormElement;
