import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { parse } from 'query-string';
import styled from 'styled-components';
import { Card } from 'antd';
import { api } from '../api';
import { Button } from '../components/common';
import LoginModal from '../components/Modals/LoginModal';
import { text, colors } from '../constants';

const { SecondaryButton } = Button;

type State = {
  isLoginModalVisible: boolean
};

export default class CancelView extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      isLoginModalVisible: false,
    };
  }

  componentDidMount() {
    const { session_id } = parse(this.props.location.search);
    if (!session_id)
      return;
    this.cancelSubscription(session_id as string);
  }

  render() {
    const { isLoginModalVisible } = this.state;
    return (
      <Container className="success-view-container">
        <StyledCard
          className="success-view-card"
          hoverable
        >
          <Content>
            <Title>
              Payment Cancelled
            </Title>
            <SecondaryButton onClick={this.handleRedirectToHome}>Return to home page</SecondaryButton>
          </Content>
        </StyledCard>
        {
          isLoginModalVisible && (
            <LoginModal
              isVisible={isLoginModalVisible}
              handleLoginModal={this.handleLoginModalVisibilityChange}
            />
          )
        }
      </Container>
    );
  }

  cancelSubscription = (sessionId: string) => api.post('/payments/cancel', { sessionId });

  handleLoginModalVisibilityChange = (state: boolean) => this.setState({ isLoginModalVisible: state });

  handleRedirectToHome = () => this.props.history.push('/');
}

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
  padding-bottom: 4%;
  padding-top: 4%;
`;

const StyledCard = styled(Card)`
  .ant-card-cover {
    padding: 10%;
    width: 100%;
  }

  .ant-card-head {
    border: none;
    text-align: center;
    padding-top: 10px;

    .ant-card-head-title {
            ${text.title}
            max-width: initial;
            white-space: normal !important;
            color: ${colors.text.dark}
        }
  }

  width: 25%;
`;

const Title = styled.span`
  font-family: Cambon;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 32px;
  line-height: 42px;
  color: #526B7C;
  margin-bottom: 20%;
  letter-spacing: 1.5px;
`;
