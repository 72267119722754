import TransactionTypeCard from './TransactionTypeCard';
import FormElement from './FormElement';

type Props = {
  mode?: string;
  quoteArray?: QuoteModel[];
  onTransactionTypeChange: (transactionType: TransactionType) => void;
  selectedType?: TransactionType;
};

const TransactionTypeChooser = ({
  onTransactionTypeChange, mode, quoteArray,
}: Props) => (
  <>
    <FormElement title="Which type of the transaction you are interested in?" mode={mode}>
      <TransactionTypeCard
        title="Sell"
        type="SELL"
        quoteArray={quoteArray}
        onTransactionTypeChange={onTransactionTypeChange}
      >
        We give you a preliminary offer and then perform a quality review after you&apos;ve sent us the item.
        If we agree on a final price, we&apos;ll buy the item from you.
      </TransactionTypeCard>
      <TransactionTypeCard
        title="Pawn"
        type="PAWN"
        quoteArray={quoteArray}
        onTransactionTypeChange={onTransactionTypeChange}
      >
        We give you a preliminary offer and then perform a quality review after you&apos;ve sent us the item. If
        we agree on a final price, we'll loan you the value of your item, and you&apos;ll pay us back with interest.
        When the loan is fully paid, the item will be returned to you.
      </TransactionTypeCard>
    </FormElement>
  </>
);

export default TransactionTypeChooser;
