/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */

import { message } from "antd";
import { useState } from "react";
import fonts from '../../../constants/fonts';
import ShowPawnDetails from '../components/ShowPawnDetails';

type Props = {
  interestData?: InterestData;
  quoteValue: number;
  mode: string;
  isAgreementVisible: boolean
  termsText?: any;
  agreementContent?: string;
  onCancelAgreement: () => void;
  onSignAgreement: () => void;
};

const { mainFont } = fonts.fonts;

const Agreement = ({
  interestData, quoteValue, mode, isAgreementVisible, agreementContent, onCancelAgreement, onSignAgreement,
}: Props) => {
  const [showTerms, setShowTerms] = useState(false);
  const [termsIsChecked, setTermsIsChecked] = useState(false);

  const closeTerms = () => {
    setShowTerms(false);
  };

  const handleCheckTerms = (e: any) => {
    setTermsIsChecked(e.target.checked);
  };

  const handleSubmit = () => {
    if (!termsIsChecked)
      return message.warn('Please check terms before confirm');
    onSignAgreement();
  };

  const agreement = <div dangerouslySetInnerHTML={{ __html: agreementContent || '' }} />;

  return (
    <div style={{ fontFamily: mainFont }}>
      <div className={`popup ${isAgreementVisible && 'popup--shown'}`}>
        <div className="popup__wrap agreement-popup">
          <div className="agreement-popup__header">
            <div className="agreement-popup__title">Agreement</div>
            <span className="popup__close agreement-popup__close" onClick={onCancelAgreement} />
          </div>
          <div className="agreement-popup__body">
            <div className="agreement-popup__info">
              <div className="popup__text popup__text--big">
                Below you can find the
                {`${mode === 'PAWN' ? ' loan ' : ' sale '}`}
                details:
              </div>
              <div className="popup__price popup__price--small">
                $
                {quoteValue.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
              {
                mode === 'PAWN' && (
                  <ShowPawnDetails
                    quoteValue={quoteValue}
                    interestData={interestData}
                  />
                )
              }
            </div>
            <label className="checkbox checkbox--small agreement-popup__checkbox">
              <input type="checkbox" name="checkbox" onChange={handleCheckTerms} />
              <span>
                Please confirm that you are accepting the
                <a style={{ color: '#4993c6' }} onClick={() => setShowTerms(true)}> Terms and Policy</a>
              </span>
            </label>
            <div className="popup__buttons popup__buttons--wide">
              <a className="popup__btn btn btn--dark" onClick={handleSubmit}>
                Confirm & Sign Agreement
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`popup ${showTerms && 'popup--shown'}`}>
        <div className="popup__wrap item-details">
          <div className="item-details__header">
            <div className="item-details__title">Terms and Policy</div>
            <a className="popup__close item-details__close" onClick={closeTerms} />
          </div>
          <div className="item-details__body">
            {agreement}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agreement;
