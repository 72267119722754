/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
type Props = {
  quoteValue?: number;
  shipmentInProgress?: boolean;
  onItemReceived?: () => void;
};

const AdminOfferAcceptedWaitingForShiping = ({
  quoteValue, shipmentInProgress, onItemReceived,
}: Props) => (
  <div className="dashboard-columns__right">
    <div className="dashboard-infocard marks-wrap marks-wrap--green">
      <div className="popup__header">
        <div className="popup__title">
          Preliminary Offer
          <br />
          <mark>Accepted</mark>
        </div>
      </div>
      <div className="popup__content">
        <div
          className="popup__price popup__price--small popup__price--green"
        >
          $
          {quoteValue?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="popup__footer">
        {
          shipmentInProgress
            ? (
              <>
                <div className="popup__text">Item(s) shipped by User</div>
                <div className="popup__buttons popup__buttons--wide">
                  <a className="popup__btn btn btn--dark" onClick={onItemReceived}>mark as received</a>
                </div>
              </>
            )
            : (
              <div className="popup__text">Waiting for shipping</div>
            )
        }
      </div>
    </div>
  </div>
);

export default AdminOfferAcceptedWaitingForShiping;
