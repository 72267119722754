/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
import fonts from '../../../../constants/fonts';

type Props = {
  quoteValue: number;
  onSubmit: () => void;
};

const { mainFont } = fonts.fonts;

const AcceptedByAdminCounterOffer = ({ quoteValue, onSubmit }: Props) => (
  <div style={{ fontFamily: mainFont }}>
    <div className="dashboard-columns">
      <div className="dashboard-columns__left">
        <div className="dashboard-card dashboard-card--green dashboard-card--huge-text">
          <div className="dashboard-card__title">Offer Accepted!</div>
          <div className="dashboard-card__text">
            Congratulations, we have
            <mark>accepted</mark>
            your counter offer. It’s a deal!
          </div>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 36.6668C18.1591 36.6668 16.6667 35.1744 16.6667 33.3335H23.3334C23.3334 35.1744 21.841 36.6668 20 36.6668ZM33.3334 31.6668H6.66669V28.3335L10 26.6668V17.5002C10 11.7302 12.3684 7.9885 16.6667 6.96683V3.3335H21.6667C20.5799 4.77339 19.9946 6.52953 20 8.3335C20 8.75246 20.0312 9.17084 20.0934 9.58516H20C17.9664 9.45345 16.005 10.3629 14.7917 12.0002C13.7543 13.6411 13.2452 15.5608 13.3334 17.5002V28.3335H26.6667V17.5002C26.6667 17.1485 26.655 16.8102 26.6317 16.5002C27.7341 16.7279 28.871 16.7319 29.975 16.5118C29.9934 16.8635 30 17.1985 30 17.5118V26.6668L33.3334 28.3335V31.6668ZM28.3334 13.3335C27.3263 13.3347 26.3426 13.0308 25.5117 12.4618C23.548 11.1195 22.8018 8.5795 23.7275 6.38834C24.6531 4.19717 26.9943 2.96147 29.3257 3.43356C31.657 3.90565 33.3331 5.95483 33.3334 8.3335C33.3334 11.0949 31.0948 13.3335 28.3334 13.3335Z" />
          </svg>
        </div>
        <div className="dashboard-card dashboard-card--smaller-text">
          <div className="dashboard-card__title">What’s Next?</div>
          <div className="dashboard-card__text">Now that we have agreed on an offer, you can generate a shipping label and send your item over to us. Once we receive the item, our experts will inspect your item, and upon approval, your payment will be initiated.</div>
        </div>
      </div>
      <div className="dashboard-columns__right">
        <div className="dashboard-infocard">
          <div className="popup__header">
            <div className="popup__title">Your New Offer</div>
          </div>
          <div className="popup__content">
            <div className="popup__price popup__price--small">
              $
              {quoteValue.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div className="popup__status status status--closed">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016C14.6628 11.6804 11.6804 14.6628 8.00016 14.6668ZM2.66683 8.11483C2.69837 11.049 5.09423 13.4065 8.02852 13.3908C10.9628 13.375 13.3332 10.9918 13.3332 8.05749C13.3332 5.12317 10.9628 2.74001 8.02852 2.72416C5.09423 2.70847 2.69837 5.066 2.66683 8.00016V8.11483ZM9.3335 11.3335H7.3335V8.66683H6.66683V7.3335H8.66683V10.0002H9.3335V11.3335ZM8.66683 6.00016H7.3335V4.66683H8.66683V6.00016Z" />
              </svg>
              <span>Pending Inspection</span>
              <div className="status__popup">The final offer will be determined once we have received your item(s) and our experts have completed the item inspection.</div>
            </div>
          </div>
          <div className="popup__footer">
            <div className="popup__buttons popup__buttons--wide">
              <div
                className="popup__btn btn btn--dark"
                onClick={onSubmit}
              >
                Ship item(s)
              </div>
            </div>
            <div className="popup__comment">
              Please print your shipping label
              <br />
              and send your item(s) to us
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AcceptedByAdminCounterOffer;
