import {
  Input, Modal, Form, Select,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { createRef } from 'react';
import styled from 'styled-components';
import { EmailTemplateTypes } from '../../../constants/mocks';
import { SecondaryButton } from '../../common/Button';

const { Option } = Select;

type Props = {
  visible: boolean;
  emailTemplate: EmailTemplate;
  onFinish: (values: string[], id: string) => void;
  onCancel: () => void;
};

const EmailTemplateEditModal = ({
  visible, emailTemplate, onFinish, onCancel,
}: Props) => {
  const formRef = createRef<FormInstance>();

  return (
    <Modal
      title="Edit Email Template"
      visible={visible}
      footer={[
        <SecondaryButton key="back" onClick={onCancel}>
          Close
        </SecondaryButton>,
        <SecondaryButton onClick={() => formRef.current?.submit()}>
          Save
        </SecondaryButton>,
      ]}
      onCancel={onCancel}
    >
      <Form initialValues={emailTemplate} ref={formRef} onFinish={values => onFinish(values, emailTemplate.id)}>
        <Label>Email type</Label>
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: 'Please select type',
            },
          ]}
        >
          <Select placeholder="Select type">
            {EmailTemplateTypes.map(m => <Option value={m.value}>{m.text}</Option>)}
          </Select>
        </Form.Item>
        <Label>Email subject</Label>
        <Form.Item
          name="subject"
          rules={[
            {
              required: true,
              message: 'Please type subject',
            },
          ]}
        >
          <Input value={emailTemplate.subject} />
        </Form.Item>
        <Label>Email title</Label>
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: 'Please type title',
            },
          ]}
        >
          <Input value={emailTemplate.title} />
        </Form.Item>
        <Label>Email content</Label>
        <Form.Item
          name="content"
          rules={[
            {
              required: true,
              message: 'Please type content',
            },
          ]}
        >
          <Input.TextArea rows={15} value={emailTemplate.content} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Label = styled.div`
    font-family: Cambon;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #575c60;
    margin-bottom: 10px;
`;

export default EmailTemplateEditModal;
