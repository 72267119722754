import BoxBrandNew from '../../assets/images/box-brand-new.png';
import BoxLikeNew from '../../assets/images/box-like-new.png';
import BoxUsed from '../../assets/images/box-used.png';
import BoxWarn from '../../assets/images/box-warn.png';
import FormCardBase from './FormCardBase';

type Props = {
  type: BoxTypes;
  isSelected: boolean;
  onClick: (value: BoxTypes) => void;
};

const BoxImages = {
  'brand-new': BoxBrandNew,
  'like-new': BoxLikeNew,
  used: BoxUsed,
  worn: BoxWarn,
};

const ConditionCard = ({ type, isSelected, onClick }: Props) => (
  <FormCardBase type={type} className="condition-card" isSelected={isSelected} onClick={() => onClick(type)}>
    <>
      <img src={BoxImages[type]} alt="condition-card" />
      {type}
    </>
  </FormCardBase>
);

export default ConditionCard;
