/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Form,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import {
  common,
} from 'components';
import { quoteStore, actionTypes } from '../store';
import {
  runMain,
} from '../assets/js/main';
import { api } from '../api';
import {
  usaStateCodes,
} from '../constants';
import EMAIL_NOTIFICATIONS_STAGES from '../constants/emailNotificationsStages';

type Props = RouteComponentProps;

const {
  Spinner,
} = common;

const { RF_3_3_METAL_COUNTER, RF_3_3_METAL_AUTO, RF_3_3_ITEM } = EMAIL_NOTIFICATIONS_STAGES;

const PaymentInformation = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<any>({});
  const [phoneNumberLength, setPhoneNumberLength] = useState(0);
  const [paymentType, setPamentType] = useState('');
  const [formPayPal] = Form.useForm();
  const [formCheck] = Form.useForm();

  useEffect(() => {
    runMain();
    setState(props.location.state);
    if (!props.location.state)
      props.history.push('/');
  });

  const handlePaymentType = (value: string) => {
    setPamentType(value);
  };

  const validatePhoneNumberLength = (_: any, __: any, callback: any): any => {
    if (phoneNumberLength === 11 || phoneNumberLength === 0) {
      callback();
    } else {
      callback('Phone number should contain 11 digits');
    }
  };

  const phoneNumberOnChange = (e: { target: { value: any; }; }) => {
    const input = [...e.target.value];

    const num = input.filter(i => i.match('[0-9]'));

    setPhoneNumberLength(num.length);
  };

  const handleFinish = (values: any) => {
    setIsLoading(true);

    if (state.type === 'bullion') {
      api.post('metal-requests/submit', {
        generalInfo: state.generalInfo,
        emailNotification: state.generalInfo.isCounterOffer ? RF_3_3_METAL_COUNTER : RF_3_3_METAL_AUTO,
        payment: { type: paymentType, ...values },
      }).then(res => {
        props.history.push(`/request-details/${res.data}`);
      })
        .finally(() => setIsLoading(false));

      return;
    }

    api
      .post('quotes/submit', { quotes: state.quote, payment: { type: paymentType, ...values }, emailNotification: RF_3_3_ITEM })
      .then(res => {
        setIsLoading(false);
        props.history.push(`/request-details/${res.data}`);
      })
      .finally(() => {
        quoteStore.dispatch({ type: actionTypes.CLEAR_ITEMS });
      });
  };

  return (
    <main className="main" style={{ margin: '84px 0 0 0' }}>
      <div className="request">

        <div className="request-header request-header--payment">
          <div className="container">
            <div className="request-payment-steps">
              <div
                className="
                  request-payment-steps-unit
                  request-payment-steps-unit--completed
                "
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18C32.9909 26.2805 26.2805 32.9909 18 33ZM17.976 30H18C24.6251 29.9934 29.9913 24.6191 29.988 17.994C29.9847 11.3689 24.6131 6.00001 17.988 6.00001C11.3629 6.00001 5.99131 11.3689 5.988 17.994C5.98469 24.6191 11.3509 29.9934 17.976 30ZM15 25.5L9 19.5L11.115 17.385L15 21.255L24.885 11.37L27 13.5L15 25.5Z"
                  />
                </svg>
                <span>Step 1. Add Items</span>
              </div>
              <div className="request-payment-steps-unit">
                <span>Step 2. Add Payment Information</span>
              </div>
            </div>
          </div>
        </div>
        <div className="request-body">
          <div className="container">
            <div className="request-payment">
              <div className="request-payment__title">
                How would you like to be paid?
              </div>
              <div className="request-payment__text">
                Provide descripyion here, how it works, and that nothing will be
                charged without confirmation and only after signed agreement.
              </div>
              <div className="request-payment__wrap">

                <div className="request-payment-option">
                  <label className="checkbox request-payment-option__header">
                    <input type="radio" name="js-payment-option" onChange={() => handlePaymentType('paypal')} />
                    <span>
                      <img
                        className="request-payment-option__image"
                        src="images/payment-options/paypal.png"
                        alt="paypal"
                      />
                    </span>
                  </label>
                  <div className="request-payment-option__description">
                    Payment directly to your PayPal account
                  </div>
                  <div className="request-payment-option__body">
                    <Form id="formPayPal" form={formPayPal} className="request-payment-form" onFinish={handleFinish}>
                      <div className="request-payment-form__text">
                        Confirm your PayPal email address so we can ensure
                        prompt payment.
                      </div>

                      <div className="request-payment-form__body">
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">
                              PayPal email address
                            </div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  type: 'email',
                                  message: '',
                                },
                                {
                                  required: true,
                                  message: 'Email is required',
                                },
                              ]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="email"
                                placeholder="Email address"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">
                              Confirm email adress
                            </div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="confirmEmail"
                              dependencies={['email']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please confirm your email address',
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value || getFieldValue('email') === value) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject('Email addresses do not match');
                                  },
                                }),
                              ]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="email"
                                placeholder="Confirm address"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>

                      <button
                        className="request-payment-form__submit btn btn--dark"
                        type="submit"
                        onClick={handleFinish}
                      >
                        {
                          isLoading
                            ? <Spinner loaderSize={{ width: 20, height: 20 }} tip={' '} style={{ width: 100, marginBottom: 10 }} />
                            : 'Submit & Get an Offer'
                        }
                      </button>
                    </Form>
                  </div>
                </div>
                <div className="request-payment-option">
                  <label className="checkbox request-payment-option__header">
                    <input type="radio" name="js-payment-option" onChange={() => handlePaymentType('check')} />
                    <span>
                      <img
                        className="request-payment-option__image"
                        src="images/payment-options/check.png"
                        alt="check"
                      />
                    </span>
                  </label>
                  <div className="request-payment-option__description">
                    Check delivered by mail within 10 days upon receival and
                    approval of your item(s)
                  </div>
                  <div className="request-payment-option__body">
                    <Form id="formCheck" form={formCheck} className="request-payment-form" onFinish={handleFinish}>
                      <div className="request-payment-form__text">
                        {"Please confirm or change the address you'd like your"}
                        check sent to.
                      </div>
                      <div className="request-payment-form__body">
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">
                              Payable to
                            </div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="payableTo"
                              rules={[
                                {
                                  required: true,
                                  message: 'Payable to is required',
                                },
                              ]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="text"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">
                              Address Line 1
                            </div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="firstAddress"
                              rules={[
                                {
                                  required: true,
                                  message: 'Address Line 1 is required',
                                },
                              ]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="text"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">
                              Address Line 2
                            </div>
                            <div className="request-payment-form-unit__comment">
                              (optional)
                            </div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="secondAddress"
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="text"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">
                              City
                            </div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="city"
                              rules={[
                                {
                                  required: true,
                                  message: 'City is required',
                                },
                              ]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="text"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">
                              State
                            </div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item name="stateOfProvinceCode" rules={[{ required: true, message: '' }]} initialValue={'AL'}>
                              <select>
                                {Object.entries(usaStateCodes).map(([key, value]) => (
                                  <option key={value} value={key} className="request-payment-form-unit__title">{`${key} - ${value}`}</option>
                                ))}
                              </select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">
                              Zip Code
                            </div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="zipCode"
                              rules={[
                                {
                                  required: true,
                                  message: 'Zip Code is required',
                                },
                              ]}
                            >
                              <input
                                className="request-payment-form-unit__input"
                                type="text"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="request-payment-form-unit">
                          <div className="request-payment-form-unit__header">
                            <div className="request-payment-form-unit__title">
                              Phone
                            </div>
                            <div className="request-payment-form-unit__comment">
                              (optional)
                            </div>
                          </div>
                          <div className="request-payment-form-unit__body">
                            <Form.Item
                              name="phoneNumber"
                              rules={[
                                { validator: validatePhoneNumberLength },
                              ]}
                            >
                              {/* <input
                                className="request-payment-form-unit__input"
                                type="tel"
                              /> */}
                              <MaskedInput mask="+1 (***) ***-****" className="request-payment-form-unit__input" onChange={phoneNumberOnChange} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <button
                        className="request-payment-form__submit btn btn--dark"
                        onClick={handleFinish}
                      >
                        {
                          isLoading
                            ? <Spinner loaderSize={{ width: 20, height: 20 }} tip={' '} style={{ width: 100, marginBottom: 10 }} />
                            : 'Submit & Get an Offer'
                        }
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default PaymentInformation;
