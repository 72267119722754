type Props = {
  trackingInfo: ShippingLabel[] | undefined;
  isRejected?: boolean;
  trackingBackNumber?: string;
};

const TrackingInfoBody = ({ trackingInfo, isRejected, trackingBackNumber }: Props): JSX.Element => (
  <div className="tracking-info__body">
    <div className="tracking-info__title">Tracking Information:</div>
    <div className="tracking-info__unit">
      <span>Address: </span>
      <span>
        {trackingInfo ? trackingInfo[0]?.shipmentDetails.addressLine : ''}
        {trackingInfo?.length !== 0 && ' / '}
        {trackingInfo ? trackingInfo[0]?.shipmentDetails.city : ''}
        {trackingInfo?.length !== 0 && ' / '}
        {trackingInfo ? trackingInfo[0]?.shipmentDetails.postalCode : ''}
      </span>
    </div>
    <div className="tracking-info__unit">
      {isRejected
        ? (
          <>
            <span>Tracking back number: </span>
            <span>{trackingBackNumber}</span>
          </>
        )
        : (
          <>
            <span>Tracking number: </span>
            <span>{trackingInfo ? trackingInfo[0]?.trackingNumber : ''}</span>
          </>
        )}
    </div>
    <div className="tracking-info__unit">
      <span>Client name: </span>
      <span>
        {trackingInfo ? trackingInfo[0]?.shipmentDetails.firstName : ''}
        {trackingInfo ? trackingInfo[0]?.shipmentDetails.lastName : ''}
      </span>
    </div>
    <div className="tracking-info__unit">
      <span>Contact number: </span>
      <span>
        {trackingInfo ? trackingInfo[0]?.shipmentDetails.phone : ''}
      </span>
    </div>
  </div>
);

export default TrackingInfoBody;
