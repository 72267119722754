import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PlaidLink as ReactPlaidLink } from 'react-plaid-link';
import { isEqual } from 'lodash';
import { colors, text } from '../../constants';
import { api } from '../../api';
import Spinner from './Spinner';
import { userStore, actionTypes } from '../../store';

type Props = {
  typeConnection?: string;
  loanId?: string;
  onFinish: () => void;
};

const PlaidLink = ({ typeConnection, loanId, onFinish }: Props) => {
  const [linkToken, setLinkToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const createLinkToken = () => {
      setIsLoading(true);
      api
        .post('/plaid/create-link-token')
        .then(res => res.data)
        .then(res => {
          const { linkToken } = res;
          setLinkToken(linkToken);
        })
        .finally(() => setIsLoading(false));
    };
    createLinkToken();
  }, []);

  const updateUser = () => {
    api
      .post('/user')
      .then(res => res.data)
      .then((user: User) => {
        userStore.dispatch({ type: actionTypes.ADD_USER, user });
      })
      .then(() => {
        onFinish();
        setIsLoading(true);
      })
      .catch(() => { });
  };

  const onSuccess = (token: any, metadata: any) => {
    setIsLoading(true);
    const updateBankAccount = typeConnection === 'CHANGE_BANK_ACCOUNT';
    api
      .post('/plaid/create-item', {
        publicToken: token,
        ...metadata,
        updateBankAccount,
        loanId,
      })
      .then(res => res.data)
      .then(() => updateUser());
  };

  if (isLoading)
    return <Spinner />;

  return (
    <StyledPlaidLink
      token={linkToken}
      className="plaid-link"
      onSuccess={onSuccess}
    >
      Connect a bank account
    </StyledPlaidLink>
  );
};

const StyledPlaidLink = styled(ReactPlaidLink)`
  background-color: ${colors.button.primary} !important;
  color: ${colors.background['widget-white']} !important;
  border: 1px solid ${colors.button.primary} !important;
  padding: 0px 10px !important;
  ${text['buttom-caps-14']}
  font-size: 11px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.5px !important;
  cursor: pointer !important;
  outline: none !important;

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    &:not([disabled]) {
      border: 1px solid ${colors.button.primary};
      background-color: ${colors.background['widget-white']};
      color: ${colors.button.primary};
    }
  }

  @media (min-width: 1024px) {
    padding: 0 5px;
  }

  @media (mim-width: 1440px) {
    padding: 0 10px;
    font-size: 12px;
  }

  @media (min-width: 1700px) {
    font-size: 16px;
  }
`;

export default React.memo(PlaidLink, (prevProps, nextProps) => isEqual(prevProps, nextProps));
