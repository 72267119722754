/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-boolean-value */
import { Modal, Button } from 'antd';
import styled from 'styled-components';

type Props = {
  isVisible: boolean;
  quoteValue?: number;
  onCancel?: () => void;
  onOk?: () => void;
};

const CancelOfferPopUp = ({
  isVisible,
  quoteValue,
  onCancel,
  onOk,
}: Props) => (
  <Modal
    visible={isVisible}
    closable={false}
    okButtonProps={{
      style: {
        display: 'none',
      },
    }}
    cancelButtonProps={{
      style: {
        display: 'none',
      },
    }}
    destroyOnClose
  >
    <StyledWrap>
      <div className="title">Your counter offer is:</div>
      <div className="quote-value">{`$ ${quoteValue?.toFixed(2)}`}</div>
      <Button
        style={{
          backgroundColor: '#526b7c',
          color: '#fff',
          fontSize: 14,
          fontWeight: 600,
        }}
        onClick={onOk}
      >
        {'CONFIRM & SEND'}
      </Button>
      <Button
        style={{
          backgroundColor: '#FFF',
          color: '#526b7c',
          marginTop: 20,
          fontSize: 14,
        }}
        onClick={onCancel}
      >
        BACK
      </Button>
    </StyledWrap>
    {/* </div> */}
  </Modal>
);

const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .quote-value {
    font-size: 48px;
    font-weight: 600;
    color: #C0A725;
    margin: 50px 0;
  }
`;

export default CancelOfferPopUp;
