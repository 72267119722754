import React, { useState, useLayoutEffect } from 'react';
import { StyledSider } from './styles/styled';

const CustomSider = (props: { children: JSX.Element, collapsed: boolean }) => {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <StyledSider collapsedWidth={0} trigger={null} width={300} collapsed={size <= 991 ? props.collapsed : false}>
      {props.children}
    </StyledSider>
  );
};

export default CustomSider;
