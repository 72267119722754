import { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { upperFirst } from 'lodash';
import { data } from '../constants';
import { searchTree } from '../helpers/tree';

import { YellowBall, GreyBall } from '../components/common/BackgroundBalls';
import { Spinner } from '../components/common';
import HowItWorks from '../components/HowItWorks';
import Package from '../assets/images/package.svg';

type State = {
  cover: string;
  title: string;
  isLoading: boolean;
};

class WhatsNextView extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      cover: '',
      title: '',
      isLoading: true,
    };
  }

  componentDidMount() {
    this.handleGetCover();
  }

  render() {
    const { cover, title, isLoading } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <Row justify="center" gutter={[32, 32]}>
        <Col span={24} style={{ marginTop: 40 }}>
          <ImageContainer>
            <ImageContainerContent url={cover} style={{ height: '100%', width: '100%' }}>
              <Row align="middle" style={{ height: '100%', width: '100%' }}>
                <Col span={24}>
                  <Row justify="center">
                    <ImageText>
                      Thank You for the Request:
                    </ImageText>
                  </Row>
                  <Row justify="center">
                    <BigImageText>
                      {this.handleDisplayTitle(title)}
                    </BigImageText>
                  </Row>
                </Col>
              </Row>
            </ImageContainerContent>
          </ImageContainer>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Header>What&apos;s next?</Header>
          </Row>
        </Col>
        <Col style={{ height: '75%' }} span={23}>
          <HowItWorks gutter={[32, 32]} justify="center" />
        </Col>

        <GreyBall
          top="150px"
          left="calc(50% - 50px - 325px)"
          width="100px"
          height="100px"
        />
        <GreyBall
          top="350px"
          left="calc(50% + 290px)"
          width="90px"
          height="90px"
        />
        <YellowBall
          top="130px"
          left="calc(50% + 390px)"
          width="40px"
          height="40px"
        />
      </Row>
    );
  }

  handleGetCover = () => {
    const { location: { pathname } } = this.props;

    const coverName = pathname.split('/').pop();

    if (!coverName) return;

    if (coverName === 'group')
      return this.setState({ cover: Package, title: 'Package', isLoading: false });

    const category = searchTree(data.CATEGORIES, coverName);

    if (category.imgCover) {
      switch (coverName) {
        case 'other-coins':
          this.setState({ cover: category.imgCover, title: 'Other' });
          break;
        case 'metal-gold':
          this.setState({ cover: category.imgCover, title: 'Gold' });
          break;
        case 'metal-silver':
          this.setState({ cover: category.imgCover, title: 'Silver' });
          break;
        case 'metal-platinium':
          this.setState({ cover: category.imgCover, title: 'Platinium' });
          break;
        default:
          this.setState({ cover: category.imgCover, title: category.name });
          break;
      }
    }
    this.setState({ isLoading: false });
  };

  handleDisplayTitle = (title: string) => {
    if (title.includes('&')) {
      return title.split('&').map((str: string) => upperFirst(str)).join(' / ');
    }
    return upperFirst(title);
  };
}

const Header = styled.span`
    font-family: Cambon;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    color: #31393E;
`;

const ImageContainer = styled(Row)`
    width: 626px;
    height: 340px;
    margin: auto;

    @media (max-width: 768px) {
        width: 90%;
        margin: auto;
    }
`;

const ImageContainerContent = styled.div<{ url: string }>`
    &:before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(66, 72, 87, 0.8) 0.01%,
          rgba(66, 72, 87, 0.3) 100%),
        url(${props => props.url}) no-repeat top center;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
        border-radius: 20px;
    }
    position: relative;
    z-index: 2;
`;

const ImageText = styled.span`
    font-family: Cambon;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #B7BFC5;
    position: relative;
    z-index: 2;
`;

const BigImageText = styled.span`
    font-family: Cambon;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;
    color: #FFFFFF;
    position: relative;
    z-index: 2;
`;

export default withRouter(WhatsNextView);
