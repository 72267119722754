import { useState } from 'react';
import {
  Collapse, Button, Row, Select, Col, Typography,
} from 'antd';
import styled from 'styled-components';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { colors } from '../constants';
import { Input } from './common';

const { Panel } = Collapse;
const { Option } = Select;
const { Title } = Typography;
const { ControlledFormTextArea } = Input;

type Props = {
  faq: Faq;
  onFaqChange: ({ title, content }: Faq) => void;
  onFaqDelete: (id: string) => void;
  size: number;
};

const Faq = ({
  faq: {
    title, id, content, sequence,
  }, onFaqChange, onFaqDelete, size,
}: Props) => {
  const [isEdditing, setIsEdditing] = useState<boolean>(false);
  const [newTitle, setNewTitle] = useState(title);
  const [newContent, setNewContent] = useState(content);
  const [newSequence, setNewSequence] = useState(sequence);

  const onSave = () => {
    onFaqChange({
      title: newTitle,
      content: newContent,
      id,
      sequence: newSequence,
    });
    setIsEdditing(false);
  };

  const onDelete = () => {
    onFaqDelete(id);
    setIsEdditing(false);
  };

  return (
    <StyledCollapse accordion>
      <Panel header={title} key={id}>
        <Row gutter={[16, 16]}>
          <Button size="small" icon={<EditOutlined />} type="text" onClick={() => setIsEdditing(!isEdditing)}>
            {isEdditing ? 'Close' : 'Edit'}
          </Button>
          <Button size="small" icon={<DeleteOutlined />} type="text" onClick={() => onDelete()}>
            Delete
          </Button>
          {isEdditing && (
            <Button size="small" icon={<EditOutlined />} type="text" onClick={() => onSave()}>
              Save
            </Button>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          {isEdditing && (
            <CSelect
              dropdownStyle={{ textAlign: 'center' }}
              defaultValue={sequence}
              style={{ width: 120 }}
              onChange={e => setNewSequence(parseInt(`${e}`, 10))}
            >
              {new Array(size)
                .fill(0)
                .map((_, idx) => 1 + idx)
                .map(c => (
                  <Option key={c} value={c}>
                    {c}
                  </Option>
                ))}
            </CSelect>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={23} sm={12}>
            <Title level={5}>Title:</Title>
          </Col>
        </Row>
        <Row>
          <Col xs={23} sm={12}>
            {isEdditing ? (
              <ControlledFormTextArea rows={6} onChange={e => setNewTitle(e)} value={newTitle} />
            ) : (
              newTitle
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={23} sm={12}>
            <Title level={5}>Content:</Title>
          </Col>
        </Row>
        <Row>
          <Col xs={23} sm={12}>
            {isEdditing ? (
              <ControlledFormTextArea rows={6} onChange={e => setNewContent(e)} value={newContent} />
            ) : (
              newContent
            )}
          </Col>
        </Row>
      </Panel>
    </StyledCollapse>
  );
};

const CSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    color: ${colors.text.light} !important;
    background-color: ${colors.textFields} !important;
    .ant-select-selection-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ant-select-item-option-content {
    text-align: center;
  }
`;

const StyledCollapse = styled(Collapse)`
  width: 100%;
  .form-text-area {
    width: 50% !important;
    max-width: 50% !important;
    margin-top: 2%;
  }

  @media(max-width: 1600px) {
    .form-text-area {
      width: 75% !important;
      max-width: 75% !important;
      margin-top: 2%;
    }
  }

  @media(max-width: 992px) {
    .form-text-area {
      width: 100% !important;
      max-width: 100% !important;
      margin-top: 2%;
    }
  } 
`;

export default Faq;
