import { createStore } from 'redux';
import userReducer from './reducer';
import quoteReducer from './quoteReducer';
import optionReducer from './optionReducer';

const userStore = createStore(userReducer);
const quoteStore = createStore(quoteReducer);
const optionStore = createStore(optionReducer);

export { quoteStore, userStore, optionStore };
