/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
type Props = {
  quoteValue: number;
  onConfirm: () => void;
};

const AdminSalePaymentConfirmation = ({ quoteValue, onConfirm }: Props) => (
  <div className="dashboard-columns__right">
    <div className="dashboard-infocard">
      <div className="popup__header">
        <div className="popup__title">Sale agreement signed</div>
      </div>
      <div className="popup__content">
        <div className="popup__price popup__price--small">
          $
          {quoteValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="popup__footer">
        <div className="popup__buttons popup__buttons--wide">
          <a className="popup__btn btn btn--dark" onClick={onConfirm}>Confirm Payment</a>
        </div>
      </div>
    </div>
  </div>
);

export default AdminSalePaymentConfirmation;
