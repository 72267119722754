import styled from 'styled-components';
import { colors } from '../../constants';

type Props = {
  tip?: string,
  style?: {},
  loaderSize?: {},
};

const Spinner = (props: Props) => (
  <StyledDiv style={{ ...props.style }}>
    <Loader style={{ ...props.loaderSize }} />
    <Text>{props.tip || 'Loading...'}</Text>
  </StyledDiv>
);

const StyledDiv = styled.div`
    margin-top: 10%;
    width: 100%;
`;
const Text = styled.div`
    margin-top: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: medium;
    color: ${colors.text.body};
`;

const Loader = styled.div`
    border: 5px solid #F3EFE0;
    border-top: 5px solid ${colors.text.body};
    border-radius: 50%;
    transform-origin: center center;
    width: 90px;
    height: 90px;
    animation: spin 1s linear infinite;
    position: relative;
    margin: 0 auto;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
`;

export default Spinner;
