import dayjs from 'dayjs';
import { upperFirst } from 'lodash';

export const thereAreUserTransactionColumns = (callback: (record: Loan) =>
JSX.Element, textMapperCallback: (text: String) => String) => (
  [
    {
      title: 'Number',
      key: 'id',
      dataIndex: 'customId',
    },
    {
      title: 'Name',
      dataIndex: 'quote',
      key: 'name',
      render: ({ name }: Quote) => (
        <>
          {upperFirst(name)}
        </>
      ),
    },
    {
      title: 'Principal Amount',
      dataIndex: 'loanAmount',
      key: 'principalAmount',
      render: (text: String) => textMapperCallback(text),
    },
    {
      title: 'Current Interests',
      dataIndex: 'interestValue',
      key: 'currentInterests',
      render: (text: String) => textMapperCallback(text),
    },
    {
      title: 'Maturity Date',
      dataIndex: 'startDate',
      key: 'maturityDate',
      render: (date: Date) => <>{dayjs(date).add(90, 'd').format('MM.DD.YYYY')}</>,
    },
    {
      title: 'Status',
      key: 'status',
      render: (record: any) => <>{record.payment ? 'Closed' : 'Open'}</>,
    },
    {
      title: '',
      key: 'action',
      render: (record: Loan) => callback(record),
    },
  ]
);

export const thereAreAdminTransactionColumns = (callback: (record: Loan) =>
JSX.Element, textMapperCallback: (text: String) => String) => (
  [
    {
      title: 'Number',
      key: 'id',
      dataIndex: 'customId',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'name',
      render: ({ firstName, lastName }: User) => (
        <>
          {`${firstName} ${lastName}`}
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'quote',
      key: 'name',
      render: ({ name }: Quote) => (
        <>
          {upperFirst(name)}
        </>
      ),
    },
    {
      title: 'End date',
      dataIndex: 'startDate',
      key: 'renevalTimes',
      render: (date: Date) => <>{dayjs(date).add(90, 'd').format('MM.DD.YYYY')}</>,
    },
    {
      title: 'Principal Amount',
      dataIndex: 'loanAmount',
      key: 'principalAmount',
      render: (text: String) => textMapperCallback(text),
    },
    {
      title: 'Current Interests',
      dataIndex: 'interestValue',
      key: 'currentInterests',
      render: (text: String) => textMapperCallback(text),
    },
    {
      title: 'Interest payment method',
      dataIndex: 'interestRepaymentType',
      key: 'interestRepaymentType',
      render: (text: String) => upperFirst(text.toLocaleLowerCase()),
    },
    {
      title: 'Loan Balance',
      dataIndex: 'loanAmount',
      key: 'principalAmount',
      render: (text: String) => textMapperCallback(text),
    },
    {
      title: 'Status',
      key: 'status',
      render: (record: Loan) => (
        <>
          { record.payment ? 'Closed' : 'Open' }
        </>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (record: Loan) => callback(record),
    },
  ]
);
