const DiamondSvg = () => (
  <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9371 16L1 5.25M10.9371 16L21
      5.25M10.9371 16L15.4654 5.25M10.9371 16L6.40881 5.25M1 5.25L4.27044 1H8.79874M1
      5.25H6.40881M21 5.25L17.478 1H13.1384M21 5.25H15.4654M15.4654 5.25L13.1384 1M15.4654
      5.25H6.40881M6.40881 5.25L8.79874 1M8.79874 1H13.1384"
      stroke="url(#paint0_linear)"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="11" y1="1" x2="11" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BFDFF4" />
        <stop offset="1" stopColor="#AEC9DB" />
      </linearGradient>
    </defs>
  </svg>
);

export default DiamondSvg;
