const toString = (value: any) => {
  if (!value) {
    return '';
  }
  if (typeof (value) === 'boolean') {
    return value.toString();
  }
  if (Array.isArray(value)) {
    return value.join(' ');
  }
  return value;
};

export {
  toString,
};
