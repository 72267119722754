import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash';
import pad from '../helpers/pad';
import ingot from '../assets/images/gold-ingot.png';
import { common, Modals, Timeline as CustomTimeline } from '../components';
import {
  colors, data, text, date,
} from '../constants';
import { searchTree } from '../helpers/tree';
import { api } from '../api';
import { userStore } from '../store';

const {
  Button, Spinner, Table, Badge, Title, Steper,
} = common;
const { PayModal, Agreement } = Modals;
const { AgreementDetailsModal } = Agreement;
const { ActionButton, SecondaryButton } = Button;

type Props = {
} & RouteComponentProps;

type State = {
  cover: string,
  loan: Loan,
  isLoading: boolean,
  isPayModalVisible: boolean,
  isAgreementDetailsVisible: boolean,
};

class TransactionDetailsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cover: '',
      loan: data.LOAN_DEFUALT,
      isLoading: true,
      isPayModalVisible: false,
      isAgreementDetailsVisible: false,
    };
  }

  componentDidMount() {
    this.fetchTransaction();
  }

  render() {
    const {
      cover,
      loan: {
        quote: { context, name, agreement },
        user: { firstName, lastName },
        startDate,
        loanAmount,
        interestValue,
        payment,
        subscription,
        monthlyPercentageInterestRate,
        interestRepaymentType,
      },
      isLoading,
      isPayModalVisible,
      isAgreementDetailsVisible,
    } = this.state;

    if (isLoading)
      return <Spinner />;

    const { role } = userStore.getState();
    return (
      <body className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <Steper title="Transactions" description="Transaction Details" />
            <Container className="transaction-detals-view-container">
              <Col style={{ marginRight: '30px' }}>
                <ItemCard>
                  <ItemInfo style={{ float: 'left' }}>
                    <CaptionText>
                      {context.mode}
                    </CaptionText>
                    <CaptionText>
                      {`Updated: ${dayjs(startDate).format(date.full)}`}
                    </CaptionText>
                    <CardTitle>{upperFirst(name)}</CardTitle>
                    <Badge
                      backgroundColor={colors.background.inputNumber}
                      color={colors.text.green}
                      width="80%"
                      textAlignCenter
                    >
                      Request Accepted
                    </Badge>
                  </ItemInfo>
                  <CardImage src={cover} alt="cover" />
                </ItemCard>
                <StyledRow>
                  <SecondaryButton
                    style={{ marginRight: '10px' }}
                    onClick={() => this.handleRedirectToRequest()}
                  >
                    View Request
                  </SecondaryButton>
                  <SecondaryButton onClick={() => this.setState({ isAgreementDetailsVisible: true })}>
                    View Agreement
                  </SecondaryButton>
                </StyledRow>
                {role === 'user'
                  && (
                    <StyledRow>
                      <SecondaryButton
                        disabled={!!payment}
                        onClick={() => this.handlePayModalVisibility(true)}
                      >
                        Add Manual Payment
                      </SecondaryButton>
                    </StyledRow>
                  )}
              </Col>
              <ActionButtonsSection>
                <ActionButtons>
                  <CaptionTextMedium>
                    {`Current Agreement: ${agreement?.id || ''}`}
                  </CaptionTextMedium>
                  <CaptionTextMedium>
                    {`Agreement Date: ${agreement?.createdAt ? dayjs(agreement?.createdAt).format(date.full) : ''}`}
                  </CaptionTextMedium>
                  <CaptionTextMedium>
                    {`Maturity Date: ${dayjs(startDate).add(90, 'd').format(date.full)}`}
                  </CaptionTextMedium>
                  <CaptionTextMedium>
                    {`Requestor: ${firstName} ${lastName}`}
                  </CaptionTextMedium>
                  <CaptionTextMedium>
                    {`Principal Payment: $${loanAmount}`}
                  </CaptionTextMedium>
                  <CaptionTextMedium>
                    {`Current Interests: $${interestValue}`}
                  </CaptionTextMedium>
                  <CaptionTextMedium>
                    {`Interest ${monthlyPercentageInterestRate * 100}% per 30 days (36% APR): $${interestValue}`}
                  </CaptionTextMedium>
                  <CaptionTextMedium>
                    {`Interest ${monthlyPercentageInterestRate * 100}% per 30 days (36% APR): $${interestValue}`}
                  </CaptionTextMedium>
                  <CaptionTextMedium>
                    {`Interest payment method: ${upperFirst(interestRepaymentType.toLocaleLowerCase())}`}
                  </CaptionTextMedium>
                  <CaptionTextMedium>
                    {`Renewal Times: ${dayjs(subscription?.persiodEnd).add(30, 'd').format(date.full)}`}
                  </CaptionTextMedium>
                </ActionButtons>
              </ActionButtonsSection>
              <SecondContainer>
                <CustomTimeline loan={this.state.loan} />
                <Title title="Transaction History" />
                <Table dataSource={this.formatTableDataSource()} columns={this.columns} />
              </SecondContainer>
            </Container>
            <PayModal
              visible={isPayModalVisible}
              onPay={this.handlePay}
              onCancel={() => this.handlePayModalVisibility(false)}
              transaction={this.state.loan}
              onBankConnection={this.fetchTransaction}
            />
            {isAgreementDetailsVisible && (
              <AgreementDetailsModal
                agreement={this.state.loan.quote?.agreement}
                onCancel={() => this.setState({ isAgreementDetailsVisible: false })}
                readonly
              />
            )}
          </div>
        </div>
      </body>
    );
  }

  handleIsLoading = (isLoading: boolean) => this.setState({ isLoading });

  handlePay = () => this.setState({ isPayModalVisible: false }, () => this.fetchTransaction());

  formatTableDataSource = () => {
    const { loan } = this.state;

    const invoices = loan.invoices.map(i => ({
      principalPaid: 0,
      agreementDate: dayjs(loan.startDate).format('MM.DD.YYYY'),
      maturityDate: dayjs(i.date).format('MM.DD.YYYY'),
      principalAmount: loan.loanAmount,
      interestsPaid: i.value,
      totalPaid: i.value,
      details: false,
      status: 'Succeeded',
    }));

    if (!loan.payment)
      return invoices;

    const principalPaid = Math.max(0, loan.payment.value.total - +loan.payment.value.interest).toFixed(2);

    return [
      ...invoices, {
        principalPaid,
        details: true,
        agreementDate: dayjs(loan.startDate).format('MM.DD.YYYY'),
        maturityDate: loan.charges.length > 3 && dayjs(loan.charges[3].date).format('MM.DD.YYYY'),
        principalAmount: loan.loanAmount,
        interestsPaid: loan.payment.value.interest,
        totalPaid: loan.payment.value.total,
        status: loan.payment.status,
      }];
  };

  handleRedirectToRequest = () => {
    const { loan: { quote: { groupId } } } = this.state;
    this.props.history.push(`/request-details/${groupId}`);
  };

  handleRedirectToPaymentDetails = () => {
    const { loan: { id } } = this.state;
    this.props.history.push(`/transactions/payment/${id}`);
  };

  fetchTransaction = () => {
    const { location } = this.props;
    const paths = location.pathname.split('/');
    const id = [...paths].pop();

    this.setState({ isLoading: true });

    api
      .get(`/transactions/${id}`)
      .then(res => res.data)
      .then(res => {
        const cover = this.findCover(res.quote.name || '');
        this.setState({ loan: res, cover });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  findCover = (name: string) => {
    const cover = searchTree(data.CATEGORIES, name);
    return cover.imgCover || ingot;
  };

  handlePayModalVisibility = (state: boolean) => this.setState({ isPayModalVisible: state });

  columns = [
    {
      title: 'Number',
      key: 'number',
      render: (_: any, __: any, index: any) => <>{pad(index + 1, 6)}</>,
    },
    {
      title: 'Principal Paid',
      dataIndex: 'principalPaid',
      key: 'principalPaid',
      render: (value: string) => `$ ${parseFloat(value).toFixed(2)}`,
    },
    {
      title: 'Agreement Date',
      dataIndex: 'agreementDate',
      key: 'agreementDate',
    },
    {
      title: 'Maturity Date',
      dataIndex: 'maturityDate',
      key: 'maturityDate',
    },
    {
      title: 'Principal Amount',
      dataIndex: 'principalAmount',
      key: 'principalAmount',
      render: (value: string) => `$ ${parseFloat(value).toFixed(2)}`,
    },
    {
      title: 'Interests Paid',
      dataIndex: 'interestsPaid',
      key: 'interestsPaid',
      render: (value: string) => `$ ${parseFloat(value).toFixed(2)}`,
    },
    {
      title: 'Total Paid',
      dataIndex: 'totalPaid',
      key: 'totalPaid',
      render: (value: string) => `$ ${parseFloat(value).toFixed(2)}`,
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'paymentStatus',
      render: (status: string) => <>{upperFirst(status)}</>,
    },
    {
      title: '',
      key: 'actions',
      render: (record: any) => {
        <>
          {!record.details ? <></> : (
            <ActionButton
              disabled={!record.details}
              onClick={() => this.handleRedirectToPaymentDetails()}
            >
              Details
            </ActionButton>
          )}
        </>;
      },
    },
  ];
}

const StyledRow = styled(Row)`
  margin-top: 15px;
  margin-left: 20px;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 10%;

    @media (min-width: 1100px) {
        flex-direction: row;
    }
`;

const SecondContainer = styled.div`
  .ant-timeline {
    margin-left: 20px;
  }
  margin-bottom: 20px;
`;

const ItemCard = styled.div`
    width: 100% !important;
    max-width: 500px;
    max-height: 200px;
    padding: 20px 25px;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;

    @media(min-width: 400px) {
        width: 50%;
        margin: 50px 20px 30px;
    }

    @media (min-width: 1100px) {
        margin: 50px 20px 30px;
    }

    @media (min-width: 1460px) {
        max-width: 500px;
    }
`;

const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const CaptionText = styled.span`
    ${text.caption}
    color: #8FA1AC;
`;

const CaptionTextMedium = styled.span`
    ${text['menu-item']}
    color: #8FA1AC;
`;

const CardTitle = styled.span`
    ${text['title-20']}
    color: #4F6470;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const CardImage = styled.img`
    width: 120px;
    height: 100px;
`;

const ActionButtonsSection = styled.div`
    margin: 20px auto;

    @media (min-width: 1100px) {
        margin: 50px 20px 50px;
    }
`;

const ActionButtons = styled.div`
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    width: 300px !important;
    flex-direction: column;

    @media (min-width: 1440px) {
       width: 150px;
    }
    @media (min-width: 1700px) {
        width: 180px;
    }

    span {
      margin-top: 10px;
    }
`;

export default TransactionDetailsView;
