import * as React from 'react';
import styled from 'styled-components';
import greyBall from '../../assets/images/whatsnew-icons/ball.png';
import yellowBall from '../../assets/images/whatsnew-icons/yellow_ball.png';

type BallImage = {
  width: string,
  height: string,
  left: string,
  top: string
};

export function GreyBall(props: BallImage) {
  return (
    <AbsoluteImageDiv
      top={props.top}
      left={props.left}
      width={props.width}
      height={props.height}
    >
      <img alt="" src={greyBall} />
    </AbsoluteImageDiv>
  );
}

export function YellowBall(props: BallImage) {
  return (
    <AbsoluteImageDiv
      top={props.top}
      left={props.left}
      width={props.width}
      height={props.height}
    >
      <img alt="" src={yellowBall} />
    </AbsoluteImageDiv>
  );
}

const AbsoluteImageDiv = styled.div<BallImage>`
  position: absolute;
  width: ${props => props.width};
  height: ${props => props.height};
  left: ${props => props.left};
  top: ${props => props.top};
  z-index: 0;

  @media (max-width: 980px) {
    display: none;
  }
`;
