import { Component } from 'react';
import styled from 'styled-components';
import {
  message,
  Pagination,
} from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { upperFirst } from 'lodash';
import { QUOTE_DEFAULT, AGREEMENT_DEFAULT } from '../constants/data';
import { api } from '../api';
import {
  Spinner, Title, Button, Input,
} from '../components/common';
import { Agreement } from '../components/Modals';
import ResourceTable from '../components/ResourceTable';

const { ActionButton } = Button;
const { SearchInput } = Input;

const {
  AgreementDetailsModal,
  AgreementDeleteModal,
} = Agreement;

type State = {
  quotes: Quote[],
  quote: Quote,
  isAgreementDetailsModalVisible: boolean;
  isDeleteAgreementModalVisible: boolean;
  isLoading: boolean,
  quoteFilters: {
    current: number,
    limit: number,
    total: number,
    filter: string,
  }
};

export default class AgreementsView extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      quotes: [],
      quote: QUOTE_DEFAULT,
      isAgreementDetailsModalVisible: false,
      isDeleteAgreementModalVisible: false,
      isLoading: true,
      quoteFilters: {
        current: 1,
        limit: 5,
        total: 0,
        filter: '',
      },
    };
  }

  componentDidMount() {
    this.fetchQuotes();
  }

  render() {
    const {
      isLoading,
      isAgreementDetailsModalVisible,
      isDeleteAgreementModalVisible,
      quoteFilters: {
        filter,
        current,
        limit,
        total,
      },
      quotes,
      quote,
    } = this.state;

    if (isLoading)
      return <Spinner />;

    return (
      <body className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <Header>
              <Title title="Agreement" />
            </Header>
            <form>
              <Filter>
                <SearchInput
                  value={filter}
                  onChange={e => this.handleFilterChange(e)}
                  placeholder="Search by name or number"
                />
                <ActionButton
                  type="submit"
                  className="search-button"
                  onClick={() => this.fetchQuotes()}
                >
                  Search
                </ActionButton>
              </Filter>
            </form>
            <ResourceTable
              customColumns={this.quotesColumn}
              dataSource={quotes}
              callback={this.navigateToDetailsView}
            />
            <CPagination
              showSizeChanger
              onShowSizeChange={this.onShowSizeChange}
              onChange={this.onChange}
              current={current}
              pageSizeOptions={['2', '5', '10', '20']}
              pageSize={limit}
              total={total}
            />
            {isAgreementDetailsModalVisible
              && (
                <AgreementDetailsModal
                  agreementId={(quote as any).agreementId}
                  onCancel={() => this.setState({ isAgreementDetailsModalVisible: false })}
                />
              )}
            <AgreementDeleteModal
              visible={isDeleteAgreementModalVisible}
              agreement={quote.agreement || AGREEMENT_DEFAULT}
              onDelete={this.handleDeleteAgreement}
              onCancel={() => this.setState({ isDeleteAgreementModalVisible: false })}
            />
          </div>
        </div>
      </body>
    );
  }

  handleFilterChange = (value: string) => this.setState(prevState => ({
    quoteFilters: { ...prevState.quoteFilters, filter: value },
  }));

  onShowSizeChange = (_: number, limit: number) => this.setState(prevState => ({
    quoteFilters: { ...prevState.quoteFilters, limit },
  }), () => this.fetchQuotes());

  navigateToDetailsView = (id: String): void => this.props.history.push(`/request-details/${id}`);

  onChange = (page: number) => this.setState(prevState => ({
    quoteFilters: { ...prevState.quoteFilters, current: page },
  }), () => this.fetchQuotes());

  fetchQuotes = () => {
    const {
      quoteFilters: {
        limit,
        current,
        filter,
      },
    } = this.state;

    const offset = (current - 1) * (limit || 0);

    this.setState({ isLoading: true });

    api.get('/quotes', {
      params: {
        category: 'agreement',
        filter,
        limit,
        offset,
      },
    })
      .then(res => res.data)
      .then(({ quotes, total }) => this.setState(prevState => ({
        ...prevState,
        quotes,
        quoteFilters: {
          ...prevState.quoteFilters,
          total,
        },
      })))
      .finally(() => this.setState({ isLoading: false }));
  };

  handleDeleteAgreement = (id: string) => {
    api
      .delete(`/agreement/delete/${id}`)
      .then(() => this.setState({ isDeleteAgreementModalVisible: false }))
      .then(() => message.success('Agreement has been deleted'))
      .catch(() => message.error('Error'));
  };

  handleDisplayDetails = (quote: Quote) => this.setState({ quote, isAgreementDetailsModalVisible: true });

  quotesColumn = [
    {
      title: 'Number',
      key: 'id',
      dataIndex: 'agreementCustomId',
    },
    {
      title: 'Name', key: 'name', render: ({ name }: Quote) => <>{upperFirst(name)}</>,
    },
    {
      title: 'Status', key: 'status', render: ({ context: { status } }: Quote) => <>{status}</>,
    },
    {
      title: 'Transaction',
      key: 'transaction',
      render: (record: any) => <>{upperFirst(record.loanId || '')}</>,
    },
    {
      title: 'Agreement Details',
      key: 'agreemnt-details',
      render: (quote: any) => (
        <>
          {quote.agreementId
            && <ActionButton onClick={() => this.handleDisplayDetails(quote)}>Agreement Details</ActionButton>}
        </>
      ),
    },
    {
      title: 'Request details',
      key: 'request-details',
      render: (quote: any) => (

        <ActionButton onClick={() => this.navigateToDetailsView(quote.groupId)}>Request Details</ActionButton>
      ),
    },
  ];
}

const Header = styled.div`
    width: 100%;
    padding: 24px;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .search-button {
    height: 60px;
    margin-left: 1%;
  }
`;

const CPagination = styled(Pagination)`
  margin-bottom: 6%;
  margin-top: 1%;
`;
