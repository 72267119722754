import { Timeline, Row, Col } from 'antd';
import styled from 'styled-components';

import dayjs from 'dayjs';
import { isEmpty, sortBy } from 'lodash';
import BadgeMessage from './common/Badge';

type Props = {
  loan: Loan;
};

type Item = {
  type: string
  value?: number;
  date: Date;
  label?: string;
  properties?: {}
};

const CustomTimeline = ({ loan }: Props) => {
  const getBadgeStyleBasedOnItem = (item: any) => {
    switch (item.type) {
      case 'agreement':
        return {
          backgroundColor: '#E8F1F7',
          color: '#8FA1AC',
        };
      case 'interest':
        return {
          backgroundColor: 'rgba(126, 192, 153, 0.1)',
          color: '#7EC099',
        };
      case 'transaction':
        return {
          backgroundColor: 'rgba(49, 57, 62, 0.1)',
          color: '#31393E',
        };
      case 'itemSent':
        return {
          backgroundColor: '#E8F1F7',
          color: '#8FA1AC',
        };
      default:
        return {
          backgroundColor: '#E8F1F7',
          color: '#8FA1AC',
        };
    }
  };

  const getDotColorBasedOnItem = (item: any) => {
    switch (item.type) {
      case 'interest':
        return '#7EC099';

      default:
        return '#4F6470';
    }
  };

  const displayProperties = (item: Item) => {
    if (!item.properties && isEmpty(item.properties))
      return;
    return Object.entries(item.properties as any).map(([key, value]) => (
      <RowWithMargin key={`row-${key}`}>
        <ContentLabel key={`content-${key}`}>
          {`${key}: ${value}`}
        </ContentLabel>
      </RowWithMargin>
    ));
  };

  const getTimelineItems = (): Item[] => {
    const { payment, quote } = loan;
    const result: Item[] = [];
    const charges: TypedCharge[] = loan.charges.filter(i => dayjs().diff(i.date, 'd') >= 0).map((c: any) => ({
      ...c,
      type: 'interest',
    }));

    if (payment) {
      result.push({
        value: payment.value.total,
        date: payment.date || new Date(),
        type: 'payment',
      });
    }

    return sortBy([...charges, ...quote.history, ...result], 'date');
  };

  return (
    <StyledContainer>
      <Timeline mode="left">
        {getTimelineItems().map(item => (
          <Timeline.Item dot={<OutlinedCircle color={getDotColorBasedOnItem(item)} />}>
            <>
              <Row>
                <DateLabel>{dayjs(item.date).format('MM.DD.YYYY')}</DateLabel>
              </Row>
              <Row>
                <Col xs={22} sm={22} md={18} lg={14} xl={12}>
                  {(item.type === 'interest' || item.type === 'payment') ? (
                    <TimelineItemContainer>
                      <Row align="middle" gutter={[16, 0]}>
                        <Col>
                          <BadgeMessage
                            backgroundColor={getBadgeStyleBasedOnItem({ type: 'interest' }).backgroundColor}
                            color={getBadgeStyleBasedOnItem({ type: 'interest' }).color}
                          >
                            {`$${item.value?.toFixed(2) || 0}`}
                          </BadgeMessage>
                        </Col>
                        <Col>
                          <ContentLabel>
                            {item.type === 'interest' ? 'Interests added' : 'Manual payment'}
                          </ContentLabel>
                        </Col>
                      </Row>
                    </TimelineItemContainer>
                  ) : (
                    <TimelineItemContainer>
                      <Row align="middle" gutter={[16, 0]}>
                        <Col>
                          <BadgeMessage
                            backgroundColor={getBadgeStyleBasedOnItem(item).backgroundColor}
                            color={getBadgeStyleBasedOnItem(item).color}
                          >
                            {item?.label}
                          </BadgeMessage>
                        </Col>
                        <Col />
                      </Row>
                      <>
                        {item.type === 'officialAgreementGenerated' ? (
                          <>
                            <RowWithMargin>
                              <ContentLabel>
                                {`Principal: $${loan.loanAmount}`}
                              </ContentLabel>
                            </RowWithMargin>
                            <RowWithMargin>
                              <ContentLabel>
                                Interests Rate: $
                                {`Interests Rate: ${(loan.monthlyPercentageInterestRate * 100).toFixed(3)} % / month`}
                              </ContentLabel>
                            </RowWithMargin>
                            <RowWithMargin>
                              <ContentLabel>
                                Terms: 90 days
                              </ContentLabel>
                            </RowWithMargin>
                          </>
                        ) : (
                          <>
                            {displayProperties(item)}
                          </>
                        )}
                      </>
                    </TimelineItemContainer>
                  )}

                </Col>
              </Row>
            </>
          </Timeline.Item>
        ))}
      </Timeline>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
    .ant-timeline-item-tail {
        border-left: 1px solid #8fa1ac !important;
        margin-top: 5px;
    }

    .ant-timeline-item-head {
        background-color: unset;
    }

    .ant-timeline-item {
        margin-bottom: 10px;
    }
`;

const DateLabel = styled.span`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #8fa1ac;
    margin-left: 20px;
    margin-top: 5px;
`;

const TimelineItemContainer = styled.div`
    padding: 20px;
    background: rgba(255, 255, 255, 0.4);
    border: 1px dashed #8fa1ac;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    margin-left: 20px;
`;

const ContentLabel = styled.span`
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #4f6470;
    text-align: left;
`;

type CircleProps = {
  color: string;
};

const OutlinedCircle = styled.div<CircleProps>`
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    background-color: #f6f4f0;
    border: 2px solid ${props => props.color || '#4F6470'};
`;

const RowWithMargin = styled(Row)`
    margin-top: 10px;
`;

export default CustomTimeline;
