/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
import fonts from '../../../constants/fonts';

const { mainFont } = fonts.fonts;

type Props = {
  onShowLoanDetails: () => void;
};

const LoanCreated = ({ onShowLoanDetails }: Props) => (
  <div style={{ fontFamily: mainFont }}>
    <div className="dashboard-columns">
      <div className="dashboard-columns__left">
        <div className="dashboard-card dashboard-card--bordered dashboard-card--green dashboard-card--huge-text">
          <div className="dashboard-card__title">Loan Created!</div>
          <div className="dashboard-card__text">
            Thank you, you have authorized payment charges and we will now initiate your loan payment via
            <span className="accent"> paypal</span>
            .
          </div>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 36.6668C18.1591 36.6668 16.6667 35.1744 16.6667 33.3335H23.3334C23.3334 35.1744 21.841 36.6668 20 36.6668ZM33.3334 31.6668H6.66669V28.3335L10 26.6668V17.5002C10 11.7302 12.3684 7.9885 16.6667 6.96683V3.3335H21.6667C20.5799 4.77339 19.9946 6.52953 20 8.3335C20 8.75246 20.0312 9.17084 20.0934 9.58516H20C17.9664 9.45345 16.005 10.3629 14.7917 12.0002C13.7543 13.6411 13.2452 15.5608 13.3334 17.5002V28.3335H26.6667V17.5002C26.6667 17.1485 26.655 16.8102 26.6317 16.5002C27.7341 16.7279 28.871 16.7319 29.975 16.5118C29.9934 16.8635 30 17.1985 30 17.5118V26.6668L33.3334 28.3335V31.6668ZM28.3334 13.3335C27.3263 13.3347 26.3426 13.0308 25.5117 12.4618C23.548 11.1195 22.8018 8.5795 23.7275 6.38834C24.6531 4.19717 26.9943 2.96147 29.3257 3.43356C31.657 3.90565 33.3331 5.95483 33.3334 8.3335C33.3334 11.0949 31.0948 13.3335 28.3334 13.3335Z" />
          </svg>
        </div>
        <div className="dashboard-card dashboard-card--smaller-text">
          <div className="dashboard-card__title">What’s Next?</div>
          <div className="dashboard-card__text">Your loan payment will be made out shortly. Expect to receive your money in the next 24-48 hours.</div>
        </div>
      </div>
      <div className="dashboard-columns__right">
        <div className="dashboard-infocard">
          <div className="popup__header">
            <div className="popup__title">Loan Created</div>
          </div>
          <div className="popup__content">
            <div className="popup__image popup__image--green">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 3L27 3C28.6569 3 30 4.34315 30 6L30 30C30 31.6569 28.6569 33 27 33L16.5 33C16.4837 32.9993 16.4675 32.9963 16.452 32.991C16.4373 32.9865 16.4223 32.9835 16.407 32.982C16.2747 32.9735 16.1441 32.9478 16.0185 32.9055L15.9765 32.892C15.9425 32.8805 15.9094 32.8664 15.8775 32.85C15.7141 32.7774 15.5652 32.6757 15.438 32.55L6.438 23.55C6.31228 23.4228 6.21064 23.2739 6.138 23.1105C6.123 23.0775 6.1125 23.043 6.1005 23.0085L6.087 22.9695C6.04512 22.8444 6.0204 22.7142 6.0135 22.5825C6.01117 22.5687 6.00766 22.5552 6.003 22.542C6.00035 22.5282 5.99934 22.5141 5.9998 22.5L5.9998 6C5.9998 4.34315 7.34314 3 9 3ZM27 30L27 6L9 6L9 21L16.5 21C17.3284 21 18 21.6716 18 22.5L18 30L27 30ZM15 27.879L15 24L11.121 24L15 27.879Z" stroke="none" />
              </svg>
            </div>
          </div>
          <div className="popup__footer">
            <div className="popup__text">Your loan payment will be made out shortly. Expect to receive your money in the next 24-48 hours</div>
            <div className="popup__buttons popup__buttons--wide">
              <a className="popup__btn btn btn--dark" onClick={onShowLoanDetails}>
                VIEW LOAN DETAILS
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LoanCreated;
