import { CategoryCard } from './Cards';
import { data } from '../constants';

type Props = { quote?: boolean };

const Categories = ({ quote }: Props) => (
  <div className="row">
    {data.CATEGORIES.children
      ?.filter(c => !c.name.includes('metal'))
      .filter(c => !c.name.includes('other-coins'))
      .map(c => (
        <CategoryCard
          key={c.name}
          title={c.title || c.name}
          id={c.name}
          imgCover={c.imgCover || ''}
          hasSubcategory={!!c.children}
          quote={quote}
        />
      ))}

  </div>
);

export default Categories;
