/* eslint-disable max-len */
type Props = {
  type: string;
  notificationText?: string;
};

const ShowNotificationBox = ({ type, notificationText }: Props) => {
  switch (type) {
    case 'accepted':
      return (
        <div className="status status--accepted">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.98931 13.3333H7.99998C10.9445 13.3303 13.3295 10.9417 13.328 7.99725C13.3265 5.05278 10.9391 2.66659 7.99465 2.66659C5.05017 2.66659 2.66279 5.05278 2.66131 7.99725C2.65984 10.9417 5.04484 13.3303 7.98931 13.3333ZM6.66665 11.3333L3.99998 8.66659L4.93998 7.72659L6.66665 9.44659L11.06 5.05325L12 5.99992L6.66665 11.3333Z"
            />
          </svg
          >
          <span>accepted</span>
        </div>
      );
    case 'rejected':
      return (
        <div className="status status--rejected">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 14.6666C5.30237 14.6682 2.86966 13.0439 1.83711 10.5517C0.804556 8.05946 1.37569 5.19063 3.284 3.28391C4.96886 1.59904 7.42461 0.941026 9.72617 1.55773C12.0277 2.17443 13.8255 3.97216 14.4422 6.27373C15.0589 8.5753 14.4009 11.031 12.716 12.7159C11.4676 13.9699 9.76944 14.6723 8 14.6666ZM2.66666 8.11457C2.69821 11.0487 5.09407 13.4063 8.02836 13.3906C10.9626 13.3747 13.333 10.9916 13.333 8.05724C13.333 5.12291 10.9626 2.73975 8.02836 2.7239C5.09407 2.70821 2.69821 5.06575 2.66666 7.99991V8.11457ZM6.27266 10.6666L5.33333 9.72657L7.06 7.99991L5.33333 6.27324L6.27333 5.33324L8 7.05991L9.72666 5.33324L10.6667 6.27324L8.94 7.99991L10.6667 9.72657L9.72733 10.6666L8 8.93991L6.27333 10.6666H6.27266Z"
            />
          </svg
          >
          <span>rejected</span>
        </div>
      );
    default:
      return (
        <div className="status status--closed">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.99998 2.66659C5.05446 2.66659 2.66665 5.0544 2.66665 7.99992C2.66665 10.9454 5.05446 13.3333 7.99998 13.3333C10.9455 13.3333 13.3333 10.9454 13.3333 7.99992C13.33 5.05577 10.9441 2.66989 7.99998 2.66659ZM11.3333 8.66659H7.33331V4.66659H8.66665V7.33325H11.3333V8.66659Z"
            />
          </svg
          >
          <span>{notificationText === 'Request sent' ? 'under review' : notificationText}</span>
        </div>
      );
  }
};

export default ShowNotificationBox;
