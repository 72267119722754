import { Modal, Image, Row } from 'antd';
import styled from 'styled-components';
import { text, colors } from '../../constants';
import { ActionButton, SecondaryButton } from '../common/Button';
import acceptModal from '../../assets/images/acceptModal.png';
import rejectModal from '../../assets/images/rejectModal.png';

type Props = {
  modalType: 'accept' | 'reject' | 'off'
  handleSetAndDisplayModal: (modalType: 'accept' | 'reject' | 'off') => void;
  onSend: () => void;
  onReject: () => void
};

const AcceptRejectModal = ({
  handleSetAndDisplayModal, modalType, onSend, onReject,
}: Props) => (
  <StyledModal
    style={{ top: 16 }}
    footer={null}
    visible={modalType !== 'off' && true}
    onCancel={() => handleSetAndDisplayModal('off')}
  >
    <div className="ant-modal-header">
      <Row>
        <p>
          The offer will be
          {modalType && modalType === 'accept' ? <Accepted> accepted </Accepted> : <Rejected> rejected </Rejected>}
          and send to the E&B. Are you sure?
        </p>
      </Row>
    </div>
    <Row>
      <Image src={modalType && modalType === 'accept' ? acceptModal : rejectModal} />
    </Row>
    <StyledRow>
      <SecondaryButton onClick={() => (modalType === 'accept' ? onSend() : onReject())}>
        Send
      </SecondaryButton>
    </StyledRow>
    <Row>
      <StyledActionButton onClick={(() => handleSetAndDisplayModal('off'))}>
        Back
      </StyledActionButton>
    </Row>
  </StyledModal>
);

const Accepted = styled.span`
    color: ${colors.text.green}
`;

const Rejected = styled.span`
    color: ${colors.text.red}
`;

const StyledRow = styled(Row)`
    padding-top: 40px;
    padding-bottom: 20px;
`;

const StyledActionButton = styled(ActionButton)`
    background: ${colors.background.main};
`;

const StyledModal = styled(Modal)`
    width: 800px !important;

    .ant-modal-header {
        display: flex;
        justify-content: center;
        padding-top: 40px !important;
        border: none;
        color: ${colors.text.dark};
        .ant-modal-title {
            ${text.title}
        }
    }

    p {
        font-size: 20px;
        color: ${colors.text.body};
    }

    .ant-modal-close-x {
        svg {
            height: 19px;
            width: 19px;
        }

        color: ${colors.text.light};
    }

    .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: ${colors.background['widget-white']};
    }

`;

export default AcceptRejectModal;
