import { Component } from 'react';
import {
  Rate, Spin, Row, Col, Collapse,
} from 'antd';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  Input,
  Button,
  Title,
  Spinner,
} from '../components/common';
import { data, date } from '../constants';
import { api } from '../api';
import { CustomModal } from '../custom';

const { SecondaryButton } = Button;
const { ControlledFormInput, ControlledFormTextArea } = Input;
const { Panel } = Collapse;

type Props = {};

type State = {
  comments: CommentType[],
  isLoading: boolean,
  newComment: CommentType | null
};

export default class CommentsAdminView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      comments: [],
      isLoading: false,
      newComment: null,
    };
  }

  componentDidMount() {
    this.fetchComments();
  }

  render() {
    const { comments, isLoading, newComment } = this.state;
    return (
      <body className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <Container className="store-editor-container">
              <Row gutter={[32, 40]}>
                <Col>
                  <Title title="Comments" />
                </Col>
              </Row>
              <Row gutter={[32, 40]}>
                <Col>
                  <SecondaryButton
                    onClick={this.handleAddNew}
                  >
                    Add New
                  </SecondaryButton>
                </Col>
              </Row>
              <Spin spinning={isLoading} indicator={<Spinner tip="Loading" />}>
                <>
                  {newComment
                    ? (
                      <CommentCard
                        onSave={this.handleSave}
                        comment={newComment}
                        onNewCommentChange={this.handleNewCommentChange}
                      />
                    )
                    : <></>}
                  <StyledCollapse>
                    {comments.map(c => (
                      <Panel header={`${c.fullName}, ${c.city} ${dayjs(c.createdAt).format(date.short)}`} key={c.id} extra={this.genExtra(c.id)}>
                        <Rate disabled defaultValue={c.rate} />
                        <p>{c.content}</p>
                      </Panel>
                    ))}
                  </StyledCollapse>
                </>
              </Spin>
            </Container>
          </div>
        </div>
      </body>
    );
  }

  genExtra = (id: string) => (
    <DeleteOutlined
      onClick={event => {
        event.stopPropagation();
        this.handleDelete(id);
      }}
    />
  );

  handleAddNew = () => this.setState(prevState => ({
    newComment: !prevState.newComment ? data.COMMENT_DEFAULT : null,
  }));

  handleNewCommentChange = ({
    rate, fullName, city, content,
  }: { rate?: number, fullName?: string, city?: string, content?: string }) => this.setState(prevState => {
    let defCom: CommentType = data.COMMENT_DEFAULT;
    if (prevState.newComment)
      defCom = prevState.newComment;

    if (rate)
      return {
        newComment: { ...defCom, rate },
      };

    if (fullName)
      return {
        newComment: { ...defCom, fullName },
      };
    if (city)
      return {
        newComment: { ...defCom, city },
      };
    return {
      newComment: { ...defCom, content: content || '' },
    };
  });

  fetchComments = () => {
    this.setState({ isLoading: true });
    api
      .get('/comments')
      .then(res => res.data)
      .then((res: any) => this.setState({ comments: res }))
      .catch(() => { })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleSave = () => {
    const { newComment } = this.state;

    this.setState({ isLoading: true });
    api
      .post('/comments', { comment: newComment })
      .then(() => {
        this.setState({
          newComment: null,
        });
        this.fetchComments();
      })
      .catch(() => {
        CustomModal.displayCustomWarning({
          title: 'Error during comment adding',
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleDelete = (id: string) => {
    this.setState(prevState => ({
      isLoading: true,
      comments: prevState.comments.filter(c => c.id !== id),
    }));
    api
      .delete(`/comments/${id}`)
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Comment deleted',
        });
      })
      .catch(() => {
        CustomModal.displayCustomWarning({
          title: 'Error during comment deleting',
        });
        this.fetchComments();
      })
      .finally(() => this.setState({ isLoading: false }));
  };
}

type CardProps = {
  comment: CommentType,
  onNewCommentChange: ({
    rate, fullName, city, content,
  }: { rate?: number, fullName?: string, city?: string, content?: string }) => void;
  onSave: () => void;
};

const CommentCard = ({
  comment: {
    rate, fullName, city, content,
  },
  onNewCommentChange,
  onSave,
}: CardProps) => (
  <>
    <Row justify="start">
      <Col xs={20}>
        <Title title="Rate" />
        <StyledRate allowHalf value={rate} onChange={value => onNewCommentChange({ rate: value })} />
      </Col>
      <Col xs={20}>
        <Title title="Fullname" />
        <ControlledFormInput value={fullName} onChange={(e: Input) => onNewCommentChange({ fullName: e.target.value })} />
      </Col>
      <Col xs={20}>
        <Title title="City" />
        <ControlledFormInput value={city} onChange={(e: Input) => onNewCommentChange({ city: e.target.value })} />
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title title="Comment" />
        <ControlledFormTextArea
          onChange={(e: string) => onNewCommentChange({ content: e })}
          rows={5}
          value={content}
        />
      </Col>
      <Col>
        <SecondaryButton onClick={onSave}>
          Save
        </SecondaryButton>
      </Col>
    </Row>
  </>

);

const StyledRate = styled(Rate)`
   background-color: rgba(79, 100, 112, 0.1);
`;

const Container = styled.div`
    flex: 1;
    display: flex;
    padding-left: 10px;
    flex-direction: column;
`;

const StyledCollapse = styled(Collapse)`
  margin-top: 10px;
`;
