/* eslint-disable jsx-a11y/label-has-associated-control */
type Props = {
  type: string;
  isSelected: boolean;
  onClick: (value: string) => void;
  children?: JSX.Element | JSX.Element[];
  className?: string;
};

const FormCardBase = ({
  type, isSelected, onClick, children,
}: Props) => (
  <label className="request-form-label" style={{ width: 134, height: 134, margin: '24px 30px 0px 0px' }}>
    <input type="radio" name="size/" checked={isSelected} onClick={() => onClick(type)} />
    <span className="request-form-label__body request-form-size">
      <div
        className="request-form-size__icon-wrap"
        style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', marginTop: 20,
        }}
      >
        {children}
      </div>
    </span>
  </label>
);

export default FormCardBase;
