import { useState } from 'react';
import {
  message,
  Modal,
  Row,
  Col,
  Typography,
} from 'antd';
import { Button, Spinner } from '../../common';
import Editor from '../../Editor';
import { api } from '../../../api';

const { Text } = Typography;

type Props = {
  visible: boolean;
  onFinish: (content: string, attachments?: any[]) => void;
  onCancel: () => void;
};

const AgreementCreateModal = ({ visible, onFinish, onCancel }: Props) => {
  const [getContent, setContent] = useState<string>('');
  const [attachments, setAttachments] = useState<any[]>([]);
  const [errorVisible, setErrorVisible] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getTemplate = () => {
    setLoading(true);
    api
      .get('/configuration/agreement-template')
      .then(res => res.data)
      .then(data => {
        if (!data)
          return;
        const { attachments, content } = JSON.parse(data.content);
        setContent(content);
        setAttachments(attachments);
        if (errorVisible)
          setErrorVisible(false);
      })
      .catch(() => setErrorVisible(true))
      .finally(() => setLoading(false));
  };

  const onCreate = () => {
    if (!getContent)
      return message.error('Error: Agreement content not entered');
    if (attachments.length === 0)
      return message.error('Error: Agreement does not contain any attachments ');
    return onFinish(getContent, attachments);
  };

  return (
    <Modal
      title="Create Agreement"
      visible={visible}
      footer={[
        <Button.SecondaryButton key="back" onClick={onCancel}>
          Close
        </Button.SecondaryButton>,
        <Button.SecondaryButton
          onClick={onCreate}
        >
          Create
        </Button.SecondaryButton>,
      ]}
      onCancel={onCancel}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Button.SecondaryButton key="back" onClick={getTemplate}>
            Use template
          </Button.SecondaryButton>
        </Col>
        <>
          { errorVisible && (
          <Col span={12}>
            <Text type="danger"> Template not created</Text>
          </Col>
          ) }
        </>

        <Col span={24}>
          {
            isLoading ? <Spinner />
              : (
                <Editor
                  fileList={attachments}
                  setFileList={setAttachments}
                  content={getContent}
                  setContent={setContent}
                />
              )
          }
        </Col>
      </Row>
    </Modal>
  );
};

export default AgreementCreateModal;
