/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-brace-presence */
import { useState } from 'react';
import styled from 'styled-components';
import {
  Input,
} from '../../../common';
import fonts from '../../../../constants/fonts';

const { mainFont } = fonts.fonts;

const { CurrencyInputNumber } = Input;

type Props = {
  typeRequest?: string;
  quoteValue: number;
  onIsVisibleAcceptRejectOfferPopUp: () => void;
  onQuoteValueChange: (e: any) => void;
  onIsVivisbleConfirmOfferPopUp: () => void;
};

const ReceivedItemCounterOfferByAdmin = ({
  typeRequest,
  quoteValue,
  onIsVisibleAcceptRejectOfferPopUp,
  onQuoteValueChange,
  onIsVivisbleConfirmOfferPopUp,
}: Props) => {
  const [isCounterOfferActive, setIsCounterOfferActive] = useState(false);
  const [currentCounterOffer, setCurrentCounterOffer] = useState(0);

  return (
    <div style={{ fontFamily: mainFont }}>
      <div className="dashboard-columns">
        <div className="dashboard-columns__left">
          <div className="dashboard-card dashboard-card--blue dashboard-card--huge-text">
            <div className="dashboard-card__title">Notification</div>
            <div className="dashboard-card__text">We have inspected your item(s) and because of the condition (or other variables), we have sent you a new offer. </div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 36.6668C18.1591 36.6668 16.6667 35.1744 16.6667 33.3335H23.3334C23.3334 35.1744 21.841 36.6668 20 36.6668ZM33.3334 31.6668H6.66669V28.3335L10 26.6668V17.5002C10 11.7302 12.3684 7.9885 16.6667 6.96683V3.3335H21.6667C20.5799 4.77339 19.9946 6.52953 20 8.3335C20 8.75246 20.0312 9.17084 20.0934 9.58516H20C17.9664 9.45345 16.005 10.3629 14.7917 12.0002C13.7543 13.6411 13.2452 15.5608 13.3334 17.5002V28.3335H26.6667V17.5002C26.6667 17.1485 26.655 16.8102 26.6317 16.5002C27.7341 16.7279 28.871 16.7319 29.975 16.5118C29.9934 16.8635 30 17.1985 30 17.5118V26.6668L33.3334 28.3335V31.6668ZM28.3334 13.3335C27.3263 13.3347 26.3426 13.0308 25.5117 12.4618C23.548 11.1195 22.8018 8.5795 23.7275 6.38834C24.6531 4.19717 26.9943 2.96147 29.3257 3.43356C31.657 3.90565 33.3331 5.95483 33.3334 8.3335C33.3334 11.0949 31.0948 13.3335 28.3334 13.3335Z" />
            </svg>
          </div>
          <div className="dashboard-card dashboard-card--smaller-text">
            <div className="dashboard-card__title">What’s Next?</div>
            <div className="dashboard-card__text">Please review our offer and continue.</div>
          </div>
        </div>
        <div className="dashboard-columns__right">
          <div className="dashboard-infocard">
            <div className="popup__header">
              <div className="popup__title">Your New Offer</div>
            </div>
            <div className="popup__content">
              <div className="popup__price popup__price--small">
                $
                {quoteValue.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
              {typeRequest === 'PAWN' && <div className="popup__comment">Our offer is for a 90 day loan. You will receive your items back once the loan is repaid.</div>}
            </div>
            <div className="popup__footer">
              <div className="popup__buttons popup__buttons--wide">
                <div
                  className="popup__btn btn btn--green"
                  onClick={onIsVisibleAcceptRejectOfferPopUp}
                >
                  Accept & Get Paid
                </div>
              </div>
              {
                isCounterOfferActive
                  ? (
                    <>
                      <div
                        style={{
                          fontSize: 11,
                          cursor: 'pointer',
                          color: '#4993C6',
                        }}
                        onClick={() => setIsCounterOfferActive(false)}
                      >
                        Cancel Counter Offer
                      </div>
                      <CounterOfferSection>
                        <InputWrapper>
                          <CurrencyInputNumber
                            value={currentCounterOffer}
                            onChange={e => {
                              onQuoteValueChange(e);
                              setCurrentCounterOffer(e);
                            }}
                          />
                        </InputWrapper>
                        <a
                          className="
                        popup-counteroffer__submit
                        btn btn--dark
                        popup__btn
                      "
                          onClick={onIsVivisbleConfirmOfferPopUp}
                        >
                          Submit a Counter Offer
                        </a>
                      </CounterOfferSection>
                    </>
                  )
                  : (
                    <div className="popup__comment">
                      If you are not satisfied with our quote,
                      <br />
                      you can
                      <span> </span>
                      <span
                        style={{ color: '#42b1fc', cursor: 'pointer' }}
                        onClick={() => setIsCounterOfferActive(true)}
                      >
                        make a counter offer here
                      </span>
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CounterOfferSection = styled.div`
        display: flex;
        margin-top: 10px;
        `;

const InputWrapper = styled.div`
        width: 150px !important;
        `;

export default ReceivedItemCounterOfferByAdmin;
