/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components';
import { text, colors } from '../../constants';
import Diamond from '../../assets/images/DiamondSvg';

type Props = {
  title: string;
  hide?: boolean;
  hideDiamond?: boolean;
  id: number;
  onClick: (e: string) => void;
  isSelected: boolean;
};

const DiamondSizeCard = ({
  title, isSelected, onClick,
}: Props) => (
  <div>
    <label
      className="request-form-label"
      style={{
        width: 134, height: 134,
      }}
    >
      <input type="radio" name="size/" checked={isSelected} onClick={() => onClick(title)} />
      <span className="request-form-label__body request-form-size">
        <div
          className="request-form-size__icon-wrap"
          style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', marginTop: 20,
          }}
        >
          <Diamond />
          <Title>{title}</Title>
        </div>
      </span>
    </label>
  </div>
);

const Title = styled.h2`
    ${text.body}
    color: ${colors.text.dark};
    `;

export default DiamondSizeCard;
