import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  BullionForm,
  FlatwareForm,
  WatchForm,
  OtherForm,
  DesignerForm,
  HandbagsForm,
  DiamondForm,
  CoinsForm,
  OtherJewelryForm,
} from '../components/GeneralInfo/Form';
import { withCategories } from '../components/withCategories';
import { quoteStore } from '../store';

type Props = {} & RouteComponentProps & WithCategoriesProps;

type State = {
  generalInfo: any
};

class GeneralInfoView extends Component<Props, State> {
  render() {
    return <>{this.handleDisplayCategory()}</>;
  }

  handleJewelry = () => {
    const { subCategory, categories } = this.props;

    switch (subCategory) {
      case 'diamond':
        return <DiamondForm {...this.props} subCategories={[...categories].splice(1)} />;
      case 'other':
        return <OtherJewelryForm {...this.props} subCategory={subCategory} />;
      case 'designer':
        return <DesignerForm {...this.props} subCategory={subCategory} />;
      default:
        return <> Category doesn&apos;t exits </>;
    }
  };

  handleDisplayCategory = (): JSX.Element => {
    const { categories } = this.props;
    if (categories.length === 0) return <></>;

    const { quotes } = quoteStore.getState();

    switch (categories[0]) {
      case 'bullion':
        if (quotes.length > 0)
          this.props.history.push('/categories');
        return <BullionForm {...this.props} />;
      case 'watch':
        return <WatchForm {...this.props} />;
      case 'handbags':
        return <HandbagsForm {...this.props} />;
      case 'flatware&serving-ware':
        return <FlatwareForm {...this.props} />;
      case 'other':
        return <OtherForm {...this.props} />;
      case 'coins':
        return <CoinsForm {...this.props} />;
      case 'jewelry':
        return this.handleJewelry();
      default:
        return <> Category doesn&apos;t exits </>;
    }
  };
}

export default withCategories(GeneralInfoView);
