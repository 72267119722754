import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { userStore } from '../store';

export interface IPrivateRouteProps extends RouteProps {
  redirectPath?: string;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = (props: IPrivateRouteProps) => {
  const user = userStore.getState();
  return (user.id
    ? <Route {...props} component={props.component} render={undefined} />
    : <Redirect to={{ pathname: props.redirectPath || '/' }} />
  );
};

export default PrivateRoute;
