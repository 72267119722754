import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { runMain } from '../../assets/js/main';

const HighestPayouts = () => {
  useEffect(() => { runMain(); }, []);
  return (
    <div className="section section-cta overflow-hidden">
      <picture>
        <source type="image/webp" media="(max-width: 600px)" srcSet="images/bg/money-mobile.webp" />
        <source type="image/jpg" media="(max-width: 600px)" srcSet="images/bg/money-mobile.jpg" />
        <source type="image/webp" srcSet="images/bg/money.webp, images/bg/money@2x.webp 2x" />
        <img
          className="section__parallax-bg rellax"
          data-rellax-speed={-4}
          alt="Money"
          src="images/bg/money.jpg"
          srcSet="images/bg/money.jpg, images/bg/money@2x.jpg 2x"
        />
      </picture>
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-9">
            <div className="box box--gold">
              <div className="box__body box__body--thin">
                <div className="box__body-in">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-6">
                      <p className="box__title box__title--mb-lg box__title--lg">
                        <span className="gradient-color">Highest</span>
                        &#160; payouts!
                      </p>
                    </div>
                    <div className="col-lg-5 text-right col-md-4 col-6">
                      <img className="box__icon" alt="Arr up" src="images/sprites/main.stack.svg#image-medal" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-9">
                      <p className="box__text">We do our homework to get you the highest price offer.</p>
                      <Link className="btn btn--lg btn--secondary text-center w-100" to="/categories">
                        get an instant offer
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default HighestPayouts;
