import styled from 'styled-components';
import { Table as AntdTable, Empty } from 'antd';
import { colors, text } from '../../constants';
import TableNoContentMessage from './TableNoContentMessage';

type Props = {
  columns: any[];
  dataSource: any[];
};

const Table = ({ columns, dataSource }: Props) => (
  <TableContanier>
    <MobileTable>
      {dataSource?.length === 0
        && (
          <Empty
            style={{
              marginBottom: 25,
            }}
          />
        )}
      {dataSource && Array.isArray(dataSource) && dataSource.map((data: any, idx: number) => (
        <div className="table-row" key={data.key}>
          {columns && Array.isArray(columns) && columns.map((column: any) => (
            <div className="table-column" key={data.key}>
              <b style={{ fontWeight: 600 }}>{column.title}</b>
              <div className="table-data">
                {column.render
                  ? column.render(data[column.dataIndex] || data, data, idx)
                  : data[column.key]}
              </div>
            </div>
          ))}
        </div>
      ))}
    </MobileTable>
    <DesktopTable>
      <StyledTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="small"
        locale={{ emptyText: <TableNoContentMessage /> }}
      />
    </DesktopTable>
  </TableContanier>
);

const StyledTable = styled(AntdTable)`

    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
        background: unset;
    }
    .ant-table-row {
      height: 72px;
    }
    table {
        width: 100%;
        table-layout: fixed !important;
        overflow-wrap: break-word;
        border: 1px solid #faf9f7;
        thead {
            ${text['table-thead']}
            tr {
                .ant-table-cell {
                    background-color: ${colors.table.tr};
                    font-family: Poppins !important;
                }
            }
        }
        tbody {
            tr:nth-child(2n + 1) {
                background: ${colors.background.main};
            }
            tr:nth-child(2n) {
                background: ${colors.table.tr};
            }
            tr {
                &:hover {
                    background-color: ${colors.table.hover};
                }
                .ant-table-cell {
                    ${text.body}
                    font-size: 12px;
                    font-family: Poppins !important;
                }
            }
        }
    }

    @media(min-width: 1280px) {
        table tbody tr .ant-table-cell {
            font-size: 12px;
        }
    }

    @media(min-width: 1440px) {
        table tbody tr .ant-table-cell {
            font-size: 15px;
        }
    }

    @media(min-width: 1700) {
        table tbody tr .ant-table-cell {
            font-size: 16px
        }
    }
`;

const TableContanier = styled.div`
    margin-bottom: 10%;
    padding: 10px 0 10px 20px;
    background-color: ${colors.background.main};
    .table-mobile {
        padding: 24px;
    }
`;

const MobileTable = styled.div`
    padding: 15px;
    display: none;

    .table-row {
        padding: 25px 0px;

        &:first-of-type {
            border-top: none;
            margin: unset;
            padding-top: 0px;
        }

        .table-column {
            margin-top: 10px;

            &:first-child {
                margin-top: 0px;
            }
        }
    }

    a, span {
        color: green
    }

    @media screen and (max-width: 1200px) {
        display: block;
    }
`;

const DesktopTable = styled.div`
    display: block;

    @media screen and (max-width: 1200px) {
        display: none;
    }
`;

export default Table;
