import { Component, useEffect } from 'react';
import styled from 'styled-components';
import { api } from '../api';
import { Spinner } from '../components/common';
import { runMain, enableSpoilers } from '../assets/js/main';

type Props = {};

type State = {
  faq: Faq[];
  isLoading: boolean;
  current: number;
  limit: number;
  total: number;
};

export default class FaqView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      faq: [],
      isLoading: false,
      current: 1,
      limit: 5,
      total: 0,
    };
  }

  componentDidMount() {
    runMain();
    enableSpoilers();
    this.fetchFaqs();
  }

  render() {
    const {
      faq, isLoading,
    } = this.state;

    if (isLoading) return <Spinner />;

    return (
      <main className="main">
        <div className="section section-faq">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h1
                  className="text-center section__title section__title--xs"
                  data-aos={window.innerWidth > 1000 ? 'fade-down' : ''}
                  data-aos-delay={0}
                  data-aos-duration={1200}
                  data-aos-easing="ease-in-out"
                  data-aos-anchor="#header"
                  data-aos-once
                >
                  Frequently Asked Questions
                </h1>
                <div
                  className="section-faq__content"
                  data-aos={window.innerWidth > 1000 ? 'fade-up' : ''}
                  data-aos-delay={300}
                  data-aos-duration={1200}
                  data-aos-easing="ease-in-out"
                  data-aos-anchor="#header"
                  data-aos-once
                >
                  <>
                    {faq.map(({ title, content }) => <FaqCard title={title} content={content} />)}
                  </>
                  <div className="section-faq__pagination">
                    <div className="pagination justify-content-center">
                      {this.displayPagination()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }

  onCurrentChange = (current: number) => this.setState({ current }, () => this.fetchFaqs());

  displayPagination = () => {
    const { limit, total, current } = this.state;
    const itemsAmount = Math.ceil(total / limit);
    const result: JSX.Element[] = [];

    for (let index = 0; index < itemsAmount; index += 1) {
      result.push(<Pagination onCurrentChange={this.onCurrentChange} current={current} index={index + 1} />);
    }
    return result;
  };

  fetchFaqs = () => {
    const { current, limit } = this.state;
    const offset = (current - 1) * (limit || 0);
    this.setState({ isLoading: true });
    api
      .get('/faq', { params: { offset, limit } })
      .then(res => res.data)
      .then(({ faq, size }: { faq: any[], size: number }) => {
        this.setState({
          faq,
          total: size,
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  };
}

type PaginationPropsType = {
  current: number,
  index: number,
  onCurrentChange: (current: number) => void;
};

const Pagination = ({ current, index, onCurrentChange }:PaginationPropsType) => {
  if (current === index)
    return <span className="pagination__item pagination__item--active">{index}</span>;
  return (
    <Button onClick={() => onCurrentChange(index)} className="pagination__item">
      {index}
    </Button>
  );
};

type FaqCardType = {
  title: string,
  content: string
};

const FaqCard = ({ title, content }: FaqCardType) => {
  useEffect(() => {
    enableSpoilers();
  }, []);

  return (
    <div className="spoiler">
      <div className="spoiler__header">
        <p className="spoiler__title">{title}</p>
        <button type="button" className="spoiler__toggle" aria-label="Toggle" />
      </div>
      <div className="spoiler__content">
        <div className="row no-gutters">
          <div className="col-md-9">
            <p>
              {content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Button = styled.button`
  border:none;
  outline:none;
`;
