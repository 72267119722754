/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import { Component, createRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import logoColor from '../../assets/images/logo-color.svg';
import { RegisterModal, LoginModal } from '../Modals';
import { api } from '../../api';
import { userStore } from '../../store';
import { runMain, toggleHeaderBurger } from '../../assets/js/main';

type Props = {
  isLogged: boolean;
  handleLogout: () => void;
  onSwitchChange: (checked: boolean) => void;
  onCloseLogginForm: () => void;
  displayToggler: boolean;
  showLoginForm: boolean;
  shouldBeTranspaarent: boolean;
} & RouteComponentProps;

type State = {
  isRegistering: boolean;
  isLogging: boolean;
  storeLink: string;
};

type Mode = {
  url: string,
  darkMode: boolean,
  darkPhone: boolean,
  alwaysWhite: boolean,
};

export default class Navbar extends Component<Props, State> {
  private burgerRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLogging: false,
      isRegistering: false,
      storeLink: '',
    };

    this.burgerRef = createRef();
  }

  componentDidMount() {
    runMain();
    this.fetchStoreLink();
  }

  render() {
    const { isLogging, isRegistering, storeLink } = this.state;
    const {
      displayToggler, isLogged, showLoginForm, shouldBeTranspaarent,
    } = this.props;
    const { id } = userStore.getState();

    const { darkMode } = this.getMode();

    return (
      <>
        <Header
          darkMode={darkMode}
          className={`header ${displayToggler && 'header--always-white'} ${!shouldBeTranspaarent && 'header--always-white'}`}
          id="header"
        >
          <div className="container header__container">
            <div className="header__in">
              <div className="header__group">
                <Link className="header__logo-link" to="/">
                  <img className="header__logo" src={logoColor} alt="E&amp;B" width="82" height="28" />
                </Link>
                <a
                  className="header__link header__link--mobile"
                  href="tel:8562352463"
                >
                  856-235-2463
                </a>
              </div>
              <button
                type="button"
                onClick={toggleHeaderBurger}
                className="header__burger"
                aria-label="Menu"
                ref={this.burgerRef}
              >
                <span className="header__burger-in" aria-hidden="true" />
              </button>
              <div className="header__group header__group--mobile-menu">
                <a className="header__link header__link--mobile-only" href="tel:8562352463">
                  Call: 856-235-2463
                </a>
                {isLogged
                  && (
                    <Link className="header__link" onClick={this.handleLinkClick} to="/requests/all">
                      Dashboard
                    </Link>
                  )}
                <Button
                  fontSize={window.innerWidth < 992 ? '20px' : ''}
                  className="header__link"
                  onClick={() => {
                    if (storeLink) {
                      window.open(storeLink, '_blank');
                      this.handleLinkClick();
                    }
                  }}
                >
                  Store
                </Button>
                <Link className="header__link" onClick={this.handleLinkClick} to="/about">
                  About
                </Link>
                <Link className="header__link" onClick={this.handleLinkClick} to="/faq">
                  FAQ
                </Link>
                <Link className="header__link" onClick={this.handleLinkClick} to="/contact-us">
                  Contact
                </Link>
                {id ? (
                  <Button
                    fontSize={window.innerWidth < 992 ? '20px' : ''}
                    type="submit"
                    className="header__link"
                    onClick={() => {
                      this.props.handleLogout();
                      this.handleLinkClick();
                    }}
                  >
                    LOGOUT
                  </Button>
                ) : (
                  <Button
                    fontSize={window.innerWidth < 992 ? '20px' : ''}
                    type="submit"
                    className="header__link"
                    onClick={() => {
                      this.handleIsLoggingChange(true);
                      this.handleLinkClick();
                    }}
                  >
                    LOGIN
                  </Button>
                )}
                <Link to="/categories" onClick={this.handleLinkClick} className="header__link header__link--btn btn btn--outline-white">
                  Get a quote
                </Link>
              </div>
            </div>
          </div>
        </Header>
        {
          (isLogging || showLoginForm) && (
            <LoginModal
              isVisible={isLogging || showLoginForm}
              handleLoginModal={this.handleIsLoggingChange}
              handleRegisterModal={this.handleIsRegisteringChange}
            />
          )
        }
        {
          isRegistering && (
            <RegisterModal
              isVisible={isRegistering}
              handleLoginModal={this.handleIsLoggingChange}
              handleRegisterModal={this.handleIsRegisteringChange}
            />
          )
        }
      </>
    );
  }

  loadExtendedScripts = () => {
    const script = document.createElement('script');

    script.src = '/resources/js/main3.js';
    script.async = false;

    document.body.appendChild(script);
  };

  getMode = () => {
    const { id } = userStore.getState();

    const defaultMode: Mode = {
      darkMode: true,
      alwaysWhite: false,
      darkPhone: false,
      url: '',
    };

    const pages: Mode[] = [
      {
        url: '/',
        darkMode: false,
        darkPhone: !!id || false,
        alwaysWhite: !!id || false,
      },
      {
        url: '/categories',
        darkMode: false,
        darkPhone: false,
        alwaysWhite: true,
      },
      {
        url: '/about',
        darkMode: false,
        darkPhone: true,
        alwaysWhite: false,
      },
      {
        url: '/contact-us',
        darkMode: false,
        darkPhone: false,
        alwaysWhite: true,
      },
      {
        url: '/faq',
        darkMode: false,
        darkPhone: false,
        alwaysWhite: true,
      },
    ];

    const currentLocation = this.props.location.pathname;
    let foundPage = null;
    pages.forEach(page => {
      if (
        this.props.location.pathname === page.url
        || page.url === currentLocation.substring(0, currentLocation.lastIndexOf('/'))
      ) {
        foundPage = page;
      }
    });
    return foundPage || defaultMode;
  };

  handleIsLoggingChange = (isLogging: boolean) => {
    this.setState({ isLogging });
    if (!isLogging)
      this.props.onCloseLogginForm();
  };

  handleIsRegisteringChange = (isRegistering: boolean) => this.setState({ isRegistering });

  fetchStoreLink = () => {
    api
      .get('/configuration/store-link')
      .then(res => res.data)
      .then(({ content }) => this.setState({ storeLink: content }))
      .catch(() => this.setState({ storeLink: '' }));
  };

  handleLinkClick = () => {
    if (window.innerWidth > 991) {
      return;
    }

    return toggleHeaderBurger();
  };
}

type HeaderStyleProps = {
  darkMode: boolean;
};

const Header = styled.header<HeaderStyleProps>`
          // background: ${props => (props.darkMode ? 'rgba(82, 107, 124, 1)' : 'inherit')};
          // background: #fff;
          `;

type ButtonStyleProps = {
  fontSize: string
};

const Button = styled.button<ButtonStyleProps>`
            font-size: ${props => props.fontSize};
            margin-bottom: ${props => (props.fontSize ? '30px' : '')};
            background: none;
            border: none;
            `;
