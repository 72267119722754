import React, { Component } from 'react';
import dayjs from 'dayjs';
import { api } from 'api';
import { Space } from 'antd';
import { Table } from 'components/common';
import { CustomRejectButton } from 'custom';
import { date as dateFormat } from '../../../constants';

type Props = {
  requestId: string,
  isAdmin?: boolean
};

type State = {
  isLoading: boolean;
  orderLabels: any[];
  isShipmentLoading: boolean;
  isReturnShipmentLoading: boolean;
};

export default class ShippingTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      orderLabels: [],
      isShipmentLoading: false,
      isReturnShipmentLoading: false,
    };
  }

  componentDidMount() {
    this.fetchLabels();
  }

  columns = [
    {
      title: 'Tracking number',
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string): string => dayjs(date).format(dateFormat.full),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      render: (row: any): JSX.Element => (
        <Space direction="horizontal" align="center">
          <CustomRejectButton
            key={row.trackingNumber}
            loading={!!row.isLoadingPreview}
            onClick={() => this.handleGetShipmentLabel(row.trackingNumber)}
            style={{ padding: 0, marginRight: '1%' }}
          >
            Preview
          </CustomRejectButton>
          { this.props.isAdmin && (
          <CustomRejectButton
            key={row.trackingNumber}
            loading={!!row.isLoadingCancel}
            onClick={() => this.handleCancelShipment(row.trackingNumber)}
            style={{ padding: 0 }}
          >
            Cancel
          </CustomRejectButton>
          )}
        </Space>
      ),

    },
  ];

  render() {
    const { orderLabels } = this.state;
    return (
      <Table columns={this.columns} dataSource={orderLabels} />
    );
  }

  fetchLabels = () => {
    const { requestId } = this.props;
    this.setState({ isLoading: true });
    api
      .get(`/quotes/${requestId}/shipment-labels`)
      .then(res => res.data)
      .then((orderLabels: any) => this.setState({ orderLabels }))
      .finally(() => this.setState({ isLoading: false }));
  };

  handleGetShipmentLabel = (trackingNumber: string) => {
    const { requestId } = this.props;
    this.changeButtonLoading(trackingNumber, 'Preview');
    api
      .get(`quotes/${requestId}/label/${trackingNumber}`)
      .then(res => res.data)
      .then((linkToLabel: string) => {
        window.open(linkToLabel, '_blank')?.focus();
      })
      .finally(() => this.changeButtonLoading(trackingNumber, 'Preview'));
  };

  handleCancelShipment = (trackingNumber: string) => {
    this.changeButtonLoading(trackingNumber, 'Cancel');
    // dal.handleCancelShipment(trackingNumber)
    //   .finally(() => {
    //     this.changeButtonLoading(trackingNumber, 'Cancel');
    //     this.fetchLabels();
    //   });
  };

  changeButtonLoading = (trackingNumber: string, button: 'Preview' | 'Cancel') => {
    this.setState(prevState => {
      switch (button) {
        case 'Preview':
          this.setState({
            orderLabels: prevState.orderLabels.map((m: any) => (m.trackingNumber === trackingNumber
              ? ({ ...m, isLoadingPreview: !m.isLoadingPreview }) : ({ ...m }))),
          });
          break;
        case 'Cancel':
          this.setState({
            orderLabels: prevState.orderLabels.map((m: any) => (m.trackingNumber === trackingNumber
              ? ({ ...m, isLoadingCancel: !m.isLoadingCancel }) : ({ ...m }))),
          });
          break;
        default:
          break;
      }
    });
  };
}
