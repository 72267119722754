import { Breadcrumb } from 'antd';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { colors, text, data } from '../../constants';
import { useWindowSize } from '../../hooks';
import { Separator } from './Separator';

type Props = {
  hasDescription?: boolean;
  hasAttachments?: boolean;
};

export const QuoteBreadcrumb = ({ hasAttachments, hasDescription }: Props) => {
  const { width } = useWindowSize();
  const location = useLocation();
  const history = useHistory();

  const handleRedirect = (url: string) => {
    if (url === 'categories') {
      return history.push('/categories');
    }

    const paths = location.pathname.split('/');
    paths[1] = url;
    history.push(`/${paths.join('/')}`.replace('//', '/'));
  };

  const displayItems = () => {
    let urlsCopy = [...data.STEPS];
    const path = location.pathname.split('/')[1];

    if (!hasAttachments) urlsCopy = urlsCopy.filter(u => u.title !== 'Attachments');

    if (!hasDescription) urlsCopy = urlsCopy.filter(u => u.title !== 'Description');

    let current = 5;

    return urlsCopy.map((v, idx) => {
      if (v.url === path) {
        current = idx;
      }
      return (
        <span key={v.title}>
          <QBreadCrumbItem
            isCurrent={v.url === path ? 'true' : 'false'}
            isnext={idx > current ? 'true' : 'false'}
            onClick={() => idx <= current && handleRedirect(v.url)}
            clickable={idx <= current ? 'true' : 'false'}
          >
            {idx + 1}
            .
            &nbsp;
            {v.title}
          </QBreadCrumbItem>
          {idx !== urlsCopy.length - 1 && <Separator key={`sep-${v.title}`} />}
        </span>
      );
    });
  };

  const displayMobileItems = () => {
    let urlsCopy = [...data.STEPS];
    const path = location.pathname.split('/')[1];

    if (!hasAttachments) urlsCopy = urlsCopy.filter(u => u.title !== 'Attachments');

    if (!hasDescription) urlsCopy = urlsCopy.filter(u => u.title !== 'Description');

    let current = 5;

    return urlsCopy.map((v, idx) => {
      if (v.url === path) {
        current = idx;
      }
      return (
        <MobileItem key={v.url} className="mobile-item">
          <QBreadCrumbItem
            key={v.title}
            isCurrent={v.url === path ? 'true' : 'false'}
            isnext={idx > current ? 'true' : 'false'}
            onClick={() => idx <= current && handleRedirect(v.url)}
            clickable={idx <= current ? 'true' : 'false'}
          >
            {idx + 1}
            .
            &nbsp;
            {v.title}
          </QBreadCrumbItem>
          {idx !== urlsCopy.length - 1 && <Separator />}
        </MobileItem>
      );
    });
  };

  return (
    <Line>
      <QBreadcrumb separator="">{width > 576 ? displayItems() : displayMobileItems()}</QBreadcrumb>
    </Line>
  );
};

const QBreadCrumbItem = styled<any>(Breadcrumb.Item)`
    color: ${props => (props.isCurrent ? '#C0A725' : (props.isnext ? '#707F89' : colors.button.primary))};
    cursor: ${props => (props.clickable ? 'pointer' : 'not-allowed')};
    ${text.tab};
    font-family: Poppins;
`;

const MobileItem = styled.span`
  margin-bottom: 30px !important;
  .anticon {
    margin-left: 20px;
  }
`;

const QBreadcrumb = styled<any>(Breadcrumb)`
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
    @media screen and (max-width: 576px){
      flex-direction: column;
    }

    .ant-breadcrumb-separator {
      display: none;
    }

    > span {
        align-items: center;
        margin: 10px 0px 10px 0px;
        display: flex;
    }
`;

const Line = styled.div`
  border-bottom: 1px solid #B7BFC5;
  width: 100%;
`;
