import React, { Component } from 'react';

type Props = {};

export default class ShippingLabel extends Component {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return <div>xdd</div>;
  }
}
