import React from 'react';
import { Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { Table, Button } from '../common';
import { userStore } from '../../store';
import { columns } from '../../constants';

type Props = {
  dataSource: Loan[]
};

const { ActionButton } = Button;
const { thereAreAdminTransactionColumns, thereAreUserTransactionColumns } = columns;

const TransactionTable: React.FC<Props> = ({ dataSource }: Props) => {
  const history = useHistory();

  const displayColumns = () => {
    const { role } = userStore.getState();
    const func = role === 'admin' ? thereAreAdminTransactionColumns : thereAreUserTransactionColumns;
    return func(generateActionButtons, dollarMapper);
  };

  const generateActionButtons = (record: Loan) => (
    <Space size="middle">
      <ActionButton onClick={() => history.push(`/transactions/details/${record.id}`)}>Details</ActionButton>
    </Space>
  );

  const dollarMapper = (text: String) => `$${text}`;

  return <Table columns={displayColumns()} dataSource={dataSource} />;
};

export default TransactionTable;
