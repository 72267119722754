import { Component } from 'react';
import styled from 'styled-components';
import {
  Col,
} from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import {
  Spinner, Table as CustomTable, Title,
} from '../components/common';
import { api } from '../api';
import { SecondaryButton } from '../components/common/Button';
import { EmailTemplates } from '../components/Modals';
import { EmailTemplateTypes } from '../constants/mocks';
import pad from '../helpers/pad';
import { CustomModal } from '../custom';

const {
  EmailTemplateCreateModal,
  EmailTemplateDeleteModal,
  EmailTemplateViewModal,
  EmailTemplateEditModal,
} = EmailTemplates;

type State = {
  emailTemplates: EmailTemplate[],
  emailTemplate: EmailTemplate,
  isDetailsEmailTemplateModalVisible: boolean;
  isCreateEmailTemplateModalVisible: boolean;
  isEditEmailTemplateModalVisible: boolean;
  isDeleteEmailTemplateModalVisible: boolean;
  isLoading: boolean,
};

export default class TemplateView extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      emailTemplates: [],
      emailTemplate: {
        id: '',
        type: '',
        title: '',
        subject: '',
        content: '',
      },
      isDetailsEmailTemplateModalVisible: false,
      isCreateEmailTemplateModalVisible: false,
      isEditEmailTemplateModalVisible: false,
      isDeleteEmailTemplateModalVisible: false,
      isLoading: true,
    };
  }

  columns = [
    {
      title: 'Number',
      key: 'id',
      render: (_: any, __: any, index: any) => <>{pad(index + 1, 6)}</>,
    },
    {
      title: 'Type',
      render: (emailTemplate: EmailTemplate) => EmailTemplateTypes.find(f => f.value === emailTemplate.type)?.text,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      render: (content: string) => (content.length <= 45 ? content : `${content.substring(0, 45)}...`),
    },
    {
      title: 'Action',
      render: (row: EmailTemplate) => (
        <div>
          <SecondaryButton
            style={{ marginRight: '1%' }}
            onClick={() => this.setState({ isDetailsEmailTemplateModalVisible: true, emailTemplate: row })}
          >
            View
          </SecondaryButton>
          <SecondaryButton
            style={{ marginRight: '1%' }}
            onClick={() => this.setState({ isEditEmailTemplateModalVisible: true, emailTemplate: row })}
          >
            Edit
          </SecondaryButton>
          <SecondaryButton
            onClick={() => this.setState({ isDeleteEmailTemplateModalVisible: true, emailTemplate: row })}
          >
            Delete
          </SecondaryButton>
        </div>
      ),
    },
  ];

  componentDidMount() {
    this.fetchTemplates();
  }

  render() {
    const {
      emailTemplates,
      isLoading,
      isDetailsEmailTemplateModalVisible,
      isCreateEmailTemplateModalVisible,
      isDeleteEmailTemplateModalVisible,
      emailTemplate,
      isEditEmailTemplateModalVisible,
    } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <body className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <Container className="template-view-container">
              <Header>
                <Title title="Email Templates Templates" />
                {emailTemplates.length < 4
                  && (
                    <SecondaryButton
                      onClick={() => this.setState({ isCreateEmailTemplateModalVisible: true })}
                      style={{ float: 'right' }}
                    >
                      Add email template
                    </SecondaryButton>
                  )}
              </Header>
              <Col span={24}>
                <CustomTable columns={this.columns} dataSource={emailTemplates} />
              </Col>
              <EmailTemplateViewModal
                visible={isDetailsEmailTemplateModalVisible}
                emailTemplate={emailTemplate}
                onCancel={() => this.setState({ isDetailsEmailTemplateModalVisible: false })}
              />
              <EmailTemplateCreateModal
                visible={isCreateEmailTemplateModalVisible}
                emailTemplates={emailTemplates}
                onFinish={this.handleCreateEmailTemplate}
                onCancel={() => this.setState({ isCreateEmailTemplateModalVisible: false })}
              />
              <EmailTemplateEditModal
                visible={isEditEmailTemplateModalVisible}
                emailTemplate={emailTemplate}
                onFinish={this.handleEditEmailTemplate}
                onCancel={() => this.setState({ isEditEmailTemplateModalVisible: false })}
              />
              <EmailTemplateDeleteModal
                visible={isDeleteEmailTemplateModalVisible}
                emailTemplate={emailTemplate}
                onDelete={this.handleDeleteEmailTemplate}
                onCancel={() => this.setState({ isDeleteEmailTemplateModalVisible: false })}
              />
            </Container>
          </div>
        </div>
      </body>
    );
  }

  fetchTemplates = () => {
    this.setState({ isLoading: true });
    api
      .get('/templates')
      .then(res => res.data)
      .then(emailTemplates => this.setState({ emailTemplates }))
      .catch(() => {
        CustomModal.displayCustomError({
          title: 'Error',
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleDeleteEmailTemplate = (id: string) => {
    api
      .delete(`/templates/delete/${id}`)
      .then(() => { this.setState({ isDeleteEmailTemplateModalVisible: false }); this.fetchTemplates(); })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Template has been deleted',
        });
      })
      .catch(() => {
        CustomModal.displayCustomError({
          title: 'Error',
        });
      });
  };

  handleCreateEmailTemplate = (values: string[]) => {
    api
      .post('/templates/create', { ...values })
      .then(() => { this.setState({ isCreateEmailTemplateModalVisible: false }); this.fetchTemplates(); })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Template has been created',
        });
      })
      .catch(() => {
        CustomModal.displayCustomError({
          title: 'Error',
        });
      });
  };

  handleEditEmailTemplate = (values: string[], id: string) => {
    api
      .patch(`/templates/${id}`, { ...values })
      .then(() => { this.setState({ isEditEmailTemplateModalVisible: false }); this.fetchTemplates(); })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Template has been saved',
        });
      })
      .catch(() => {
        CustomModal.displayCustomError({
          title: 'Error',
        });
      });
  };
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    thead tr {
        background-color: black !important;
    }
    padding: 0px 15px 15px 15px;
`;

const Header = styled.div`
  width: 100%;
  padding: 24px;
`;
