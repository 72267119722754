/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/button-has-type */
import React, { Component, createRef } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Spinner } from '../common';
import { api } from '../../api';
import { interceptPostError } from '../../utils/interceptors';
import { ADD_USER } from '../../store/actionTypes';
import { userStore } from '../../store/configureStore';
import ErrorMessage from '../common/ErrorMessage';
import fonts from '../../constants/fonts';
import RecoverPasswordModal from './RecoverPasswordModal';
import RecoveryPasswordSent from './RecoveryPasswordSent';
import ContactUs from './ContactUs';

const { mainFont } = fonts.fonts;

type Props = {
  isVisible: boolean;
  handleLoginModal: (state: boolean) => void;
  handleRegisterModal?: (state: boolean) => void;
  onFinish?: () => void;
};

type State = {
  errorMessage?: string,
  isLoading: boolean,
  isLoadingModal: boolean,
  isRecoverPasswordView: boolean,
  isSendingEmail: boolean,
  isContactUsVisible: boolean,
  isConfirmSentMessageVisible: boolean;
  showEmail: string;
};

export default class LoginModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingModal: false,
      isRecoverPasswordView: false,
      isSendingEmail: false,
      isContactUsVisible: false,
      isConfirmSentMessageVisible: false,
      showEmail: '',
    };
  }

  formRef = createRef<FormInstance>();

  componentDidMount = () => {
    if (this.props.isVisible)
      setTimeout(() => this.setState({ isLoadingModal: true }), 70);
  };

  render() {
    const {
      errorMessage,
      isLoadingModal,
      isRecoverPasswordView,
      isSendingEmail,
      isContactUsVisible,
      isConfirmSentMessageVisible,
      showEmail,
      isLoading,
    } = this.state;

    return (
      <>
        <div className={`popup ${isLoadingModal && 'popup--shown'}`} style={{ fontFamily: mainFont }}>
          <div className="popup__wrap popup__wrap--wide">
            <a className="popup__close" onClick={() => this.handleCloseModal()} />
            <div className="popup__inner">
              <div className="popup__header">
                <div className="popup__title">Please login</div>
                {/* <div className="popup__text">
                You have chosen to sell your: 1 Ring, 1 Watch
                <br />
                You need  login to get paid in your account
              </div> */}
              </div>
              <div className="popup__content">
                <Form className="login-form" ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                  <div className="login-form__body">
                    <div className="form-input">
                      <Form.Item
                        rules={[
                          {
                            type: 'email',
                            message: '',
                          },
                          {
                            required: true,
                            message: 'Email is required',
                          },
                        ]}
                        name="email"
                        required
                      >
                        <input type="email" placeholder="Email" />
                      </Form.Item>
                    </div>
                    <div className="form-input">
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Password is required',
                          },
                        ]}
                      >
                        <input type="password" placeholder="Password" />
                      </Form.Item>
                      <div className="form-input__footer">
                        <span>Forgot your password?</span>
                        <a style={{ color: '#4993c6' }} onClick={() => this.setState({ isRecoverPasswordView: true })}>Recover Here</a>
                      </div>
                    </div>
                  </div>

                  <div className="login-form__footer">
                    <button className="btn btn--dark popup__btn popup__btn--wide login-form__submit" type="submit">
                      {
                        isLoading
                          ? <Spinner loaderSize={{ width: 20, height: 20 }} tip={' '} style={{ marginTop: 20 }} />
                          : 'Login'
                      }
                    </button>
                    <div className="form-input__footer">
                      <span>{"Don't have an account yet?"}</span>
                      <a onClick={this.onRegisterClick} style={{ color: '#4993c6' }}>Create Here</a>
                    </div>
                  </div>
                </Form>
                {errorMessage && <ErrorMessage message={errorMessage} />}
              </div>
            </div>
          </div>
        </div>
        <RecoverPasswordModal
          isVisible={isRecoverPasswordView}
          isSendingEmail={isSendingEmail}
          onCloseModal={() => this.setState({ isRecoverPasswordView: false })}
          onSubmit={this.onRecoverPassword}
        />
        <ContactUs
          isVisible={isContactUsVisible}
          onCancel={() => this.setState({ isContactUsVisible: false })}
        />
        <RecoveryPasswordSent
          isVisible={isConfirmSentMessageVisible}
          showEmail={showEmail}
          onCancel={() => this.setState({ isConfirmSentMessageVisible: false })}
          onShowContact={() => this.setState({ isContactUsVisible: true, isConfirmSentMessageVisible: false })}
        />
      </>
    );
  }

  handleCloseModal = () => {
    this.props.handleLoginModal(false);
    this.setState({ isLoadingModal: false });
  };

  handleShowConfirmSentEmail = (email: any) => {
    this.setState({ isConfirmSentMessageVisible: true, showEmail: email.email });
  };

  onRegisterClick = () => {
    const { handleRegisterModal, handleLoginModal } = this.props;
    if (!handleRegisterModal)
      return;
    this.formRef.current?.resetFields();
    handleLoginModal(false);
    handleRegisterModal(true);
  };

  onFinish = (values: any) => {
    this.setState({ isLoading: true, errorMessage: undefined });
    api.post('/users/login', { ...values })
      .then(res => res.data)
      .then((user: User) => {
        userStore.dispatch({ type: ADD_USER, user });
        if (this.props.onFinish) {
          this.props.onFinish();
        }
        this.setState({ errorMessage: undefined, isLoading: false });
        this.formRef.current?.resetFields();
        this.props.handleLoginModal(false);
      })
      .catch(err => this.setState({ errorMessage: err && err.response.data.message }))
      .finally(() => this.setState({ isLoading: false }));
  };

  onRecoverPassword = (email: string) => {
    this.setState({ isSendingEmail: true });
    api
      .post('/user/reset-password', email)
      .then(() => this.handleShowConfirmSentEmail(email))
      .catch(() => interceptPostError)
      .finally(() => this.setState({ isSendingEmail: false, isRecoverPasswordView: false }));
  };
}
