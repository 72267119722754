/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-brace-presence */
import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { AxiosError } from 'axios';
import {
  Spinner,
  StateActionCard,
} from '../components/common';
import { data } from '../constants';
import { config } from '../config';
import { api } from '../api';
import { interceptPostError, interceptPostErrorMessages, interceptGetError } from '../utils/interceptors';
import { Modals } from '../components';
import { CustomParameters, CustomModal } from '../custom';
import { actionTypes, userStore } from '../store';
import { CreateShippingLabelModal, ConfirmDeliveryAddressModal } from '../components/features/fedex';
import EMAIL_NOTIFICATIONS_STAGES from '../constants/emailNotificationsStages';
import AcceptedByUser from '../components/Notifications/user/Accepted/AcceptedByUser';
import AcceptedByAdmin from '../components/Notifications/user/Accepted/AcceptedByAdmin';
import AcceptedByAdminCounterOffer from '../components/Notifications/user/Accepted/AcceptedByAdminCounterOffer';
import PreliminaryQuote from '../components/Notifications/user/PreliminaryQuote';
import PreliminaryQuotedFinal from '../components/Notifications/user/PreliminaryQuotedFinal';
import ItemSent from '../components/Notifications/user/Shipping/ItemSent';
import ShippingBox from '../components/Notifications/user/Shipping/ShippingBox';
import ShipmentInProgress from '../components/Notifications/user/ShipmentInProgress';
import RequestDetailsContent from '../components/RequestDetailsContent';
import RequestSentEstimate from '../components/Notifications/user/RequestSentEstimate';
import RejectedByUser from '../components/Notifications/user/Rejected/RejectedByUser';
import RejectedByAdmin from '../components/Notifications/user/Rejected/RejectedByAdmin';
import RequestSentCounterOffer from '../components/Notifications/user/RequestSentCounterOffer';
import ReviewProcess from '../components/Notifications/user/ReviewProcess';
import ReceivedItemCounterOfferByAdmin from '../components/Notifications/user/ItemReceived/ReceivedItemCounterOfferByAdmin';
import ReceivedItemCounterOfferByUser from '../components/Notifications/user/ItemReceived/ReceivedItemCounterOfferByUser';
import ReceivedItemAcceptedByUser from '../components/Notifications/user/ItemReceived/ReceivedItemAcceptedByUser';
import ReceivedItemAcceptedByAdmin from '../components/Notifications/user/ItemReceived/ReceivedItemAcceptedByAdmin';
import ReceivedItemCounterOfferByAdminFinal from '../components/Notifications/user/ItemReceived/ReceivedItemCounterOfferByAdminFinal';
import ReceivedRejectedByAdmin from '../components/Notifications/user/ItemReceived/ReceivedRejectedByAdmin';
import ReceivedRejectedByUser from '../components/Notifications/user/ItemReceived/ReceivedRejectedByUser';
import PaymentMainView from '../components/Notifications/user/PaymentInformation/PaymentMainView';
import ContactUs from '../components/Modals/ContactUs';
import LoanCreated from '../components/Notifications/user/LoanCreated';
import SaleCreated from '../components/Notifications/user/SaleCreated';
import LoanBalance from '../components/Notifications/user/PaymentStatus/LoanBalance';
import OptOut from '../components/Modals/OptOut';
import PaymentAction from '../components/Notifications/user/PaymentStatus/PaymentAction';
import Agreement from '../components/Notifications/user/Agreement';
import LoanClosed from '../components/Notifications/user/LoanClosed';

const {
  UV_3A_ACCEPT,
  UV_3A_COUNTER,
  UV_4A_FINAL_ACCEPT,
  UV_4A_FINAL_CANCEL,
  UV_5E_5F_SENT,
  UV_5E_BOX_NOT_ARRIVED,
  UV_7C_ACCEPT_COUNTER,
  UV_7C_COUNTER,
  UV_7D_FINAL_ACCEPT,
  UV_7D_FINAL_CANCEL,
} = EMAIL_NOTIFICATIONS_STAGES;

const {
  AcceptRejectModal, RequestDetailsModal, CancelOfferPopUp, AcceptRejectOfferUser, ConfirmShipItems,
} = Modals;
const { customQuoteFormatParameters } = CustomParameters;

interface Props extends RouteComponentProps { }

type ModalMode = 'accept' | 'reject' | 'off';

type State = {
  modalMode: ModalMode;
  request: Quote;
  choosenRequest: Quote;
  transactions: Loan[];
  requests: Quote[];
  loan?: Loan;
  actionHistory: Action[];
  quoteValue: number;
  interestData?: InterestData;
  isLoading: boolean;
  isDetailsModalVisible: boolean;
  isAgreementVisible: boolean;
  isAgreementDemoVisible: boolean;
  isConnectButtonVisible: boolean;
  isShippmentCreateModalVisible: boolean;
  isShippedBackModalVisible: boolean;
  shipmentDetails: ShipmentDetails | undefined
  isVivisbleConfirmOfferPopUp: boolean;
  isVisibleAcceptRejectOfferPopUp: boolean;
  isVisibleAcceptShipItemsPopUp: boolean;
  isContactUsVisible: boolean;
  isOptOutVisible: boolean;
  errorRecurringPayment: boolean;
  modeAcceptRejectOffer: 'accept' | 'reject';
  update: boolean;
  showPaymentNotification: boolean;
  isPaymentSuccess: boolean;
  isPaymentActionVisible: boolean;
  isBoxRequest: boolean;
  isLoanBalanceVisible: boolean;
  manualPaymentOption: string;
  emailNotification?: string;
  agreementContent?: any;
  isChangingBankAccount: boolean;
};

export default class RequestDetailsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalMode: 'off',
      request: data.QUOTE_DEFAULT,
      choosenRequest: data.QUOTE_DEFAULT,
      requests: [],
      actionHistory: [],
      transactions: [],
      quoteValue: 0,
      isLoading: true,
      isDetailsModalVisible: false,
      isAgreementVisible: false,
      isAgreementDemoVisible: true,
      isConnectButtonVisible: false,
      isShippmentCreateModalVisible: false,
      shipmentDetails: undefined,
      isShippedBackModalVisible: false,
      isVivisbleConfirmOfferPopUp: false,
      isVisibleAcceptRejectOfferPopUp: false,
      isVisibleAcceptShipItemsPopUp: false,
      isContactUsVisible: false,
      isOptOutVisible: false,
      errorRecurringPayment: false,
      modeAcceptRejectOffer: 'accept',
      update: false,
      showPaymentNotification: false,
      isPaymentSuccess: false,
      isPaymentActionVisible: false,
      isBoxRequest: false,
      isLoanBalanceVisible: false,
      manualPaymentOption: '',
      isChangingBankAccount: false,
    };
  }

  componentDidMount() {
    this.fetchRequest();
  }

  render() {
    const {
      modalMode,
      isLoading,
      isDetailsModalVisible,
      choosenRequest,
      requests,
      actionHistory,
      isShippmentCreateModalVisible,
      isShippedBackModalVisible,
      request: { id, shippingLabels, context },
      quoteValue,
      isVivisbleConfirmOfferPopUp,
      isVisibleAcceptRejectOfferPopUp,
      isVisibleAcceptShipItemsPopUp,
      isContactUsVisible,
      isOptOutVisible,
      modeAcceptRejectOffer,
      isPaymentSuccess,
      isPaymentActionVisible,
      isBoxRequest,
      isAgreementVisible,
      interestData,
      agreementContent,
    } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        <RequestDetailsContent
          mode={context.mode}
          step={context.stateStepper}
          actionHistory={actionHistory}
          requests={requests}
          contentData={this.handleDisplayStateActions()}
          showDetails={(id: any) => this.handleViewDetails(id)}
        />
        <RequestDetailsModal
          fetchRequest={this.fetchRequest}
          isVisible={isDetailsModalVisible}
          quote={choosenRequest}
          handleVisibileChange={this.handleDetailsModalVisibilityChange}
        />
        <AcceptRejectModal
          onReject={() => this.handleTransition('reject', 'Rejected')}
          onSend={this.handleAccept}
          modalType={modalMode}
          handleSetAndDisplayModal={this.handleSetAndDisplayModal}
        />
        <RequestDetailsModal
          fetchRequest={this.fetchRequest}
          isVisible={isDetailsModalVisible}
          quote={choosenRequest}
          handleVisibileChange={this.handleDetailsModalVisibilityChange}
        />
        <CreateShippingLabelModal
          requestId={id}
          onVisibleChange={this.handleChangeIsShippingModalVisible}
          isVisible={isShippmentCreateModalVisible}
          onFinish={() => this.handleTransition('send', 'Shipment label created')}
        />
        {
          shippingLabels.length > 0 && (
            <ConfirmDeliveryAddressModal
              details={shippingLabels}
              isVisible={isShippedBackModalVisible}
              onFinish={this.handleShipback}
              onVisibleChange={this.handleChangeIsShippedBackModalVisible}
            />
          )
        }
        <CancelOfferPopUp
          isVisible={isVivisbleConfirmOfferPopUp}
          quoteValue={quoteValue}
          onCancel={() => this.setState({ isVivisbleConfirmOfferPopUp: false })}
          onOk={this.handleCounterOfferSend}
        />
        <AcceptRejectOfferUser
          isVisible={isVisibleAcceptRejectOfferPopUp}
          mode={modeAcceptRejectOffer}
          onCancel={() => this.setState({ isVisibleAcceptRejectOfferPopUp: false })}
          onOk={this.handleAcceptRejectOffer}
        />
        <ConfirmShipItems
          isBoxRequest={isBoxRequest}
          isVisible={isVisibleAcceptShipItemsPopUp}
          onCancel={() => this.setState({ isVisibleAcceptShipItemsPopUp: false })}
          onConfirm={value => this.handleShippingSentItem(value)}
        />
        <ContactUs
          isVisible={isContactUsVisible}
          onCancel={() => this.setState({ isContactUsVisible: false })}
        />
        <OptOut
          isVisible={isOptOutVisible}
          onCancel={() => this.setState({ isOptOutVisible: false })}
          onConfirm={this.handleOptOut}
        />
        <PaymentAction
          isVisible={isPaymentActionVisible}
          paymentSuccess={isPaymentSuccess}
          onGoBack={() => this.setState({ isPaymentActionVisible: false })}
        />
        {
          isAgreementVisible && (
            <Agreement
              interestData={interestData}
              quoteValue={this.showQuoteValue(requests)}
              mode={context.mode}
              isAgreementVisible={isAgreementVisible}
              agreementContent={agreementContent}
              onCancelAgreement={() => this.setState({ isAgreementVisible: false })}
              onSignAgreement={() => this.handleTransition('sign', 'Agreement signed')}
            />
          )
        }
      </>
    );
  }

  showQuoteValue = (request: Quote[]) => {
    if (request.length > 1) {
      const quote = request.filter(quote => !quote.parentId);
      return quote[0].context.quoteValue;
    }
    return request[0].context.quoteValue;
  };

  getInterestRate = (amount: number) => {
    let montlyPercentageRate = 0;
    let yearlyPercentageRate = 0;
    data.INTEREST_FORMULA.forEach((f: any) => {
      if (amount >= f.min && amount <= f.max) {
        montlyPercentageRate = f.monthly / 100;
        yearlyPercentageRate = f.yearly / 100;
      }
    });

    const interestRate = amount * montlyPercentageRate * 3;
    return {
      interestValue: parseFloat(interestRate.toFixed(2)),
      montlyPercentageRate,
      yearlyPercentageRate,
    };
  };

  setInterestRates = (amount: number) => {
    const {
      interestValue,
      montlyPercentageRate,
      yearlyPercentageRate,
    } = this.getInterestRate(amount);
    this.setState({
      interestData: {
        interestValue,
        montlyPercentageRate,
        yearlyPercentageRate,
      },
    });
  };

  handleShippingSentItem = (value: string) => {
    if (value === 'transport')
      this.handleTransition(value, 'Items shiped');
    else
      this.handleUpdateQuote({ boxIsNotArrived: true });
  };

  capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  handleShipback = (values: any) => {
    const { request: { id } } = this.state;
    this.handleChangeIsShippedBackModalVisible(false);
    api.post('/shipment/return', {
      values,
      requestId: id,
    }).then(res => res.data)
      .then((linkToLabel: string) => {
        window.open(linkToLabel, '_blank')?.focus();
        this.handleTransition('reject', 'Rejected');
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status === 503) {
          CustomModal.displayCustomWarning({
            title: error.response?.data.message,
            content: error.response?.data.data,
          });
        } else {
          interceptPostErrorMessages(error.response?.data.errorMessages);
        }
      });
  };

  handleChangeIsShippedBackModalVisible = (state: boolean) => this.setState({ isShippedBackModalVisible: state });

  handleChangeIsShippingModalVisible = (isShippmentCreateModalVisible: boolean) => {
    this.setState({ isShippmentCreateModalVisible });
  };

  handleFormatTableDataSource = () => {
    const { requests } = this.state;
    return requests
      .filter(r => r.category !== 'package')
      .map(r => ({
        ...r,
        name: r.name.replace('&', ' / '),
        parameters: customQuoteFormatParameters(r),
      }));
  };

  handleSetAndDisplayModal = (modalMode: ModalMode) => this.setState({ modalMode: modalMode || 'off' });

  fetchRequest = () => {
    const id = this.props.location.pathname.split('/').pop();
    if (!id) return;

    this.setState({ isLoading: true });

    api
      .get(`/quotes/group/${id}`)
      .then(res => res.data)
      .then((res: Quote[]) => {
        const request = res.find(r => r.parentId === null) || res[0];
        this.setState({ request, requests: res });
        this.fetchtransactionsByRequestId(request.id);
        this.handleDisplayStateActions();
        this.fetchActions();
        this.fetchAgreementContent(request.context.mode);
        this.setInterestRates(request.context.quoteValue);
        if (request.state === 'active') this.fetchLoanByQuoteId(request.id);
      })
      .catch(() => { })
      .finally(() => this.setState({ isLoading: false }));
  };

  fetchLoanByQuoteId = (id: string) => {
    api
      .get(`/loans/quote/${id}`)
      .then(res => res.data)
      .then(loan => this.setState({ loan }));
  };

  handleAgreementValue = (value: string) => {
    switch (value) {
      case 'PAWN':
        return 'agreement-pawn';
      default:
        return 'agreement-sell';
    }
  };

  fetchAgreementContent = (value: string) => {
    const agreementTemplate = this.handleAgreementValue(value);
    api
      .get(`/configuration/${agreementTemplate}`)
      .then(res => res.data)
      .then(agreementContent => {
        const agreementData = JSON.parse(agreementContent?.content);
        this.setState({ agreementContent: agreementData.content });
      })
      .catch(interceptGetError);
  };

  fetchActions = () => {
    const id = this.state.requests[0].groupId;

    api
      .get(`/action-history/${id}`)
      .then(res => res.data)
      .then(actionHistory => this.setState({ actionHistory }))
      .catch(() => { });
  };

  fetchtransactionsByRequestId = (id: string) => {
    api
      .get(`/transactions/quote/${id}`)
      .then(res => res.data)
      .then(transactions => this.setState({ transactions }));
  };

  handleSubscribe = (type: string) => {
    const {
      request: { id },
    } = this.state;

    this.setState({ isLoading: true });

    if (type === 'CARD') {
      return api
        .post(`/quotes/subscribe/${id}`, { type })
        .then(res => res.data)
        .then(async ({ sessionId }) => {
          const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
          stripePromise?.redirectToCheckout({ sessionId });
        })
        .catch(interceptPostError)
        .finally(() => this.setState({ isLoading: false }));
    }

    api
      .post(`/quotes/subscribe/${id}`, { type })
      .then(res => res.data)
      .then(() => this.fetchRequest())
      .catch(interceptPostError)
      .finally(() => this.setState({ isLoading: false }));
  };

  handleManualCardPayment = (manualPaymentOption: string, otherAmountValue?: string) => {
    const {
      request: { id },
    } = this.state;

    let otherAmount;

    if (otherAmountValue === '$0')
      otherAmount = 0;
    else
      otherAmount = Number(otherAmountValue).toFixed(2);

    this.setState({ isLoading: true });

    api
      .post('/payments/pay-card', { id, manualPaymentOption, otherAmountValue: otherAmount })
      .then(res => res.data)
      .then(async ({ sessionId }) => {
        const stripePromise = await loadStripe(config.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        stripePromise?.redirectToCheckout({ sessionId });
      })
      .catch(interceptPostError)
      .finally(() => this.setState({ isLoading: false }));
  };

  handleManualBankPayment = (manualPaymentOption: string, otherAmountValue?: string) => {
    const {
      request: { id },
    } = this.state;

    let otherAmount;

    if (otherAmountValue === '$0')
      otherAmount = 0;
    else
      otherAmount = Number(otherAmountValue).toFixed(2);

    this.setState({ isLoading: true });

    api
      .post('/payments/pay', { id, manualPaymentOption, otherAmountValue: otherAmount })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Payment success',
        });
        this.setState({ isPaymentSuccess: true, showPaymentNotification: true });
      })
      .catch(error => {
        CustomModal.displayCustomWarning({
          title: 'Payment failed',
          content: error.response?.data.message,
        });
        this.setState({ isPaymentSuccess: false, showPaymentNotification: true });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleAcceptRejectOffer = (mode: string) => {
    this.handleTransition(mode, mode === 'accept' ? 'Accepted' : 'Rejected');
  };

  handleTransition = (action: string, successMessage: string) => {
    const {
      request: { id, context }, emailNotification,
    } = this.state;

    let { stateStepper } = context;

    if (action === 'accept' || action === 'sign')
      stateStepper = context.stateStepper + 1;

    this.setState({ isLoading: true });
    api
      .post(`/quotes/${action}/${id}`, { isAdminRejected: false, stateStepper, emailNotification })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: successMessage,
        });
        this.fetchRequest();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({
        isLoading: false,
        isVisibleAcceptRejectOfferPopUp: false,
        isVisibleAcceptShipItemsPopUp: false,
        isAgreementVisible: false,
      }));
  };

  handleUpdateQuote = (values: any) => {
    const { request: { id, context }, emailNotification } = this.state;
    this.setState({ isLoading: true });
    const updateContext = { ...context, ...values };
    api
      .patch(`/quotes/${id}`, { updateContext, emailNotification })
      .then(() => this.fetchRequest())
      .catch(interceptPostError)
      .finally(() => this.setState({ isLoading: false, isVisibleAcceptShipItemsPopUp: false, isBoxRequest: false }));
  };

  handleAccept = () => {
    const {
      request: { id },
    } = this.state;
    this.setState({ modalMode: 'off', isLoading: true });
    api
      .post(`/quotes/accept/${id}`, { isAdminAccepted: false })
      .then(() => this.fetchRequest())
      .finally(() => this.setState({ isLoading: false }));
  };

  handleCounterOfferSend = () => {
    const {
      quoteValue,
      request: { id },
      emailNotification,
    } = this.state;
    if (quoteValue <= 0) {
      CustomModal.displayCustomWarning({
        title: 'Counter offer must be greater than 0',
      });
    }

    this.setState({ isLoading: true });
    api
      .post(`/quotes/counter/${id}`, { quoteValue, emailNotification })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Counter offer sent',
        });
        this.fetchRequest();
      })
      .finally(() => this.setState({ isLoading: false, isVivisbleConfirmOfferPopUp: false }));
  };

  handleIsLoading = (isLoading: boolean) => this.setState({ isLoading });

  handleQuoteValueChange = (e: number) => this.setState({ quoteValue: e });

  handleDetailsModalVisibilityChange = (state: boolean) => this.setState({ isDetailsModalVisible: state });

  handleViewDetails = (id: string) => {
    const choosenRequest = this.state.requests.filter(req => req.id === id)[0];
    this.setState({ choosenRequest, isDetailsModalVisible: true });
  };

  handleRedirectToTransactionDetails = () => this.props.history.push('/transactions/default');

  handleGetFileNames = (): string[] => this.state.request.agreement?.attachments?.map(c => c.fileName) || [];

  handleBankSubscription = () => {
    const { bankAccount } = userStore.getState();
    this.setState({ isConnectButtonVisible: false });
    if (bankAccount) {
      return this.handleSubscribe('BANK');
    }
    this.setState({ isConnectButtonVisible: true });
  };

  handleOptOut = () => {
    this.setState({ isLoading: true });
    const { id } = userStore.getState();
    const loanId = this.state.loan?.id;
    const requestId = this.state.request.id;

    api
      .post(`/payments/delete-payment-method/${id}`, { loanId, requestId })
      .then(() => {
        this.updateUser();
      })
      .finally(() => this.setState({ isLoading: false, isOptOutVisible: false }));
  };

  updateUser = () => {
    api
      .post('/user')
      .then(res => res.data)
      .then((user: User) => {
        userStore.dispatch({ type: actionTypes.ADD_USER, user });
      })
      .then(() => this.fetchRequest())
      .catch(() => { });
  };

  handleMakeManualPayment = (payWith: string, option: string, otherAmount?: string) => {
    switch (payWith) {
      case 'BANK':
        return this.handleManualBankPayment(option, otherAmount);
      case 'CARD':
        return this.handleManualCardPayment(option, otherAmount);
      default:
    }
  };

  handleOnClosePaymentNotification = () => {
    this.setState({ showPaymentNotification: false });
    this.fetchRequest();
  };

  handleCloseChangingBankAccount = () => {
    this.setState({ isChangingBankAccount: false });
    this.fetchRequest();
  };

  handleDisplayStateActions = () => {
    const {
      request: {
        state,
        context: {
          mode,
          quoteValue,
          status,
          isFinal,
          isDelivered,
          isCounterOffer,
          isAdminRejected,
          isAdminAccepted,
          submission: {
            generalInfo: { transactionType },
          },
          isLoanBalanceVisible,
          boxIsNotArrived,
        },
        shippingLabels,
      },
      isConnectButtonVisible,
      errorRecurringPayment,
      isPaymentSuccess,
      showPaymentNotification,
      requests,
      interestData,
      loan,
    } = this.state;

    switch (state) {
      case 'submitted':
        return (
          <>
            {status === 'Request sent' && quoteValue !== 0 && isCounterOffer
              && <RequestSentCounterOffer />}

            {status === 'PQ - countered' && quoteValue !== 0
              && <RequestSentCounterOffer />}

            {status === 'OQ - countered' && quoteValue !== 0
              && <ReceivedItemCounterOfferByUser quoteValue={quoteValue} />}

            {status === 'Request sent' && quoteValue === 0
              && <RequestSentEstimate typeRequest={mode} />}

            {status === 'PQ - accepted' && quoteValue !== 0 && !isCounterOffer
              && (
                <AcceptedByAdmin
                  quoteValue={quoteValue}
                  onSubmit={() => this.setState({ isShippmentCreateModalVisible: true })}
                />
              )}
          </>
        );
      case 'underReview':
        return <ReviewProcess quoteValue={quoteValue} />;
      case 'paymentProcess':
        return <StateActionCard status={status} quoteValue={quoteValue} title="Wait for E&B decision" />;
      case 'quotted':
        return (
          <>
            {status === 'Preliminary quote' && isFinal && !isDelivered && (
              <PreliminaryQuotedFinal
                typeRequest={mode}
                quoteValue={quoteValue}
                onIsVisibleAcceptRejectOfferPopUp={() => this.setState({ isVisibleAcceptRejectOfferPopUp: true, modeAcceptRejectOffer: 'accept', emailNotification: UV_4A_FINAL_ACCEPT })}
                onQuoteValueChange={e => this.handleQuoteValueChange(e)}
                onIsVivisbleConfirmOfferPopUp={() => this.setState({ isVivisbleConfirmOfferPopUp: true })}
                onCancelTransaction={() => this.setState({ isVisibleAcceptRejectOfferPopUp: true, modeAcceptRejectOffer: 'reject', emailNotification: UV_4A_FINAL_CANCEL })}
              />
            )}
            {status === 'Preliminary quote' && !isFinal && !isDelivered && (
              <PreliminaryQuote
                typeRequest={mode}
                quoteValue={quoteValue}
                onIsVisibleAcceptRejectOfferPopUp={() => this.setState({ isVisibleAcceptRejectOfferPopUp: true, modeAcceptRejectOffer: 'accept', emailNotification: UV_3A_ACCEPT })}
                onQuoteValueChange={e => this.handleQuoteValueChange(e)}
                onIsVivisbleConfirmOfferPopUp={() => this.setState({ isVivisbleConfirmOfferPopUp: true, emailNotification: UV_3A_COUNTER })}
              />
            )}
            {isFinal && isDelivered && (
              <ReceivedItemCounterOfferByAdminFinal
                typeRequest={mode}
                quoteValue={quoteValue}
                onIsVisibleAcceptRejectOfferPopUp={() => this.setState({ isVisibleAcceptRejectOfferPopUp: true, modeAcceptRejectOffer: 'accept', emailNotification: UV_7D_FINAL_ACCEPT })}
                onQuoteValueChange={e => this.handleQuoteValueChange(e)}
                onIsVivisbleConfirmOfferPopUp={() => this.setState({ isVivisbleConfirmOfferPopUp: true })}
                onCancelTransaction={() => this.setState({ isVisibleAcceptRejectOfferPopUp: true, modeAcceptRejectOffer: 'reject', emailNotification: UV_7D_FINAL_CANCEL })}
              />
            )}
            {!isFinal && isDelivered && isCounterOffer && (
              <ReceivedItemCounterOfferByAdmin
                typeRequest={mode}
                quoteValue={quoteValue}
                onIsVisibleAcceptRejectOfferPopUp={() => this.setState({ isVisibleAcceptRejectOfferPopUp: true, modeAcceptRejectOffer: 'accept', emailNotification: UV_7C_ACCEPT_COUNTER })}
                onQuoteValueChange={e => this.handleQuoteValueChange(e)}
                onIsVivisbleConfirmOfferPopUp={() => this.setState({ isVivisbleConfirmOfferPopUp: true, emailNotification: UV_7C_COUNTER })}
              />
            )}
          </>
        );

      case 'accepted':
        return (
          <>
            {isAdminAccepted && isCounterOffer && !isDelivered
              && (
                <AcceptedByAdminCounterOffer
                  quoteValue={quoteValue}
                  onSubmit={() => this.setState({ isShippmentCreateModalVisible: true })}
                />
              )}
            {isAdminAccepted && !isCounterOffer && !isDelivered
              && (
                <AcceptedByAdmin
                  quoteValue={quoteValue}
                  onSubmit={() => this.setState({ isShippmentCreateModalVisible: true })}
                />
              )}
            {!isAdminAccepted && status !== 'OQ - accepted' && !isDelivered
              && (
                <AcceptedByUser
                  quoteValue={quoteValue}
                  onSubmit={() => this.setState({ isShippmentCreateModalVisible: true })}
                />
              )}
            {isDelivered && status === 'OQ - accepted' && !isAdminAccepted
              && (
                <ReceivedItemAcceptedByUser
                  typeRequest={mode}
                  quoteValue={quoteValue}
                  interestData={interestData}
                  onViewAgreement={emailNotification => this.setState({ isAgreementVisible: true, emailNotification })}
                />
              )}
            {isDelivered && status === 'OQ - accepted' && isAdminAccepted
              && (
                <ReceivedItemAcceptedByAdmin
                  interestData={interestData}
                  typeRequest={mode}
                  quoteValue={quoteValue}
                  onViewAgreement={emailNotification => this.setState({ isAgreementVisible: true, emailNotification })}
                />
              )}
          </>
        );
      case 'sent':
        return (
          <>
            {shippingLabels[0].shipmentDetails.boxSize === 'YOUR_PACKAGING' && (
              <ItemSent
                requestId={requests[0].id}
                quoteValue={quoteValue}
                onSentItem={() => this.setState({ isVisibleAcceptShipItemsPopUp: true, isBoxRequest: false, emailNotification: UV_5E_5F_SENT })}
              />
            )}
            {shippingLabels[0].shipmentDetails.boxSize !== 'YOUR_PACKAGING' && (
              <ShippingBox
                quoteValue={quoteValue}
                boxIsNotArrived={boxIsNotArrived}
                onSentItem={() => this.setState({ isVisibleAcceptShipItemsPopUp: true, isBoxRequest: false, emailNotification: UV_5E_5F_SENT })}
                onBoxRequest={() => this.setState({ isVisibleAcceptShipItemsPopUp: true, isBoxRequest: true, emailNotification: UV_5E_BOX_NOT_ARRIVED })}
              />
            )}
          </>
        );
      case 'shipmentInProgress':
        return (
          <ShipmentInProgress
            quoteValue={quoteValue}
          />
        );
      case 'officialAgreementGenerated':
        return;
      case 'signed':
        return (
          transactionType === 'SELL' ? (
            <SaleCreated
              quoteValue={quoteValue}
            />
          ) : (
            <PaymentMainView
              isConnectButtonVisible={isConnectButtonVisible}
              onAuthorize={this.handleBankSubscription}
            />
          )
        );
      case 'active':
        return (
          <>
            {
              isLoanBalanceVisible
                ? (
                  <LoanBalance
                    loanData={loan}
                    quoteValue={quoteValue}
                    signedAutoWithdraw={userStore.getState().bankAccount}
                    errorRecurringPayment={errorRecurringPayment}
                    isPaymentSuccess={isPaymentSuccess}
                    showPaymentNotification={showPaymentNotification}
                    onContactUs={() => this.setState({ isContactUsVisible: true })}
                    onOptOut={() => this.setState({ isOptOutVisible: true })}
                    onChangeBankAccount={() => this.setState({ isChangingBankAccount: true })}
                    onUpdatePaymentDetails={() => this.setState({ errorRecurringPayment: false })}
                    isConnectButtonVisible={isConnectButtonVisible}
                    onAuthorize={this.handleBankSubscription}
                    onAuthorizeChangeBankAccount={this.handleCloseChangingBankAccount}
                    onMakeManualPayment={this.handleMakeManualPayment}
                    onClosePaymentNotification={this.handleOnClosePaymentNotification}
                  />
                )
                : (
                  <LoanCreated
                    onShowLoanDetails={() => this.handleUpdateQuote({ isLoanBalanceVisible: true })}
                  />
                )
            }
          </>
        );
      case 'closed':
        return (
          transactionType === 'SELL' ? (
            <SaleCreated
              quoteValue={quoteValue}
            />
          ) : (
            <LoanClosed />
          )
        );
      case 'rejected':
        return (
          <>
            {!isAdminRejected && !isDelivered
              && (
                <RejectedByUser />
              )}
            {isAdminRejected && !isDelivered
              && (
                <RejectedByAdmin />
              )}
            {!isAdminRejected && isDelivered
              && (
                <ReceivedRejectedByUser />
              )}
            {isAdminRejected && isDelivered
              && (
                <ReceivedRejectedByAdmin />
              )}
          </>
        );
      default:
        return <></>;
    }
  };
}
