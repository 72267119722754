/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component, createRef } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Spinner } from '../common';
import { api } from '../../api';
import { userStore as store, actionTypes } from '../../store';
import ErrorMessage from '../common/ErrorMessage';
import fonts from '../../constants/fonts';

const { mainFont } = fonts.fonts;

type Props = {
  isVisible: boolean;
  handleLoginModal: (state: boolean) => void;
  handleRegisterModal: (state: boolean) => void;
  onFinish?: () => void;
};

type State = {
  errorMessage?: string,
  isLoading: boolean,
  isLoadingModal: boolean,
};

export default class RegisterModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingModal: false,
    };
  }

  formRef = createRef<FormInstance>();

  componentDidMount = () => {
    if (this.props.isVisible)
      setTimeout(() => this.setState({ isLoadingModal: true }), 70);
  };

  render() {
    const { errorMessage, isLoadingModal, isLoading } = this.state;

    return (
      <div className={`popup ${isLoadingModal && 'popup--shown'}`} style={{ fontFamily: mainFont }}>
        <div className="popup__wrap popup__wrap--wide">
          <a className="popup__close" onClick={() => this.handleCloseModal()} />
          <div className="popup__inner">
            <div className="popup__header">
              {/* <div className="popup__title">Please signup to get paid</div> */}
              <div className="popup__title">Please signup</div>
              {/* <div className="popup__text">
                You have chosen to sell your: 1 Ring, 1 Watch
                <br />
                You need sing up to get paid in your account
              </div> */}
            </div>
            <div className="popup__content">
              <Form className="login-form" ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                <div className="login-form__body">
                  <div className="form-input">
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: 'First name is required',
                        },
                      ]}
                    >
                      <input type="text" placeholder="First Name" />
                    </Form.Item>
                  </div>
                  <div className="form-input">
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: 'Last name is required',
                        },
                      ]}
                    >
                      <input type="text" placeholder="Last Name" />
                    </Form.Item>
                  </div>
                  <div className="form-input">
                    <Form.Item
                      rules={[
                        {
                          type: 'email',
                          message: '',
                        },
                        {
                          required: true,
                          message: 'Email is required',
                        },
                      ]}
                      name="email"
                      required
                    >
                      <input type="email" placeholder="Email" />
                    </Form.Item>
                  </div>
                  <div className="form-input">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Password is required',
                        },
                      ]}
                    >
                      <input type="password" placeholder="Password" />
                    </Form.Item>
                  </div>
                  <div className="form-input">
                    <Form.Item
                      name="confirm"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <input type="password" placeholder="Confirm Password" />
                    </Form.Item>
                  </div>
                </div>
                <button className="btn btn--dark popup__btn popup__btn--wide login-form__submit" type="submit">
                  {
                    isLoading
                      ? <Spinner loaderSize={{ width: 20, height: 20 }} tip={' '} style={{ marginTop: 20 }} />
                      : 'Sign up'
                  }
                </button>
                <div className="form-input__footer">
                  <span>Already have an account?</span>
                  <a style={{ color: '#4993c6' }} onClick={this.onLogin}>Login Here</a>
                </div>
              </Form>
              {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleCloseModal = () => {
    this.props.handleRegisterModal(false);
    this.setState({ isLoadingModal: false });
  };

  onLogin = () => {
    const { handleRegisterModal, handleLoginModal } = this.props;
    this.formRef.current?.resetFields();
    handleRegisterModal(false);
    handleLoginModal(true);
  };

  onFinish = (values: any) => {
    this.setState({ isLoading: true, errorMessage: undefined });
    api.post('/users', { ...values })
      .then(res => res.data)
      .then((user: User) => {
        store.dispatch({ type: actionTypes.ADD_USER, user });
        if (this.props.onFinish) {
          this.props.onFinish();
        }
        this.setState({ errorMessage: undefined, isLoading: false });
        this.formRef.current?.resetFields();
        this.props.handleRegisterModal(false);
      })
      .catch(err => this.setState({ errorMessage: err && err.response.data.message }))
      .finally(() => this.setState({ isLoading: false }));
  };
}
