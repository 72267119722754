import { AxiosError } from 'axios';
import { CustomModal } from '../custom';

export const interceptGetError = (): void => {
  // console.log(error);
};

export const interceptPostErrorMessages = (message?: string | Array<string>): void => {
  CustomModal.displayCustomWarning({
    title: 'An error occured',
    content:
  <div>
    An error occurred during making request. Please try one more time.
    {message
                  && (
                  <p style={{ marginTop: '2%' }}>
                    <span><i>Error Details:</i></span>
                    {Array.isArray(message) ? message.map(m => <div key={m}>{m}</div>) : <span>{message}</span>}
                  </p>
                  )}
  </div>,
  });
};

export const interceptPostError = (axiosError: AxiosError): void => {
  const { response } = axiosError;
  CustomModal.displayCustomWarning({
    title: 'Error',
    content: `An error occurred during making request.
        Please try one more time. ${response?.data ? `Error: ${response.data.content}` : ''}`,
  });
};
