import { Link } from 'react-router-dom';

const LoanClosed = () => (
  <>
    <div className="dashboard-status dashboard-status--success">
      <div className="dashboard-status__title">
        Your payment was successfull
      </div>
      <div className="dashboard-status__text">
        Your loan has been redeemed. Thank you for your business.
        <br />
        {'You can create '}
        <Link to="/new-request">a new request here</Link>
      </div>
    </div>
    <div className="dashboard-payment">
      <div className="dashboard-payment-method">
        <div className="dashboard-payment-method__title">
          Your item(s) will be shipped back within 3 business days.
        </div>
      </div>
    </div>
  </>
);

export default LoanClosed;
