import { Link } from 'react-router-dom';

const About = () => (
  <div className="section section-about">
    <div className="container">
      <div className="page-descr">
        <h2
          className="section__title section__title--lg page-descr__title page-descr__title--about"
          data-aos="fade-left"
          data-aos-delay={300}
          data-aos-duration={800}
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          data-aos-anchor-placement="center-bottom"
        >
          About
        </h2>
        <p
          className="section__title section__title--lg page-descr__title page-descr__title--about page-descr__title--top"
          data-aos="fade-left"
          data-aos-delay={300}
          data-aos-duration={800}
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          data-aos-anchor-placement="center-bottom"
        >
          Ab
          <span className="opacity-0">out</span>
        </p>
        <div className="row">
          <div className="col-lg-6 page-descr__col-image col-md-4">
            <div className="page-descr__image-wrap">
              <picture>
                <source type="image/webp" media="(max-width: 600px)" srcSet="images/chess/about.webp" />
                <source type="image/jpg" media="(max-width: 600px)" srcSet="images/chess/about.jpg" />
                <source type="image/webp" srcSet="images/chess/about.webp, images/chess/about@2x.webp 2x" />
                <img
                  className="page-descr__image page-descr__image--left"
                  alt="work"
                  src="images/chess/about.jpg"
                  srcSet="images/chess/about.jpg, images/chess/about@2x.jpg 2x"
                />
              </picture>
            </div>
            <div className="row text-right">
              <div className="col-lg-10">
                <Link
                  className="btn btn--outline-primary btn--md page-descr__btn page-descr__btn--desktop"
                  to="/about"
                >
                  Read full company information
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-8">
            <div className="row">
              <div className="col-lg-11">
                <div className="mobile-container">
                  <div
                    className="page-descr__content"
                    data-aos="fade-up"
                    data-aos-delay={150}
                    data-aos-duration={800}
                    data-aos-easing="ease-in-out"
                    data-aos-once="true"
                    data-aos-mirror="true"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <p className="page-descr__subtitle">Serving South Jersey since 1973</p>
                    <div className="page-descr__text">
                      <p>
                        We’re a family owned business that puts customers first. At E&amp;B Co.,
                        you’ll always feel at home—whether you’re in-store or online. We prioritize
                        fast, friendly, and straightforward customer service—so you can get your loan, today.
                      </p>
                      <p>
                        We extend loans, buy and sell items, and even offer repair services,
                        and we have the ability to loan money in mere minutes. To learn more about
                        our services, visit our&nbsp;
                        <Link to="/faq">FAQ</Link>
                        &nbsp;page or get in touch with one of our
                        representatives today.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default About;
