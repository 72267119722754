import { Modal } from 'antd';
import { SecondaryButton } from '../../common/Button';
import Editor from '../../Editor';

type Props = {
  onDelete: (id: string) => void;
  onCancel: () => void;
  agreement: Agreement;
  visible: boolean
};

const AgreementDeleteModal = ({
  agreement, visible, onDelete, onCancel,
}: Props) => (
  <Modal
    title="Delete Agreement"
    visible={visible}
    footer={[
      <SecondaryButton key="ok" onClick={() => onDelete(agreement.id)}>
        Delete
      </SecondaryButton>,
      <SecondaryButton key="back" onClick={onCancel}>
        Cancel
      </SecondaryButton>,
    ]}
    onCancel={onCancel}
  >
    <span style={{ fontWeight: 'bold' }}>Are you sure you want to delete following agreement?</span>
    <Editor content={agreement.content} />
  </Modal>
);

export default AgreementDeleteModal;
