/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

type Props = {
  isVisible: boolean;
  showEmail?: string;
  onCancel?: () => void;
  onShowContact?: () => void;
};

const RecoveryPasswordSent = ({
  isVisible,
  showEmail,
  onCancel,
  onShowContact,
}: Props) => (
  <div className={`popup ${isVisible && 'popup--shown'}`}>
    <div className="popup__wrap popup__wrap--wide">
      <div className="popup__close" onClick={onCancel} />
      <div className="popup__inner">
        <div className="popup__header">
          <div className="popup__title">Recover Password</div>
        </div>
        <div className="popup__content">
          <div className="popup__text">
            Recover Link has been sent to your email:
            <br />
            <strong>{showEmail}</strong>
          </div>
          <div className="popup__text">
            Check your email
            <br />
            and click on the link to recover your
            password
          </div>
        </div>
        <div className="popup__footer">
          <div className="form-input__footer">
            <span>Have a questions, problems?</span>
            <a style={{ color: '#4993c6' }} onClick={onShowContact}>Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RecoveryPasswordSent;
