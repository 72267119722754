import React from 'react';
import styled from 'styled-components';
import { Select as AntdSelect } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { SelectValue } from 'antd/lib/select';
import { data, text, colors } from '../../constants';

const { Option } = AntdSelect;

type Props = {
  onChange: (value: SelectValue) => void;
  values?: any

};

const TagSelect = ({ onChange, values }: Props) => {
  const displayChildren = () => data.PACKAGE_TAGS.map(t => (
    <Option value={t} key={t} className="tag-option">
      {t}

      <PlusOutlined />
    </Option>
  ));

  return (
    <StyledTagSelect
      mode="tags"
      dropdownClassName="tags-dropdown"
      showArrow
      onChange={onChange}
      value={values}
      allowClear
    >
      {displayChildren()}
    </StyledTagSelect>
  );
};

const StyledTagSelect = styled(AntdSelect)`
    width: 90%;
    max-width: 462px;
    .ant-select-selection-item {
        background: #8fa1ac;
        border-radius: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 16px;
    }
    .ant-select-selection-item-content {
        margin-right: 12px;
    }
    .ant-select-selector {
        background: #e8f1f7 !important;
        .ant-select-selection-item {
            span {
                color: ${colors.background['widget-white']};
                ${text.caption}
            }
            .anticon-plus {
                display: none;
            }
        }
    }
    .ant-select-item-option,
    .ant-select-item-option-active {
        background: #f3f7f9;
        border-radius: 40px;
    }
`;

type SelectProps = {
  data?: any[];
  placeholder?: string;
} & Props;

const OptionSelect = ({ onChange, data, placeholder }: SelectProps) => {
  const displayChildren = () => data?.map(t => (
    <Option value={t} key={t} className="tag-option">
      {t}
      <PlusOutlined />
    </Option>
  ));

  return (
    <StyledSelect
      dropdownClassName="option-dropdown"
      placeholder={placeholder || 'Select from List'}
      style={{ minWidth: '462px' }}
      onChange={onChange}
      allowClear
    >
      {displayChildren()}
    </StyledSelect>
  );
};

const StyledSelect = styled(AntdSelect)`
    ${text.body}
    height: 30px;
    .ant-select-selector {
        height: 40px !important;
        display: flex;
        align-items: center;
        background: #e8f1f7 !important;
        .ant-select-selection-item {
            color: ${colors.text.light} !important;
            span {
                ${text.caption}
            }
            .anticon-plus {
                display: none;
            }
        }
    }
`;

export { TagSelect, OptionSelect };
