import { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from '../../common';
import { FormElement, TransactionTypeChooser } from '../Choosers';
import { RedirectToDescriptionSection } from '../RedirectTo';
import { quoteStore, actionTypes } from '../../../store';

const { CurrencyInputNumber } = Input;

type Props = { subCategory: string } & RouteComponentProps & WithCategoriesProps;

type State = {
  transactionType?: TransactionType;
  groupType?: TransactionType;
  originalPrice: number;
};

class FlatwareForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      originalPrice: 0,
      transactionType: undefined,
    };
  }

  componentDidMount() {
    this.handleValidPackageType();
  }

  render() {
    const { originalPrice, transactionType, groupType } = this.state;

    const store = quoteStore.getState();

    return (
      <>
        <Container className="container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FormElementContainer className="form-element-container">
                <TransactionTypeChooser
                  mode="other"
                  selectedType={groupType || transactionType}
                  quoteArray={store.quotes}
                  onTransactionTypeChange={this.handleTransactionTypeChange}
                />
                <FormElement
                  className="flatware-original-price-element"
                  title="What is the original price of the item? (optional)"
                >
                  <CurrencyInputNumber onChange={e => this.handleOriginalPriceChange(e)} value={originalPrice} />
                </FormElement>
              </FormElementContainer>
            </Col>
          </Row>
        </Container>
        <RedirectToDescriptionSection
          handleValidateForm={this.handleValidateForm}
          onClick={this.handleFinish}
          category="flatware&serving-ware"
        />
      </>
    );
  }

  handleValidPackageType = () => {
    const { quotes } = quoteStore.getState();
    if (quotes.length === 0)
      return;
    const type = quotes[0].generalInfo.transactionType;
    this.setState({ groupType: type, transactionType: type });
  };

  handleValidateForm = (): boolean => {
    const {
      transactionType,
    } = this.state;

    if (!transactionType) {
      message.warn('Transaction type not selected');
      return false;
    }
    return true;
  };

  handleFinish = () => {
    const { originalPrice, transactionType, groupType } = this.state;
    const { categories } = this.props;
    quoteStore.dispatch({
      type: actionTypes.ADD_GENERAL_INFO,
      generalInfo: { categories, originalPrice, transactionType: groupType || transactionType },
    });
  };

  handleTransactionTypeChange = (transactionType: TransactionType) => {
    this.setState(prevState => {
      if (prevState.transactionType === transactionType) {
        return { transactionType: undefined };
      }
      return {
        transactionType,
      };
    });
  };

  handleOriginalPriceChange = (e: number) => this.setState({ originalPrice: e });
}

const Container = styled.div`
  background-color: #ffffff;
  .currency-input {
    width: 100%;
    margin-top: 20px;
  }

  .form-element {
    width: 100%;
  }
  .ant-input {
    width: 100%;
  }
  .flatware-original-price-element {
    margin-bottom: 180px;
  }
`;

const FormElementContainer = styled.div`
  width: max-content;
`;

export default FlatwareForm;
