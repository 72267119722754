import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card, Select, Row, Col,
} from 'antd';
import styled from 'styled-components';
import { SecondaryButton } from '../common/Button';
import { colors, text } from '../../constants';
import goldCover from '../../assets/images/gold-cover.svg';
import { quoteStore } from '../../store';
import { PackageWarningModal } from '../Modals';

const { Option } = Select;

type SelectOptions = 'gold' | 'silver' | 'platinium' | '';

const MetalQuoteCard = () => {
  const [metalOption, setMetalOption] = useState<SelectOptions>('');
  const [isWarningModalVisible, setWarningModalVisible] = useState<boolean>(false);
  const { quotes } = quoteStore.getState();

  return (
    <>
      <StyledCard title="Get a Quote for Bullion Gold, Metal, Platinum">
        <Row gutter={[16, 16]}>
          <Col className="img-col" span={12}>
            <img src={goldCover} alt="gold-cover" />
          </Col>
          <Col span={12}>
            <ActionContainer>
              <StyledSelect
                size="large"
                placeholder="Select Metal"
                onChange={e => setMetalOption(e as SelectOptions)}
                style={{ minWidth: 150 }}
              >
                <Option value="gold">Gold</Option>
                <Option value="silver">Silver</Option>
                <Option value="platinium">Platinium</Option>
              </StyledSelect>
              {quotes.length > 0
                ? (
                  <QuoteButton onClick={() => setWarningModalVisible(true)}>
                    <SecondaryButton>Metal Quote</SecondaryButton>
                  </QuoteButton>
                )
                : (
                  <QuoteButton>
                    <Link to={`/general-information/bullion/${metalOption}`}>
                      <SecondaryButton disabled={metalOption === ''}>Metal Quote</SecondaryButton>
                    </Link>
                  </QuoteButton>
                )}
            </ActionContainer>
          </Col>
        </Row>
      </StyledCard>
      <PackageWarningModal
        handleVisibileChange={setWarningModalVisible}
        isVisible={isWarningModalVisible}
        metalOption={metalOption}
      />
    </>
  );
};

const StyledCard = styled(Card)`
    .ant-card-head {
        border: none;
        .ant-card-head-title {
            ${text.title}
            max-width: 342px;
            white-space: normal !important;
            color: ${colors.text.white};
        }
    }
    z-index: 1;

    .img-col {
        padding-top: 28px !important;
    }

    height: 100%;
    background-image: ${colors.background.widget};
`;

const QuoteButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const StyledSelect = styled(Select)`
    .ant-select-selector {
        border: none !important;
    }
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
    }

    @media only screen and (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const ActionContainer = styled.div`
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export default MetalQuoteCard;
