/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

type Props = {
  isVisible: boolean;
  onCancel?: () => void;
};

const ContactUs = ({ isVisible, onCancel }: Props) => (
  <div className={`popup ${isVisible && 'popup--shown'}`}>
    <div className="popup__wrap">
      <div className="popup__close" onClick={onCancel} />
      <div className="popup__inner">
        <div className="popup__header">
          <div className="popup__title">Contact Us</div>
        </div>
        <div className="popup__content">
          <div className="popup__text popup__text--big"><a href="tel:8562352463">856-235-2463</a></div>
          <div className="popup__text popup__text--big"><a href="mailto:tedsff@yahoo.com">tedsff@yahoo.com</a></div>
        </div>
      </div>
    </div>
  </div>
);

export default ContactUs;
