import styled from 'styled-components';
import { text } from '../../constants';

type Props = {
  title: string
};

const Title = ({ title }: Props) => <StyledText className="title">{title}</StyledText>;

const StyledText = styled.p`
    ${text.text}
    margin-top: 50px;
    margin-left: 40px;
    color: #575c60;
`;

export default Title;
