import { Component } from 'react';
import {
  Row, Col, Spin, message,
} from 'antd';
import styled from 'styled-components';
import { upperFirst } from 'lodash';
import {
  Input,
  Button,
  Title,
  Spinner,
} from '../components/common';
import { api } from '../api';
import {
  interceptGetError,
  interceptPostError,
} from '../utils/interceptors';

const { ControlledFormInput } = Input;
const { SecondaryButton } = Button;

type Props = {};

type ConfigDictionary = { [id: string]: string };

type State = {
  values: ConfigDictionary;
  isLoading: boolean;
};

export default class ConfigurationView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      values: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchStoreLink();
  }

  render() {
    const { isLoading } = this.state;
    return (
      <Container className="store-editor-container">
        <Spin spinning={isLoading} indicator={<Spinner tip="Loading" />}>
          {this.handleDisplayOptions()}
        </Spin>
      </Container>
    );
  }

  handleDisplayOptions = () => {
    const { values, isLoading } = this.state;
    const types: ConfigurationType[] = ['store-link', 'contact-email'];

    return types.map(type => {
      const title = upperFirst(type.replace('-', ' '));

      return (
        <>
          <body className="body--dashboard">
            <div className="main main-dashboard">
              <div className="dashboard">
                <Row gutter={[32, 12]}>
                  <Col>
                    <Title title={title} />
                  </Col>
                </Row>
                <Row gutter={[32, 12]}>
                  <Col span={24}>
                    <ControlledFormInput
                      placeholder={title}
                      value={values[type]}
                      onChange={(e: Input) => this.handleConfigContentChange(e.target.value, type)}
                    />
                  </Col>
                  <Col span={8}>
                    <SecondaryButton
                      onClick={this.saveLink}
                      disabled={isLoading}
                    >
                      Save
                    </SecondaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          </body>
        </>
      );
    });
  };

  handleConfigContentChange = (e: string, type: ConfigurationType) => {
    this.setState(prevState => {
      const newValues = prevState.values;
      newValues[type] = e;

      return {
        ...prevState,
        values: newValues,
      };
    });
  };

  fetchStoreLink = () => {
    this.setState({ isLoading: true });
    api
      .get('/configuration')
      .then(res => res.data)
      .then(configs => this.unpackConfigs(configs))
      .catch(interceptGetError)
      .finally(() => this.setState({ isLoading: false }));
  };

  unpackConfigs = (configs: IConfiguration[]) => {
    const configDictionary: ConfigDictionary = {};
    configs.forEach((config: IConfiguration) => {
      configDictionary[config.type] = config.content;
    });

    this.setState({
      values: configDictionary,
    });
  };

  saveLink = () => {
    const { values } = this.state;

    this.setState({ isLoading: true });

    const newConfigs = this.generateConfigurationArrayFromDictionary(values);
    api
      .post('/configuration', { newConfigs })
      .then(() => message.success('Data has been saved successfully.'))
      .catch(interceptPostError)
      .finally(() => this.setState({ isLoading: false }));
  };

  generateConfigurationArrayFromDictionary = (dictionary: ConfigDictionary) => {
    const configArray: IConfiguration[] = [];

    Object.keys(dictionary).forEach(key => {
      const config: IConfiguration = {
        id: key,
        type: key as ConfigurationType,
        content: dictionary[key],
      };

      configArray.push(config);
    });

    return configArray;
  };
}

const Container = styled.div`
    flex: 1;
    display: flex;
    padding: 0px 15px 15px 15px;
    flex-direction: column;
`;
