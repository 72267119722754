import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SecondaryButton } from '../common/Button';
import { colors, text as textStyle } from '../../constants';

type Props = {
  handleSubmit: () => void;
  text?: string;
  handleNextItem?: () => void;
} & RouteComponentProps;

const BullionSubmitSection = ({
  handleNextItem,
  text, handleSubmit,
}: Props) => (
  <SubmitWrapper>
    <SubmitContainer className="container">
      <Row gutter={[8, 16]} justify="start">
        <Col
          xs={{ span: 16 }}
          sm={{ span: 10 }}
          md={{ span: handleNextItem ? 11 : 12 }}
          lg={{ span: 12 }}
          xl={{ span: 9 }}
        >
          <Button onClick={handleSubmit}>Submit & Send Request</Button>
        </Col>
        {handleNextItem ? (
          <Col
            xs={{ offset: 2, span: 16 }}
            sm={{ offset: 2, span: 16 }}
            md={{ span: 11 }}
            lg={{ span: 10 }}
            xl={{ span: 8 }}
          >
            <SButton onClick={() => handleNextItem()}>Submit & Add Next Item</SButton>
          </Col>
        ) : (
          <Col offset={0} xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
            <p>{text || ''}</p>
          </Col>
        )}
      </Row>
    </SubmitContainer>
  </SubmitWrapper>
);

const SubmitWrapper = styled.div`
  background-color: ${colors.background.main};
`;

const SubmitContainer = styled.div`
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ant-row {
        height: 100%;
        margin: 0 !important;
    }

    .ant-col {
        align-self: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
            padding: 19.5px 30px;
            ${textStyle.text};
            margin-bottom: 0;
        }
    }
`;

const Button = styled(SecondaryButton)`
    ${textStyle['buttom-caps-24']}
    padding: 10px 40px;
    min-height: 60px;

    @media(max-width: 768px) {
      margin: 5px 0;
      font-size: 11px;
      padding: 10px 15px;
    }

    @media(max-width: 1024px) {
      margin: 5px 0;
      font-size: 12px;
      padding: 10px 20px;
    }
`;

const SButton = styled(SecondaryButton)`
    ${textStyle['buttom-caps-24']}
    background-color:white;
    color: ${colors.text.body};
    padding: 10px 40px;
    border: dashed 1px ${colors.text.dark};
    outline: none;
    min-height: 60px;

    @media(max-width: 768px) {
      margin: 5px 0;
      font-size: 11px;
      padding: 10px 15px;
    }

    @media(max-width: 1024px) {
      margin: 5px 0;
      font-size: 12px;
      padding: 10px 20px;
    }
`;

export default withRouter(BullionSubmitSection);
