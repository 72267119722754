/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import AdminStepper from 'components/Stepper/AdminStepper';
import AnotherShippingBox from './AdminNotificationContenerComponents/AnotherShippinxBox';
import TrackingInfoBody from './AdminNotificationContenerComponents/TrackingInfoBody';
import PaymentInformation from './AdminNotificationContenerComponents/PaymentInformation';
import LoanInformation from './AdminNotificationContenerComponents/LoanInformation';
import ShippingBackInfo from './AdminNotificationContenerComponents/ShippingBackInfo';

type Props = {
  mode: string;
  isSaleSigned?: boolean;
  isAccepted?: boolean;
  isRejected?: boolean;
  isLoanInProgrss?: boolean;
  isLoanClosed?: boolean;
  boxIsNotArrived?: boolean;
  trackingInfo?: ShippingLabel[];
  contentData?: any;
  adminStep: number;
  payment?: ConfirmPayment;
  loanData?: Loan;
  shippingLabel?: ShippingLabel;
  context?: QuoteContext;
  contactedSolved?: boolean;
  trackingBackNumber?: string;
  loanDelayed?: { isDelayed: boolean, delayDays: number };
  onChangeContactShippingBox?: () => void;
};

const AdminNotificationContener = ({
  mode,
  isSaleSigned = false,
  isAccepted = false,
  isRejected = false,
  isLoanInProgrss = false,
  isLoanClosed = false,
  boxIsNotArrived,
  trackingInfo,
  contentData,
  adminStep,
  payment,
  loanData,
  shippingLabel,
  context,
  contactedSolved,
  trackingBackNumber,
  loanDelayed,
  onChangeContactShippingBox,
}: Props) => (
  <>
    {(isAccepted || isRejected) && (
      <div className="tracking-info">
        <TrackingInfoBody trackingInfo={trackingInfo} isRejected={isRejected} trackingBackNumber={trackingBackNumber} />
        {
          boxIsNotArrived && (
            <AnotherShippingBox
              boxIsNotArrived={boxIsNotArrived}
              trackingInfo={trackingInfo}
              contactedSolved={contactedSolved}
              onChangeContact={onChangeContactShippingBox}
            />
          )
        }
      </div>
    )}
    {
      (isSaleSigned || isLoanClosed) && <PaymentInformation payment={payment} />
    }
    {
      isLoanInProgrss && <LoanInformation loanData={loanData} loanDelayed={loanDelayed} />
    }
    {
      (isLoanClosed && isRejected) && <ShippingBackInfo context={context} shippingLabel={shippingLabel} />
    }
    <div className="dashboard-columns dashboard-columns--no-gap">
      <div className="dashboard-columns__left">
        <AdminStepper
          mode={mode}
          step={adminStep}
        />
      </div>
      {contentData}
    </div>
  </>
);

export default AdminNotificationContener;
