/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
type Props = {
  type: 'US' | 'Foreign';
  selectedOriginCountry: OriginCountryType;
  onOriginTypeChange: (originCountry: OriginCountryType) => void;
};

const OriginButton = ({ type, selectedOriginCountry, onOriginTypeChange }: Props) => (
  <>
    <label className="request-form-label" style={{ width: 150 }}>
      <input
        type="radio"
        name="other"
        defaultChecked={type === selectedOriginCountry}
        onClick={() => onOriginTypeChange(type)}
      />
      <span className="request-form-label__body request-form-other">{type}</span>
    </label>
  </>
);

export default OriginButton;
