import {
  Modal, Form, Select, Input,
} from 'antd';
import styled from 'styled-components';
import { EmailTemplateTypes } from '../../../constants/mocks';
import { SecondaryButton } from '../../common/Button';

type Props = {
  onCancel: () => void;
  visible: boolean;
  emailTemplate: EmailTemplate
};

const EmailTemplateViewModal = ({ visible, emailTemplate, onCancel }: Props) => (
  <Modal
    title="Email Template Details"
    visible={visible}
    footer={[
      <SecondaryButton key="back" onClick={onCancel}>
        Close
      </SecondaryButton>,
    ]}
    onCancel={onCancel}
  >
    <Form>
      <Label>Email type</Label>
      <Form.Item>
        <Select disabled value={EmailTemplateTypes.find(f => f.value === emailTemplate.type)?.text} />
      </Form.Item>
      <Label>Email subject</Label>
      <Form.Item>
        <Input disabled value={emailTemplate.subject} />
      </Form.Item>
      <Label>Email title</Label>
      <Form.Item>
        <Input disabled value={emailTemplate.title} />
      </Form.Item>
      <Label>Email content</Label>
      <Form.Item>
        <Input.TextArea rows={15} disabled value={emailTemplate.content} />
      </Form.Item>
    </Form>
  </Modal>
);

const Label = styled.div`
    font-family: Cambon;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #575c60;
    margin-bottom: 10px;
`;

export default EmailTemplateViewModal;
