/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-boolean-value */
import AcceptOffer from '../Notifications/components/AcceptOffer';
import RejectOffer from '../Notifications/components/RejectOffer';

type Props = {
  isVisible: boolean;
  mode: 'accept' | 'reject';
  onCancel: () => void;
  onOk: (mode: 'accept' | 'reject') => void;
};

const AcceptRejectOfferUser = ({
  isVisible,
  mode,
  onCancel,
  onOk,
}: Props) => (
  <>
    {mode === 'accept'
      ? (
        <AcceptOffer
          isVisible={isVisible}
          mode={mode}
          onCancel={onCancel}
          onOk={onOk}
        />
      )
      : (
        <RejectOffer
          isVisible={isVisible}
          mode={mode}
          onCancel={onCancel}
          onOk={onOk}
        />
      )}
  </>
);

export default AcceptRejectOfferUser;
