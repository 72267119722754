import { Link } from 'react-router-dom';

const ChessList = () => (
  <div className="section-chess-list">
    <div className="container">
      <div className="chess">
        <div className="row chess__row chess__row--mobile-reverse">
          <div className="col-lg-5 offset-lg-1 col-md-8">
            <div className="mobile-container">
              <h2 style={{ color: '#4f6470' }} className="section__title section__title--sm chess__title">
                Offering
                <br />
                the &#160;
                <span className="gradient-color">best prices</span>
                <br />
                on the market
              </h2>
            </div>
            <div className="chess__mobile-image-wrap">
              <picture>
                <source
                  type="image/webp"
                  srcSet="images/chess/best-price-mobile.webp, images/chess/best-price-mobile@2x.webp 2x"
                />
                <img
                  className="chess__image chess__image--right chess__image--mobile"
                  alt="worker"
                  src="images/chess/best-price-mobile.jpg"
                  srcSet="images/chess/best-price-mobile.jpg, images/chess/best-price-mobile@2x.jpg 2x"
                />
              </picture>
            </div>
            <div className="mobile-container">
              <div className="row">
                <div className="col-md-10">
                  <div className="mobile-container" />
                  <p className="chess__text">
                    &nbsp;
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-11">
                  <Link className="btn btn--secondary btn--lg chess__btn" to="/categories">
                    Check price of my item
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-4">
            <picture>
              <source
                type="image/webp"
                srcSet="images/chess/best-price.webp, images/chess/best-price@2x.webp 2x"
              />
              <img
                className="chess__image chess__image--right chess__image--desktop"
                alt="worker"
                src="images/chess/best-price.jpg"
                srcSet="images/chess/best-price.jpg, images/chess/best-price@2x.jpg 2x"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>

);

export default ChessList;
