import { Component } from 'react';
import axios from 'axios';
import { Link, RouteComponentProps } from 'react-router-dom';
import { message } from 'antd';
import {
  runMain,
} from '../assets/js/main';
import { api } from '../api';
import { Sections } from '../components';
import { quoteStore } from '../store';
import ResetPasswordSetNewView from './ResetPasswordSetNewView';

const {
  About,
  HowWeWorks,
  HighestPayouts,
  Comments,
  Questions,
  ChessList,
  Offer,
} = Sections;

type ChangePasswordValues = {
  email: string;
  newPassword: string;
  confirmNewPassword: string;
};

type Props = {
  match: {
    params: {
      id?: string;
    }
  },
  onSuccessChangePassword: () => void;
} & RouteComponentProps;

type State = {
  metalType: string,
  comments: CommentType[],
  isVisibleResetPasswordModal: boolean;
  isSettingNewPassword: boolean;
};

export default class HomeView extends Component<Props, State> {
  source = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);

    this.state = {
      metalType: '',
      comments: [],
      isVisibleResetPasswordModal: false,
      isSettingNewPassword: false,
    };
  }

  componentDidMount() {
    runMain();
    this.fetchComments();
    if (this.props.match.params.id) {
      this.getOneResetPasswordLink();
    }
  }

  render() {
    const {
      metalType, comments, isVisibleResetPasswordModal, isSettingNewPassword,
    } = this.state;
    const { quotes } = quoteStore.getState();

    return (
      <main className="main body__whitesmoke">
        <div className="hero">
          <div className="hero__bg-wrap">
            <picture>
              <source
                type="image/webp"
                media="(max-width: 600px)"
                srcSet="images/hero-mobile.webp, images/hero-mobile@2x.webp 2x"
              />
              <source
                type="image/jpg"
                media="(max-width: 600px)"
                srcSet="images/hero-mobile.jpg, images/hero-mobile@2x.jpg 2x"
              />
              <source type="image/webp" srcSet="images/hero.webp" />
              <img className="hero__bg" alt="work" src="images/hero.jpg" />
            </picture>
          </div>
          <div className="container hero__container hero__in">
            <div className="row">
              <div className="col-lg-7">
                <div className="hero__content w-100">
                  <h1
                    className="hero__title hero__title--mb-sm"
                    id="hero-title"
                    style={{ color: 'inherit' }}
                    data-aos="fade-down"
                    data-aos-delay={300}
                    data-aos-duration={1200}
                    data-aos-easing="ease-in-out"
                    data-aos-once="true"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <span>Get &#160; </span>
                    <span className="gradient-color gradient-underline">the best quote</span>
                    <span> &#160;for your item!</span>
                  </h1>
                  <p
                    className="hero__text hero__text--mb"
                    data-aos="fade-down"
                    data-aos-delay={300}
                    data-aos-duration={1200}
                    data-aos-easing="ease-in-out"
                    data-aos-once="true"
                    data-aos-anchor-placement="center-bottom"
                  >
                    Family owned. Customer friendly. We have close to 50 years of experience extending loans, buying
                    and selling items, and offering repair services to our clients. Our commitment is to our
                    customers—you can count on E&amp;B to get you a fair loan in minutes.
                  </p>
                  <p
                    className="hero__scroll"
                    data-aos="fade-in"
                    data-aos-delay={1200}
                    data-aos-duration={1200}
                    data-aos-easing="ease-in-out"
                    data-aos-once="true"
                    style={{
                      color: '#4f6470',
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                    }}
                    data-aos-anchor-placement="center-bottom"
                  >
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
            <div className="hero__append">
              <div
                className="hero__append-in"
                data-aos="fade-up"
                data-aos-delay={2400}
                data-aos-duration={1200}
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                data-aos-anchor="#hero-title"
              >
                <div className="row justify-content-center hero__append-row">
                  <div className="col-md-6 d-flex">
                    <div className="box box--fixed-height box--hover box--gold hero__box">
                      <div className="box__body text-center">
                        <p className="box__title">Get a quote for bullion gold, metal, platinum</p>
                        <picture>
                          <source type="image/webp" srcSet="images/box/gold.webp, images/box/gold@2x.webp 2x" />
                          <img
                            className="box__thumbnail"
                            alt="gold"
                            src="images/box/gold.png"
                            srcSet="images/box/gold.png, images/box/gold@2x.png 2x"
                          />
                        </picture>
                        <div className="box__content">
                          <form action="." method="POST">
                            <div className="custom-select box__select">
                              <select onChange={e => this.handleMetalTypeChange(e)} name="type_metal" defaultValue="">
                                <option key="index" value="" disabled>
                                  Select Metal
                                </option>
                                <option key="gold" value="gold">Gold</option>
                                <option key="silver" value="silver">Silver</option>
                                <option key="platinium" value="platinium">Platinium</option>
                              </select>
                            </div>
                            <button
                              disabled={metalType === ''}
                              onClick={() => {
                                if (quotes.length > 0)
                                  return message.warn('Metal can not be part of a package');
                                return this.props.history.push(`/general-information/bullion/${metalType}`);
                              }}
                              type="button"
                              className="btn btn--secondary btn--lg w-100 text-center"
                            >
                              Get Metal Quote
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="box box--hover box--fixed-height">
                      <div className="box__body text-center">
                        <p className="box__title">
                          Get a quote
                          <br />
                          for any item
                        </p>
                        <picture>
                          <source type="image/webp" srcSet="images/box/watch.webp, images/box/watch@2x.webp 2x" />
                          <img
                            className="box__thumbnail box__thumbnail--hover-sm"
                            alt="watch"
                            src="images/box/watch.png"
                            srcSet="images/box/watch.png, images/box/watch@2x.png 2x"
                          />
                        </picture>
                        <div className="box__content">
                          <Link className="btn btn--secondary btn--lg w-100 text-center" to="/categories">
                            Get Item Quote
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <About />
        <Offer />
        <HowWeWorks />
        <HighestPayouts />
        <Comments comments={comments} />
        <Questions />
        <ChessList />
        {
          isVisibleResetPasswordModal && (
            <ResetPasswordSetNewView
              isVisible={isVisibleResetPasswordModal}
              isLoading={isSettingNewPassword}
              onSubmit={this.handleSetNewPassword}
              {...this.props}
            />
          )
        }
      </main>
    );
  }

  fetchComments = () => {
    api
      .get('/comments')
      .then(res => res.data)
      .then(comments => this.setState({ comments: comments || [] }));
  };

  handleMetalTypeChange = (e: any) => this.setState({ metalType: e.target.value });

  handleSetNewPassword = (values: ChangePasswordValues): void => {
    this.setState({ isSettingNewPassword: true });
    const { onSuccessChangePassword } = this.props;

    const { id } = this.props.match.params;

    api
      .patch('/user/set-new-password', { values, id }, { cancelToken: this.source.token })
      .then(() => {
        message.success('New password has been set.');
        this.props.history.push('/');
        this.setState({ isSettingNewPassword: false, isVisibleResetPasswordModal: false });
        onSuccessChangePassword();
      })
      .catch(error => {
        if (error?.response?.status === 400) {
          message.error('Password change error.');
        }
      })
      .finally(() => {
        this.setState({ isSettingNewPassword: false });
      });
  };

  getOneResetPasswordLink = () => {
    const { id } = this.props.match.params;
    api
      .get('/user/link-reset-password', {
        params: { id },
        cancelToken: this.source.token,
      })
      .then(() => this.setState({ isVisibleResetPasswordModal: true }))
      .catch(error => {
        if (error?.response?.status === 400) {
          message.error(error.response.data.message);
          this.props.history.push('/');
        }
      });
  };
}
