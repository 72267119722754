import { useRef, useState } from 'react';
import {
  Modal, Row, Col, Carousel, Button,
} from 'antd';
import styled from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { upperFirst } from 'lodash';
import { SecondaryButton } from '../common/Button';
import { text, colors } from '../../constants';
import { quoteStore } from '../../store';
import leftArrow from '../../assets/images/leftArrowVector.svg';
import rightArrow from '../../assets/images/rightArrowVector.svg';
import placeholder from '../../assets/images/placeholder.jpg';

type Props = {
  fileList?: any[]
  isVisible: boolean;
  handleVisibileChange?: (state: boolean) => void;
  onClose?: () => void;
  onConfirm?: () => void;
};

const SummaryModal = ({
  isVisible, onConfirm, handleVisibileChange, fileList,
}: Props) => {
  const [quoteIndex, setQuoteIndex] = useState(0);
  const slider = useRef<any>(null);
  const Footer = () => (
    <StyledFooter>
      {handleArrows()}
      <div>
        <SecondaryButton onClick={() => onConfirm && onConfirm()}>Confirm &amp; Send</SecondaryButton>
      </div>
    </StyledFooter>
  );

  const handleArrows = () => {
    const { quotes } = quoteStore.getState();

    return (
      <div>
        {quoteIndex > 0 && (
          <ItemButton onClick={() => setQuoteIndex(quoteIndex - 1)}>
            <LeftOutlined />
            {`Item ${quoteIndex}`}
          </ItemButton>
        )}
        {quotes.length > quoteIndex && (
          <ItemButton onClick={() => setQuoteIndex(quoteIndex + 1)}>
            <RightOutlined />
            {`Item ${quoteIndex + 2}`}
          </ItemButton>
        )}
      </div>
    );
  };

  const displayProperty = (value: any) => {
    if (!value) {
      return '';
    }
    if (typeof (value) === 'boolean') {
      return value.toString();
    }
    if (Array.isArray(value)) {
      return value.join(' ');
    }
    return value;
  };

  const displayDetails = () => {
    const store = quoteStore.getState();
    const quotes: QuoteModel[] = [
      ...store.quotes,
      { attachments: fileList, generalInfo: store.generalInfo, description: store.description },
    ];
    if (quotes.length <= quoteIndex)
      return <></>;
    const { description, generalInfo } = quotes[quoteIndex];
    if (!generalInfo) return <></>;

    const elements: JSX.Element[] = [];
    Object.entries(generalInfo).forEach(([key, value]: [string, any]) => {
      if (key === 'categories') return;
      if (key === 'transactionType') return;
      if (!key || !value) return;
      const element = (
        <div className="category">
          <p className="category-p">
            {key.replace(/([A-Z])/g, ' $1')
              .replace(/^./, str => str.toUpperCase())}
          </p>
          <p>
            {key.toLocaleLowerCase().includes('price') ? '$' : ''}
            {key ? displayProperty(value) : ''}
          </p>
        </div>
      );
      elements.push(element);
    });
    return (
      <>
        <Details>
          <div className="category">
            <p className="category-p">
              {` ${quoteIndex + 1} / ${generalInfo.transactionType || 'Sell'}`}
            </p>
          </div>
          <h3>
            {
              generalInfo.categories[0].includes('&')
                ? (generalInfo.categories[0].split('&').map((str: string) => upperFirst(str)).join(' / '))
                : upperFirst(generalInfo.categories[0] || '')
            }
          </h3>
          {elements}
        </Details>
        <Description>
          <p className="category-p">Description:</p>
          <p>
            {description}
          </p>
        </Description>
      </>
    );
  };

  const { quotes } = quoteStore.getState();
  const files = [...quotes.map(q => q.attachments && [...q.attachments]), fileList] || [];

  return (
    <StyledModal
      footer={<Footer />}
      onCancel={() => handleVisibileChange && handleVisibileChange(false)}
      visible={isVisible}
    >
      <Row gutter={[16, 16]}>
        <Col sm={{ span: 24 }} md={{ span: 12 }} className="carousel-col">
          <Carousel ref={slider} className="summary-carousel">
            {(files.length > 0 && (files[quoteIndex]?.length || 0) > 0) ? files[quoteIndex]?.map(i => (
              <div>
                <h3>
                  <Button className="previous-arrow" onClick={() => slider.current.prev()}>
                    <ArrowImg src={rightArrow} />
                  </Button>
                  <img alt="summary" src={i.url || ''} />
                  <Button className="next-arrow" onClick={() => slider.current.next()}>
                    <ArrowImg src={leftArrow} />
                  </Button>
                </h3>
              </div>
            )) : (
              <div>
                <h3>
                  <Button className="previous-arrow" onClick={() => slider.current.prev()}>
                    <ArrowImg src={rightArrow} />
                  </Button>
                  <img alt="summary" src={placeholder} />
                  <Button className="next-arrow" onClick={() => slider.current.next()}>
                    <ArrowImg src={leftArrow} />
                  </Button>
                </h3>
              </div>
            )}
          </Carousel>
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 12 }}>
          {displayDetails()}
        </Col>
      </Row>
    </StyledModal>
  );
};

const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 20px 60px;
    justify-content: space-between;
    > div {
        display: flex;
    }
`;

const StyledModal = styled(Modal)`
    width: 80% !important;
    height: 100%;
    top: 10;

    .ant-modal-content {
        background-color: ${colors.background.main};
    }

    .ant-col {
        p {
            ${text.body}
            color: ${colors.text.body};
        }

        .category-p {
            ${text.body}
            color: ${colors.text.light};
            margin-right: 5px;
        }
        .category {
            display: flex;
        }
    }
    .ant-modal-footer {
        padding: 0 !important;
    }

    .ant-modal-body {
        padding: 60px;
        display: flex;
        flex-direction: column;

        .no-attachment-modal {
            display: flex;
            justify-content: center;

            margin-top: 20px;
        }
    }

    .summary-carousel {
        max-width: 496px;
        h3 {
            color: #fff;
            position: relative;
            line-height: 160px;
            text-align: center;
            display: flex;
            max-width: 496px;
            button {
                position: absolute;
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                border: none;
                outline: none;
                align-items: center;
                top: 50%;
                background: rgba(199, 193, 193, 0.2);
                backdrop-filter: blur(10px);
            }
            .previous-arrow {
                margin-left: 20px;
            }
            .next-arrow {
                margin-right: 20px;
                right: 0;
            }
            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }
`;

const ArrowImg = styled.img`
    width: 9px !important;
    height: 9px !important;
`;

const ItemButton = styled.div`
    height: 40px;
    width: 91px;
    left: 60px;
    top: 20px;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #526b7c;
    box-sizing: border-box;

    margin-left: 10px;
    margin-right: 10px;

    span {
        padding: 5px;
    }

    filter: drop-shadow(-20px 30px 30px rgba(158, 177, 189, 0.1));
`;

const Details = styled.div`
    background: rgba(255, 255, 255, 0.6);
    padding: 20px;

    h3 {
        ${text['title-20-Aeonik']}
        color: ${colors.text.body};
    }
`;

const Description = styled.div`
    padding: 20px;
`;

export default SummaryModal;
