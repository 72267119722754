/* eslint-disable jsx-a11y/label-has-associated-control */
import { Card, Button } from 'antd';
import styled from 'styled-components';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { text, colors } from '../../../constants';

type Props = {
  size: string;
  amount: number;
  handleSumChange: (key: string, type: 'INCEREMENT' | 'DECREMENT') => void;
};

const SizeCard = ({ size, amount, handleSumChange }: Props) => (
  <>
    {size !== 'sum' ? (
      <StyledCard title={`${size} Ounce`} isSelected={amount > 0}>
        <AmountButtonContainer>
          <StyledButton
            shape="circle"
            icon={<MinusCircleOutlined />}
            onClick={() => handleSumChange(size, 'DECREMENT')}
          />
          {amount}
          <StyledButton
            shape="circle"
            icon={<PlusCircleOutlined />}
            onClick={() => handleSumChange(size, 'INCEREMENT')}
          />
        </AmountButtonContainer>
      </StyledCard>
    ) : (
      <StyledCard title={`${amount.toFixed(2)} Ounce`} isSelected={false} isSum>
        Total
      </StyledCard>
    )}
  </>
);

type StyledCardProps = { isSelected: boolean; isSum?: boolean };

const StyledButton = styled(Button)`
    span {
      font - size: 20px;
    }
    border: none !important;
    width: 20px;
    display: flex;
    padding: 0 !important;
    min-width: 20px;
    height: 20px;
    justify-content: center;
    `;

const AmountButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    `;

const StyledCard = styled(Card) <StyledCardProps>`
      height: 120px;
      max-width: ${props => (props?.isSum ? '216px' : '134px')};
      width: 50%;
      margin-right: 6%;
      margin-top: 30px;
      background-color: ${props => (props?.isSum ? colors.textFields : '#ffffff')};
      border: ${props => !props.isSum
    && (props.isSelected
      ? `solid 2px ${colors.selected}`
      : `solid 1px ${colors.text.dark} `)};

      .ant-card-head {
        border: none;
      text-align: center;
    }
      .ant-card-body {
        padding: 0px 20px !important;
      text-align: center;
      ${text.body}
    }
      `;

export default SizeCard;
