import dayjs from 'dayjs';

type Props = {
  loanData: Loan | undefined;
  loanDelayed?: { isDelayed: boolean, delayDays: number };
};

const LoanInformation = ({ loanData, loanDelayed }: Props): JSX.Element => {
  const showDelayMessage = (
    <div className="status status--rejected tracking-info__status" style={{ marginBottom: 10 }}>
      {`Delayed Payment - ${loanDelayed?.delayDays} days`}
    </div>
  );

  return (
    <div className={`tracking-info ${loanDelayed?.isDelayed && 'tracking-info--red'}`}>
      <div className="tracking-info__body">
        <div className="tracking-info__title">Loan Information:</div>
        {loanDelayed?.isDelayed && showDelayMessage}
        <div className="tracking-info__unit">
          Rate:
          <span>
            {` ${((loanData?.monthlyPercentageInterestRate || 0) * 100).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
            % per month (
            {((loanData?.yearlyPercentageInterestRate || 0) * 100).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            % APR)
          </span>
        </div>
        <div className="tracking-info__unit">
          Current interests:
          <span>
            {' $'}
            {(loanData?.interestValue)?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
        <div className="tracking-info__unit">
          Due date:
          <span>{` ${dayjs(loanData?.charges[loanData?.charges.length - 1].date).format('MM.DD.YYYY')}`}</span>
        </div>
        <div className="tracking-info__unit">
          Auto-withdraw:
          <span>&nbsp;Yes</span>
        </div>
      </div>
    </div>
  );
};

export default LoanInformation;
