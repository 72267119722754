import { toString } from '../helpers/string';

const customQuoteFormatParameters = ({ context: { submission: { generalInfo } } }: Quote) => {
  const elements: JSX.Element[] = [];
  Object.entries(generalInfo).forEach(([key, value]: [string, any]) => {
    if (key === 'categories')
      return;
    if (key === 'transactionType')
      return;
    if (key.toLocaleLowerCase() === 'sizes')
      return;
    if (key.toLocaleLowerCase().includes('counter'))
      return;
    if (!key || !value)
      return;

    const element = (
      <>
        {` ${key.replace(/([A-Z])/g, ' $1')
          .replace(/^./, str => str.toUpperCase())}: ${(key.toLocaleLowerCase().includes('price')
    || key.toLocaleLowerCase().includes('value')) ? '$' : ''} ${toString(value)}`}
      </>
    );

    elements.push(element);
    elements.push(<br />);
  });

  return elements;
};

export {
  customQuoteFormatParameters,
};
