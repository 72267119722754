/* eslint-disable max-len */
type Props = {
  mode?: string;
  step?: number;
};

const iconClock = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.99998 2.66659C5.05446 2.66659 2.66665 5.0544 2.66665 7.99992C2.66665 10.9454 5.05446 13.3333 7.99998 13.3333C10.9455 13.3333 13.3333 10.9454 13.3333 7.99992C13.33 5.05577 10.9441 2.66989 7.99998 2.66659ZM11.3333 8.66659H7.33331V4.66659H8.66665V7.33325H11.3333V8.66659Z"
    />
  </svg>
);

const iconCompleted = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.98931 13.3333H7.99998C10.9445 13.3303 13.3295 10.9417 13.328 7.99725C13.3265 5.05278 10.9391 2.66659 7.99465 2.66659C5.05017 2.66659 2.66279 5.05278 2.66131 7.99725C2.65984 10.9417 5.04484 13.3303 7.98931 13.3333ZM6.66665 11.3333L3.99998 8.66659L4.93998 7.72659L6.66665 9.44659L11.06 5.05325L12 5.99992L6.66665 11.3333Z"
    />
  </svg>
);

const Stepper = ({ mode, step }: Props) => (
  <div style={{ margin: '40px 0 80px 0' }}>
    {
      mode === 'PAWN'
        ? (
          <div className="dashboard-timeline">
            <div className="dashboard-timeline__title">Timeline</div>
            <div className="dashboard-timeline__body">
              <div className={`dashboard-timeline-unit ${step && step > 1 && 'dashboard-timeline-unit--completed'} ${step && step === 1 && 'dashboard-timeline-unit--current'}`}>
                <div className="dashboard-timeline-unit__body">
                  <div className="dashboard-timeline-unit__pretitle">Step 1</div>
                  <div className="dashboard-timeline-unit__icon">
                    {
                      step && step > 1
                        ? iconCompleted
                        : iconClock
                    }
                  </div>
                  <div className="dashboard-timeline-unit__title">
                    Request Review
                  </div>
                  <div className="dashboard-timeline-unit__clone">
                    <div className="dashboard-timeline-unit__pretitle">Step 1</div>
                    <div className="dashboard-timeline-unit__icon">
                      {
                        step && step > 1
                          ? iconCompleted
                          : iconClock
                      }
                    </div>
                    <div className="dashboard-timeline-unit__title">
                      Request Review
                    </div>
                    <div className="dashboard-timeline-unit__description">
                      In this step you will receive preliminary quote for the item(s) with possibility to negotiate the price. After we agree on the price, you will be able to send the item(s) for review.
                    </div>
                  </div>
                </div>
              </div>
              <div className={`dashboard-timeline-unit ${step && step > 2 && 'dashboard-timeline-unit--completed'} ${step && step === 2 && 'dashboard-timeline-unit--current'}`}>
                <div className="dashboard-timeline-unit__body">
                  <div className="dashboard-timeline-unit__pretitle">Step 2</div>
                  <div className="dashboard-timeline-unit__icon">
                    {
                      step && step > 2
                        ? iconCompleted
                        : iconClock
                    }
                  </div>
                  <div className="dashboard-timeline-unit__title">Items Review</div>
                  <div className="dashboard-timeline-unit__clone">
                    <div className="dashboard-timeline-unit__pretitle">Step 2</div>
                    <div className="dashboard-timeline-unit__icon">
                      {
                        step && step > 2
                          ? iconCompleted
                          : iconClock
                      }
                    </div>
                    <div className="dashboard-timeline-unit__title">
                      Items Review
                    </div>
                    <div className="dashboard-timeline-unit__description">
                      In this step our experts will inspect the item(s) and confirm the price or send a counter offer for acceptance.
                    </div>
                  </div>
                </div>
              </div>
              <div className={`dashboard-timeline-unit ${step && step > 3 && 'dashboard-timeline-unit--completed'} ${step && step === 3 && 'dashboard-timeline-unit--current'}`}>
                <div className="dashboard-timeline-unit__body">
                  <div className="dashboard-timeline-unit__pretitle">Step 3</div>
                  <div className="dashboard-timeline-unit__icon">
                    {
                      step && step > 3
                        ? iconCompleted
                        : iconClock
                    }
                  </div>
                  <div className="dashboard-timeline-unit__title">Agreement</div>
                  <div className="dashboard-timeline-unit__clone">
                    <div className="dashboard-timeline-unit__pretitle">Step 3</div>
                    <div className="dashboard-timeline-unit__icon">
                      {
                        step && step > 3
                          ? iconCompleted
                          : iconClock
                      }
                    </div>
                    <div className="dashboard-timeline-unit__title">Agreement</div>
                    <div className="dashboard-timeline-unit__description">
                      In this step you will be able to review and accept the agreement.
                    </div>
                  </div>
                </div>
              </div>
              <div className={`dashboard-timeline-unit ${step && step > 4 && 'dashboard-timeline-unit--completed'} ${step && step === 4 && 'dashboard-timeline-unit--current'}`}>
                <div className="dashboard-timeline-unit__body">
                  <div className="dashboard-timeline-unit__pretitle">Step 4</div>
                  <div className="dashboard-timeline-unit__icon">
                    {
                      step && step > 4
                        ? iconCompleted
                        : iconClock
                    }
                  </div>
                  <div className="dashboard-timeline-unit__title">Payment</div>
                  <div className="dashboard-timeline-unit__clone">
                    <div className="dashboard-timeline-unit__pretitle">Step 4</div>
                    <div className="dashboard-timeline-unit__icon">
                      {
                        step && step > 4
                          ? iconCompleted
                          : iconClock
                      }
                    </div>
                    <div className="dashboard-timeline-unit__title">Payment</div>
                    <div className="dashboard-timeline-unit__description">
                      After authorization you will receive your payment and loan will be opened. You will be able to pay manually.
                    </div>
                  </div>
                </div>
              </div>
              <div className={`dashboard-timeline-unit ${step && step > 5 && 'dashboard-timeline-unit--completed'} ${step && step === 5 && 'dashboard-timeline-unit--current'}`}>
                <div className="dashboard-timeline-unit__body">
                  <div className="dashboard-timeline-unit__pretitle">Step 5</div>
                  <div className="dashboard-timeline-unit__icon">
                    {
                      step && step > 5
                        ? iconCompleted
                        : iconClock
                    }
                  </div>
                  <div className="dashboard-timeline-unit__title">Getting Back</div>
                  <div className="dashboard-timeline-unit__clone">
                    <div className="dashboard-timeline-unit__pretitle">Step 5</div>
                    <div className="dashboard-timeline-unit__icon">
                      {
                        step && step > 5
                          ? iconCompleted
                          : iconClock
                      }
                    </div>
                    <div className="dashboard-timeline-unit__title">
                      Getting Back
                    </div>
                    <div className="dashboard-timeline-unit__description">
                      Loan is closed, soon you will receive your item(s)!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className="dashboard-timeline">
            <div className="dashboard-timeline__title">Timeline</div>
            <div className="dashboard-timeline__body">
              <div className={`dashboard-timeline-unit ${step && step > 1 && 'dashboard-timeline-unit--completed'} ${step && step === 1 && 'dashboard-timeline-unit--current'}`}>
                <div className="dashboard-timeline-unit__body">
                  <div className="dashboard-timeline-unit__pretitle">Step 1</div>
                  <div className="dashboard-timeline-unit__icon">
                    {
                      step && step > 1
                        ? iconCompleted
                        : iconClock
                    }
                  </div>
                  <div className="dashboard-timeline-unit__title">
                    Request Review
                  </div>
                  <div className="dashboard-timeline-unit__clone">
                    <div className="dashboard-timeline-unit__pretitle">Step 1</div>
                    <div className="dashboard-timeline-unit__icon">
                      {
                        step && step > 1
                          ? iconCompleted
                          : iconClock
                      }
                    </div>
                    <div className="dashboard-timeline-unit__title">
                      Request Review
                    </div>
                    <div className="dashboard-timeline-unit__description">
                      In this step you will receive preliminary quote for the item(s) with possibility to negotiate the price. After we agree on the price, you will be able to send the item(s) for review.
                    </div>
                  </div>
                </div>
              </div>
              <div className={`dashboard-timeline-unit ${step && step > 2 && 'dashboard-timeline-unit--completed'} ${step && step === 2 && 'dashboard-timeline-unit--current'}`}>
                <div className="dashboard-timeline-unit__body">
                  <div className="dashboard-timeline-unit__pretitle">Step 2</div>
                  <div className="dashboard-timeline-unit__icon">
                    {
                      step && step > 2
                        ? iconCompleted
                        : iconClock
                    }
                  </div>
                  <div className="dashboard-timeline-unit__title">Items Review</div>
                  <div className="dashboard-timeline-unit__clone">
                    <div className="dashboard-timeline-unit__pretitle">Step 2</div>
                    <div className="dashboard-timeline-unit__icon">
                      {
                        step && step > 2
                          ? iconCompleted
                          : iconClock
                      }
                    </div>
                    <div className="dashboard-timeline-unit__title">
                      Items Review
                    </div>
                    <div className="dashboard-timeline-unit__description">
                      In this step our experts will inspect the item(s) and confirm the price or send a counter offer for acceptance.
                    </div>
                  </div>
                </div>
              </div>
              <div className={`dashboard-timeline-unit ${step && step > 4 && 'dashboard-timeline-unit--completed'} ${step && step === 4 && 'dashboard-timeline-unit--current'}`}>
                <div className="dashboard-timeline-unit__body">
                  <div className="dashboard-timeline-unit__pretitle">Step 3</div>
                  <div className="dashboard-timeline-unit__icon">
                    {
                      step && step > 4
                        ? iconCompleted
                        : iconClock
                    }
                  </div>
                  <div className="dashboard-timeline-unit__title">Payment</div>
                  <div className="dashboard-timeline-unit__clone">
                    <div className="dashboard-timeline-unit__pretitle">Step 3</div>
                    <div className="dashboard-timeline-unit__icon">
                      {
                        step && step > 4
                          ? iconCompleted
                          : iconClock
                      }
                    </div>
                    <div className="dashboard-timeline-unit__title">Payment</div>
                    <div className="dashboard-timeline-unit__description">
                      Well done! After confirming the sale you are just waiting for the payment!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
  </div>
);

export default Stepper;
