import {
  Upload as AntdUpload, Modal, Button,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import Link from '../../assets/images/link.svg';
import { text, colors } from '../../constants';
import { api } from '../../api';
import { interceptors } from '../../utils';

const FILE_LIMIT = 5;

type Props = {
  onFileListChange: (fileList: any[]) => void;
  fileList: any[];
  apiBodyParams?: object;
  readonly?: boolean;
};

const FileUpload = ({ fileList, onFileListChange, readonly }: Props) => {
  const onRemove = (file: any) => {
    const filteredList = fileList.filter(el => el.uid !== file.uid);
    onFileListChange(filteredList);
  };

  const onPreview = async (file: any) => {
    const { fileName, name } = file;
    const blob = await api
      .get(`/files/file/${fileName}`, { responseType: 'blob' })
      .then(res => res.data);

    const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  const beforeUpload = (file: any) => {
    if (file.type !== 'plain/text' && file.type !== 'application/pdf') {
      Modal.error({ title: 'Incorrect type of file', content: 'Allowed types are: pdf, txt.' });
      return false;
    }
    const formData = new FormData();
    formData.append('file', file);

    api
      .post('/agreement/upload', formData)
      .then(res => res.data)
      .then((fileName: string) => {
        const fileUrl = `${process.env.REACT_APP_API_URL}/files/${fileName}`;
        const newFile = {
          fileName,
          name: file.name,
          uid: file.uid,
          url: fileUrl,
          originUrl: fileUrl,
        };
        onFileListChange([...fileList, newFile]);
      })
      .catch(interceptors.interceptPostError);
    return false;
  };

  return (
    <AntdUpload
      fileList={fileList}
      beforeUpload={beforeUpload}
      onRemove={onRemove}
      onPreview={onPreview}
    >
      {!readonly && <Button icon={<UploadOutlined />}>Upload</Button>}
    </AntdUpload>
  );
};

const Upload = ({ fileList, onFileListChange }: Props) => {
  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    if (!imgWindow)
      return;
    imgWindow.document.write(image.outerHTML);
  };

  const onRemove = (file: any) => {
    const filteredList = fileList.filter(el => el.uid !== file.uid);
    if (onFileListChange)
      onFileListChange(filteredList);
  };

  const handleBeforeUpload = (file: any) => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      showError('Incorrect type of file', 'Allowed types are: jpg, png.');
      return false;
    }

    const formData = new FormData();
    formData.append('image', file);

    api
      .post('/files/upload', formData)
      .then(res => res.data)
      .then((imgUrl: string) => {
        const newImage = {
          uid: file.uid,
          url: imgUrl,
          originUrl: imgUrl,
        };
        if (onFileListChange)
          onFileListChange([...fileList, newImage]);
      })
      .catch(interceptors.interceptPostError);

    return false;
  };

  const showError = (title: string, content: string) => Modal.error({ title, content });

  return (
    <ImgCrop rotate>
      <StyledUpload
        listType="picture-card"
        fileList={fileList}
        onPreview={onPreview}
        onRemove={onRemove}
        beforeUpload={handleBeforeUpload}
      >
        {fileList.length < FILE_LIMIT && (
          <CardContent className="upload-card-content">
            <img alt="" src={Link} width="19" height="40" />
            click to attach
          </CardContent>
        )}
      </StyledUpload>
    </ImgCrop>
  );
};

const StyledUpload = styled(AntdUpload)`
  .ant-upload-list-picture-card-container {
    width: 256px;
    height: 256px;
    .ant-upload-list-item-list-type-picture-card {
      padding: 0;
    }
    .ant-upload-list-picture {
      border: 0;
    }
  }
  .ant-upload-select-picture-card {
    width: 256px;
    border: 0;
    height: 256px;
  }
  
`;

const CardContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 84px;
  flex-direction: column;
  color: ${colors.text.light};
  ${text['title-20-Aeonik']}
  img {
    margin: 0px auto 20px auto;
  }
`;

export default Upload;

export {
  Upload,
  FileUpload,
};
