/* eslint-disable max-len */
import fonts from '../../../constants/fonts';

const { mainFont } = fonts.fonts;

const RequestSentCounterOffer = () => (
  <div style={{ fontFamily: mainFont }}>
    <div className="dashboard-columns">
      <div className="dashboard-columns__left">
        <div className="dashboard-card dashboard-card--blue">
          <div className="dashboard-card__title">Thank you</div>
          <div className="dashboard-card__text">Thank you for sending your counter offer! Our team will review and let you know if we accept your offer.</div>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 36.6668C18.1591 36.6668 16.6667 35.1744 16.6667 33.3335H23.3334C23.3334 35.1744 21.841 36.6668 20 36.6668ZM33.3334 31.6668H6.66669V28.3335L10 26.6668V17.5002C10 11.7302 12.3684 7.9885 16.6667 6.96683V3.3335H21.6667C20.5799 4.77339 19.9946 6.52953 20 8.3335C20 8.75246 20.0312 9.17084 20.0934 9.58516H20C17.9664 9.45345 16.005 10.3629 14.7917 12.0002C13.7543 13.6411 13.2452 15.5608 13.3334 17.5002V28.3335H26.6667V17.5002C26.6667 17.1485 26.655 16.8102 26.6317 16.5002C27.7341 16.7279 28.871 16.7319 29.975 16.5118C29.9934 16.8635 30 17.1985 30 17.5118V26.6668L33.3334 28.3335V31.6668ZM28.3334 13.3335C27.3263 13.3347 26.3426 13.0308 25.5117 12.4618C23.548 11.1195 22.8018 8.5795 23.7275 6.38834C24.6531 4.19717 26.9943 2.96147 29.3257 3.43356C31.657 3.90565 33.3331 5.95483 33.3334 8.3335C33.3334 11.0949 31.0948 13.3335 28.3334 13.3335Z" />
          </svg>
        </div>
        <div className="dashboard-card dashboard-card--smaller-text">
          <div className="dashboard-card__title">What’s Next?</div>
          <div className="dashboard-card__text">Please wait while our experts review your counter offer. We will notify you once we have an update.</div>
        </div>
      </div>
      <div className="dashboard-columns__right">
        <div className="dashboard-infocard">
          <div className="popup__header">
            <div className="popup__title">Thank you for sending your counter offer!</div>
          </div>
          <div className="popup__content">
            <div className="popup__image popup__image--green">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.98931 13.3333H7.99998C10.9445 13.3303 13.3295 10.9417 13.328 7.99725C13.3265 5.05278 10.9391 2.66659 7.99465 2.66659C5.05017 2.66659 2.66279 5.05278 2.66131 7.99725C2.65984 10.9417 5.04484 13.3303 7.98931 13.3333ZM6.66665 11.3333L3.99998 8.66659L4.93998 7.72659L6.66665 9.44659L11.06 5.05325L12 5.99992L6.66665 11.3333Z" />
              </svg>
            </div>
          </div>
          <div className="popup__footer">
            <div className="popup__text">Our experts are reviewing your counter offer and we will get back to you shortly</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RequestSentCounterOffer;
