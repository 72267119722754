import styled from 'styled-components';

const Questions = () => (
  <div className="section section-questions">
    <div className="container">
      <div
        className="questions-box"
        data-aos="zoom-in"
        data-aos-delay={0}
        data-aos-duration={800}
        data-aos-easing="ease-in-out"
        data-aos-once="true"
        data-aos-mirror="true"
        data-aos-anchor-placement="center-bottom"
      >
        <div className="questions-box__in">
          <div className="row no-gutters">
            <div className="col-lg-5 offset-lg-1 questions-box__col">
              <div className="questions-box__body">
                <div className="row questions-box__row">
                  <div className="col-md-10 questions-box__content">
                    <div className="questions-box__content-in">
                      <p className="questions-box__title questions-box__title--mb">
                        Questions?
                      </p>
                      <div className="row">
                        <div className="col-md-10">
                          <p className="questions-box__text questions-box__text--mb">
                            Feel free to contact us to enquire about a specific item or learn more about our services.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="questions-box__details">
                      <div className="questions-box__detail">
                        <div className="row">
                          <div className="col-md-3 col-sm-12 col-3">
                            <span>Call:</span>
                          </div>
                          <div className="col-md-9 col-sm-12 col-9">
                            <a className="questions-box__link" href="tel:8562352463">
                              <span>856 235 2463</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="questions-box__detail">
                        <div className="row">
                          <div className="col-md-3 col-sm-12 col-3">
                            <span>Write:</span>
                          </div>
                          <div className="col-md-9 col-sm-12 col-9">
                            <LinkButton
                              onClick={() => window.smartsupp('chat:open')}
                              className="questions-box__link questions-box__link--with-icon"
                            >
                              Chat with us
                            </LinkButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 questions-box__col">
              <div className="questions-box__body">
                <div className="row questions-box__row">
                  <div className="col-md-10 questions-box__content">
                    <div className="questions-box__content-in">
                      <p className="questions-box__title questions-box__title--mb">
                        Visit us in store
                      </p>
                      <div className="row">
                        <div className="col-md-10">
                          <p className="questions-box__text questions-box__text--mb">
                            Our store is open from 8am – 5pm Monday – Friday. Come say hello!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="questions-box__details">
                      <div className="questions-box__detail questions-box__detail--multiline">
                        <a
                          className="questions-box__link questions-box__link--with-icon"
                          href="https://g.page/EandBPawn?share"
                        >
                          <span>
                            110 W Camden Ave,
                            <br />
                            Moorestown, NJ 08057
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

);

const LinkButton = styled.button`
  text-decoration: underline;
  background-color: unset;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
`;

export default Questions;
