import { Component } from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Select,
} from 'antd';
import {
  Title, Spinner, Button,
} from '../components/common';
import Editor from '../components/Editor';
import { interceptors } from '../utils';
import { api } from '../api';
import { CustomModal } from '../custom';

type Props = {};

type State = {
  content: string,
  attachments: any[],
  isLoading: boolean;
  activeAgreementTemplate: ConfigurationType;
};

const { SecondaryButton } = Button;

const { Option } = Select;

export default class AgreementTemplateView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      content: '',
      attachments: [],
      isLoading: false,
      activeAgreementTemplate: 'agreement-sell',
    };
  }

  componentDidMount() {
    this.fetchTemplate('agreement-sell');
  }

  render() {
    const { attachments, content, isLoading } = this.state;

    const showTemplateOptions = (
      <Select
        style={{ width: '100%' }}
        defaultValue="agreement-sell"
        onChange={this.handleChangeAgreementTemplate}
      >
        <Option value="agreement-sell">Sell</Option>
        <Option value="agreement-pawn">Pawn</Option>
      </Select>
    );

    return (
      <body className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <Container>
              <Row gutter={[32, 40]}>
                <Col>
                  <Title title="Agreement Template" />
                  {showTemplateOptions}
                </Col>
              </Row>
              <Row gutter={[32, 40]}>
                <Col>
                  {
                    isLoading
                      ? <Spinner />
                      : (
                        <Editor
                          fileList={attachments}
                          setFileList={this.onAttachementsChange}
                          content={content}
                          setContent={this.onContentChange}
                        />
                      )
                  }
                </Col>
              </Row>
              <Row gutter={[32, 40]}>
                <Col>
                  <SecondaryButton onClick={this.saveTemplate}>
                    Save template
                  </SecondaryButton>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </body>
    );
  }

  onAttachementsChange = (attachments: any[]) => this.setState({ attachments });

  onContentChange = (content: string) => this.setState({ content });

  handleChangeAgreementTemplate = (value: ConfigurationType) => {
    this.setState({ activeAgreementTemplate: value });
    this.fetchTemplate(value);
  };

  saveTemplate = () => {
    const { attachments, content, activeAgreementTemplate } = this.state;

    const newConfigs: IConfiguration[] = [{
      content: JSON.stringify({ attachments, content }),
      type: activeAgreementTemplate,
      id: '',
    }];

    this.setState({ isLoading: true });
    api
      .post('/configuration', { newConfigs })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Data has been saved successfully.',
        });
      })
      .catch(interceptors.interceptPostError)
      .finally(() => this.setState({ isLoading: false }));
  };

  fetchTemplate = (value: ConfigurationType) => {
    this.setState({ isLoading: true });
    api
      .get(`/configuration/${value}`)
      .then(res => res.data)
      .then(data => {
        if (!data)
          return;
        const { attachments, content } = JSON.parse(data.content);
        this.setState({ attachments, content });
      })
      .catch(interceptors.interceptGetError)
      .finally(() => this.setState({ isLoading: false }));
  };
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 15px 15px 15px;
`;
