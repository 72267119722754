/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-access-state-in-setstate */
import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {
  Space,
} from 'antd';
import { AxiosError } from 'axios';
import {
  Button, Spinner,
} from '../components/common';
import { text, data } from '../constants';
import EMAIL_NOTIFICATIONS_STAGES from '../constants/emailNotificationsStages';
import { api } from '../api';
import { interceptPostErrorMessages, interceptPostError } from '../utils/interceptors';
import { Modals } from '../components';
import { CustomParameters, CustomModal } from '../custom';
import AdminRequestDetailsContent from '../components/Notifications/admin/AdminRequestDetailsContent';
import AdminRequestSent from '../components/Notifications/admin/AdminRequestSent';
import AcceptRejectSendPreliminaryOffer from '../components/Notifications/admin/AcceptRejectSendPreliminaryOffer';
import AdminNotificationContener from '../components/Notifications/admin/AdminNotificationContener';
import AdminPreliminaryOffer from '../components/Notifications/admin/AdminPreliminaryOffer';
import AdminRequestRejected from '../components/Notifications/admin/AdminRequestRejected';
import AdminCounterOfferFromUser from '../components/Notifications/admin/AdminCounterOfferFromUser';
import AdminOfferAcceptedWaitingForShiping from '../components/Notifications/admin/AdminOfferAcceptedWaitingForShiping';
import AdminItemReceived from '../components/Notifications/admin/ItemReceived/AdminItemReceived';
import AdminSaleHasBeenMade from '../components/Notifications/admin/SaleSucces/AdminSaleHasBeenMade';
import AdminSalePaymentConfirmation from '../components/Notifications/admin/PaymentConfirmation/AdminSalePaymentConfirmation';
import AdminItemReceivedRejected from '../components/Notifications/admin/ItemReceived/AdminItemReceivedRejected';
import AdminItemReceivedShippingBackModal from '../components/Notifications/admin/ItemReceived/AdminItemReceivedShippingBackModal';
import AdminAgreementHasBeenSentSigned from '../components/Notifications/admin/SaleSucces/AdminAgreementHasBeenSentSigned';
import ConfirmPaymentModal from '../components/Modals/ConfirmPaymentModal';
import AdminSaleClosed from '../components/Notifications/admin/Closed/AdminSaleClosed';
import AdminLoanIsActive from '../components/Notifications/admin/LoanActive/AdminLoanIsActive';
import AdminLoanClosed from '../components/Notifications/admin/Closed/AdminLoanClosed';
import ShipmentBackModal from '../components/Modals/ShipmentBackModal';

const { RequestDetailsModal, Agreement } = Modals;
const { ActionButton } = Button;
const { AgreementCreateModal } = Agreement;

interface Props extends RouteComponentProps { }

type State = {
  request: Quote;
  loan?: Loan;
  actionHistory: Action[];
  choosenRequest: Quote;
  requests: Quote[];
  quoteValue: number;
  interestData?: InterestData;
  transactions: Loan[],
  isFinal: boolean;
  loading: boolean;
  isDetailsModalVisible: boolean;
  isCreateAgreementModalVisible: boolean;
  isVisibleRejectSendPreliminaryOffer: boolean;
  isVisibleReceivedShippingBack: boolean;
  typeNotification: string;
  isVisibleConfirmPaymentModal: boolean;
  isAddingPaymentId: boolean;
  isShipmentBackModalVisible: boolean;
  isAddingShipmentBackTrackingNumber: boolean;
  emailNotification?: string;
  isAddingTrackingBackNumber: boolean;
};

const {
  AV_3A_COUNTER_FINAL,
  AV_5_RECEIVED,
  AV_6B_6C_SHIPMENT_BACK,
  AV_8B_9C_CONFIRM_PAYMENT,
  AV_10_CONFIRM_SHIPMENT,
} = EMAIL_NOTIFICATIONS_STAGES;

export default class AdminRequestDetailsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      request: data.QUOTE_DEFAULT,
      actionHistory: [],
      choosenRequest: data.QUOTE_DEFAULT,
      requests: [],
      quoteValue: 0,
      transactions: [],
      isFinal: false,
      loading: true,
      isDetailsModalVisible: false,
      isCreateAgreementModalVisible: false,
      isVisibleRejectSendPreliminaryOffer: false,
      isVisibleReceivedShippingBack: false,
      typeNotification: '',
      isVisibleConfirmPaymentModal: false,
      isAddingPaymentId: false,
      isShipmentBackModalVisible: false,
      isAddingShipmentBackTrackingNumber: false,
      isAddingTrackingBackNumber: false,
    };
  }

  componentDidMount() {
    this.fetchRequest();
  }

  render() {
    const {
      request,
      requests,
      actionHistory,
      loading,
      isDetailsModalVisible,
      choosenRequest,
      isCreateAgreementModalVisible,
      isVisibleRejectSendPreliminaryOffer,
      isVisibleReceivedShippingBack,
      typeNotification,
      isVisibleConfirmPaymentModal,
      isAddingPaymentId,
      isShipmentBackModalVisible,
      isAddingShipmentBackTrackingNumber,
      isAddingTrackingBackNumber,
    } = this.state;

    if (loading) {
      return <Spinner />;
    }

    const {
      context: {
        mode, stateStepper,
      },
      payment,
    } = request;

    return (
      <>
        <AdminRequestDetailsContent
          mode={mode}
          step={stateStepper}
          actionHistory={actionHistory}
          requests={requests}
          contentData={this.handleDisplayStateActions()}
          showDetails={(id: any) => this.handleViewDetails(id)}
          onExludeItem={this.handleExludeItem}
          onActiveDemo={() => { }}
          handledDemoStateAction={() => { }}
        />
        <RequestDetailsModal
          fetchRequest={this.fetchRequest}
          isVisible={isDetailsModalVisible}
          quote={choosenRequest}
          handleVisibileChange={this.handleDetailsModalVisibilityChange}
        />
        <AgreementCreateModal
          onCancel={() => this.handleCreateAgreementModalVisibilityChange(false)}
          visible={isCreateAgreementModalVisible}
          onFinish={this.handleCreateAgreement}
        />
        <AcceptRejectSendPreliminaryOffer
          quoteValue={this.state.quoteValue}
          isVisibleRejectSendPreliminaryOffer={isVisibleRejectSendPreliminaryOffer}
          typeCallback={typeNotification}
          onSendPreliminaryOffer={this.handleCounterOfferSend}
          onRejectRequest={this.handleReject}
          onAcceptRequest={this.handleAccept}
          onCancel={() => this.setState({ isVisibleRejectSendPreliminaryOffer: false })}
        />
        <AdminItemReceivedShippingBackModal
          isVisible={isVisibleReceivedShippingBack}
          isAddingTrackingBackNumber={isAddingTrackingBackNumber}
          onCancel={() => this.setState({ isVisibleReceivedShippingBack: false })}
          onSubmit={this.handleShippingBackTrackingNumber}
        />
        <ConfirmPaymentModal
          isVisible={isVisibleConfirmPaymentModal}
          typePayment={payment}
          isLoading={isAddingPaymentId}
          onCancel={() => this.setState({ isVisibleConfirmPaymentModal: false })}
          onSubmit={this.handleClosePaymentModal}
        />
        <ShipmentBackModal
          isVisible={isShipmentBackModalVisible}
          isLoading={isAddingShipmentBackTrackingNumber}
          onCancel={() => this.setState({ isShipmentBackModalVisible: false })}
          onSubmit={this.handleCloseShipmentBackModal}
        />
      </>
    );
  }

  handleFormatTableDataSource = () => {
    const { requests } = this.state;
    return requests
      .filter(r => r.category !== 'package')
      .map(r => ({
        ...r,
        name: r.name.replace('&', ' / '),
        parameters: CustomParameters.customQuoteFormatParameters(r),
      }));
  };

  handleShipback = (values: any) => {
    const { request: { id } } = this.state;
    api.post('/shipment/return', {
      values,
      requestId: id,
    }).then(res => res.data)
      .then((linkToLabel: string) => {
        window.open(linkToLabel, '_blank')?.focus();
        this.handleReject();
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status === 503) {
          CustomModal.displayCustomWarning({
            title: error.response?.data.message,
            content: error.response?.data.data,
          });
        } else {
          interceptPostErrorMessages(error.response?.data.errorMessages);
        }
      });
  };

  getInterestRate = (amount: number) => {
    let montlyPercentageRate = 0;
    let yearlyPercentageRate = 0;
    data.INTEREST_FORMULA.forEach((f: any) => {
      if (amount >= f.min && amount <= f.max) {
        montlyPercentageRate = f.monthly / 100;
        yearlyPercentageRate = f.yearly / 100;
      }
    });

    const interestRate = amount * montlyPercentageRate * 3;
    return {
      interestValue: parseFloat(interestRate.toFixed(2)),
      montlyPercentageRate,
      yearlyPercentageRate,
    };
  };

  setInterestRates = (amount: number) => {
    const {
      interestValue,
      montlyPercentageRate,
      yearlyPercentageRate,
    } = this.getInterestRate(amount);
    this.setState({
      interestData: {
        interestValue,
        montlyPercentageRate,
        yearlyPercentageRate,
      },
    });
  };

  handleDisplayStateActions = () => {
    const {
      request: {
        state,
        context: {
          boxIsNotArrived,
          quoteValue,
          status,
          isDelivered,
          isAdminRejected,
          isCounterOffer,
          previousAdminOffer,
          isAdminAction,
          mode,
          contactedSolved,
          trackingBackNumber,
        },
        shippingLabels,
        updatedAt,
        payment,
        context,
      },
      interestData,
      loan,
    } = this.state;

    const today = new Date();
    const diff = dayjs(today).diff(loan?.startDate || '', 'd');
    const loanDelayed = { isDelayed: diff > 90, delayDays: diff - 90 };

    switch (state) {
      case 'submitted':
        return (
          <>
            {status === 'PQ - countered' && isCounterOffer
              && (
                <AdminNotificationContener
                  adminStep={1}
                  mode={mode}
                  payment={payment}
                  contentData={(
                    <AdminCounterOfferFromUser
                      quoteValue={quoteValue}
                      actualQuoteValue={this.state.quoteValue}
                      previousAdminOffer={previousAdminOffer}
                      onChangeValue={this.handleQuoteValueChange}
                      onFinalOffer={this.handleIsFinalChange}
                      onSubmit={(value, emailNotification) => this.handleMakePreliminaryOffer(value, emailNotification)}
                    />
                  )}
                />
              )}
            {status === 'PQ - accepted' && !isDelivered
              && (
                <AdminNotificationContener
                  adminStep={2}
                  mode={mode}
                  payment={payment}
                  contentData={(
                    <AdminOfferAcceptedWaitingForShiping
                      quoteValue={quoteValue}
                    />
                  )}
                />
              )}
            {status === 'Request sent' && isCounterOffer && (
              <AdminNotificationContener
                adminStep={1}
                mode={mode}
                payment={payment}
                contentData={(
                  <AdminCounterOfferFromUser
                    quoteValue={quoteValue}
                    actualQuoteValue={this.state.quoteValue}
                    previousAdminOffer={previousAdminOffer}
                    onChangeValue={this.handleQuoteValueChange}
                    onFinalOffer={this.handleIsFinalChange}
                    onSubmit={(value, emailNotification) => this.handleMakePreliminaryOffer(value, emailNotification)}
                  />
                )}
              />
            )}
            {status === 'Request sent' && !isCounterOffer && (
              <AdminNotificationContener
                adminStep={1}
                mode={mode}
                payment={payment}
                contentData={(
                  <AdminRequestSent
                    value={this.state.quoteValue}
                    onChangeValue={this.handleQuoteValueChange}
                    onFinalOffer={this.handleIsFinalChange}
                    onSubmit={(value, emailNotifocation) => this.handleMakePreliminaryOffer(value, emailNotifocation)}
                  />
                )}
              />
            )}
            {status === 'OQ - countered' && (
              <AdminNotificationContener
                adminStep={1}
                mode={mode}
                payment={payment}
                contentData={(
                  <AdminItemReceived
                    status={status}
                    modeQuote={mode}
                    quoteValue={quoteValue}
                    actualQuoteValue={this.state.quoteValue}
                    previousAdminOffer={previousAdminOffer}
                    onChangeValue={this.handleQuoteValueChange}
                    onFinalOffer={this.handleIsFinalChange}
                    onSubmit={(value, emailNotification) => this.handleMakePreliminaryOffer(value, emailNotification)}
                  />
                )}
              />
            )}
          </>
        );
      case 'accepted':
        return (
          <>
            {status === 'OQ - accepted' && isDelivered && mode === 'SELL' && (
              <AdminNotificationContener
                adminStep={5}
                mode={mode}
                isAccepted={true}
                trackingInfo={shippingLabels}
                contentData={(
                  <AdminSaleHasBeenMade
                    quoteValue={quoteValue}
                  />
                )}
              />
            )}
            {status === 'OQ - accepted' && isDelivered && mode === 'PAWN' && (
              <AdminNotificationContener
                adminStep={5}
                mode={mode}
                isAccepted={true}
                trackingInfo={shippingLabels}
                contentData={(
                  <AdminAgreementHasBeenSentSigned
                    interestData={interestData}
                    quoteValue={quoteValue}
                  />
                )}
              />
            )}
            {!isDelivered && (
              <AdminNotificationContener
                adminStep={2}
                mode={mode}
                isAccepted={true}
                trackingInfo={shippingLabels}
                contentData={(
                  <AdminOfferAcceptedWaitingForShiping
                    quoteValue={quoteValue}
                  />
                )}
              />
            )}
          </>
        );
      case 'sent':
        return (
          <AdminNotificationContener
            adminStep={2}
            mode={mode}
            isAccepted={true}
            boxIsNotArrived={boxIsNotArrived}
            trackingInfo={shippingLabels}
            contactedSolved={contactedSolved}
            onChangeContactShippingBox={this.handleOnChangeContactShippingBox}
            contentData={(
              <AdminOfferAcceptedWaitingForShiping
                quoteValue={quoteValue}
              />
            )}
          />
        );
      case 'shipmentInProgress':
        return (
          <AdminNotificationContener
            adminStep={3}
            mode={mode}
            isAccepted={true}
            trackingInfo={shippingLabels}
            contentData={(
              <AdminOfferAcceptedWaitingForShiping
                quoteValue={quoteValue}
                shipmentInProgress={true}
                onItemReceived={() => this.handleConfirmItemArrival()}
              />
            )}
          />
        );
      case 'underReview':
        return (
          <AdminNotificationContener
            adminStep={4}
            mode={mode}
            contentData={(
              <AdminItemReceived
                modeQuote={mode}
                quoteValue={quoteValue}
                actualQuoteValue={this.state.quoteValue}
                previousAdminOffer={previousAdminOffer}
                onChangeValue={this.handleQuoteValueChange}
                onFinalOffer={this.handleIsFinalChange}
                onSubmit={(value, emailNotification) => this.handleMakePreliminaryOffer(value, emailNotification)}
              />
            )}
          />
        );
      case 'quotted':
        return (
          <>
            {status === 'Preliminary quote' && isCounterOffer && isAdminAction
              && (
                <AdminNotificationContener
                  adminStep={1}
                  mode={mode}
                  contentData={(
                    <AdminCounterOfferFromUser
                      quoteValue={quoteValue}
                      actualQuoteValue={this.state.quoteValue}
                      previousAdminOffer={previousAdminOffer}
                      onChangeValue={this.handleQuoteValueChange}
                      onFinalOffer={this.handleIsFinalChange}
                      onSubmit={(value, emailNotification) => this.handleMakePreliminaryOffer(value, emailNotification)}
                    />
                  )}
                />
              )}
            {status === 'Preliminary quote' && isCounterOffer && !isAdminAction
              && (
                <AdminNotificationContener
                  adminStep={1}
                  mode={mode}
                  contentData={(
                    <AdminPreliminaryOffer
                      quoteValue={quoteValue}
                    />
                  )}
                />
              )}
            {status === 'Official quote' && isCounterOffer && !isAdminAction
              && (
                <AdminNotificationContener
                  adminStep={1}
                  mode={mode}
                  contentData={(
                    <AdminPreliminaryOffer
                      quoteValue={quoteValue}
                    />
                  )}
                />
              )}
          </>
        );
      case 'signed':
        return (
          <>
            <AdminNotificationContener
              adminStep={6}
              mode={mode}
              payment={payment}
              isSaleSigned={true}
              contentData={
                mode === 'SELL'
                  ? (
                    <AdminSalePaymentConfirmation
                      quoteValue={quoteValue}
                      onConfirm={() => this.setState({ isVisibleConfirmPaymentModal: true })}
                    />
                  )
                  : (
                    <AdminAgreementHasBeenSentSigned
                      interestData={interestData}
                      mode={mode}
                      quoteValue={quoteValue}
                    />
                  )
              }
            />
          </>
        );
      case 'paymentProcess':
        return (
          <Space direction="vertical">
            <CardTitle>{`${status} $${quoteValue}`}</CardTitle>
            <ActionButton onClick={() => this.handleConfirmWithdrawal()}>Confirm withdrawal and close</ActionButton>
          </Space>
        );
      case 'active':
        return (
          <>
            <AdminNotificationContener
              adminStep={8}
              mode={mode}
              isLoanInProgrss={true}
              loanData={loan}
              loanDelayed={loanDelayed}
              contentData={(
                <AdminLoanIsActive
                  loanData={loan}
                  quoteValue={quoteValue}
                  loanDelayed={loanDelayed}
                />
              )}
            />
          </>
        );
      case 'rejected':
        return (
          isDelivered
            ? (
              <AdminNotificationContener
                adminStep={5}
                isRejected={true}
                trackingInfo={shippingLabels}
                trackingBackNumber={trackingBackNumber}
                mode={mode}
                contentData={(
                  <AdminItemReceivedRejected
                    updatedAt={updatedAt}
                    isAdminRejected={isAdminRejected}
                    trackingBackNumber={trackingBackNumber}
                    onConfirm={() => this.setState({ isVisibleReceivedShippingBack: true, emailNotification: AV_6B_6C_SHIPMENT_BACK })}
                  />
                )}
              />
            )
            : (
              <AdminNotificationContener
                adminStep={1}
                mode={mode}
                contentData={(
                  <AdminRequestRejected />
                )}
              />
            )
        );
      case 'closed':
        return (
          <>
            <AdminNotificationContener
              adminStep={context.shippingBackTrackingNumber ? 9 : 8}
              mode={mode}
              isLoanClosed={true}
              payment={payment}
              shippingLabel={shippingLabels[0]}
              context={context}
              contentData={
                mode === 'SELL'
                  ? (
                    <AdminSaleClosed
                      updatedAt={updatedAt}
                    />
                  )
                  : (
                    <AdminLoanClosed
                      updatedAt={updatedAt}
                      context={context}
                      onConfirm={() => this.setState({ isShipmentBackModalVisible: true })}
                    />
                  )
              }
            />
          </>
        );
      default:
        return <></>;
    }
  };

  handleMakePreliminaryOffer = (value: string, emailNotification?: string) => {
    this.setState({
      isVisibleRejectSendPreliminaryOffer: true,
      typeNotification: value,
      emailNotification,
    });
  };

  handleAccept = () => {
    const {
      request: { id, context }, emailNotification,
    } = this.state;

    const stateStepper = context.stateStepper + 1;

    this.setState({ loading: true });
    api
      .post(`/quotes/accept/${id}`, { isAdminAccepted: true, stateStepper, emailNotification })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Accepted',
        });
        this.fetchRequest();
      })
      .catch(error => {
        CustomModal.displayCustomWarning({
          title: 'Error',
          content: error.response?.data.message,
        });
      })
      .finally(() => this.setState({
        loading: false,
        isVisibleRejectSendPreliminaryOffer: false,
      }));
  };

  handleConfirmWithdrawal = () => {
    const {
      request: { id },
    } = this.state;
    this.setState({ loading: true });
    api
      .post(`/quotes/confirmWithdraw/${id}`)
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Accepted',
        });
        this.fetchRequest();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({ loading: false }));
  };

  handleRedirectToTransactionDetails = () => this.props.history.push('/transactions/default');

  handleCreateAgreementModalVisibilityChange = (state: boolean) => this.setState({
    isCreateAgreementModalVisible: state,
  });

  handleCreateAgreement = (content: string, attachments?: any[]) => {
    const {
      request: { id: quoteId },
    } = this.state;
    this.setState({ loading: true });
    api
      .post('/agreement/create', { content, attachments, quoteId })
      .then(res => res.data)
      .then(() => {
        this.setState({
          isCreateAgreementModalVisible: false,
          loading: true,
        });
        CustomModal.displayCustomSuccess({
          title: 'Agreement has been created',
        });
      })
      .then(() => this.handleGenerateAgreement())
      .catch(interceptPostError)
      .finally(() => this.setState({ loading: false }));
  };

  handleGenerateAgreement = () => {
    const {
      request: { id },
    } = this.state;

    this.setState({ loading: true });
    api
      .post(`/quotes/generate/${id}`)
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Agreement sent',
        });
        this.fetchRequest();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({ loading: false }));
  };

  handleReject = () => {
    const {
      request: { id }, emailNotification,
    } = this.state;
    this.setState({ loading: true });
    api
      .post(`/quotes/reject/${id}`, { isAdminRejected: true, emailNotification })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Rejected',
        });
        this.fetchRequest();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({
        loading: false,
        isVisibleRejectSendPreliminaryOffer: false,
      }));
  };

  handleConfirmItemArrival = () => {
    const {
      request: { id },
    } = this.state;

    this.setState({ loading: true });
    api
      .post(`/quotes/pick/${id}`, { emailNotification: AV_5_RECEIVED })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Item arrival confirmed',
        });
        this.fetchRequest();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({ loading: false }));
  };

  handleExludeItem = (quote: Quote) => {
    const { id, rejectedFromGroup } = quote;

    this.setState({ loading: true });
    api
      .patch(`/quotes/exlude/${id}`, { excluded: !rejectedFromGroup })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: `Item ${!rejectedFromGroup ? 'excluded' : 'included'}`,
        });
        this.fetchRequest();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({ loading: false }));
  };

  handleCounterOfferSend = () => {
    const {
      quoteValue,
      isFinal,
      request: { id },
      emailNotification,
    } = this.state;

    if (quoteValue <= 0) {
      return CustomModal.displayCustomWarning({
        title: 'Counter offer must be greater than 0',
      });
    }

    const isCounterOffer = true;

    this.setState({ loading: true });
    api
      .post(`/quotes/quote/${id}`, {
        isFinal,
        quoteValue,
        isCounterOffer,
        previousAdminOffer: quoteValue,
        emailNotification: isFinal ? AV_3A_COUNTER_FINAL : emailNotification,
      })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Counter offer sent',
        });
        this.fetchRequest();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({ loading: false, isVisibleRejectSendPreliminaryOffer: false }));
  };

  handleQuoteValueChange = (e: any) => this.setState({ quoteValue: Number(e.target.value) });

  handleSendPreliminaryQuote = () => {
    const {
      request: { id },
      isFinal,
      quoteValue,
    } = this.state;
    this.setState({ loading: true });
    api
      .post(`/quotes/quote/${id}`, { isFinal, quoteValue })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Preliminary quote sent',
        });
        this.fetchRequest();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({
        loading: false,
        isVisibleRejectSendPreliminaryOffer: false,
      }));
  };

  handleDetailsModalVisibilityChange = (isVisible: boolean) => this.setState({ isDetailsModalVisible: isVisible });

  handleViewDetails = (id: string) => {
    const choosenRequest = this.state.requests.filter(req => req.id === id)[0];
    this.setState({ choosenRequest, isDetailsModalVisible: true });
  };

  handleIsFinalChange = (e: CheckboxChangeEvent) => this.setState({ isFinal: e.target.checked });

  fetchRequest = () => {
    const id = this.props.location.pathname.split('/').pop();
    if (!id) return;

    this.setState({ loading: true });

    api
      .get(`/quotes/group/${id}`)
      .then(res => res.data)
      .then((res: Quote[]) => {
        const request = res.find(r => r.parentId === null) || res[0];
        this.setState({ request, requests: res });
        this.fetchtransactionsByRequestId(request.id);
        this.setInterestRates(request.context.quoteValue);
        this.fetchActions();
        if (request.state === 'active') this.fetchLoanByQuoteId(request.id);
      })
      .catch(() => { })
      .finally(() => this.setState({ loading: false }));
  };

  fetchLoanByQuoteId = (id: string) => {
    api
      .get(`/loans/quote/${id}`)
      .then(res => res.data)
      .then(loan => this.setState({ loan }));
  };

  fetchtransactionsByRequestId = (id: string) => {
    api
      .get(`/transactions/quote/${id}`)
      .then(res => res.data)
      .then(transactions => this.setState({ transactions }));
  };

  fetchActions = () => {
    const id = this.state.requests[0].groupId;

    api
      .get(`/action-history/${id}`)
      .then(res => res.data)
      .then(actionHistory => this.setState({ actionHistory }))
      .catch(() => { });
  };

  handleClosePaymentModal = (values: any) => {
    this.setState({ isAddingPaymentId: true });
    const {
      request: { id, context },
    } = this.state;

    const stateStepper = context.stateStepper + 1;

    this.setState({ loading: true });
    api
      .post(`/quotes/close/${id}`, { stateStepper, values, emailNotification: AV_8B_9C_CONFIRM_PAYMENT })
      .then(() => {
        CustomModal.displayCustomSuccess({
          title: 'Closed',
        });
        this.fetchRequest();
      })
      .catch(interceptPostError)
      .finally(() => this.setState({
        loading: false,
        isVisibleConfirmPaymentModal: false,
        isAddingPaymentId: false,
      }));
  };

  handleCloseShipmentBackModal = (values: any) => {
    this.setState({ isAddingShipmentBackTrackingNumber: true });
    const {
      request: { id, context },
    } = this.state;

    const updateContext = { ...context, ...values };
    api
      .patch(`/quotes/${id}`, { updateContext, closeStatus: true, emailNotification: AV_10_CONFIRM_SHIPMENT })
      .then(() => this.fetchRequest())
      .catch(interceptPostError)
      .finally(() => this.setState({ isAddingShipmentBackTrackingNumber: false, isShipmentBackModalVisible: false }));
  };

  handleOnChangeContactShippingBox = () => {
    const {
      request: {
        id,
        context,
        context: {
          contactedSolved,
        },
      },
    } = this.state;
    const updateContext = { ...context, contactedSolved: !contactedSolved };
    api
      .patch(`/quotes/${id}`, { updateContext })
      .then(() => this.fetchRequest())
      .catch(interceptPostError);
  };

  handleShippingBackTrackingNumber = (values: TrackingBackNumber) => {
    this.setState({ isAddingTrackingBackNumber: true });
    const {
      request: {
        id,
        context,
      },
    } = this.state;
    const { trackingBackNumber } = values;
    const updateContext = { ...context, trackingBackNumber };
    api
      .patch(`/quotes/${id}`, { updateContext })
      .then(() => {
        this.setState({ isVisibleReceivedShippingBack: false });
        this.fetchRequest();
      })
      .catch(interceptPostError);
  };
}

const CardTitle = styled.span`
  ${text['title-20']}
  color: #4F6470;
  margin-top: 20px;
  margin-bottom: 20px;
`;
