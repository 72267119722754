import { css } from 'styled-components';

interface MarginProps {
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
}

export const marginStyle = css<MarginProps>`
    margin-top: ${props => (props?.marginTop ? `${props.marginTop}px` : '')};
    margin-left: ${props => (props?.marginLeft ? `${props.marginLeft}px` : '')};
    margin-bottom: ${props => (props?.marginBottom ? `${props.marginBottom}px` : '')};
    margin-right: ${props => (props?.marginRight ? `${props.marginRight}px` : '')};
`;
