import { Component } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import {
  TransactionTypeChooser, MetalTypeChooser, TypeChooser,
} from '../Choosers';
import { Input } from '../../common';
import FormElement from '../Choosers/FormElement';
import { RedirectToDescriptionSection } from '../RedirectTo';
import { actionTypes, quoteStore } from '../../../store';
import { data } from '../../../constants';

const { ControlledFormTextArea, CurrencyInputNumber } = Input;

type Props = { subCategory: string } & RouteComponentProps & WithCategoriesProps;

type State = {
  transactionType?: TransactionType;
  groupType?: TransactionType;
  originalPrice: number;
  properType: string;
  metalType: string;
  jewelryDescription: string;
};

class OtherJewelryForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      transactionType: undefined,
      originalPrice: 0,
      properType: '',
      metalType: '',
      jewelryDescription: '',
    };
  }

  componentDidMount() {
    this.handleValidPackageType();
  }

  render() {
    const {
      metalType, transactionType, originalPrice, groupType,
    } = this.state;

    const store = quoteStore.getState();

    return (
      <>
        <Container className="container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TransactionTypeChooser
                selectedType={groupType || transactionType}
                quoteArray={store.quotes}
                onTransactionTypeChange={this.handleTransactionTypeChange}
              />
              <MetalTypeChooser metal={metalType} onMetalChange={this.handleMetalTypeChange} />
              {this.handleMetalOptions()}
              <FormElementContainer>
                <FormElement
                  className="other-jewelry-original-price-element"
                  title="What is the original price of the item? (optional)"
                >
                  <CurrencyInputNumber value={originalPrice} onChange={e => this.handleOriginalPriceChange(e)} />
                </FormElement>
              </FormElementContainer>
            </Col>
          </Row>
        </Container>
        <RedirectToDescriptionSection
          handleValidateForm={this.handleValidateForm}
          onClick={this.handleFinish}
          category="jewelry/other"
        />
      </>
    );
  }

  handleValidPackageType = () => {
    const { quotes } = quoteStore.getState();
    if (quotes.length === 0)
      return;
    const type = quotes[0].generalInfo.transactionType;
    this.setState({ groupType: type, transactionType: type });
  };

  handleValidateForm = (): boolean => {
    const {
      transactionType, metalType, properType, jewelryDescription,
    } = this.state;

    if (!transactionType) {
      message.warn('Transaction type not selected');
      return false;
    }

    if (!metalType) {
      message.warn('Metal type not selected');
      return false;
    }

    if (!properType && metalType !== "I Don't Know" && metalType !== 'sterling' && metalType !== 'other') {
      message.warn('Proper type not selected');
      return false;
    }

    if (!jewelryDescription && metalType === 'other') {
      message.warn('Please describe type of metal');
      return false;
    }

    return true;
  };

  handleFinish = () => {
    if (!this.handleValidateForm()) return;

    const {
      jewelryDescription, metalType, originalPrice, properType, transactionType, groupType,
    } = this.state;
    const { categories } = this.props;

    switch (metalType) {
      case 'gold':
      case 'platinium':
        return quoteStore.dispatch({
          type: actionTypes.ADD_GENERAL_INFO,
          generalInfo: {
            categories, metalType, originalPrice, properType, transactionType: groupType || transactionType,
          },
        });
      case 'sterling':
        return quoteStore.dispatch({
          type: actionTypes.ADD_GENERAL_INFO,
          generalInfo: {
            categories, metalType, originalPrice, transactionType: groupType || transactionType,
          },
        });
      case 'other':
        return quoteStore.dispatch({
          type: actionTypes.ADD_GENERAL_INFO,
          generalInfo: {
            categories, jewelryDescription, metalType, originalPrice, transactionType: groupType || transactionType,
          },
        });

      default:
        return quoteStore.dispatch({
          type: actionTypes.ADD_GENERAL_INFO,
          generalInfo: {
            categories, metalType, originalPrice, transactionType: groupType || transactionType,
          },
        });
    }
  };

  handleMetalOptions = () => {
    const {
      metalType, properType, jewelryDescription,
    } = this.state;

    switch (metalType) {
      case 'gold':
        return (
          <TypeChooser
            data={data.METAL_PROPER_TYPES}
            type={properType}
            onTypeChange={this.handleProperTypeChange}
          />
        );
      case 'sterling':
        return <></>;
      case 'platinium':
        return (
          <TypeChooser data={['90%', '95%']} type={properType} onTypeChange={this.handleProperTypeChange} />
        );
      case 'other':
        return (
          <FormElement className="describe-jewelry" title="Please describe type of metal">
            <ControlledFormTextArea
              rows={1}
              maxLength={30}
              placeholder="Cooper"
              value={jewelryDescription}
              onChange={this.handleDescribeJewelry}
            />
          </FormElement>
        );
      default:
    }
  };

  handleOriginalPriceChange = (e: number) => this.setState({ originalPrice: e });

  handleMetalTypeChange = (e: string) => this.setState({ metalType: e });

  handleProperTypeChange = (e: string) => this.setState({ properType: e });

  handleDescribeJewelry = (e: string) => this.setState({ jewelryDescription: e });

  handleTransactionTypeChange = (transactionType: TransactionType) => {
    this.setState(prevState => {
      if (prevState.transactionType === transactionType) {
        return { transactionType: undefined };
      }
      return {
        transactionType,
      };
    });
  };
}
const Container = styled.div`
    background-color: #ffffff;
    .currency-input {
        width: 100%;
        margin-top: 20px;
    }

    .form-element {
        width: 100%;
    }
    .ant-input {
        width: 100%;
    }
    .flatware-original-price-element {
        margin-bottom: 180px;
    }

    .describe-jewelry {
        @media only screen and (min-width: 600px) {
            max-width: 462px !important;
        }

        @media only screen and (max-width: 600px) {

        }
    }
`;

const FormElementContainer = styled.div`
    width: max-content;
    margin-bottom: 180px;
`;

export default OtherJewelryForm;
