import styled from 'styled-components';
import { colors } from '../../constants';

type ContentProps = {
  children?: JSX.Element | JSX.Element[] | string;
};

const Content = ({ children }: ContentProps) => <Container className="content">{children}</Container>;

const Container = styled.div`
    display: flex;
    background-color: ${colors.background.main};
    flex-direction: row;
    width: 100;
    flex: 1;
`;

export default Content;
