import styled from 'styled-components';
import { Layout } from 'antd';
import { colors, text } from '../../constants';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
    box-sizing: border-box;
    padding-top: 80px;
    background-color: white;
    height: 110vh;
    position: fixed;
    top: 0;
    left: 0;
    // z-index: 100000000;

    .ant-menu {
        min-height: 100%;
        .menu-item-title,
        .ant-menu-submenu-title {
            &:active {
                background-color: rgba(82, 107, 124, 0.05) !important;
            }
            cursor: pointer !important;
            padding-left: 40px !important;
            height: 56px;
        }

        .ant-menu-item-only-child {
            padding-left: 82px !important;
            margin: 0px !important;
        }

        .ant-menu-item,
        .ant-menu-submenu .ant-menu-submenu-title {
            margin: 0;
            &:disabled {
                cursor: pointer !important;
            }
            &::after {
                border-right: 4px solid ${colors.text.light} !important;
            }

            p {
                margin: 0;
            }

            img,
            svg {
                margin-right: 16px !important;
                path {
                    fill: ${colors.text.light};
                }
            }

            display: flex;
            align-items: center;
            span {
                ${text['title-20']};
                a {
                    ${text['title-20']};
                }
            }
        }

        .ant-menu-item  {
            a {
                ${text['menu-item']};
                color: ${colors.text.light};
            }
        }
    }
    .ant-menu-submenu-arrow {
        display: none;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        font-weight: 500;
    }

    .ant-menu-item a:hover {
        text-decoration: none;
    }

    .ant-menu-item-selected {
        background-color: rgba(82, 107, 124, 0.05) !important;

        span,
        svg,
        p {
            color: ${colors.text.selected} !important;
            path {
                fill: ${colors.text.selected} !important;
            }
        }
    }

    .ant-menu-item-selected:hover {
    }

    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
        /* border-right: none !important; */
    }

    .ant-menu-item-only-child:hover {
        border-left: 4px solid #707f89 !important;
        transform: scaleX(1) !important;
    }

    .ant-menu-item-selected.ant-menu-item-only-child {
        border-left: 4px solid #707f89 !important;
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        border-right: none !important;
    }

    .ant-menu-item a, span {
        font-size: 16px !important;
        font-family: Poppins !important;
    }

    .ant-menu-item-only-child a {
        font-size: 14px !important;
        font-family: Poppins !important;
    }
`;
