import { Link } from 'react-router-dom';
import { Card, Row, Col } from 'antd';
import styled from 'styled-components';
import { SecondaryButton } from '../common/Button';
import { colors, text } from '../../constants';
import itemCover from '../../assets/images/any-item.svg';

const ItemQuoteCard = () => (
  <StyledCard title="Get a Quote for Any Item">
    <Row>
      <Col style={{ padding: '3.5px' }} span={15} md={{ span: 14 }}>
        <img alt="item-cover" src={itemCover} />
      </Col>
      <Col span={9} md={{ span: 10 }}>
        <ActionContainer>
          <QuoteButton>
            <Link to="/categories">
              <SecondaryButton>Item Quote</SecondaryButton>
            </Link>
          </QuoteButton>
        </ActionContainer>
      </Col>
    </Row>
  </StyledCard>
);

const StyledCard = styled(Card)`
    .ant-card-head {
        border: none;
        .ant-card-head-title {
            ${text.title}
            max-width: 342px;
            white-space: normal !important;
            color: ${colors.text.white};
        }
    }
    z-index: 1;

    @media only screen and(min-width: 600px) {
        width: 462px;
    }
    height: 100%;
    background-image: ${colors.background.widget};
`;

const QuoteButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`;

export default ItemQuoteCard;
