import { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { TransactionTypeChooser, ConditionChooser } from '../Choosers';
import { LoginModal, RegisterModal } from '../../Modals';
import { Switch, Input } from '../../common';
import { RedirectToDescriptionSection } from '../RedirectTo';
import FormElement from '../Choosers/FormElement';

import { actionTypes, quoteStore } from '../../../store';

const { CurrencyInputNumber } = Input;

type Props = { subCategory: string } & RouteComponentProps & WithCategoriesProps;

type State = {
  transactionType?: TransactionType;
  groupType?: TransactionType;
  condition: 'brand-new' | 'like-new' | 'used' | 'worn';
  hasBoxAndPapers: boolean;
  originalPrice: number;
  isLoginModalVisible: boolean;
  isRegisterModalVisible: boolean;
};

class WatchForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      transactionType: undefined,
      condition: 'brand-new',
      originalPrice: 0,
      isRegisterModalVisible: false,
      isLoginModalVisible: false,
      hasBoxAndPapers: true,
    };
  }

  componentDidMount() {
    this.handleValidPackageType();
  }

  render() {
    const {
      transactionType,
      isLoginModalVisible,
      isRegisterModalVisible,
      hasBoxAndPapers,
      condition,
      originalPrice,
      groupType,
    } = this.state;

    const store = quoteStore.getState();

    return (
      <>
        <Container className="container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TransactionTypeChooser
                selectedType={groupType || transactionType}
                quoteArray={store.quotes}
                onTransactionTypeChange={this.handleTransactionTypeChange}
              />
              <Switch
                onChange={this.handleBoxAndPapersChange}
                title="Do you have both a box and papers?"
                values={['Yes', 'No']}
                value={hasBoxAndPapers}
              />
              <FormElement title="What is the original price of the item? (optional)">
                <CurrencyInputNumber value={originalPrice} onChange={this.handleChangeOriginalPrice} />
              </FormElement>
              <ConditionChooser condition={condition} onConditionChange={this.handleConditionChange} />
            </Col>
          </Row>
        </Container>
        <RedirectToDescriptionSection
          handleValidateForm={this.handleValidateForm}
          onClick={this.handleFinish}
          category="watch"
          subcategory={this.props.subCategoriesString}
        />
        {
          isLoginModalVisible && (
            <LoginModal
              handleLoginModal={this.handleLoginModalVisibilityChange}
              handleRegisterModal={this.handleRegisterModalVisibilityChange}
              isVisible={isLoginModalVisible}
              onFinish={() => { }}
            />
          )
        }
        {
          isRegisterModalVisible && (
            <RegisterModal
              handleLoginModal={this.handleLoginModalVisibilityChange}
              handleRegisterModal={this.handleRegisterModalVisibilityChange}
              isVisible={isRegisterModalVisible}
            />
          )
        }
      </>
    );
  }

  handleValidPackageType = () => {
    const { quotes } = quoteStore.getState();
    if (quotes.length === 0)
      return;
    const type = quotes[0].generalInfo.transactionType;
    this.setState({ groupType: type, transactionType: type });
  };

  handleValidateForm = (): boolean => {
    const {
      transactionType, condition,
    } = this.state;

    if (!transactionType) {
      message.warn('Transaction type not selected');
      return false;
    }

    if (!condition) {
      message.warn('Condition not selected');
      return false;
    }
    return true;
  };

  handleFinish = () => {
    if (!this.handleValidateForm()) return;

    const {
      condition, hasBoxAndPapers, originalPrice, transactionType, groupType,
    } = this.state;
    const { categories } = this.props;
    quoteStore.dispatch({
      type: actionTypes.ADD_GENERAL_INFO,
      generalInfo: {
        categories, condition, hasBoxAndPapers, originalPrice, transactionType: groupType || transactionType,
      },
    });
  };

  handleTransactionTypeChange = (transactionType: TransactionType) => {
    this.setState(prevState => {
      if (prevState.transactionType === transactionType) {
        return { transactionType: undefined };
      }
      return {
        transactionType,
      };
    });
  };

  handleConditionChange = (e: BoxTypes) => this.setState({ condition: e });

  handleChangeOriginalPrice = (value: number) => this.setState({ originalPrice: value });

  handleRegisterModalVisibilityChange = (isVisible: boolean) => this.setState({ isRegisterModalVisible: isVisible });

  handleLoginModalVisibilityChange = (isVisible: boolean) => this.setState({ isLoginModalVisible: isVisible });

  handleBoxAndPapersChange = (value: boolean) => this.setState({ hasBoxAndPapers: value });
}

const Container = styled.div`
    background-color: #ffffff;
    .currency-input {
        width: 29%;
        margin-top: 20px;
    }
`;

export default WatchForm;
