import styled from 'styled-components';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const Layout = ({ children }: Props) => (
  <Container>
    {children}
  </Container>
);

const Container = styled.div`
    display: flex;
    min-height: 100vh;
    overflow: hidden;

    > div {
      padding-top: 88px;
    }
`;

export default Layout;
