/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
import { createRef, Component } from 'react';
import {
  Col, Carousel,
} from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { CarouselRef } from 'antd/es/carousel';
import { Input, Button as SButton } from '../common';
import { userStore } from '../../store';
import { api } from '../../api';
import placeholder from '../../assets/images/placeholder.jpg';
import DetailsSection from '../Notifications/components/DetailsSection';

type Props = {
  isVisible: boolean;
  handleVisibileChange?: (state: boolean) => void;
  onClose?: () => void;
  onConfirm?: () => void;
  onNextItem?: () => void;
  quote: Quote;
  fetchRequest: () => void;
} & RouteComponentProps;

type State = {
  comment: string
};

class RequestDetailsModal extends Component<Props, State> {
  private slider: React.RefObject<CarouselRef>;

  constructor(props: Props) {
    super(props);

    this.state = {
      comment: '',
    };

    this.slider = createRef();
  }

  componentDidMount() {
    this.setState({ comment: this.props.quote.comment });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.quote.id !== this.props.quote.id || prevProps.quote.comment !== this.props.quote.comment) {
      this.setState({ comment: this.props.quote.comment });
    }
  }

  render() {
    const user = userStore.getState();
    const { comment } = this.state;

    const {
      isVisible, handleVisibileChange, quote: {
        id, context: {
          submission: {
            attachments,
          },
        }, rejectedFromGroup,
      },
    } = this.props;

    return (
      id && (
        <div className={`popup ${isVisible && 'popup--shown'}`}>
          <div className="popup__wrap item-details">
            <div className="item-details__header">
              <div className="item-details__title">Item Details</div>
              <div className="popup__close item-details__close" onClick={() => handleVisibileChange && handleVisibileChange(false)}>
              </div>
            </div>
            <div className="item-details__body">
              <div className="item-details__left">
                <div className="item-details-gallery">
                  <StyledDiv>
                    <Col sm={{ span: 24 }} md={{ span: 12 }} className="carousel-col">
                      <Carousel ref={this.slider} className="summary-carousel">
                        {attachments?.map(i => (
                          <div>
                            <h3>
                              <img alt="summary" src={i.url || ''} />
                            </h3>
                          </div>
                        ))}
                        {attachments?.length === 0 && <img src={placeholder} alt="" />}
                      </Carousel>
                    </Col>
                  </StyledDiv>
                  <div className="item-details-gallery__pagination slider-pagination"></div>
                  <div className="slider-controls item-details-gallery__controls" onClick={() => this.slider.current?.prev()}>
                    <div className="slider-controls__unit slider-controls__unit--prev">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.65686 18L15.3137 12.3431L9.65686 6.68629" strokeWidth="2"></path>
                      </svg>
                    </div>
                    <div className="slider-controls__unit slider-controls__unit--next" onClick={() => this.slider.current?.next()}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.65686 18L15.3137 12.3431L9.65686 6.68629" strokeWidth="2"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                {rejectedFromGroup && (
                  <div className="status status--rejected item-details__status">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 14.6666C5.30237 14.6682 2.86966 13.0439 1.83711 10.5517C0.804556 8.05946 1.37569 5.19063 3.284 3.28391C4.96886 1.59904 7.42461 0.941026 9.72617 1.55773C12.0277 2.17443 13.8255 3.97216 14.4422 6.27373C15.0589 8.5753 14.4009 11.031 12.716 12.7159C11.4676 13.9699 9.76944 14.6723 8 14.6666ZM2.66666 8.11457C2.69821 11.0487 5.09407 13.4063 8.02836 13.3906C10.9626 13.3747 13.333 10.9916 13.333 8.05724C13.333 5.12291 10.9626 2.73975 8.02836 2.7239C5.09407 2.70821 2.69821 5.06575 2.66666 7.99991V8.11457ZM6.27266 10.6666L5.33333 9.72657L7.06 7.99991L5.33333 6.27324L6.27333 5.33324L8 7.05991L9.72666 5.33324L10.6667 6.27324L8.94 7.99991L10.6667 9.72657L9.72733 10.6666L8 8.93991L6.27333 10.6666H6.27266Z" />
                    </svg>
                    <span>Excluded Item</span>
                  </div>
                )}
                <div className="item-details-comment">
                  <div className="item-details-comment__title">Here is our comment about this item:</div>
                  <div className="item-details-comment__text">
                    {user.role === 'admin'
                      ? (
                        <>
                          <Input.ControlledFormTextArea
                            value={comment}
                            onChange={e => this.handleCommentChange(e)}
                            maxLength={60}
                            rows={1}
                          />
                          {this.displayFooter()}
                        </>
                      )
                      : <p>{comment}</p>}
                  </div>
                </div>
              </div>
              <DetailsSection quote={this.props.quote} />
            </div>
          </div>
        </div>
      )
    );
  }

  handleCommentChange = (comment: string) => this.setState({ comment });

  handleSaveComment = () => {
    const { quote: { id }, fetchRequest, handleVisibileChange } = this.props;
    const { comment } = this.state;
    api.patch(`/quotes/comment/${id}`, { comment }).then(() => {
      fetchRequest();
      if (handleVisibileChange)
        handleVisibileChange(false);
    });
  };

  displayFooter = () => (
    <StyledFooter>
      <div>
        <SButton.SecondaryButton onClick={() => this.handleSaveComment()}>Submit</SButton.SecondaryButton>
      </div>
    </StyledFooter>
  );

  displayProperty = (value: any) => {
    if (!value) {
      return '';
    }
    if (typeof (value) === 'boolean') {
      return value.toString();
    }
    if (Array.isArray(value)) {
      return value.join(' ');
    }
    return value;
  };
}

const StyledFooter = styled.div`
          display: flex;
          flex-direction: row;
          padding: 20px 0;
          justify-content: flex-end;
    > div {
            display: flex;
    }
          `;

const StyledDiv = styled.div`
          .summary-carousel {
            max-width: 496px;
            min-width: 496px;
          h3 {
            color: #fff;
          position: relative;
          line-height: 160px;
          text-align: center;
          display: flex;
          max-width: 496px;
          button {
            position: absolute;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          border: none;
          outline: none;
          align-items: center;
          top: 50%;
          background: rgba(199, 193, 193, 0.2);
          backdrop-filter: blur(10px);
            }
          .previous-arrow {
            margin-left: 5px;
            }
          .next-arrow {
            margin-right: 5px;
          right: 0;
            }
          img {
            width: 100%;
          object-fit: contain;
            }
        }
          `;

export default withRouter(RequestDetailsModal);
