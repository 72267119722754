/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import EMAIL_NOTIFICATIONS_STAGES from '../../../../constants/emailNotificationsStages';

const {
  AV_6A_ACCEPT,
  AV_6_REJECT,
  AV_6A_COUNTER,
  AV_6E_COUNTER,
  AV_6E_ACCEPT_AGREEMENT,
} = EMAIL_NOTIFICATIONS_STAGES;

type Props = {
  status?: string;
  modeQuote: string;
  quoteValue: number;
  actualQuoteValue: number;
  previousAdminOffer?: number;
  onChangeValue: (e: any) => void;
  onFinalOffer: (e: any) => void;
  onSubmit: (typeNotification: string, emailNotification: string) => void;
};

const AdminItemReceived = ({
  status, modeQuote, quoteValue, actualQuoteValue, previousAdminOffer, onChangeValue, onFinalOffer, onSubmit,
}: Props) => {
  const acceptStatus = status === 'OQ - countered' ? AV_6E_ACCEPT_AGREEMENT : AV_6A_ACCEPT;
  const counterStatus = status === 'OQ - countered' ? AV_6E_COUNTER : AV_6A_COUNTER;

  return (
    <>
      <div className="dashboard-columns__right">
        <div className="dashboard-infocard">
          <div className="popup__header">
            <div className="popup__title">Review Item(s)</div>
            <div className="popup__text">
              Previous E&B offer:
              $
              {previousAdminOffer?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div className="popup__content">
            <div className="popup__price popup__price--small">
              $
              {quoteValue?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <form className="popup-counteroffer dashboard-infocard__counteroffer">
              <div className="form-input popup-counteroffer__input">
                <input type="number" placeholder="$0" value={actualQuoteValue} onChange={onChangeValue} />
              </div>
              <a className="popup-counteroffer__submit btn btn--dark popup__btn" onClick={() => onSubmit('ACCEPT', counterStatus)}>
                Send a Counter Offer
              </a>
            </form>
            <label className="checkbox checkbox--small checkbox--align-left">
              <input type="checkbox" name="checkbox" onChange={onFinalOffer} />
              <span>
                Final
                offer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shipped
                with the label
              </span>
            </label>
          </div>
          <div className="popup__footer">
            <div className="popup__buttons popup__buttons--wide">
              {
                modeQuote === 'SELL'
                  ? (
                    <a className="popup__btn btn btn--dark" onClick={() => onSubmit('ACCEPT_OFFER', acceptStatus)}>
                      Accept Sale Offer
                    </a>
                  )
                  : (
                    <a className="popup__btn btn btn--dark" onClick={() => onSubmit('ACCEPT_OFFER', acceptStatus)}>
                      Generate Official Agreement
                    </a>
                  )
              }
              <a className="popup__btn btn btn--red" onClick={() => onSubmit('REJECT_ITEMS', AV_6_REJECT)}>
                Reject item(s)
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminItemReceived;
