import { useEffect } from 'react';
import { runMain } from '../../assets/js/main';

type Props = {
  comments: CommentType[]
};

const Comments = ({ comments }: Props) => {
  useEffect(() => {
    runMain();
  }, []);
  return (
    <div className="section section-comments">
      <div className="container">
        <div className="mobile-container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h2 style={{ color: '#4f6470' }} className="section__title section__title--sm">
                A &#160;
                <span className="gradient-color">
                  few words
                  <br />
                </span>
                from our clients
              </h2>
            </div>
          </div>
          <div className="row section-comments__content no-gutters">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="quote-square section-comments__quote-square">
                <div className="quote-square__body">
                  <img className="quote-square__icon" alt="quotes" src="images/sprites/main.stack.svg#image-qq" width="160" height="160" />
                  <picture>
                    <source
                      type="image/webp"
                      srcSet="images/humans/human-circle-1.webp, images/humans/human-circle-1@2x.webp 2x"
                    />
                    <img
                      className="quote-square__image quote-square__image--first"
                      alt="man"
                      src="images/humans/human-circle-1.jpg"
                      srcSet="images/humans/human-circle-1.jpg, images/humans/human-circle-1@2x.jpg 2x"
                    />
                  </picture>
                  <picture>
                    <source
                      type="image/webp"
                      srcSet="images/humans/human-circle-2.webp, images/humans/human-circle-2@2x.webp 2x"
                    />
                    <img
                      className="quote-square__image quote-square__image--second"
                      alt="man"
                      src="images/humans/human-circle-2.jpg"
                      srcSet="images/humans/human-circle-2.jpg, images/humans/human-circle-2@2x.jpg 2x"
                    />
                  </picture>
                  <picture>
                    <source
                      type="image/webp"
                      srcSet="images/humans/human-circle-3.webp, images/humans/human-circle-3@2x.webp 2x"
                    />
                    <img
                      className="quote-square__image quote-square__image--third"
                      alt="man"
                      src="images/humans/human-circle-3.jpg"
                      srcSet="images/humans/human-circle-3.jpg, images/humans/human-circle-3@2x.jpg 2x"
                    />
                  </picture>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1 col-md-8">
              <div className="section-comments__slider comments-slider">
                <div className="splide">
                  <div className="splide__track">
                    <ul className="splide__list">
                      {comments.map(c => <Splide {...c} />)}
                    </ul>
                  </div>
                  <div className="splide__arrows">
                    <button type="button" className="splide__arrow splide__arrow--prev btn btn--outline-secondary">
                      <svg
                        className="splide__arrow-icon"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.3428 6L8.68592 11.6569L14.3428 17.3137"
                          stroke="currentColor"
                          strokeWidth={2}
                        />
                      </svg>
                    </button>
                    <button type="button" className="splide__arrow splide__arrow--next btn btn--outline-secondary">
                      <svg
                        className="splide__arrow-icon"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.65723 18L15.3141 12.3431L9.65723 6.68629"
                          stroke="currentColor"
                          strokeWidth={2}
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

const Splide = ({
  content, fullName, city, rate,
}: CommentType) => {
  useEffect(() => {
    runMain();
  });

  const getStars = () => {
    const stars = [];
    const fullStars = Math.floor(rate);
    const restStars = Math.floor(5 - rate);

    for (let index = 0; index < fullStars; index++) {
      stars.push(
        <span className="rating__star" aria-hidden="true">
          <span className="rating__star-fill" />
        </span>,
      );
    }

    if (fullStars !== rate) {
      stars.push(
        <span className="rating__star" aria-hidden="true">
          <span className="rating__star-fill" style={{ width: '50%' }} />
        </span>,
      );
    }

    for (let index = 0; index < restStars; index++) {
      stars.push(<span className="rating__star" aria-hidden="true" />);
    }

    return stars;
  };

  return (
    <li className="splide__slide">
      <div className="comment">
        <div className="comment__rating rating">
          {
            getStars()
          }
        </div>
        <div className="comment__text">
          <p>
            {content}
          </p>
        </div>
        <p className="comment__author">{`${fullName}, ${city}`}</p>
      </div>
    </li>

  );
};

export default Comments;
