import Jewelry from '../assets/images/jewelry.webp';
import Watches from '../assets/images/watches.webp';
import Coins from '../assets/images/coins.webp';
import Handbags from '../assets/images/handbags.webp';
import Flatware from '../assets/images/flatware.webp';
import Other from '../assets/images/other.webp';
import Diamond from '../assets/images/diamond.webp';
import Designer from '../assets/images/designer.webp';
import Bracelet from '../assets/images/bracelet.webp';
import Earring from '../assets/images/earring.webp';
import Ring from '../assets/images/ring.webp';
import OtherJewelry from '../assets/images/otherJewelry.webp';
import GoldCategory from '../assets/images/goldCategory.webp';
import SilverCategory from '../assets/images/SilverCategory.webp';
import OtherGold from '../assets/images/otherGold.webp';
import Bulion from '../assets/images/gold-ingot.webp';
import { metal } from './colors';

const MANUAL_PAYMENT_OPTIONS = [
  'INTEREST_ONLY', 'INTEREST_10', 'INTEREST_OTHER', 'FULL_AMOUNT',
];

const BOX_SIZE = [
  {
    size: 'FEDEX_SMALL_BOX',
    visualDescription: 'Small box: 12"/4"/2"',
    values: {
      weight: 1,
      height: 2,
      width: 4,
      length: 12,
    },
  },
  {
    size: 'FEDEX_MEDIUM_BOX',
    visualDescription: 'Medium box: 16"/8"/4"',
    values: {
      weight: 2,
      height: 4,
      width: 8,
      length: 16,
    },
  },
  {
    size: 'FEDEX_LARGE_BOX',
    visualDescription: 'Large box: 20"/12"/10"',
    values: {
      weight: 3,
      height: 10,
      width: 12,
      length: 20,
    },
  },
];

const CATEGORIES: CategoryTree<string> = {
  name: 'categories',
  treeLevel: 0,
  children: [
    {
      name: 'jewelry',
      treeLevel: 1,
      imgCover: Jewelry,
      children: [
        {
          name: 'diamond',
          imgCover: Diamond,
          treeLevel: 2,
          children: [
            { name: 'bracelet', treeLevel: 3, imgCover: Bracelet },
            { name: 'earring', treeLevel: 3, imgCover: Earring },
            { name: 'ring', treeLevel: 3, imgCover: Ring },
            { name: 'other', treeLevel: 3, imgCover: OtherJewelry },
          ],
        },
        { name: 'other', treeLevel: 2, imgCover: OtherJewelry },
        { name: 'designer', treeLevel: 2, imgCover: Designer },
      ],
    },
    { name: 'watch', treeLevel: 1, imgCover: Watches },
    {
      name: 'coins',
      treeLevel: 1,
      imgCover: Coins,
      children: [
        { name: 'gold', treeLevel: 2, imgCover: GoldCategory },
        { name: 'silver', treeLevel: 2, imgCover: SilverCategory },
        { name: 'other', treeLevel: 2, imgCover: OtherGold },
        { name: 'other-coins', treeLevel: 2, imgCover: OtherGold },
      ],
    },
    { name: 'handbags', treeLevel: 1, imgCover: Handbags },
    {
      name: 'flatware&serving-ware', treeLevel: 1, imgCover: Flatware, title: 'Flatware / Serveware',
    },
    { name: 'other', treeLevel: 1, imgCover: Other },
    { name: 'metal-gold', treeLevel: 1, imgCover: Bulion },
    { name: 'metal-silver', treeLevel: 1, imgCover: Bulion },
    { name: 'metal-platinium', treeLevel: 1, imgCover: Bulion },
  ],
};

const PACKAGE_TAGS = ['Tag', 'Dust Bag', 'Warrant Card', 'Box', 'All'];

const DIAMOND_SIZES: DiamondSize[] = [
  {
    id: 0,
    isDiamondCard: true,
    title: '< .50ct',
  },
  {
    id: 1,
    isDiamondCard: true,
    title: '.50 - .74ct',
  },
  {
    id: 2,
    isDiamondCard: true,
    title: '.75 - .99ct',
  },
  {
    id: 3,
    isDiamondCard: true,
    title: '1.00 - 1.49ct',
  },
  {
    id: 4,
    isDiamondCard: true,
    title: '1.50 - 1.99ct',
  },

  {
    id: 5,
    isDiamondCard: true,
    title: '2.00 - 2.99ct',
  },
  {
    id: 6,
    isDiamondCard: true,
    title: '3.00 - 4.99ct',
  },
  {
    id: 7,
    isDiamondCard: true,
    title: '> 5.00ct',
  },
  {
    id: 8,
    isDiamondCard: false,
    title: "I Don't Know",
  },
];

const ORIGINAL_PACKING = ['CC', 'C', 'D (GA)', 'D (CO)', 'O', 'P', 'S', 'W'];

const METAL_NAMES = [
  { name: 'gold', hasCover: true, color: metal.gold },
  { name: 'sterling', hasCover: true, color: metal.sterling },
  { name: 'platinium', hasCover: true, color: metal.platinium },
  { name: 'other', hasCover: true, color: metal.other },
  { name: "I Don't Know", hasCover: false, color: undefined },
];

const METAL_PROPER_TYPES = ['10k', '14k', '18k', '22k', "I Don't Know"];

const STEPS = [
  { title: 'Select Category', url: 'categories' },
  { title: 'General Information', url: 'general-information' },
  { title: 'Description', url: 'description' },
  { title: 'Attachments', url: 'attachments' },
];

const QUOTE_DEFAULT: Quote = {
  context: {
    boxIsNotArrived: false,
    isAdminAction: false,
    isDelivered: false,
    isFinal: false,
    isCounterOffer: false,
    isMetal: false,
    mode: 'PAWN',
    quoteValue: 0,
    status: '',
    submission: {
      generalInfo: {},
    },
    isAdminRejected: false,
    isAdminAccepted: false,
    stateStepper: 1,
    previousAdminOffer: 0,
  },
  history: [],
  id: '',
  name: '',
  quoteNumber: 0,
  startDate: new Date(),
  state: '',
  updatedAt: new Date(),
  userId: '',
  comment: '',
  rejectedFromGroup: false,
  parentId: null,
  category: '',
  ordinalNumber: 0,
  groupId: '',
  shippingLabels: [],
};

const AGREEMENT_DEFAULT: Agreement = {
  content: '',
  createdAt: new Date(),
  id: '',
  attachments: [],
};

const USER_DEFAULT: User = {
  address: '',
  role: 'admin',
  country: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  password: '',
  bankAccount: '',
  stripeCustomerId: '',
};

const LOAN_DEFUALT: Loan = {
  charges: [],
  payment: undefined,
  id: '',
  interestValue: 0,
  loanAmount: 0,
  monthlyPercentageInterestRate: 0,
  startDate: new Date(),
  yearlyPercentageInterestRate: 0,
  quote: QUOTE_DEFAULT,
  user: USER_DEFAULT,
  subscription: null,
  invoices: [],
  interestRepaymentType: 'OFF',
};

const COMMENT_DEFAULT: CommentType = {
  content: '',
  fullName: '',
  id: '',
  city: '',
  rate: 0,
  createdAt: new Date(),
};

const INTEREST_FORMULA = [
  {
    min: 0, max: 250.0, monthly: 3.548, yearly: 42.576,
  },
  {
    min: 250.01, max: 999.99, monthly: 3.295, yearly: 39.54,
  },
  {
    min: 1000.0, max: Infinity, monthly: 3.05, yearly: 36.6,
  },
];

export {
  BOX_SIZE,
  CATEGORIES,
  PACKAGE_TAGS,
  ORIGINAL_PACKING,
  DIAMOND_SIZES,
  METAL_PROPER_TYPES,
  METAL_NAMES, STEPS,
  QUOTE_DEFAULT,
  USER_DEFAULT,
  LOAN_DEFUALT,
  AGREEMENT_DEFAULT,
  COMMENT_DEFAULT,
  INTEREST_FORMULA,
  MANUAL_PAYMENT_OPTIONS,
};
