/* eslint-disable */
export default function Edit() {
    return (
    <span style={{ padding: '0 7px 0 0' }}>
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.21 9.28933C1.06974 9.28909 0.936031 9.22995 0.841497 9.12633C0.745219 9.02356 0.697379 8.88459 0.709997 8.74433L0.832497 7.39733L6.4915 1.74033L8.26 3.50833L2.6025 9.16483L1.2555 9.28733C1.24 9.28883 1.2245 9.28933 1.21 9.28933ZM8.613 3.15483L6.845 1.38683L7.9055 0.326331C7.99928 0.232442 8.12654 0.179688 8.25925 0.179688C8.39195 0.179688 8.51921 0.232442 8.613 0.326331L9.6735 1.38683C9.76739 1.48061 9.82014 1.60788 9.82014 1.74058C9.82014 1.87329 9.76739 2.00055 9.6735 2.09433L8.6135 3.15433L8.613 3.15483Z" fill="#526B7C"/>
        </svg>
    </span>
    );
  };
