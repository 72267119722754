import styled from 'styled-components';
import { ConditionCard } from '../../Cards';
import FormElement from './FormElement';

type Props = {
  condition: BoxTypes;
  onConditionChange: (value: BoxTypes) => void;
};
const ConditionChooser = ({ condition, onConditionChange }: Props) => {
  const BoxTypesArray: BoxTypes[] = ['brand-new', 'like-new', 'used', 'worn'];
  return (
    <FormElement title="What is the condition of the item?">
      <ConditionChooserContainer className="condition-chooser-container">
        {BoxTypesArray.map(c => (
          <ConditionCard type={c} isSelected={c === condition} onClick={onConditionChange} />
        ))}
      </ConditionChooserContainer>
    </FormElement>
  );
};

const ConditionChooserContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 2%;
`;

export default ConditionChooser;
