import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { colors, text } from '../../constants';
import { SecondaryButton } from '../common/Button';

type Props = {
  category?: string;
  subcategory?: String;
  onClick?: () => void;
  handleValidateForm?: () => boolean;
};

const RedirectToDescriptionSection = ({
  category, subcategory, onClick, handleValidateForm,
}: Props) => {
  const history = useHistory();

  return (
    <SubmitWrapper>
      <SubmitContainer className="container">
        <Row gutter={[8, 16]} justify="start">
          <Col>
            <StyledButton onClick={() => {
              if (handleValidateForm) {
                if (!handleValidateForm()) return false;
              }
              if (onClick) { onClick(); }
              history.push(`/description/${category}${subcategory ? subcategory.replace(/\s/g, '') : ''}`);
            }}
            >
              Next
            </StyledButton>
            <p>3/4. Description</p>
          </Col>
        </Row>
      </SubmitContainer>
    </SubmitWrapper>
  );
};

const RedirectToAttachmentsSection = ({
  category, subcategory, onClick, handleValidateForm,
}: Props) => {
  const history = useHistory();

  return (
    <SubmitWrapper>
      <SubmitContainer className="container">
        <Row gutter={[8, 16]} justify="start">
          <Col>
            <StyledButton onClick={() => {
              if (handleValidateForm) {
                if (!handleValidateForm()) return false;
              }
              if (onClick) { onClick(); }
              history.push(`/attachments/${category}${subcategory ? subcategory.replace(/\s/g, '') : ''}`);
            }}
            >
              Next
            </StyledButton>
            <p>4/4. Attachments</p>
          </Col>
        </Row>
      </SubmitContainer>
    </SubmitWrapper>
  );
};

const SubmitWrapper = styled.div`
    background-color: ${colors.background.main};
`;

const SubmitContainer = styled.div`
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ant-row {
        height: 100%;
        margin: 0 !important;
    }

    .ant-col {
        align-self: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
            padding: 19.5px 30px;
            ${text.text}
            margin-bottom: 0;
            white-space: nowrap;
        }
    }
`;

const StyledButton = styled(SecondaryButton)`
    ${text['buttom-caps-24']}
    padding: 10px 40px;
    height: 60px;
`;

export { RedirectToDescriptionSection, RedirectToAttachmentsSection };
