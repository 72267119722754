import { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import BullionSubmitSection from '../BullionSubmitSection';
import { TransactionTypeChooser, OriginChooser, SizeChooser } from '../Choosers';
import { RegisterModal, MetalOfferModal, LoginModal } from '../../Modals';
import { api } from '../../../api';
import { userStore } from '../../../store';
import { Spinner } from '../../common';

type Props = { subCategory: string } & RouteComponentProps;

type State = {
  transactionType?: TransactionType;
  originType: OriginCountryType;
  originCountry: string;
  sizes: Dictionary<string, number>;
  isOfferModalVisible: boolean;
  offerValue: number;
  isCounterOffer: boolean;
  isLoginModalVisible: boolean;
  isRegisterModalVisible: boolean;
  isLoading: boolean;
  isGettingRateMetalValue: boolean;
};

class BullionForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      transactionType: undefined,
      originType: 'US',
      originCountry: '',
      sizes: {
        1: 0,
        0.5: 0,
        0.25: 0,
        0.1: 0,
        sum: 0,
      },
      isOfferModalVisible: false,
      offerValue: 0,
      isCounterOffer: false,
      isRegisterModalVisible: false,
      isLoginModalVisible: false,
      isLoading: false,
      isGettingRateMetalValue: true,
    };
  }

  componentDidMount() {
    this.verifyParams();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname)
      this.verifyParams();
  }

  render() {
    const {
      transactionType,
      originType,
      originCountry,
      sizes,
      isOfferModalVisible,
      offerValue,
      isLoginModalVisible,
      isRegisterModalVisible,
      isLoading,
      isGettingRateMetalValue,
    } = this.state;

    if (isLoading)
      return <Spinner style={{ marginBottom: '20%' }} />;

    return (
      <>
        <Container className="container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TransactionTypeChooser
                selectedType={transactionType}
                onTransactionTypeChange={this.handleTransactionTypeChange}
              />
              <OriginChooser
                selectedOriginType={originType}
                originCountry={originCountry}
                onOriginTypeChange={this.handleOriginTypeChange}
                onOriginCountryChange={this.handleOriginCountryChange}
              />
              <SizeChooser currentSizes={sizes} handleSumChange={this.handleSumChange} />
            </Col>
          </Row>
        </Container>
        <BullionSubmitSection
          handleSubmit={this.handleSubmit}
          text="You can change bullion category by returning to it"
        />
        <MetalOfferModal
          amount={offerValue}
          isGettingRateMetalValue={isGettingRateMetalValue}
          isVisible={isOfferModalVisible}
          setVisibility={this.handleOfferModalVisibilityChange}
          handleSendOffer={this.handleSendOffer}
        />
        {
          isLoginModalVisible && (
            <LoginModal
              handleLoginModal={this.handleLoginModalVisibilityChange}
              handleRegisterModal={this.handleRegisterModalVisibilityChange}
              isVisible={isLoginModalVisible}
              onFinish={this.handleFinish}
            />
          )
        }
        {
          isRegisterModalVisible && (
            <RegisterModal
              handleLoginModal={this.handleLoginModalVisibilityChange}
              handleRegisterModal={this.handleRegisterModalVisibilityChange}
              isVisible={isRegisterModalVisible}
              onFinish={this.handleFinish}
            />
          )
        }
      </>
    );
  }

  handleTransactionTypeChange = (transactionType: TransactionType) => {
    this.setState(prevState => {
      if (prevState.transactionType === transactionType) {
        return { transactionType: undefined };
      }
      return {
        transactionType,
      };
    });
  };

  verifyParams = () => {
    const { location: { pathname } } = this.props;
    const metals = ['platinium', 'gold', 'silver'];
    const metal = [...pathname.split('/')].pop();

    const isValidMetal = !!metals.find(c => c === metal);
    if (!isValidMetal) {
      message.warn('Choose valid bullion type');
      return this.props.history.push('/');
    }
  };

  handleOriginCountryChange = (originCountry: string) => this.setState({ originCountry });

  handleRegisterModalVisibilityChange = (isVisible: boolean) => this.setState({ isRegisterModalVisible: isVisible });

  handleLoginModalVisibilityChange = (isVisible: boolean) => this.setState({ isLoginModalVisible: isVisible });

  handleOfferModalVisibilityChange = (isVisible: boolean) => this.setState({ isOfferModalVisible: isVisible });

  handleValidateForm = () => {
    const {
      transactionType, originType, originCountry, sizes,
    } = this.state;

    if (!transactionType) {
      message.warn('Transaction type not selected');
      return false;
    }
    if (originType === 'Foreign' && originCountry === '') {
      message.warn('Invalid foreign country');
      return false;
    }

    const sumRecord = Object.entries(sizes).find(([fKey]) => fKey === 'sum');

    if (!sumRecord) {
      message.warn('Incorrect number of ounces');
      return false;
    }

    const sumValue = sumRecord[1];

    if (sumValue <= 0) {
      message.warn('The number of ounces must be greater than zero');
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    if (!this.handleValidateForm()) return;
    this.handleGetMetalRequestPrice();
    this.setState({
      isOfferModalVisible: true,
    });
  };

  handleOriginTypeChange = (originType: OriginCountryType) => {
    if (originType !== 'US') {
      return this.setState({
        originType,
        originCountry: 'England',
      });
    }
    return this.setState({
      originType,
      originCountry: '',
    });
  };

  handleSumChange = (key: string, type: 'INCEREMENT' | 'DECREMENT') => {
    this.setState(prevState => {
      const size = Object.entries(prevState.sizes).find(([fKey]) => key === fKey);
      const sum = Object.entries(prevState.sizes).find(([fKey]) => fKey === 'sum');
      if (!size || !sum) return {};

      const [sizeK, sizeValue]: [string, number] = size;
      const sumValue = sum[1];

      if (!sizeValue && type === 'DECREMENT') return {};

      const coefficient = type === 'INCEREMENT' ? 1 : -1;
      const newValue: Dictionary<string, number> = { [sizeK]: sizeValue + coefficient };
      const newSum: Dictionary<string, number> = { sum: sumValue + coefficient * parseFloat(sizeK) };
      const sizeCopy = { ...prevState.sizes };
      const newSizes = Object.assign(sizeCopy, newValue, newSum);

      return { ...prevState, sizes: newSizes };
    });
  };

  handleGetMetalRequestPrice = async () => {
    const { sizes } = this.state;
    const sum = Object.entries(sizes).find(([fKey]) => fKey === 'sum');
    if (sum) {
      const value = sum[1];
      const { subCategory } = this.props;
      const { data: { offer } } = await api.post('metal-requests/get-rate', {
        metal: subCategory,
        amount: value,
      });
      this.setState({ offerValue: offer, isGettingRateMetalValue: false });
    }
  };

  handleSendOffer = (value: number, isCounterOffer?: boolean) => {
    const user = userStore.getState();

    if (user.id) {
      return this.setState({
        offerValue: value,
        isCounterOffer: !!isCounterOffer,
        isOfferModalVisible: false,
      }, () => this.handleFinish());
    }

    return this.setState({
      offerValue: value,
      isCounterOffer: !!isCounterOffer,
      isOfferModalVisible: false,
      isLoginModalVisible: true,
    });
  };

  handleFinish = () => {
    this.setState({ isLoading: true });
    const {
      isCounterOffer, offerValue, sizes, originCountry, originType, transactionType,
    } = this.state;
    const { subCategory } = this.props;

    this.props.history.push({
      pathname: '/payment-information',
      state: {
        generalInfo: {
          metal: subCategory, isCounterOffer, offerValue, sizes, originCountry, originType, transactionType,
        },
        type: 'bullion',
      },
    });
  };
}

const Container = styled.div`
    background-color: #ffffff;
    .ant.col {
      > div {
        max-width: 80%;
      }
    }
`;

export default BullionForm;
