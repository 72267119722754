import { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { message } from 'antd';
import { runMain } from '../../assets/js/main';
import { quoteStore } from '../../store';

const QuoteSection = () => {
  const [metalType, setMetalType] = useState('');
  const history = useHistory();
  useEffect(() => {
    runMain();
  }, []);
  const { quotes } = quoteStore.getState();
  return (
    <div className="row justify-content-center hero__append-row">
      <div className="col-md-6 d-flex">
        <div className="box box--fixed-height box--hover box--gold hero__box">
          <div className="box__body text-center">
            <p className="box__title">Get a quote for bullion gold, metal, platinum</p>
            <picture>
              <source type="image/webp" srcSet="images/box/gold.webp, images/box/gold@2x.webp 2x" />
              <img
                className="box__thumbnail"
                alt="gold"
                src="images/box/gold.png"
                srcSet="images/box/gold.png, images/box/gold@2x.png 2x"
              />
            </picture>
            <div className="box__content">
              <form action="." method="POST">
                <div className="custom-select box__select">
                  <select onChange={e => setMetalType(e.target.value)} name="type_metal">
                    <option key="index" value="" disabled selected>
                      Select Metal
                    </option>
                    <option key="gold" value="gold">Gold</option>
                    <option key="silver" value="silver">Silver</option>
                    <option key="platinium" value="platinium">Platinium</option>
                  </select>
                </div>
                <button
                  disabled={metalType === ''}
                  onClick={() => {
                    if (quotes.length > 0)
                      return message.warn('Metal can not be part of a package');
                    return history.push(`/general-information/bullion/${metalType}`);
                  }}
                  type="button"
                  className="btn btn--secondary btn--lg w-100 text-center"
                >
                  Get Metal Quote
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 d-flex">
        <div className="box box--hover box--fixed-height">
          <div className="box__body text-center">
            <p className="box__title">
              Get a quote
              <br />
              for any item
            </p>
            <picture>
              <source type="image/webp" srcSet="images/box/watch.webp, images/box/watch@2x.webp 2x" />
              <img
                className="box__thumbnail box__thumbnail--hover-sm"
                alt="watch"
                src="images/box/watch.png"
                srcSet="images/box/watch.png, images/box/watch@2x.png 2x"
              />
            </picture>
            <div className="box__content">
              <Link className="btn btn--secondary btn--lg w-100 text-center" to="/categories">
                Get Item Quote
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default QuoteSection;
