const HowWeWorks = () => (
  <div className="section section-how-we-works">
    <div className="container">
      <img
        className="section-how-we-works__line section-how-we-works__line--top"
        src="images/line-1-gold.svg"
        alt="line"
        data-aos="line-clip-patch"
        data-aos-delay="000"
        data-aos-duration={1500}
        data-aos-easing="ease-in-out"
        data-aos-once="true"
        data-aos-anchor-placement="center-bottom"
      />
      <img
        className="section-how-we-works__line"
        src="images/line-2-gold.svg"
        alt="line"
        data-aos="line-clip-patch"
        data-aos-delay={500}
        data-aos-duration={1500}
        data-aos-easing="ease-in-out"
        data-aos-once="true"
        data-aos-anchor-placement="center-bottom"
      />
      <div className="mobile-container">
        <div className="section__header section-how-we-works__header">
          <h2 style={{ color: '#4f6470' }} className="section__title">How we work</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <div className="row">
              <div className="col-lg-10">
                <div
                  className="number-box number-box--mb"
                  data-aos="fade-up"
                  data-aos-delay={0}
                  data-aos-duration={800}
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="number-box__body">
                    <div className="number-box__header">
                      <p className="number-box__digit">01.</p>
                      <img
                        className="number-box__icon"
                        alt="Mail"
                        src="images/sprites/main.stack.svg#image-mail-gold"
                      />
                    </div>
                    <p className="number-box__title">Get a Quote</p>
                    <p className="number-box__text">
                      Get in touch with one of our representatives to talk through our process and to get a quote on your item.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="row justify-content-lg-center">
              <div className="col-lg-10">
                <div
                  className="number-box number-box--mb"
                  data-aos="fade-up"
                  data-aos-delay={150}
                  data-aos-duration={800}
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="number-box__body">
                    <div className="number-box__header">
                      <p className="number-box__digit">02.</p>
                      <img
                        className="number-box__icon"
                        alt="Delivery"
                        src="images/sprites/main.stack.svg#image-delivery-gold"
                      />
                    </div>
                    <p className="number-box__title">Ship the Item</p>
                    <p className="number-box__text">
                      Send your item to one of our locations to have it professionally appraised by our team of experts in person.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-delay={300}
            data-aos-duration={800}
            data-aos-easing="ease-in-out"
            data-aos-once="true"
            data-aos-mirror="true"
            data-aos-anchor-placement="center-bottom"
          >
            <div className="row justify-content-lg-end">
              <div className="col-lg-10">
                <div className="number-box number-box--mb">
                  <div className="number-box__body">
                    <div className="number-box__header">
                      <p className="number-box__digit">03.</p>
                      <img
                        className="number-box__icon"
                        alt="Check"
                        src="images/sprites/main.stack.svg#image-check-circle-gold"
                      />
                    </div>
                    <p className="number-box__title">Pass the Quality Review</p>
                    <p className="number-box__text">
                      All of our items undergo a quality review to ensure that your item(s) meet our standard for quality, functionality and/or authenticity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="row">
              <div className="col-lg-10">
                <div
                  className="number-box number-box--mb"
                  data-aos="fade-up"
                  data-aos-delay={0}
                  data-aos-duration={800}
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="number-box__body">
                    <div className="number-box__header">
                      <p className="number-box__digit">04.</p>
                      <img
                        className="number-box__icon"
                        alt="Sign"
                        src="images/sprites/main.stack.svg#image-sign-gold"
                      />
                    </div>
                    <p className="number-box__title">Sign the Agreement</p>
                    <p className="number-box__text">
                      Once you’ve passed our quality review, you’re just
                      one step away from getting paid! We’ll send you a formal
                      agreement detailing our terms and conditions and your final price.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="row justify-content-lg-end">
              <div className="col-lg-10">
                <div
                  className="number-box number-box--mb"
                  data-aos="fade-up"
                  data-aos-delay={150}
                  data-aos-duration={800}
                  data-aos-easing="ease-in-out"
                  data-aos-once="true"
                  data-aos-mirror="true"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="number-box__body">
                    <div className="number-box__header">
                      <p className="number-box__digit">05.</p>
                      <img
                        className="number-box__icon"
                        alt="Paid"
                        src="images/sprites/main.stack.svg#image-paid-gold"
                      />
                    </div>
                    <p className="number-box__title">Get Paid</p>
                    <p className="number-box__text">
                      Once our agreement is finalized, you can get paid!
                      We’ll offer you either a buy price for outright purchases
                      or a loan price with a 90-day term.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HowWeWorks;
