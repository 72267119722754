/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
import { PlaidLink } from 'components/common';
import fonts from '../../../../constants/fonts';

const { mainFont } = fonts.fonts;

type Props = {
  isConnectButtonVisible?: boolean;
  onAuthorize: () => void;
};

const PaymentMainView = ({ isConnectButtonVisible, onAuthorize }: Props) => (
  <div style={{ fontFamily: mainFont }}>
    <div className="dashboard-payment">
      <div className="dashboard-payment__header">
        <div className="dashboard-payment__title">
          <span className="dashboard-payment__title-status">Agreement signed! </span>
          We are ready to pay you via
          <span className="dashboard-payment__title-highlight"> bank transfer</span>
          .
        </div>
        <div className="dashboard-payment__subtitle">Before you get paid you need to authorize interest payment charges.</div>
      </div>
      <div className="dashboard-payment-method">
        <div className="dashboard-payment-method__title">Interest Payment Method: </div>
        <div className="dashboard-payment-method__description">Please add your card details to pay the loan’s interest charges. Your card to be authorized to pay the interest payment charges automatically at the end of the loan term. You will also have the option to pay the loan back manually at anytime.</div>
      </div>
      <div className="dashboard-payment-options">
        <div className="dashboard-payment-option">
          <div className="dashboard-payment-option__header">
            <div className="dashboard-payment-option__title">Bank Account</div>
            <img className="dashboard-payment-option__icon" src="/images/dashboard/payment-option-icon-01.svg" alt="" />
          </div>
          {
            isConnectButtonVisible
              ? <PlaidLink onFinish={onAuthorize} />
              : <a className="dashboard-payment-option__btn btn btn--dark" onClick={onAuthorize}>AUTHORIZE</a>
          }
        </div>
      </div>
    </div>
  </div>
);

export default PaymentMainView;
