import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import {
  Row, Col, message,
} from 'antd';
import { data } from '../constants';
import FormElement from '../components/GeneralInfo/Choosers/FormElement';
import { searchTree } from '../helpers/tree';
import { Upload } from '../components/common';
import {
  LoginModal, RegisterModal, NoAttachmentsModal, NewSummaryModal,
} from '../components/Modals';
import { withCategories } from '../components/withCategories';
import { quoteStore, userStore, actionTypes } from '../store';
import { SecondaryButton, OutlineButton } from '../components/common/Button';

type Props = RouteComponentProps & WithCategoriesProps;

type State = {
  fileList: any[];
  isAlertModalVisible: boolean;
  isSummaryModalVisible: boolean;
  isLoginModalVisible: boolean;
  isRegisterModalVisible: boolean;
  isPaymentInformationVisible: boolean;
  isLoading: boolean;

};

class AttachmentsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fileList: [],
      isAlertModalVisible: false,
      isSummaryModalVisible: false,
      isLoginModalVisible: false,
      isRegisterModalVisible: false,
      isPaymentInformationVisible: false,
      isLoading: false,
    };
  }

  handleRegisterModalVisibilityChange = (isVisible: boolean) => this.setState({ isRegisterModalVisible: isVisible });

  handleLoginModalVisibilityChange = (isVisible: boolean) => this.setState({ isLoginModalVisible: isVisible });

  handleSubmitClick = () => {
    const { fileList } = this.state;
    const { id } = userStore.getState();

    this.beforeSubmit();

    if (!fileList && !id) {
      return this.handleLoginModalVisibilityChange(true);
    }

    return this.handleFinish();
  };

  handleFinish = () => {
    const isSummaryModalVisible = sessionStorage.getItem('isSummaryModalVisible');
    const isAlertModalVisible = sessionStorage.getItem('isAlertModalVisible');

    if (isSummaryModalVisible === 'true' || isAlertModalVisible === 'true') {
      return null;
    }

    sessionStorage.removeItem('isSummaryModalVisible');
    sessionStorage.removeItem('isAlertModalVisible');

    this.setState({ isLoading: true });

    const { fileList } = this.state;

    const {
      generalInfo, description, quotes,
    } = quoteStore.getState();

    const category = this.props.location.pathname.split('/').pop();

    const quotesArray = [...quotes, { generalInfo, description, attachments: fileList }];

    this.props.history.push({
      pathname: '/payment-information',
      state: {
        quote: quotesArray,
        category,
      },
    });
  };

  handleNextItem = () => {
    const { fileList } = this.state;

    this.beforeSubmit();

    this.setState({ isLoading: true });

    sessionStorage.removeItem('isSummaryModalVisible');
    sessionStorage.removeItem('isAlertModalVisible');

    if (fileList && fileList.length > 0)
      quoteStore.dispatch({ type: actionTypes.ADD_ATTACHMENTS, attachments: fileList });
    quoteStore.dispatch({ type: actionTypes.ADD_NEXT_ITEM });

    message.success('Item added');
    this.props.history.push('/categories');
  };

  handleConfirm = () => {
    this.handleSummaryModalVisibleChange(false);

    sessionStorage.setItem('isSummaryModalVisible', 'false');

    const { id } = userStore.getState();

    if (!id) return this.handleLoginModalVisibilityChange(true);
    this.handleFinish();
  };

  render() {
    const store = quoteStore.getState();

    const {
      fileList,
      isAlertModalVisible,
      isSummaryModalVisible,
      isLoginModalVisible,
      isRegisterModalVisible,
    } = this.state;
    return (
      <Container className="container">
        <Content className="content">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <FormElement title="Please provide images of the item (optional)">
                <Upload fileList={fileList} onFileListChange={this.handleFileListChange} />
              </FormElement>
            </Col>
            <Col span={12}>
              <SummaryContainer>
                <SummaryChildrenContainer>
                  {store.generalInfo && (
                    <Summary>
                      <span className="transaction-type">
                        {store.generalInfo.transactionType}
                      </span>
                      <div className="category">
                        {store.generalInfo.categories[store.generalInfo.categories.length - 1]}
                      </div>
                      <div className="item">
                        <span>Size of the largest diamond:</span>
                        &nbsp;
                        {store.generalInfo.diamondSize}
                      </div>
                      <div className="item">
                        <span>Original price:</span>
                        &nbsp;
                        $
                        {store.generalInfo.originalPrice}
                      </div>
                      <div className="item">
                        <p>Additional diamonds information:</p>
                        {store.generalInfo.sizeDescription}
                      </div>
                      <div className="item">
                        <p>Description:</p>
                        {store.description}
                      </div>
                      <SecondaryButton onClick={() => this.handleSubmitClick()}>Get an Offer</SecondaryButton>
                      <OutlineButton onClick={() => this.handleNextItem()}>Save &amp; Add New Item</OutlineButton>
                    </Summary>
                  )}
                </SummaryChildrenContainer>
              </SummaryContainer>
            </Col>
          </Row>
        </Content>
        {/* <SubmitSection
          beforeSubmit={this.beforeSubmit}
          fileList={fileList}
          isSummaryModalVisible={isSummaryModalVisible}
          handleSummaryModalVisibleChange={this.handleSummaryModalVisibleChange}
        /> */}
        <NoAttachmentsModal
          onSubmit={this.handleConfirmSubmittingWithoutPhotos}
          onClose={this.handleCloseAlertModal}
          title="Would you like to upload a photos for this request? Without a photo, the offer will be less."
          isVisible={isAlertModalVisible}
        />
        {
          isLoginModalVisible && (
            <LoginModal
              handleLoginModal={this.handleLoginModalVisibilityChange}
              handleRegisterModal={this.handleRegisterModalVisibilityChange}
              isVisible={isLoginModalVisible}
              onFinish={this.handleFinish}
            />
          )
        }
        {
          isRegisterModalVisible && (
            <RegisterModal
              handleLoginModal={this.handleLoginModalVisibilityChange}
              handleRegisterModal={this.handleRegisterModalVisibilityChange}
              isVisible={isRegisterModalVisible}
              onFinish={this.handleFinish}
            />
          )
        }
        <NewSummaryModal
          fileList={fileList}
          handleVisibileChange={this.handleSummaryModalVisibleChange}
          onConfirm={this.handleConfirm}
          onClose={this.handleNextItem}
          isVisible={!!isSummaryModalVisible}
        />
      </Container>
    );
  }

  handleGetImageCover = () => {
    const { categories } = this.props;

    const mainSubcategory = [...categories].pop();

    if (mainSubcategory === undefined) return '';

    const element = searchTree(data.CATEGORIES, mainSubcategory);

    if (element.imgCover === undefined) return '';

    return element.imgCover;
  };

  handleFileListChange = (fileList: any[]) => this.setState({ fileList });

  beforeSubmit = () => {
    const { fileList } = this.state;
    if (fileList.length === 0) {
      sessionStorage.setItem('isAlertModalVisible', 'true');
      return this.setState({ isAlertModalVisible: true });
    }
    sessionStorage.setItem('isSummaryModalVisible', 'true');
    return this.setState({ isSummaryModalVisible: true });
  };

  handleConfirmSubmittingWithoutPhotos = () => {
    this.handleCloseAlertModal();
    sessionStorage.setItem('isAlertModalVisible', 'false');
    this.setState({ isSummaryModalVisible: true });
  };

  handleSummaryModalVisibleChange = (state: boolean) => this.setState({ isSummaryModalVisible: state });

  handleCloseAlertModal = () => this.setState({ isAlertModalVisible: false, isSummaryModalVisible: false });
}

const Container = styled.div`
  flex: 1;
`;

const Content = styled.div`
  background-color: #ffffff;
  padding-bottom: 190px;
`;

const Summary = styled.div`
  background: #F3F7F9;
  width: 100%;
  padding: 32px;
  font-size: 16px;
  line-height: 24px;

  .item {
    margin: 6px 0;
    color: #4F6470;

    p, span {
      margin: 20px 0 4px 0;
      font-weight: 500;
      color: #707F89;
    }
  }

  .transaction-type {
    padding: 7px 12px;
    background: #0000001A;
    font-size: 11px;
    line-height: 18px;
  }
  .category {
    padding: 20px 0;
    color: #4F6470;
    font-size: 18px;
    line-height: 28px;
    text-transform: capitalize;
  }
`;

const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 60px !important;
    @media only screen and (max-width: 600px){
      max-width: 80% !important;
    }
`;

const SummaryChildrenContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100% !important;

    @media only screen and (min-width: 600px){
      input {
        max-width: 462px !important;
      }
    }
`;

export default withCategories(AttachmentsView);
