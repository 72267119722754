/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import fonts from '../../../constants/fonts';

const { mainFont } = fonts.fonts;

type Props = {
  quoteValue?: number;
};

const AdminPreliminaryOffer = ({
  quoteValue,
}: Props) => (
  <div className="dashboard-columns__right" style={{ fontFamily: mainFont }}>
    <div className="dashboard-infocard">
      <div className="popup__header">
        <div className="popup__title">
          Preliminary offer has been sent
        </div>
      </div>
      <div className="popup__content">
        <div className="popup__price popup__price--small">
          $
          {quoteValue?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="popup__footer">
        <div className="popup__text">Waiting for user decision</div>
      </div>
    </div>
  </div>
);

export default AdminPreliminaryOffer;
