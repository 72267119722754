/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable max-len */
import { Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import RequestLogo from '../assets/images/RequestLogo';

const { SubMenu } = Menu;

export const AdminMenu = () => {
  const location = useLocation();
  return (
    <CustomMenu
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['requests', 'transactions']}
      selectedKeys={[location.pathname]}
      mode="inline"
      className="sidebar-menu"
    >
      <Menu.Item
        key="/profile"
        className="menu-item-title"
        icon={(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C10.488 22.0043 8.9952 21.6622 7.63598 21C7.13853 20.758 6.66191 20.4754 6.21098 20.155L6.07398 20.055C4.8338 19.1396 3.81985 17.9522 3.10998 16.584C2.37571 15.1679 1.99489 13.5952 1.99993 12C1.99993 6.47715 6.47713 2 12 2C17.5228 2 22 6.47715 22 12C22.005 13.5944 21.6245 15.1664 20.891 16.582C20.1821 17.9494 19.1696 19.1364 17.931 20.052C17.4637 20.394 16.9679 20.6951 16.449 20.952L16.369 20.992C15.0089 21.6577 13.5142 22.0026 12 22ZM12 17C10.5015 16.9971 9.12764 17.834 8.44298 19.167C10.6844 20.2772 13.3156 20.2772 15.557 19.167V19.162C14.8715 17.8305 13.4976 16.9954 12 17ZM12 15C14.1661 15.0028 16.1634 16.1701 17.229 18.056L17.244 18.043L17.258 18.031L17.241 18.046L17.231 18.054C19.76 15.8691 20.6643 12.3423 19.4986 9.21011C18.333 6.07788 15.3431 4.00032 12.001 4.00032C8.65889 4.00032 5.66897 6.07788 4.50333 9.21011C3.33769 12.3423 4.24198 15.8691 6.77098 18.054C7.83724 16.169 9.83434 15.0026 12 15ZM12 14C9.79084 14 7.99998 12.2091 7.99998 10C7.99998 7.79086 9.79084 6 12 6C14.2091 6 16 7.79086 16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0608 14 12 14ZM12 8C10.8954 8 9.99998 8.89543 9.99998 10C9.99998 11.1046 10.8954 12 12 12C13.1045 12 14 11.1046 14 10C14 8.89543 13.1045 8 12 8Z" />
          </svg>
        )}
        title="Profile"
      >
        <Link
          to="/profile/info"
        >
          My Profile
        </Link>
      </Menu.Item>
      <SubMenu key="requests" icon={<RequestLogo />} title="Requests">
        <Menu.Item className="submenu-item" key="/requests/all">
          <Link to="/requests/all">All</Link>
        </Menu.Item>
        <Menu.Item
          className="submenu-item"
          key="/requests/admin-action-required"
        >
          <Link to="/requests/admin-action-required">
            Admin Action Required
          </Link>
        </Menu.Item>
        <Menu.Item
          className="submenu-item"
          key="/requests/user-action-required"
        >
          <Link to="/requests/user-action-required">User Action Required</Link>
        </Menu.Item>
        <Menu.Item className="submenu-item" key="/requests/accepted">
          <Link to="/requests/accepted">Accepted</Link>
        </Menu.Item>
        <Menu.Item className="submenu-item" key="/requests/closed">
          <Link to="/requests/closed">Closed</Link>
        </Menu.Item>
        <Menu.Item className="submenu-item" key="/requests/rejected">
          <Link to="/requests/rejected">Rejected</Link>
        </Menu.Item>
      </SubMenu>
      {/* <SubMenu
        key="/transactions"
        icon={<TransactionLogo />}
        title="Transactions"
      >
        <Menu.Item className="submenu-item" key="/default">
          <Link to="/transactions/default">Default</Link>
        </Menu.Item>
        <Menu.Item className="submenu-item" key="/shipped-back">
          <Link to="/transactions/shipped-back">Shipped back</Link>
        </Menu.Item>
      </SubMenu> */}
      <SubMenu key="configration" icon={<SettingOutlined />} title="Configuration">
        {/* <Menu.Item
          key="notification"
          className="menu-item-title"
          title="Notifications"
        >
          <Link to="/notifications">Notifications</Link>
        </Menu.Item> */}
        <Menu.Item
          key="agreement-template"
          className="menu-item-title"
          title="Agreement tempalte"
        >
          <Link to="/configuration/agreement-template">Agreement Templates</Link>
        </Menu.Item>
        <Menu.Item
          key="faq"
          className="menu-item-title"
          title="FAQ"
        >
          <Link to="/profile/faq">FAQ</Link>
        </Menu.Item>
        <Menu.Item
          key="faq"
          className="menu-item-title"
          title="FAQ"
        >
          <Link to="/configuration/store-contact-us">Store and Contact Us</Link>
        </Menu.Item>
        <Menu.Item
          key="comments"
          className="menu-item-title"
          title="Comments"
        >
          <Link to="/configuration/comments">Comments</Link>
        </Menu.Item>
        {' '}
        <Menu.Item
          key="shipment"
          className="menu-item-title"
          title="Shipment"
        >
          <Link to="/configurations/shipment">Shipment</Link>
        </Menu.Item>
      </SubMenu>
    </CustomMenu>
  );
};

export const UserMenu = () => {
  const location = useLocation();
  return (
    <CustomMenu
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['requests', 'transactions']}
      selectedKeys={[location.pathname]}
      mode="inline"
      className="sidebar-menu"
    >
      <Menu.Item
        key="/profile"
        className="menu-item-title"
        icon={(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C10.488 22.0043 8.9952 21.6622 7.63598 21C7.13853 20.758 6.66191 20.4754 6.21098 20.155L6.07398 20.055C4.8338 19.1396 3.81985 17.9522 3.10998 16.584C2.37571 15.1679 1.99489 13.5952 1.99993 12C1.99993 6.47715 6.47713 2 12 2C17.5228 2 22 6.47715 22 12C22.005 13.5944 21.6245 15.1664 20.891 16.582C20.1821 17.9494 19.1696 19.1364 17.931 20.052C17.4637 20.394 16.9679 20.6951 16.449 20.952L16.369 20.992C15.0089 21.6577 13.5142 22.0026 12 22ZM12 17C10.5015 16.9971 9.12764 17.834 8.44298 19.167C10.6844 20.2772 13.3156 20.2772 15.557 19.167V19.162C14.8715 17.8305 13.4976 16.9954 12 17ZM12 15C14.1661 15.0028 16.1634 16.1701 17.229 18.056L17.244 18.043L17.258 18.031L17.241 18.046L17.231 18.054C19.76 15.8691 20.6643 12.3423 19.4986 9.21011C18.333 6.07788 15.3431 4.00032 12.001 4.00032C8.65889 4.00032 5.66897 6.07788 4.50333 9.21011C3.33769 12.3423 4.24198 15.8691 6.77098 18.054C7.83724 16.169 9.83434 15.0026 12 15ZM12 14C9.79084 14 7.99998 12.2091 7.99998 10C7.99998 7.79086 9.79084 6 12 6C14.2091 6 16 7.79086 16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0608 14 12 14ZM12 8C10.8954 8 9.99998 8.89543 9.99998 10C9.99998 11.1046 10.8954 12 12 12C13.1045 12 14 11.1046 14 10C14 8.89543 13.1045 8 12 8Z" />
          </svg>
        )}
        title="Profile"
      >
        <Link
          to="/profile/info"
        >
          My Profile
        </Link>
      </Menu.Item>
      <Menu.Item
        key="/new-request"
        className="menu-item-title"
        icon={(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM4 12.172C4.04732 16.5732 7.64111 20.1095 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.68451 16.444 4.10977 12.0425 4.086C7.64111 4.06246 4.04732 7.59876 4 12V12.172ZM13 17H11V13H7V11H11V7H13V11H17V13H13V17Z" />
          </svg>
        )}
      >
        <Link to="/new-request" className="sidebar-menu-unit__title">New request</Link>
      </Menu.Item>
      <SubMenu
        key="requests"
        icon={(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" />
          </svg>
        )}
        title="My Requests"
      >
        <Menu.Item className="submenu-item" key="/requests/all">
          <Link to="/requests/all">All</Link>
        </Menu.Item>
        <Menu.Item className="submenu-item" key="/requests/open">
          <Link to="/requests/open">Open</Link>
        </Menu.Item>
        <Menu.Item className="submenu-item" key="/requests/negotiations">
          <Link to="/requests/negotiations">Negotiations</Link>
        </Menu.Item>
        <Menu.Item className="submenu-item" key="/requests/closed">
          <Link to="/requests/closed">Closed</Link>
        </Menu.Item>
        <Menu.Item className="submenu-item" key="/requests/rejected">
          <Link to="/requests/rejected">Rejected</Link>
        </Menu.Item>
      </SubMenu>
    </CustomMenu>
  );
};

const CustomMenu = styled(Menu)`
  height: 100% !important;
  margin-bottom: 20px;
  border-right: none !important;
`;
