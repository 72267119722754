import { ITimelineItem } from './models';

const resourceDataSource = [
  {
    id: '0000001',
    name: 'Earring',
    items: 1,
    currentValue: 1200,
    updatedAt: '2020-12-13T23:00:00.000Z',
    status: 'Shipment in progress',
    action: 'Not required',
  },
  {
    id: '0000002',
    name: 'Package',
    items: 2,
    currentValue: 400,
    updatedAt: '2020-12-10T23:00:00.000Z',
    status: 'Preliminary quote',
    action: 'Required',
  },
  {
    id: '0000003',
    name: 'Iphone X',
    items: 1,
    currentValue: 1000,
    updatedAt: '2020-12-07T03:00:00.000Z',
    status: 'Review process',
    action: 'Required',
  },
  {
    id: '0000004',
    name: 'Laptop longe..',
    items: 1,
    currentValue: 200,
    updatedAt: '2020-12-07T23:00:00.000Z',
    status: 'Shipment in progress',
    action: 'Not required',
  },
];

const transactionsDataSource = [
  {
    id: '0000001',
    name: 'Earring',
    renevalTimes: 1,
    principalAmount: 1200,
    currentInterests: 16000,
    maturityDate: '01.07.2021',
    status: 'Open',
  },
  {
    id: '0000002',
    name: 'Phone',
    renevalTimes: 1,
    principalAmount: 1200,
    currentInterests: 16000,
    maturityDate: '01.07.2021',
    status: 'Open',
  }, {
    id: '0000003',
    name: 'Laptop',
    renevalTimes: 1,
    principalAmount: 1200,
    currentInterests: 16000,
    maturityDate: '01.07.2021',
    status: 'Closed',
  },
];

const requestDetailViewDataSource = [
  {
    id: 1,
    name: 'Earing',
    parameters: 'Inputs from Client according to parameters',
    comments: 'Admin Comment',
  },
];

const timeLineItems: ITimelineItem[] = [{
  type: 'agreement',
  date: '22-08-2020',
  badgeText: 'test badge',
  content: 'big test content',
  principal: 4,
  interestsRate: 'test interest rate',
  terms: 'test terms',
},
{
  type: 'agreement',
  date: '22-08-2020',
  badgeText: 'test badge',
  content: 'big test content',
  principal: 4,
  interestsRate: 'test interest rate',
  terms: 'test terms',
},
{
  type: 'agreement',
  date: '22-08-2020',
  badgeText: 'test badge',
  content: 'big test content',
  principal: 4,
  interestsRate: 'test interest rate',
  terms: 'test terms',
}];

const EmailTemplateTypes: Option[] = [{
  value: 'WEEK_BEFORE_90_DAY',
  text: 'A week before the 90th day - that the Client has one week to pay',
},
{
  value: 'ON_90_DAY',
  text: 'On the 90th day - that this is the last payment day',
},
{
  value: 'ON_91_DAY',
  text: 'On the 91st day - that he has the final 30 days to get the item back, otherwise it will be sold',
},
{
  value: 'WEEK_BEFORE_120_DAY',
  text: 'A week before the 120th day - ask if the Client really wants to lose the item',
}];

export {
  resourceDataSource,
  transactionsDataSource,
  requestDetailViewDataSource,
  timeLineItems,
  EmailTemplateTypes,
};
