import styled from 'styled-components';

const Cover = styled.img`
    @media only screen and (min-width: 1024px) {
        position: absolute;
        right: 300px;
        top: -110px;
        margin-top: 0px !important;
        border-radius: 20px;
    }
    @media only screen and (max-width: 1024px){
      margin-bottom: 10% !important;
    }

    margin-top: 10% !important;
    width: 240px;
    height: 200px;
`;

export { Cover };
