/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */

import dayjs from 'dayjs';

type Props = {
  updatedAt: Date;
  isAdminRejected: boolean;
  trackingBackNumber?: string;
  onConfirm: () => void;
};

const AdminItemReceivedRejected = ({
  updatedAt,
  isAdminRejected,
  trackingBackNumber,
  onConfirm,
}: Props) => {
  const buttonIsVisible = !trackingBackNumber;
  return (
    <div className="dashboard-columns__right">
      <div className="dashboard-infocard marks-wrap marks-wrap--red">
        <div className="popup__header">
          <div className="popup__title">
            {
              isAdminRejected
                ? (
                  <span>
                    Item(s)
                    <br />
                    has been
                    <mark>rejected</mark>
                  </span>
                )
                : (
                  <span>
                    User has
                    <mark>rejected</mark>
                    the offer
                  </span>
                )
            }

          </div>
          <div className="popup__text">
            <span>Date of rejection: </span>
            {dayjs(updatedAt).format('MM-DD-YYYY')}
          </div>
        </div>
        <div className="popup__content">
          <div className="popup__image popup__image--red">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 14.6666C5.30237 14.6682 2.86966 13.0439 1.83711 10.5517C0.804556 8.05946 1.37569 5.19063 3.284 3.28391C4.96886 1.59904 7.42461 0.941026 9.72617 1.55773C12.0277 2.17443 13.8255 3.97216 14.4422 6.27373C15.0589 8.5753 14.4009 11.031 12.716 12.7159C11.4676 13.9699 9.76944 14.6723 8 14.6666ZM2.66666 8.11457C2.69821 11.0487 5.09407 13.4063 8.02836 13.3906C10.9626 13.3747 13.333 10.9916 13.333 8.05724C13.333 5.12291 10.9626 2.73975 8.02836 2.7239C5.09407 2.70821 2.69821 5.06575 2.66666 7.99991V8.11457ZM6.27266 10.6666L5.33333 9.72657L7.06 7.99991L5.33333 6.27324L6.27333 5.33324L8 7.05991L9.72666 5.33324L10.6667 6.27324L8.94 7.99991L10.6667 9.72657L9.72733 10.6666L8 8.93991L6.27333 10.6666H6.27266Z"
              />
            </svg>
          </div>
        </div>
        <div className="popup__footer">
          <div className="popup__buttons popup__buttons--wide">
            {buttonIsVisible && <a className="popup__btn btn btn--dark" onClick={onConfirm}>confirm shipment</a>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminItemReceivedRejected;
