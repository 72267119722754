/* eslint-disable react/jsx-curly-brace-presence */
type Props = {
  interestData?: InterestData;
  quoteValue: number;
};

const ShowPawnDetails = ({ interestData, quoteValue }: Props) => (
  <>
    <div className="attachments-card__info">
      Rate:
      <span>
        {` ${((interestData?.montlyPercentageRate || 0) * 100).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`}
        % per month (
        {((interestData?.yearlyPercentageRate || 0) * 100).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        % APR)
      </span>
    </div>
    <div className="attachments-card__info">
      Monthly interest:
      <span>
        <span> $</span>
        {(quoteValue * (interestData?.montlyPercentageRate || 0)).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </span>
    </div>
    <div className="attachments-card__info">
      {"Loan's length:"}
      <span> 3 months </span>
    </div>
  </>
);

export default ShowPawnDetails;
