import { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { TransactionTypeChooser, OriginChooser, FormElement } from '../Choosers';
import { Input, Switch, Select } from '../../common';
import { data } from '../../../constants';
import SubmitSection from '../SubmitSection';
import { RedirectToDescriptionSection } from '../RedirectTo';
import { quoteStore, actionTypes } from '../../../store';

const { ControlledFormInput, InputNumber } = Input;
const { OptionSelect } = Select;

type Props = { subCategory: string } & RouteComponentProps & WithCategoriesProps;

type Item = {
  transactionType?: TransactionType;
  originType: OriginCountryType;
  originCountry: string;
  isGraded: boolean;
  year: number;
  denomination: string;
  grantingCompany: string;
  mintMark: string;
};
type State = {
  item: Item;
  groupType?: TransactionType;
};

const DEFAULT_ITEM: Item = {
  transactionType: undefined,
  originType: 'US',
  originCountry: '',
  isGraded: true,
  year: 1990,
  denomination: '',
  grantingCompany: '',
  mintMark: '',
};

class CoinsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      item: DEFAULT_ITEM,
      groupType: undefined,
    };
  }

  componentDidMount() {
    this.handleValidPackageType();
  }

  render() {
    const {
      item: {
        transactionType, originCountry, originType, isGraded, year,
      },
      groupType,
    } = this.state;

    const store = quoteStore.getState();

    return (
      <>
        <Container className="container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TransactionTypeChooser
                selectedType={groupType || transactionType}
                quoteArray={store.quotes}
                onTransactionTypeChange={this.handleTransactionTypeChange}
              />
              <OriginChooser
                originText="Is the coin domestic or foreign?"
                selectedOriginType={originType}
                originCountry={originCountry}
                onOriginTypeChange={this.handleOriginTypeChange}
                onOriginCountryChange={this.handleOriginCountryChange}
              />
              <Switch onChange={this.handleIsGraded} title="Is it graded?" values={['Yes', 'No']} value={isGraded} />
              <FormElementContainer className="form-element-container">
                {isGraded && (
                  <FormElement title="Who is the grading company?">
                    <ControlledFormInput
                      placeholder="Company Name"
                      onChange={(value: InputType) => this.handleGrantingCompanyChange(value.target.value)}
                    />
                  </FormElement>
                )}
                <FormElement title="Denomination?">
                  <ControlledFormInput
                    placeholder="Nickel"
                    onChange={(value: InputType) => this.handleDenominationChange(value.target.value)}
                  />
                </FormElement>
                <FormElement title="What is the year?">
                  <InputNumber value={year} onChange={e => this.handleYearChange(e)} />
                </FormElement>
                <FormElement className="mint-mark-form-element" title="What is the mintmark?">
                  <OptionSelect onChange={this.handleOriginalPacking} data={data.ORIGINAL_PACKING} />
                </FormElement>
              </FormElementContainer>
            </Col>
          </Row>
        </Container>
        {this.props.subCategory === 'other' ? (
          <RedirectToDescriptionSection
            handleValidateForm={this.handleValidateForm}
            onClick={this.handleFinish}
            category="other-coins"
          />
        ) : (
          <SubmitSection beforeSubmit={this.handleFinish} handleValidateForm={this.handleValidateForm} />
        )}
      </>
    );
  }

  handleValidateForm = (): boolean => {
    const {
      item: {
        transactionType, grantingCompany, denomination, isGraded, year, mintMark,
      },
      groupType,
    } = this.state;

    if (!transactionType && !groupType) {
      message.warn('Transaction type not selected');
      return false;
    }

    if (isGraded === true && !grantingCompany) {
      message.warn('Grading company cannot be empty');
      return false;
    }

    if (!denomination) {
      message.warn('Denomination cannot be empty');
      return false;
    }

    if (!year) {
      message.warn('Year cannot be empty');
      return false;
    }

    if (!mintMark) {
      message.warn('Mintmark cannot be empty');
      return false;
    }

    return true;
  };

  handleValidPackageType = () => {
    const { quotes } = quoteStore.getState();
    if (quotes.length === 0) return;
    const type = quotes[0].generalInfo.transactionType;
    this.setState(prevState => ({ groupType: type, item: { ...prevState.item, transactionType: type } }));
  };

  handleFinish = () => {
    const { item } = this.state;
    const { categories } = this.props;
    quoteStore.dispatch({ type: actionTypes.ADD_GENERAL_INFO, generalInfo: { categories, ...item } });
  };

  handleTransactionTypeChange = (transactionType: TransactionType) => {
    this.setState(prevState => {
      if (prevState.item.transactionType === transactionType) {
        return { item: { ...prevState.item, transactionType: undefined } };
      }
      return {
        item: {
          ...prevState.item,
          transactionType,
        },
      };
    });
  };

  handleOriginTypeChange = (originType: OriginCountryType) => {
    if (originType !== 'US') {
      return this.setState(prevState => ({
        item: {
          ...prevState.item,
          originType,
          originCountry: 'England',
        },
      }));
    }

    return this.setState(prevState => ({ item: { ...prevState.item, originType, originCountry: '' } }));
  };

  handleYearChange = (value: number) => this.setState(prevState => ({
    item: {
      ...prevState.item,
      year: value,
    },
  }));

  handleGrantingCompanyChange = (value: any) => this.setState(prevState => ({
    item: {
      ...prevState.item,
      grantingCompany: value,
    },
  }));

  handleDenominationChange = (value: any) => this.setState(prevState => ({
    item: {
      ...prevState.item, denomination: value,
    },
  }));

  handleOriginCountryChange = (originCountry: string) => this.setState(prevState => ({
    item: {
      ...prevState.item, originCountry,
    },
  }));

  handleIsGraded = (value: boolean) => this.setState(prevState => ({
    item: { ...prevState.item, isGraded: value },
  }));

  handleOriginalPacking = (value: any) => this.setState(prevState => ({
    item: {
      ...prevState.item,
      mintMark: value,
    },
  }));

  handleHasFields = () => {
    const { item } = this.state;

    if (!item.transactionType)
      return false;

    if (!item.grantingCompany)
      return false;

    if (!item.denomination)
      return false;

    if (!item.mintMark)
      return false;

    return true;
  };
}

const Container = styled.div`
  background-color: #ffffff;
`;

const FormElementContainer = styled.div`
  width: max-content;
  .form-element {
    width: 100%;
  }
  .ant-input {
    width: 100%;
  }

  .mint-mark-form-element {
    margin-bottom: 180px;
  }
`;

export default CoinsForm;
