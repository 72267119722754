/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {
  Form, Input, Tabs,
} from 'antd';
import { api } from 'api';
import { interceptPostError } from 'utils/interceptors';
import { Button } from 'components/common';
import { CustomModal } from '../custom';

const { SecondaryButton } = Button;
const { TabPane } = Tabs;

type State = {
  isLoading: boolean;
  isUpdating: boolean;
  setupValues: Array<any>;
  activeTab: string;
};

const Container = styled.div`
    flex: 1;
    max-width: 50%;
    display: flex;
    padding: 30px 15px 15px 30px;
    flex-direction: column;
`;

export default class DictionaryView extends Component<any, State> {
  source = axios.CancelToken.source();

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: true,
      isUpdating: false,
      setupValues: [],
      activeTab: '1',
    };
  }

  componentDidMount = (): void => {
    this.getSetupValues();
  };

  componentWillUnmount = (): void => {
    this.source.cancel();
  };

  render() {
    const { activeTab, setupValues } = this.state;

    const DATA_FEDEX = setupValues
      .filter((setup: any) => setup.section === 'FEDEX')
      .map((item: any) => (
        <Form.Item
          label={item.label}
          name={[item.section, item.key]}
        >
          <Input defaultValue={item.value} />
        </Form.Item>
      ));
    return (
      <body className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <Container>
              <Form name="shipment" onFinish={this.updateSetupValue}>
                <Tabs defaultActiveKey="1" onChange={tab => this.setState({ activeTab: tab })} activeKey={activeTab}>
                  <TabPane tab="Fedex" key="1">
                    {DATA_FEDEX}
                  </TabPane>
                </Tabs>
                <SecondaryButton type="submit">
                  Save changes
                </SecondaryButton>
              </Form>
            </Container>
          </div>
        </div>
      </body>
    );
  }

  getSetupValues = (): void => {
    api
      .get('/dictionary', {
        cancelToken: this.source.token,
      })
      .then(res => res.data)
      .then(data => this.setState({
        setupValues: data.dictionaryValues,
      }))
      .finally(() => this.setState({ isLoading: false }));
  };

  updateSetupValue = (values: any): void => {
    this.setState({
      isUpdating: true,
    });
    api
      .patch('/dictionary', { ...values }, {
        cancelToken: this.source.token,
      })
      .then(() => CustomModal.displayCustomSuccess({
        title: 'Value has been saved',
        content: 'Value has been successful saved',
      }))
      .finally(() => {
        this.setState({ isUpdating: false });
        this.getSetupValues();
      })
      .catch(interceptPostError);
  };
}
