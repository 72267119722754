/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from 'styled-components';
import { colors, text } from '../../../constants';
import { marginStyle } from '../../styles/helpers';

interface ButtonProps {
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
  htmlType?: string;
}

type RequestButton = {
  onClick: () => void;
  title: string;
};

interface DefaultButtonProps extends ButtonProps { }

export const DefaultButton = styled.button<DefaultButtonProps>`
    background-color: ${colors.background['widget-white']};
    color: ${colors.button.primary};
    border: 1px solid ${colors.button.primary};
    padding: 0px 10px;
    cursor: pointer;
    ${text['buttom-caps-14']}
    &:hover {
      &:not([disabled]) {
        border: 1px solid ${colors.button.primary};
        background-color: ${colors.button.primary};
        color: ${colors.background['widget-white']};
      }
    }
    text-transform: uppercase;
    ${marginStyle}
`;

interface TextButtonProps extends ButtonProps { }

export const TextButton = styled.button<TextButtonProps>`
    ${text['buttom-caps-14']}
    ${marginStyle}
    color: ${colors.text.dark};
    background: ${colors.background['widget-white']};
    border: 1px solid rgba(0,0,0,0);
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
      &:not([disabled]) {
        border-bottom: 2px solid ${colors.button.primary} !important;
      }
    }
    &:focus {
        outline: none;
    }
`;

interface ActionButtonProps extends ButtonProps { }

export const ActionButton = styled.button<ActionButtonProps>`
    ${text['buttom-caps-14']}
    font-size: 11px;
    background: ${colors.background['widget-white']};
    color: ${colors.text.body};
    border: none !important;
    padding: 0 5px;
    cursor: pointer;
    &:focus {
        border: none !important;
        outline: none;
    }

    &:disabled {
      opacity: 40%;
    }

    &:hover {
        background-color: #e4e4e4;
    }

    @media (min-width: 1440px) {
        padding: 0 10px;
        font-size: 12px;
    }

    @media (min-width: 1700px) {
        font-size: 16px;
    }
`;

interface SecondaryButtonProps extends ButtonProps {
  width?: string | number;
}

export const SecondaryButton = styled.button<SecondaryButtonProps>`
    width: 100%;
    background: #526B7C;
    color: #fff;
    font-size: 14px;
    height: 40px;
    text-transform: uppercase;
    font-weight: bold;
    border: 1px solid #526B7C;

    &:disabled {
        opacity: 0.4;
    }

    &:hover {
      &:not([disabled]) {
        border: 1px solid ${colors.button.primary};
        background-color: ${colors.background['widget-white']};
        color: ${colors.button.primary};
      }
    }

    @media (min-width: 1024px) {
        padding: 0 5px;
    }

    @media (mim-width: 1440px) {
        padding: 0 10px;
        font-size: 12px;
    }

    @media (min-width: 1700px) {
        font-size: 16px;
    }
`;

export const OutlineButton = styled.button<SecondaryButtonProps>`
    width: 100%;
    background: transparent;
    border: 1px solid #526B7C;
    color: ##526B7C;
    font-size: 14px;
    height: 40px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 10px 0;

    &:disabled {
        opacity: 0.4;
    }

    &:hover {
      &:not([disabled]) {
        border: 1px solid ${colors.button.primary};
        background-color: ${colors.background['widget-white']};
        color: ${colors.button.primary};
      }
    }

    @media (min-width: 1024px) {
        padding: 0 5px;
    }

    @media (mim-width: 1440px) {
        padding: 0 10px;
        font-size: 12px;
    }

    @media (min-width: 1700px) {
        font-size: 16px;
    }
`;

interface ModalButtonProps extends ButtonProps { }

export const ModalButton = styled.button<ModalButtonProps>`
    background: #f6f4f0;
    text-align: center;
    padding: 0px 10px;
    border: none;
    cursor: pointer;
    outline: none;
    color: ${colors.button.primary};
    ${text['buttom-caps-14']}
    min-height: 40px;
`;

interface SwitchButtonProps extends ButtonProps {
  isSelected: boolean;
}

export const SwitchButton = styled.button<SwitchButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 135.8px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-right: 27px;
    border: ${props => (props.isSelected ? `solid 2px ${colors.selected}` : `dashed 1px ${colors.text.dark} `)};
    outline: none;
    background-color: ${props => (props.isSelected ? colors.background.selected : '#FFFFFF')};
    ${text.body}
`;

export const AcceptButton = styled.button<ActionButtonProps>`
     ${text['buttom-caps-14']}
    font-size: 11px;
    background: #7EC099;
    color: #FFF;
    border: none !important;
    cursor: pointer;
    padding: 0 5px;

    &:focus {
        border: none !important;
        outline: none;
    }

    @media (min-width: 1440px) {
        padding: 0 10px;
        font-size: 12px;
    }

    @media (min-width: 1700px) {
        font-size: 16px;
    }
`;

export const RequestDetailsButtonItem = ({ title, onClick }: RequestButton) => (
  <a
    className="dashboard-header__btn btn btn--bordered btn--bordered-dark btn--small"
    onClick={onClick}
  >
    <span className="btn--small--label">{title}</span>
  </a>
);

export const RequestDetailsButtonItems = ({ title, onClick }: RequestButton) => (
  <a
    className="dashboard-header__btn btn btn--bordered btn--bordered-dark btn--small"
    onClick={onClick}
  >
    <span className="btn--small--label">
      {title}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" />
      </svg>
    </span>
  </a>
);
