const EMAIL_NOTIFICATIONS_STAGES = {
  RF_3_3_ITEM: 'RF_3_3_ITEM',
  RF_3_3_METAL_AUTO: 'RF_3_3_METAL_AUTO',
  RF_3_3_METAL_COUNTER: 'RF_3_3_METAL_COUNTER',
  AV_1A_ACCEPT: 'AV_1A_ACCEPT',
  AV_1B_REJECT: 'AV_1B_REJECT',
  UV_3A_ACCEPT: 'UV_3A_ACCEPT',
  UV_3A_COUNTER: 'UV_3A_COUNTER',
  AV_3A_ACCEPT: 'AV_3A_ACCEPT',
  AV_3A_REJECT: 'AV_3A_REJECT',
  AV_3A_COUNTER: 'AV_3A_COUNTER',
  AV_3A_COUNTER_FINAL: 'AV_3A_COUNTER_FINAL',
  UV_4A_FINAL_ACCEPT: 'UV_4A_FINAL_ACCEPT',
  UV_4A_FINAL_CANCEL: 'UV_4A_FINAL_CANCEL',
  UV_5E_5F_SENT: 'UV_5E_5F_SENT',
  UV_5E_BOX_NOT_ARRIVED: 'UV_5E_BOX_NOT_ARRIVED',
  AV_5_RECEIVED: 'AV_5_RECEIVED',
  AV_6A_COUNTER: 'AV_6A_COUNTER',
  AV_6A_ACCEPT: 'AV_6A_ACCEPT',
  AV_6_REJECT: 'AV_6A_REJECT',
  AV_6B_6C_SHIPMENT_BACK: 'AV_6B_6C_SHIPMENT_BACK',
  AV_6E_COUNTER: 'AV_6E_COUNTER',
  AV_6E_ACCEPT_AGREEMENT: 'AV_6E_ACCEPT_AGREEMENT',
  UV_7C_ACCEPT_COUNTER: 'UV_7C_ACCEPT_COUNTER',
  UV_7C_COUNTER: 'UV_7C_COUNTER',
  UV_7B_7H_7I_CONFIRM_SALE: 'UV_7B_7H_7I_CONFIRM_SALE',
  UV_7B_7H_7I_CONFIRM_LOAN: 'UV_7B_7H_7I_CONFIRM_LOAN',
  UV_7D_FINAL_ACCEPT: 'UV_7D_FINAL_ACCEPT',
  UV_7D_FINAL_CANCEL: 'UV_7D_FINAL_CANCEL',
  UV_9_11_BANK_ACCOUNT_AUTHORIZATION_SUCCESS: 'UV_9_11_BANK_ACCOUNT_AUTHORIZATION_SUCCESS',
  UV_9_11_BANK_ACCOUNT_AUTHORIZATION_ERROR: 'UV_9_11_BANK_ACCOUNT_AUTHORIZATION_ERROR',
  AV_8B_9C_CONFIRM_PAYMENT: 'AV_8B_9C_CONFIRM_PAYMENT',
  AV_10_CONFIRM_SHIPMENT: 'AV_10_CONFIRM_SHIPMENT',
  UV_10_CONFIRM_SHIPMENT: 'UV_10_CONFIRM_SHIPMENT',
  UV_11A_OPT_OUT: 'UV_11A_OPT_OUT',
  UV_11A_PAY_INTEREST_ONLY: 'UV_11A_PAY_INTEREST_ONLY',
  UV_11A_PAY_INTEREST_ONLY_10: 'UV_11A_PAY_INTEREST_ONLY_10',
  UV_11A_PAY_INTEREST_ONLY_AMOUNT: 'UV_11A_PAY_INTEREST_ONLY_AMOUNT',
  UV_11A_PAY_FULL: 'UV_11A_PAY_FULL',
  UV_11C_PAY_ERROR: 'UV_11C_PAY_ERROR',
};

export default EMAIL_NOTIFICATIONS_STAGES;
