import Button, { ButtonProps } from 'antd/lib/button';
import { darken } from 'polished';
import styled from 'styled-components';
import { colors } from '../constants';

const StyledButton = styled(Button)`
    color: ${colors.button.primary};

    &:hover, &:active, &:focus {
        color: ${darken(0.2, colors.button.primary)};
    }
`;

export default function CustomRejectButton(props: ButtonProps): JSX.Element {
  return (
    <StyledButton type="link" {...props}>
      {props.children}
    </StyledButton>
  );
}
