/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useState } from 'react';
import dayjs from 'dayjs';
import fonts from '../../../constants/fonts';

type Props = {
  mode: string;
  step: number;
  actionHistory: Action[];
  requests: Array<Quote>;
  contentData?: any
  showDetails: (value: any) => void;
  onExludeItem: (quote: Quote) => void;
  onActiveDemo: (state: boolean) => void;
  handledDemoStateAction: (state: string) => void;
};

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const AdminRequestDetailsContent = ({
  actionHistory, requests, contentData, showDetails, onExludeItem,
}: Props) => {
  const [isTableVisible, setIsTableVisible] = useState(false);

  let statestatus = '';
  const { mainFont } = fonts.fonts;

  const parentRequest = requests?.filter(req => !req.parentId)[0];
  const childrenRequest = requests?.filter(req => req.parentId);
  const numberOfExludedItems = requests?.filter(req => req.rejectedFromGroup).length;
  const numberOfAcceptedItems = childrenRequest?.length - numberOfExludedItems;
  const isManyItems = childrenRequest?.length > 0;

  switch (parentRequest?.state) {
    case ('accepted'):
      statestatus = 'status--accepted';
      break;
    case ('sent'):
      statestatus = 'status--accepted';
      break;
    case ('rejected'):
      statestatus = 'status--rejected';
      break;
    default:
      statestatus = 'status--closed';
  }

  const showButtonDetails = () => (
    isManyItems
      ? (
        <a
          className="dashboard-header__btn btn btn--bordered btn--bordered-dark btn--small"
          onClick={() => setIsTableVisible(!isTableVisible)}
        >
          <span className="btn--small--label">
            {
              isTableVisible
                ? 'Hide Items List'
                : 'Show Items List'
            }
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" />
            </svg
            >
          </span>
        </a>
      )
      : (
        <a
          className="dashboard-header__btn btn btn--bordered btn--bordered-dark btn--small"
          onClick={() => showDetails(requests[0]?.id)}
        >
          <span className="btn--small--label">Show Details</span>
        </a>
      )
  );

  const table = childrenRequest.map((req, index) => (
    <tr
      className={`dashboard-header-table__unit ${req.rejectedFromGroup ? 'dashboard-header-table__unit--rejected' : 'dashboard-header-table__unit--accepted'}`}
      key={req.id}
    >
      <td>
        <div className="dashboard-header-table__status">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {
              req.rejectedFromGroup
                ? <path
                  d="M8 14.6666C5.30237 14.6682 2.86966 13.0439 1.83711 10.5517C0.804556 8.05946 1.37569 5.19063 3.284 3.28391C4.96886 1.59904 7.42461 0.941026 9.72617 1.55773C12.0277 2.17443 13.8255 3.97216 14.4422 6.27373C15.0589 8.5753 14.4009 11.031 12.716 12.7159C11.4676 13.9699 9.76944 14.6723 8 14.6666ZM2.66666 8.11457C2.69821 11.0487 5.09407 13.4063 8.02836 13.3906C10.9626 13.3747 13.333 10.9916 13.333 8.05724C13.333 5.12291 10.9626 2.73975 8.02836 2.7239C5.09407 2.70821 2.69821 5.06575 2.66666 7.99991V8.11457ZM6.27266 10.6666L5.33333 9.72657L7.06 7.99991L5.33333 6.27324L6.27333 5.33324L8 7.05991L9.72666 5.33324L10.6667 6.27324L8.94 7.99991L10.6667 9.72657L9.72733 10.6666L8 8.93991L6.27333 10.6666H6.27266Z"
                />
                : <path
                  d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.98931 13.3333H7.99998C10.9445 13.3303 13.3295 10.9417 13.328 7.99725C13.3265 5.05278 10.9391 2.66659 7.99465 2.66659C5.05017 2.66659 2.66279 5.05278 2.66131 7.99725C2.65984 10.9417 5.04484 13.3303 7.98931 13.3333ZM6.66665 11.3333L3.99998 8.66659L4.93998 7.72659L6.66665 9.44659L11.06 5.05325L12 5.99992L6.66665 11.3333Z"
                />
            }
          </svg>
          <span>{`${index + 1}.`}</span>
        </div>
      </td>
      <td>{capitalizeFirstLetter(req.name)}</td>
      <td>
        {req.comment}
      </td>
      <td>
        <a
          className="btn btn--alt-font btn--bordered btn--bordered-dark table__btn"
          style={{ marginRight: 10 }}
          onClick={() => {
            onExludeItem(req);
          }}
        >
          {
            req.rejectedFromGroup ? 'Include' : 'Exclude'
          }
        </a>
        <a
          className="btn btn--alt-font btn--bordered btn--bordered-dark table__btn"
          onClick={() => {
            showDetails(req.id);
            setIsTableVisible(false);
          }}
        >
          More Details
        </a>
      </td>
    </tr>
  ));

  const showActionsHistory = actionHistory.map((action, index) => (
    <tr>
      <td>{`${index + 1}.`}</td>
      <td>{dayjs(action?.startDate).format('MM.DD.YYYY h:mm A')}</td>
      <td>
        <div className="status" style={{ width: 200, display: 'flex', justifyContent: 'center' }}>{action?.state}</div>
      </td>
      <td>{action?.context.status}</td>
    </tr>
  ));

  return (
    <body className="body--dashboard-pages body--dashboard" style={{ fontFamily: mainFont }}>
      <div className="main main-dashboard">
        <div className="dashboard">
          <div className="dashboard-section dashboard-section--overflow">
            <div className={`dashboard-header js-dashboard-header--table ${isTableVisible && 'js-dashboard-header--table-shown'}`}>
              <div className="dashboard-header__body">
                <div className="dashboard-header__description">
                  <span>{parentRequest?.customId}</span>
                  <span> / </span>
                  <span>{parentRequest?.context.mode}</span>
                  <span> / </span>
                  <span>{dayjs(parentRequest?.updatedAt).format('MM.DD.YYYY h:mm A')}</span>
                </div>
                <div className="dashboard-header__title">{capitalizeFirstLetter(parentRequest.name)}</div>
                <div className="dashboard-header__controls">
                  <a className="dashboard-header__btn btn btn--bordered btn--bordered-dark btn--small" href="#history">
                    <span className="btn--small--label">View History</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 13.6H11.2V4H10V13.6H8.2L10.6 16L13 13.6Z" />
                    </svg>
                  </a>
                  {showButtonDetails()}
                  <div className={`status ${statestatus}`}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.99998 2.66659C5.05446 2.66659 2.66665 5.0544 2.66665 7.99992C2.66665 10.9454 5.05446 13.3333 7.99998 13.3333C10.9455 13.3333 13.3333 10.9454 13.3333 7.99992C13.33 5.05577 10.9441 2.66989 7.99998 2.66659ZM11.3333 8.66659H7.33331V4.66659H8.66665V7.33325H11.3333V8.66659Z" />
                    </svg>
                    <span>{parentRequest?.context.status}</span>
                  </div>
                  {
                    isManyItems && (
                      <>
                        {
                          numberOfAcceptedItems > 0 && (
                            <div className="status status--accepted">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.98931 13.3333H7.99998C10.9445 13.3303 13.3295 10.9417 13.328 7.99725C13.3265 5.05278 10.9391 2.66659 7.99465 2.66659C5.05017 2.66659 2.66279 5.05278 2.66131 7.99725C2.65984 10.9417 5.04484 13.3303 7.98931 13.3333ZM6.66665 11.3333L3.99998 8.66659L4.93998 7.72659L6.66665 9.44659L11.06 5.05325L12 5.99992L6.66665 11.3333Z"
                                />
                              </svg
                              >
                              <span>
                                {`${numberOfAcceptedItems} item(s) accepted`}
                              </span>
                            </div>
                          )
                        }
                        {
                          numberOfExludedItems > 0 && (
                            <div className="status status--rejected">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 14.6666C5.30237 14.6682 2.86966 13.0439 1.83711 10.5517C0.804556 8.05946 1.37569 5.19063 3.284 3.28391C4.96886 1.59904 7.42461 0.941026 9.72617 1.55773C12.0277 2.17443 13.8255 3.97216 14.4422 6.27373C15.0589 8.5753 14.4009 11.031 12.716 12.7159C11.4676 13.9699 9.76944 14.6723 8 14.6666ZM2.66666 8.11457C2.69821 11.0487 5.09407 13.4063 8.02836 13.3906C10.9626 13.3747 13.333 10.9916 13.333 8.05724C13.333 5.12291 10.9626 2.73975 8.02836 2.7239C5.09407 2.70821 2.69821 5.06575 2.66666 7.99991V8.11457ZM6.27266 10.6666L5.33333 9.72657L7.06 7.99991L5.33333 6.27324L6.27333 5.33324L8 7.05991L9.72666 5.33324L10.6667 6.27324L8.94 7.99991L10.6667 9.72657L9.72733 10.6666L8 8.93991L6.27333 10.6666H6.27266Z"
                                />
                              </svg
                              >
                              <span>
                                {`${numberOfExludedItems} item(s) excluded`}
                              </span>
                            </div>
                          )
                        }
                      </>
                    )
                  }
                </div>
              </div>
              <img className="dashboard-header__image" src="/images/dashboard/dashboard-header-image.svg" alt="" />
              <div className="dashboard-header-list">
                <div className="table-wrap">
                  <table className="dashboard-header-table table">
                    <tr>
                      <th>
                        <div className="table__row-title">
                          <span>Nr</span>
                          <svg
                            width="8"
                            height="5"
                            viewBox="0 0 8 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M4 5L0.535898 0.500001L7.4641 0.5L4 5Z" />
                          </svg>
                        </div>
                      </th>
                      <th>
                        <div className="table__row-title"><span>Name</span></div>
                      </th>
                      <th>
                        <div className="table__row-title">
                          <span>E&B Comments</span>
                        </div>
                      </th>
                      <th />
                    </tr>
                    {table}
                  </table>
                </div>
              </div>
            </div>
            {contentData}
          </div>
          <div className="dashboard-history" id="history">
            <div className="dashboard-history__title">Request History</div>
            <div className="dashboard-history__table-wrap">
              <table className="dashboard-history__table">
                {showActionsHistory}
              </table>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default AdminRequestDetailsContent;
