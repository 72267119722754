import { Input as AntdInput, InputNumber as AntdInputNumber } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styled, { css } from 'styled-components';
import { colors, text } from '../../constants';

type InputProps = {
  placeholder?: string;
  value?: string | number | readonly string[];
  onChange?: (value: string) => void;
  className?: string;
};

type SearchProps = {} & InputProps;

export const SearchInput = ({
  placeholder, value, onChange, className,
}: SearchProps) => (
  <StyledSearchInput
    className={className}
    placeholder={placeholder}
    value={value}
    onChange={(value: Input) => onChange && onChange(value.target.value)}
  />
);

const StyledSearchInput = styled(AntdInput)`
    height: 60px;
    width: 50%;

    @media only screen and(min-width: 600px) {
        width: 462px;
    }

    margin: 32px 0px 32px 40px;
    ${text.body};
    color: ${colors.text.light};
    border: 1px solid #8fa1ac;
    box-sizing: border-box;
    background-color: ${colors.background.main};

    &:after,
    &:focus,
    &:hover {
        border: 1px solid #8fa1ac;
        box-shadow: none !important;
    }
`;

type FormInputProps = { placeholder?: string; className?: string } & ButtonProps;

export const ControlledFormInput = (props: FormInputProps) => <FormInput {...props} />;

const FormStyle = css`
    width: 240px;
    height: 40px;
    color: ${colors.text.light};
    background-color: ${colors.textFields};
    ${text.body};

    .ant-input {
        height: auto;
    }

    input {
        &::placeholder {
            color: ${colors.text.light};
            ${text.body};
        }

        color: ${colors.text.light};
        background-color: ${colors.textFields};
        ${text.body};
    }
    &:disabled {
        &::placeholder {
            color: #b7bfc5;
        }
        background-color: ${colors.textFields};
    }

    &::placeholder {
        color: ${colors.text.light};
        ${text.body};
    }

    &:after,
    &:focus,
    &:hover {
        border: 1px solid #8fa1ac;
        box-shadow: none !important;
    }
`;

export const FormInputPassword = styled(AntdInput.Password)`
    ${FormStyle}

    @media only screen and (max-width: 600px) {

        }
`;

export const FormInput = styled(AntdInput)`
    ${FormStyle}

    @media only screen and (max-width: 600px) {

        }
`;

type CurrencyInputProps = {
  onChange: (value: number) => void;
  value?: number;
  disabled?: boolean;
  precision?: number;
  min?: number;
  max?: number
};

export const CurrencyInputNumber = ({
  onChange, value, disabled, precision = 2, min = 0, max,
}: CurrencyInputProps) => (
  <StyledInputNumber
    className="currency-input"
    value={value}
    min={min}
    max={max}
    step={0.1}
    disabled={!!disabled}
    precision={precision}
    prefix="$"
    size="large"
    onChange={value => onChange(parseFloat(`${value}`))}
  />
);
export const InputNumber = ({
  onChange, value, disabled, precision = 0, min = 0,
}: CurrencyInputProps) => (
  <StyledInputNumber
    className="currency-input"
    value={value || 0}
    min={min}
    step={1}
    disabled={!!disabled}
    precision={precision}
    size="large"
    onChange={value => onChange(parseFloat(`${value}`))}
  />
);

const StyledInputNumber = styled(AntdInputNumber)`
    ${text.body};
    background-color: ${colors.background.inputNumber};
    color: ${colors.text.light};
    box-sizing: border-box;
    width: 100%;
    height: 40px;

    .ant-input-number-input-wrap {
        @media only screen and (min-width: 600px) {
            max-width: 462px !important;
        }

        @media only screen and (max-width: 600px) {

        }
    }

    @media only screen and (min-width: 600px) {
        max-width: 462px !important;
    }

    @media only screen and (max-width: 600px) {

    }

    &:after,
    &:focus,
    &:hover {
        border: 1px solid #8fa1ac;
        box-shadow: none !important;
    }
`;

type TextAreaProps = {
  rows?: number;
  placeholder?: string;
  onChange: (e: string) => void;
  value: string;
  maxLength?: number;
  showCount?: boolean;
};

export const ControlledFormTextArea = ({
  rows, placeholder, onChange, value, maxLength,
}: TextAreaProps) => (
  <StyledTextArea
    onChange={e => onChange(e.target.value)}
    placeholder={placeholder || ''}
    value={value}
    rows={rows || 4}
    className="form-text-area"
    maxLength={maxLength}
  />
);

const { TextArea } = AntdInput;
const StyledTextArea = styled(TextArea)`
    color: ${colors.text.light};
    background-color: ${colors.textFields};
    ${text.body};
    min-height: 40px;
`;
