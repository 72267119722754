import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Row, Col, message } from 'antd';
import styled from 'styled-components';
import { colors, text, data } from '../constants';
import { searchTree } from '../helpers/tree';
import { CategoryCard, CategoryBreadcrumb } from '../components/Cards';
import { Breadcrumb, Spinner } from '../components/common';
import { runMain } from '../assets/js/main';
import { quoteStore, actionTypes } from '../store';

type Props = {} & RouteComponentProps;

type State = {
  categories: CategoryTree<string>;
  isLoading: boolean;
};

const { QuoteBreadcrumb } = Breadcrumb;
export default class CategoriesView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      categories: data.CATEGORIES,
      isLoading: true,
    };
  }

  componentDidMount() {
    runMain();
    this.handleGetFilterFromPathName();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleGetFilterFromPathName();
    }
  }

  render() {
    const {
      categories: {
        treeLevel, children,
      },
      isLoading,
    } = this.state;

    if (isLoading)
      return <Spinner />;

    const quote = quoteStore.getState();

    const isCoins = quote.quotes[0]?.generalInfo?.categories[0] === 'coins';

    const isFirstItem = quote.quotes.length === 0;

    return (
      <>
        <div className="hero" style={{ height: '100%', width: '100%' }}>
          <Container className="container hero__container">
            {treeLevel === 0 ? (
              <CategoriesContainer>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <QuoteBreadcrumb hasAttachments hasDescription />
                  </Col>
                </Row>
                {quote.quotes.length > 0 && (
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Basket>{`You have ${quote.quotes.length} item(s) in package`}</Basket>
                    </Col>
                  </Row>
                )}
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title>Select a Category</Title>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <CategoryContent>
                      {children
                        ?.filter(c => !c.name.includes('metal'))
                        .filter(c => ((!isFirstItem && isCoins)
                          ? c.name.includes('coins')
                          : (!isFirstItem && !isCoins)
                            ? !c.name.includes('coins')
                            : !c.name.includes('other-coins')))
                        .map(c => (
                          <CategoryCard
                            key={c.name}
                            title={c.name}
                            id={c.name}
                            imgCover={c.imgCover || ''}
                            hasSubcategory={!!c.children}
                          />
                        ))}
                    </CategoryContent>
                  </Col>
                </Row>
              </CategoriesContainer>
            ) : (
              <CategoriesContainer>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <QuoteBreadcrumb hasAttachments hasDescription />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <CategoryBreadcrumb />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <CategoryContent>
                      {children?.filter(c => !c.name.includes('metal'))
                        .filter(c => !c.name.includes('other-coins'))
                        .map(c => (
                          <CategoryCard
                            key={c.name}
                            title={c.name}
                            id={c.name}
                            imgCover={c.imgCover || ''}
                            hasSubcategory={!!c.children}
                          />
                        ))}
                    </CategoryContent>
                  </Col>
                </Row>
              </CategoriesContainer>
            )}
          </Container>
        </div>
      </>
    );
  }

  handleGetFilterFromPathName = (): void => {
    const {
      location: { pathname },
    } = this.props;

    this.setState({ isLoading: true });
    if (!pathname || typeof pathname !== 'string') return;

    const paths = pathname.split('/');
    const categoryName = paths.pop();

    if (!categoryName) return;

    const {
      name, children, treeLevel, imgCover,
    } = searchTree(data.CATEGORIES, categoryName);

    if (!children) return this.setState({ isLoading: false });

    this.setState({
      isLoading: false,
      categories: {
        name, children, treeLevel, imgCover,
      },
    });
  };

  handleVerifyRedirectUrl = () => {
    const { quotes } = quoteStore.getState();
    if (quotes.length <= 0)
      return false;
    const { location: { hash } } = window;
    const [path] = hash.substring(2).split('/');

    if (path === 'general-information')
      return false;

    if (path === 'categories')
      return false;

    return true;
  };

  clearStore = () => {
    if (this.handleVerifyRedirectUrl()) {
      quoteStore.dispatch({ type: actionTypes.CLEAR_ITEMS });
      return message.warn('Package items cleared');
    }
  };
}

const Basket = styled.span`
  background-color: #fff;
  padding: 8px;
  color: #4F6470;
  font-size: 12px;
  text-transform: uppercase;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoriesContainer = styled.div`
    width: 100%;
    .ant-row {
        flex: 1;
        width: 100% !important;
    }
    .ant-col {
        flex: 1 !important;
        display: flex;
    }
`;

const CategoryContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;

    .category-card {
        padding: 10px;
        margin-right: 8%;
        margin-bottom: 20px;
    }
`;

const Title = styled.h2`
    color: ${colors.text.light};
    ${text.title}
    margin-top: 32px;
    margin-bottom: 42px;
`;
