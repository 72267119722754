import { Component } from 'react';
import styled from 'styled-components';
import {
  Pagination,
} from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button, Spinner, Title, Input,
} from '../components/common';
import { Modals, table } from '../components';
import { api } from '../api';

const { SearchInput } = Input;
const { TransactionTable } = table;
const { PayModal } = Modals;
const { ActionButton } = Button;

type State = {
  payModalVisible: boolean,
  transactions: Loan[],
  transaction?: Loan,
  isLoading: boolean,
  current: number,
  limit: number,
  total: number,
  filter: string,
};

export default class TransactionsView extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      payModalVisible: false,
      transactions: [],
      transaction: undefined,
      isLoading: true,
      filter: '',
      current: 1,
      limit: 5,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchTransactions();
  }

  render() {
    const {
      payModalVisible,
      transactions,
      isLoading,
      transaction,
      current,
      limit,
      total,
      filter,
    } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <body className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <SearchContainer>
              <Title title="All Transactions" />
              <form>
                <Filter>
                  <SearchInput
                    value={filter}
                    placeholder="Search by name or number"
                    onChange={e => this.handleFilterChange(e)}
                  />
                  <ActionButton
                    type="submit"
                    className="search-button"
                    onClick={() => this.handleFilter()}
                  >
                    Search
                  </ActionButton>
                </Filter>
              </form>
            </SearchContainer>
            {transaction && (
              <PayModal
                transaction={transaction}
                visible={payModalVisible}
                onPay={this.handlePay}
                onBankConnection={this.fetchTransactions}
                onCancel={() => this.setState({ payModalVisible: false })}
              />
            )}
            <TransactionTable dataSource={transactions} />
            <CPagination
              showSizeChanger
              onShowSizeChange={this.onShowSizeChange}
              onChange={this.onChange}
              current={current}
              pageSizeOptions={['2', '5', '10', '20']}
              pageSize={limit}
              total={total}
            />
          </div>
        </div>
      </body>
    );
  }

  handleLoading = (isLoading: boolean) => this.setState({ isLoading });

  onShowSizeChange = (_: number, limit: number) => this.setState({ limit }, () => this.fetchTransactions());

  onChange = (page: number) => this.setState({ current: page }, () => this.fetchTransactions());

  handleFilterChange = (e: string) => this.setState({ filter: e });

  handleFilter = () => this.fetchTransactions();

  handlePay = () => this.setState({ payModalVisible: false }, () => this.fetchTransactions());

  fetchTransactions = () => {
    const { filter, limit, current } = this.state;
    const offset = (current - 1) * (limit || 0);
    this.setState({ isLoading: true });
    api
      .get('/transactions', { params: { filter, limit, offset } })
      .then(res => res.data)
      .then(([data, total]) => this.setState({ transactions: data, total }))
      .finally(() => this.setState({ isLoading: false }));
  };
}

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .search-button {
    height: 60px;
    margin-left: 4%;
  }
`;

const SearchContainer = styled.div`
    width: 100%;
    max-width: 600px;
`;

const CPagination = styled(Pagination)`
  margin-bottom: 6%;
  margin-top: 1%;
`;
