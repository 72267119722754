import { Component } from 'react';
import { Modal } from 'antd';
import { ModalFuncProps, ModalProps } from 'antd/lib/modal';
import styled from 'styled-components';

import { colors } from '../constants';

const StyledModal = styled(Modal)``;

export default class CustomModal extends Component<ModalProps> {
  static displayCustomConfirm = (props: ModalFuncProps): void => {
    Modal.confirm({
      okButtonProps: {
        style: {
          backgroundColor: colors.background.main,
          border: 'none',
        },
      },
      cancelButtonProps: {
        type: 'link',
        style: {
          color: colors.background.main,
        },
      },
      width: 600,
      ...props,
    });
  };

  static displayCustomWarning = (props: ModalFuncProps): void => {
    Modal.warning({
      okButtonProps: {
        style: {
          backgroundColor: colors.button.primary,
          border: 'none',
        },
      },
      width: 600,
      ...props,
    });
  };

  static displayCustomSuccess = (props: ModalFuncProps): void => {
    Modal.success({
      okButtonProps: {
        style: {
          backgroundColor: colors.button.primary,
          border: 'none',
        },
      },
      width: 600,
      ...props,
    });
  };

  static displayCustomError = (props: ModalFuncProps): void => {
    Modal.error({
      okButtonProps: {
        style: {
          backgroundColor: colors.button.primary,
          border: 'none',
        },
      },
      width: 600,
      ...props,
    });
  };

  render = (): JSX.Element => (
    <StyledModal
      width={1000}
      okButtonProps={{
        style: {
          backgroundColor: colors.background.main,
          border: 'none',
        },
      }}
      cancelButtonProps={{
        color: colors.background.main,
      }}
      {...this.props}
    >
      {this.props.children}
    </StyledModal>
  );
}
