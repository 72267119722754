/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
type Props = {
  mode?: string;
  step: number;
};

const iconClock = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.99998 2.66659C5.05446 2.66659 2.66665 5.0544 2.66665 7.99992C2.66665 10.9454 5.05446 13.3333 7.99998 13.3333C10.9455 13.3333 13.3333 10.9454 13.3333 7.99992C13.33 5.05577 10.9441 2.66989 7.99998 2.66659ZM11.3333 8.66659H7.33331V4.66659H8.66665V7.33325H11.3333V8.66659Z"
    />
  </svg>
);

const iconCompleted = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992C14.6626 11.6801 11.6802 14.6625 7.99998 14.6666ZM7.98931 13.3333H7.99998C10.9445 13.3303 13.3295 10.9417 13.328 7.99725C13.3265 5.05278 10.9391 2.66659 7.99465 2.66659C5.05017 2.66659 2.66279 5.05278 2.66131 7.99725C2.65984 10.9417 5.04484 13.3303 7.98931 13.3333ZM6.66665 11.3333L3.99998 8.66659L4.93998 7.72659L6.66665 9.44659L11.06 5.05325L12 5.99992L6.66665 11.3333Z"
    />
  </svg>
);

const AdminStepper = ({ mode, step }: Props) => (
  <>
    <div className="admin-steps" style={{ minHeight: 500 }}>
      <div className={`admin-step ${step > 1 && 'admin-step--completed'} ${step === 1 && 'admin-step--current'}`}>
        <div className="admin-step__icon">
          {
            step && step > 1
              ? iconCompleted
              : iconClock
          }
        </div>
        <div className="admin-step__title">
          <span># 1:</span>
          <span>Preliminary negotiation</span>
        </div>
      </div>
      <div className="admin-steps__divider"></div>
      <div className={`admin-step ${step > 2 && 'admin-step--completed'} ${step === 2 && 'admin-step--current'}`}>
        <div className="admin-step__icon">
          {
            step && step > 2
              ? iconCompleted
              : iconClock
          }
        </div>
        <div className="admin-step__title">
          <span># 2:</span>
          <span>Preliminary quote finalized / User to ship</span>
        </div>
      </div>
      <div className="admin-steps__divider"></div>
      <div className={`admin-step ${step > 3 && 'admin-step--completed'} ${step === 3 && 'admin-step--current'}`}>
        <div className="admin-step__icon">
          {
            step && step > 3
              ? iconCompleted
              : iconClock
          }
        </div>
        <div className="admin-step__title">
          <span># 3:</span>
          <span>Item(s) in transit</span>
        </div>
      </div>
      <div className="admin-steps__divider"></div>
      <div className={`admin-step ${step > 4 && 'admin-step--completed'} ${step === 4 && 'admin-step--current'}`}>
        <div className="admin-step__icon">
          {
            step && step > 4
              ? iconCompleted
              : iconClock
          }
        </div>
        <div className="admin-step__title">
          <span># 4:</span>
          <span>Item(s) received / Final negotiation</span>
        </div>
      </div>
      <div className="admin-steps__divider"></div>
      <div className={`admin-step ${step > 5 && 'admin-step--completed'} ${step === 5 && 'admin-step--current'}`}>
        <div className="admin-step__icon">
          {
            step && step > 5
              ? iconCompleted
              : iconClock
          }
        </div>
        <div className="admin-step__title">
          <span># 5:</span>
          <span>Deal made / User to sign agreement</span>
        </div>
      </div>
      {
        mode === 'PAWN' && (
          <>
            <div className="admin-steps__divider"></div>
            <div className={`admin-step ${step > 6 && 'admin-step--completed'} ${step === 6 && 'admin-step--current'}`}>
              <div className="admin-step__icon">
                {
                  step && step > 6
                    ? iconCompleted
                    : iconClock
                }
              </div>
              <div className="admin-step__title">
                <span># 6:</span>
                <span>Agreement signed / Waiting for authorization</span>
              </div>
            </div>
            <div className="admin-steps__divider"></div>
            <div className={`admin-step ${step > 7 && 'admin-step--completed'} ${step === 7 && 'admin-step--current'}`}>
              <div className="admin-step__icon">
                {
                  step && step > 7
                    ? iconCompleted
                    : iconClock
                }
              </div>
              <div className="admin-step__title">
                <span># 7:</span>
                <span>Authorized / Loan in Progress</span>
              </div>
            </div>
            <div className="admin-steps__divider"></div>
            <div className={`admin-step ${step > 8 && 'admin-step--completed'} ${step === 8 && 'admin-step--current'}`}>
              <div className="admin-step__icon">
                {
                  step && step > 8
                    ? iconCompleted
                    : iconClock
                }
              </div>
              <div className="admin-step__title">
                <span># 8:</span>
                <span>Transaction Finalized (Returned / Defaulted)</span>
              </div>
            </div>
            <div className="admin-steps__divider"></div>

          </>
        )
      }
    </div>
  </>
);

export default AdminStepper;
