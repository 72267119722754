import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Row, Col, message } from 'antd';
import styled from 'styled-components';
import { data } from '../constants';
import { searchTree } from '../helpers/tree';
import { Choosers, RedirectTo } from '../components/GeneralInfo';
import { withCategories } from '../components/withCategories';
import { quoteStore } from '../store/configureStore';
import { actionTypes } from '../store';
import { Input } from '../components/common';

type Props = {} & RouteComponentProps & WithCategoriesProps;
type State = {
  description: string;
};

const { ControlledFormTextArea } = Input;
const { FormElement } = Choosers;
const { RedirectToAttachmentsSection } = RedirectTo;

class DescriptionView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      description: '',
    };
  }

  render() {
    const { description } = this.state;
    const { categories, subCategoriesString } = this.props;

    return (
      <>
        <Container className="container">
          <Content>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <FormElement title="Describe, to the best of your ability, the item.">
                  <ControlledFormTextArea
                    placeholder="Does the item have stones? Is there any damage?"
                    onChange={this.handleDescriptionChange}
                    value={description}
                    rows={8}
                  />
                </FormElement>
              </Col>
            </Row>
          </Content>
        </Container>
        <RedirectToAttachmentsSection
          handleValidateForm={this.handleValidateForm}
          onClick={this.handleFinish}
          category={categories[0]}
          subcategory={subCategoriesString}
        />
      </>
    );
  }

  handleValidateForm = (): boolean => {
    const {
      description,
    } = this.state;

    if (!description) {
      message.warn('Please describe your item');
      return false;
    }

    return true;
  };

  handleFinish = () => {
    const { description } = this.state;

    quoteStore.dispatch({
      type: actionTypes.ADD_DESCRIPTION,
      description,
    });
  };

  handleGetImageCover = () => {
    const { categories } = this.props;

    const mainSubcategory = [...categories].pop();

    if (mainSubcategory === undefined) return '';

    const element = searchTree(data.CATEGORIES, mainSubcategory);

    if (element.imgCover === undefined) return '';

    return element.imgCover;
  };

  handleDescriptionChange = (e: string) => this.setState({ description: e });
}

const Content = styled.div`
    background-color: #ffffff;
    padding-bottom: 190px;
`;

const Container = styled.div`
  flex: 1;
`;

export default withCategories(DescriptionView);
