/* eslint-disable */
"use strict";
import MicroModal from 'micromodal';
import Rellax from 'rellax';
import AOS from 'aos';
import Splide from '@splidejs/splide';
import { runMain2 } from './main2';

window.addEventListener("DOMContentLoaded", function () {
  enableHeroScrollAnimation();
  enableHeaderTransition();
  enableModals();
  enableForms();
  enableSpoilers();
  enableHeaderFixed();
  enableSliders();
  enableParallax();
  enableChecks();
  enableAnimation(); // always last
});


export function runMain() {
  enableHeroScrollAnimation();
  enableHeaderTransition();
  enableModals();
  enableForms();
  enableSpoilers();
  enableHeaderFixed();
  enableSliders();
  enableParallax();
  enableChecks();
  runMain2();
  enableAnimation(); // always last
}

export function enableHeaderTransition() {
  let transitionElList = Array.from(
    document.querySelectorAll(".no-transition")
  );
  if (transitionElList.length) {
    setTimeout(function () {
      transitionElList.forEach(item => {
        item.classList.remove("no-transition");
      });
    }, 300);
  }
}

export function enableModals() {
  let currentOpenModalID;
  MicroModal.init({
    awaitCloseAnimation: true,
    disableFocus: true,
    disableScroll: true,
    onShow: function (modal, openBtn) {
      if (openBtn.closest(".micromodal-slide") !== null) {
        currentOpenModalID = openBtn.closest(".micromodal-slide").id;
        MicroModal.close(currentOpenModalID);
      }
      //console.info(`${modal.id} is shown`);
    },
    onClose: function (modal) {
      //console.info(`${modal.id} is hidden`);
    }
  });
}

export function enableForms() {
  // contact form
  let contactForm = document.querySelector(".js-contact-form");
  let contactFormModal = document.querySelector("#modal-send-success");

  if (contactForm !== null && contactFormModal !== null) {
    contactForm.addEventListener("submit", function (e) {
      e.preventDefault();
      contactForm.reset();
      MicroModal.show("modal-send-success");
    });
  }
}

export function enableSpoilers() {
  let spoilerToggleList = Array.from(
    document.querySelectorAll(".spoiler__toggle")
  );

  spoilerToggleList.forEach(toggle => {
    toggle.addEventListener("click", toggleSpoiler);
    toggle.addEventListener("touch", toggleSpoiler, {passive: true});
  });

  let toggleSpoilerTarget;
  let toggleSpoilerTargetContent;

  function toggleSpoiler(e) {
    toggleSpoilerTarget = e.target.closest(".spoiler");
    if (toggleSpoilerTarget.classList.contains("spoiler--animated")) {
      return;
    }
    toggleSpoilerTargetContent = toggleSpoilerTarget.querySelector(
      ".spoiler__content"
    );
    toggleSpoilerTarget.classList.add("spoiler--animated");
    toggleSpoilerTarget.classList.toggle("spoiler--active");
    DOMAnimations.slideToggle(toggleSpoilerTargetContent, 500, function () {
      toggleSpoilerTarget.classList.remove("spoiler--animated");
    });
  }
}

export function enableHeroScrollAnimation() {
  let hero = document.querySelector(".hero");
  if (hero !== null) {
    let heroContent = hero.querySelector(".hero__content");
    let heroAppend = hero.querySelector(".hero__append");

    if (!window.matchMedia("(max-width: 1199.98px)").matches) {
      let isFirstScroll = true;
      let body = document.querySelector("body");
      if (
        !window.pageYOffset &&
        !("ontouchstart" in document.documentElement)
      ) {
        body.classList.add("overflow-hidden");
        setTimeout(function () {
          window.addEventListener("wheel", function (e) {
            if (isFirstScroll) {
              isFirstScroll = false;
              if (heroContent && heroAppend) {
                heroContent.classList.add("hero__content--out");
                heroAppend.classList.add("hero__append--in");
                heroAppend.style.bottom = hero.offsetHeight / 2 + "px";
              }
              setTimeout(function () {
                body.classList.remove("overflow-hidden");
              }, 900);
            }
          }, {passive: true});
        }, 1800);
      } else {
        if (heroAppend) {
          heroAppend.classList.add("hero__append--hidden");
          isFirstScroll = false;
          heroContent.classList.add("hero__content--out");
          heroAppend.classList.add("hero__append--in");
          heroAppend.style.bottom = hero.offsetHeight / 2 + "px";
        }
      }
    }
  }
}

export function enableChecks() {
  let body = document.querySelector("body");

  "ontouchstart" in document.documentElement
    ? body.classList.add("is-touch")
    : "";
}

export function enableHeaderMenu() {
  let headerBurger = document.querySelector(".header__burger");

  let headerMobileMenu = document.querySelector(".header__group--mobile-menu");

  if (headerBurger !== null && headerMobileMenu !== null) {
    headerBurger.addEventListener("click", toggleHeaderBurger);
    headerBurger.addEventListener("touch", toggleHeaderBurger, {passive: true});
  }
}

export function toggleHeaderBurger() {
  const headerBurger = document.querySelector(".header__burger");
  if (!headerBurger)
    return
  headerBurger.classList.toggle("header__burger--active");
  document.addEventListener("click", function (e) {
    closeHeaderMenu(e, headerBurger);
  });
  document.addEventListener("touch", function (e) {
    closeHeaderMenu(e, headerBurger);
  }, {passive: true})
}


export function closeHeaderMenu(e, headerBurger) {
  if (headerBurger !== null && e.target.closest(".header") === null) {
    headerBurger.classList.remove("header__burger--active");
  }
  document.removeEventListener("touch", closeHeaderMenu);
  document.removeEventListener("click", closeHeaderMenu);
}

export function enableSliders() {
  let commentsSliderList = Array.from(
    document.querySelectorAll(".section-comments__slider .splide")
  );

  commentsSliderList.forEach(object => {
    createCommentsSlider(object);
  });

  function createCommentsSlider(object) {
    let slider = new Splide(object, {
      type: "loop",
      perMove: 1,
      gap: 70,
      speed: 500,
      autoplay: true,
      drag: true,
      arrows: true,
      pagination: false
    }).mount();
  }
}

export function enableParallax() {
  if (!window.matchMedia("(max-width: 1199.98px)").matches) {
    let rellax = new Rellax(".rellax");
  }
}

export function enableAnimation() {
  AOS.init({
    disable: function () {
      if (window.matchMedia("(max-width: 991.98px)").matches) {
        return true;
      }
      return false;
    }
  });
}

export function enableHeaderFixed() {
  let pageHeader = document.querySelector(".header");

  let scrollTop;
  function checkHeaderState() {
    scrollTop =
      window.scrollY ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (scrollTop >= 50) {
      pageHeader.classList.add("header--white");
    } else {
      pageHeader.classList.remove("header--white");
    }
  }

  if (pageHeader) {
    checkHeaderState();
    window.addEventListener("scroll", function () {
      checkHeaderState();
    });
  }
}

// Closest polyfill
(function () {
  if (!Element.prototype.closest) {
    Element.prototype.closest = function (css) {
      let node = this;
      while (node) {
        if (node.matches(css)) return node;
        else node = node.parentElement;
      }
      return null;
    };
  }
})();

// Matches polyfill
(function () {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector;
  }
})();

export function fadeOut(el, callback) {
  el.style.opacity = 1;

  (function fade() {
    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = "none";
      if (callback) {
        callback();
      }
    } else {
      requestAnimationFrame(fade);
    }
  })();
}

export function fadeIn(el, display, callback) {
  el.style.opacity = 0;
  el.style.display = display || "block";

  (function fade() {
    let val = parseFloat(el.style.opacity);
    if (!((val += 0.1) > 1)) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    } else {
      if (callback) {
        callback();
      }
    }
  })();
}

export function onImagesLoaded(container, event) {
  let images = container.getElementsByTagName("img");
  let loaded = images.length;
  for (let i = 0; i < images.length; i++) {
    if (images[i].complete) {
      loaded--;
    } else {
      images[i].addEventListener("load", function () {
        loaded--;
        if (loaded == 0) {
          event();
        }
      });
    }
    if (loaded == 0) {
      event();
    }
  }
}

export function nextElementSibling(elem) {
  do {
    elem = elem.nextSibling;
  } while (elem && elem.nodeType !== 1);

  return elem;
}

export function prevElementSibling(elem) {
  do {
    elem = elem.previousSibling;
  } while (elem && elem.nodeType !== 1);

  return elem;
}

/*
DOMAnimations.slideToggle(
  item.querySelector(".accordion__content"),
  accordionDelay
);
*/
let DOMAnimations = {
  /**
   * SlideUp
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @returns {Promise<boolean>}
   */
  slideUp: function (element, duration = 500, callback) {
    return new Promise(function (resolve, reject) {
      element.style.height = element.offsetHeight + "px";
      element.style.transitionProperty = `height, margin, padding`;
      element.style.transitionDuration = duration + "ms";
      element.offsetHeight;
      element.style.overflow = "hidden";
      element.style.height = 0;
      element.style.paddingTop = 0;
      element.style.paddingBottom = 0;
      element.style.marginTop = 0;
      element.style.marginBottom = 0;
      window.setTimeout(function () {
        element.style.display = "none";
        element.style.removeProperty("height");
        element.style.removeProperty("padding-top");
        element.style.removeProperty("padding-bottom");
        element.style.removeProperty("margin-top");
        element.style.removeProperty("margin-bottom");
        element.style.removeProperty("overflow");
        element.style.removeProperty("transition-duration");
        element.style.removeProperty("transition-property");
        if (callback) {
          callback();
        }
        resolve(false);
      }, duration);
    });
  },

  /**
   * SlideDown
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @returns {Promise<boolean>}
   */
  slideDown: function (element, duration = 500, callback) {
    return new Promise(function (resolve, reject) {
      element.style.removeProperty("display");
      let display = window.getComputedStyle(element).display;

      if (display === "none") display = "block";

      element.style.display = display;
      let height = element.offsetHeight;
      element.style.overflow = "hidden";
      element.style.height = 0;
      element.style.paddingTop = 0;
      element.style.paddingBottom = 0;
      element.style.marginTop = 0;
      element.style.marginBottom = 0;
      element.offsetHeight;
      element.style.transitionProperty = `height, margin, padding`;
      element.style.transitionDuration = duration + "ms";
      element.style.height = height + "px";
      element.style.removeProperty("padding-top");
      element.style.removeProperty("padding-bottom");
      element.style.removeProperty("margin-top");
      element.style.removeProperty("margin-bottom");
      window.setTimeout(function () {
        element.style.removeProperty("height");
        element.style.removeProperty("overflow");
        element.style.removeProperty("transition-duration");
        element.style.removeProperty("transition-property");
        if (callback) {
          callback();
        }
      }, duration);
    });
  },

  /**
   * SlideToggle
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @returns {Promise<boolean>}
   */
  slideToggle: function (element, duration = 500, callback) {
    if (window.getComputedStyle(element).display === "none") {
      return this.slideDown(element, duration, callback);
    } else {
      return this.slideUp(element, duration, callback);
    }
  }
};

/* IE Polyfull */

if (typeof Object.assign != "function") {
  Object.defineProperty(Object, "assign", {
    value: function assign(target, letArgs) {
      "use strict";
      if (target == null) {
        throw new TypeError("Cannot convert undefined or null to object");
      }
      let to = Object(target);
      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];
        if (nextSource != null) {
          for (let nextKey in nextSource) {
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

if (!Array.from) {
  Array.from = (function () {
    let toStr = Object.prototype.toString;
    let isCallable = function (fn) {
      return typeof fn === "function" || toStr.call(fn) === "[object Function]";
    };
    let toInteger = function (value) {
      let number = Number(value);
      if (isNaN(number)) {
        return 0;
      }
      if (number === 0 || !isFinite(number)) {
        return number;
      }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    let maxSafeInteger = Math.pow(2, 53) - 1;
    let toLength = function (value) {
      let len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };

    return function from(arrayLike) {
      let C = this;
      let items = Object(arrayLike);
      if (arrayLike == null) {
        throw new TypeError(
          "Array.from requires an array-like object - not null or undefined"
        );
      }
      let mapFn = arguments.length > 1 ? arguments[1] : void undefined;
      let T;
      if (typeof mapFn !== "undefined") {
        if (!isCallable(mapFn)) {
          throw new TypeError(
            "Array.from: when provided, the second argument must be a function"
          );
        }
        if (arguments.length > 2) {
          T = arguments[2];
        }
      }
      let len = toLength(items.length);
      let A = isCallable(C) ? Object(new C(len)) : new Array(len);
      let k = 0;
      let kValue;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] =
            typeof T === "undefined"
              ? mapFn(kValue, k)
              : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      A.length = len;
      return A;
    };
  })();
}

export function debounce(func) {
  let wait =
    arguments.length <= 1 || arguments[1] === undefined ? 100 : arguments[1];

  let timeout = void 0;
  return function () {
    let _this = this;

    for (
      let _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    clearTimeout(timeout);
    timeout = setTimeout(function () {
      func.apply(_this, args);
    }, wait);
  };
}

export function is_touch_device() {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
}
