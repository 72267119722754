/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Form } from 'antd';
import { Spinner } from 'components/common';
import { useState, useEffect } from 'react';
import fonts from '../../constants/fonts';
import ContactUs from './ContactUs';

const { mainFont } = fonts.fonts;

type Props = {
  isVisible: boolean;
  isSendingEmail: boolean;
  onCloseModal: () => void;
  onSubmit: (email: string) => void;
};

const RecoverPasswordModal = ({
  isVisible,
  isSendingEmail,
  onCloseModal,
  onSubmit,
}: Props) => {
  const [isContactUsVisible, setIsContactUsVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, isVisible]);

  return (
    <>
      <div className={`popup ${isVisible && 'popup--shown'}`} style={{ fontFamily: mainFont }}>
        <div className="popup__wrap popup__wrap--wide">
          <a className="popup__close" onClick={onCloseModal} />
          <div className="popup__inner">
            <div className="popup__header">
              <div className="popup__title">Recover Password</div>
            </div>
            <div className="popup__content">
              <Form
                className="login-form"
                form={form}
                onFinish={onSubmit}
              >
                <div className="login-form__body">
                  <div className="form-input">
                    <Form.Item
                      rules={[
                        {
                          type: 'email',
                          message: '',
                        },
                        {
                          required: true,
                          message: 'Email is required',
                        },
                      ]}
                      name="email"
                      required
                    >
                      <input type="email" placeholder="Email" />
                    </Form.Item>
                  </div>
                </div>
                <div className="login-form__footer">
                  <button
                    className="btn btn--dark popup__btn popup__btn--wide login-form__submit"
                    type="submit"
                    disabled={isSendingEmail}
                  >
                    {
                      isSendingEmail
                        ? <Spinner loaderSize={{ width: 20, height: 20 }} tip={' '} style={{ marginTop: 20 }} />
                        : ' Send Recovery Link'
                    }
                  </button>
                  <div className="form-input__footer">
                    <span>Have a questions, problems?</span>
                    <a style={{ color: '#4993c6' }} onClick={() => setIsContactUsVisible(true)}>Contact Us</a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ContactUs
        isVisible={isContactUsVisible}
        onCancel={() => setIsContactUsVisible(false)}
      />
    </>
  );
};

export default RecoverPasswordModal;
