import React from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import { text } from '../../constants';

type Props = {
  status?: string,
  quoteValue?: number,
  title?: string,
  render?: JSX.Element | JSX.Element[],
  children?: JSX.Element | JSX.Element[],
};
const StateActionCard: React.FC<Props> = ({
  status, quoteValue, title, render, children,
}: Props) => (
  <Space direction="vertical">
    {render || (
      <>
        <CardTitle>{`${status} ${quoteValue ? `$${quoteValue}` : ''}`}</CardTitle>
        <CardTitle>{title}</CardTitle>
      </>
    )}
    {children}
  </Space>
);

const CardTitle = styled.span`
  ${text['title-20']}
  color: #4F6470;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default StateActionCard;
