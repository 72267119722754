import { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { runMain } from '../../assets/js/main';
import Edit from '../../assets/images/Edit';
import { ChangeButton } from '../common/Button';
import { Separator } from '../common';

const CategoryBreadcrumb = () => {
  const history = useHistory();

  useEffect(() => {
    runMain();
  }, []);

  const getPathNames = () => history.location.pathname.split('/').slice(1);

  const paths = getPathNames();

  const routes = paths.map(p => ({
    path: p,
    breadcrumbName: p,
  }));

  const itemRender = (route: any, params: any, routes: any, paths: any) => {
    const idx = routes.indexOf(route);
    const isLast = idx === routes.length - 1;

    if (isLast) {
      return (
        <span className="select-text">
          <span>Select a</span>
          &nbsp;
          <span className="select-text-category">{route.breadcrumbName}</span>
          &nbsp;
          <span>Subcategory</span>
        </span>
      );
    }

    return (
      <>
        {routes[idx + 1].breadcrumbName}
        <Link to={`/${paths.join('/')}`}>
          <ChangeButton icon={<Edit />}>Change</ChangeButton>
        </Link>
      </>
    );
  };

  return (
    <div style={{ color: '#000' }}>
      <CategoriesBreadcrumb separator={<Separator />} routes={routes} itemRender={itemRender} />
    </div>
  );
};

const CategoriesBreadcrumb = styled<any>(Breadcrumb)`
  border: none;
  padding: 30px 0;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 28px;
  color: #4F6470;
  font-weight: 500;
  font-family: Poppins;

  .select-text {
    text-transform: none;
    color: #707F89;
  }

  .select-text-category {
    text-transform: capitalize !important;
  }
`;

export default CategoryBreadcrumb;
