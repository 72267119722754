/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
type Props = {
  isVisible: boolean;
  mode: 'accept' | 'reject';
  onCancel: () => void;
  onOk: (mode: 'accept' | 'reject') => void;
};

const AcceptOffer = ({
  isVisible,
  mode,
  onCancel,
  onOk,
}: Props) => (
  <div className={`popup  marks-wrap marks-wrap--green  ${isVisible && mode === 'accept' && 'popup--shown'}`}>
    <div className="popup__wrap">
      <div className="popup__inner">
        <div className="popup__header">
          <div className="popup__text popup__text--big">
            This request will be
            <mark>accepted.</mark>
            <br />
            Are you sure you want to proceed?
          </div>
        </div>
        <div className="popup__content">
          <div className="popup__image popup__image--green">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18C32.9909 26.2805 26.2805 32.9909 18 33ZM17.976 30H18C24.6251 29.9934 29.9913 24.6191 29.988 17.994C29.9847 11.3689 24.6131 6.00001 17.988 6.00001C11.3629 6.00001 5.99131 11.3689 5.988 17.994C5.98469 24.6191 11.3509 29.9934 17.976 30ZM15 25.5L9 19.5L11.115 17.385L15 21.255L24.885 11.37L27 13.5L15 25.5Z" />
            </svg>
          </div>
        </div>
        <div className="popup__footer">
          <div className="popup__buttons popup__buttons--horizontal">
            <div
              className="popup__btn btn btn--bordered btn--bordered-dark popup__btn--static-width"
              onClick={onCancel}
            >
              Go Back
            </div>
            <div
              className="popup__btn btn btn--green popup__btn--static-width"
              onClick={() => onOk(mode)}
            >
              Yes, Accept
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AcceptOffer;
