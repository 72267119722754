import Button, { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';
import { darken } from 'polished';

import { colors } from '../constants';

const StyledButton = styled(Button)`
    background-color: ${colors.button.primary};
    border: none;

    &:hover, &:active, &:focus {
        background-color: ${darken(0.2, colors.button.primary)};
    }
`;

export default function CustomPrimaryButton(props: ButtonProps): JSX.Element {
  return (
    <StyledButton {...props} type="primary">
      {props.children}
    </StyledButton>
  );
}
