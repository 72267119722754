import { Component } from 'react';
import styled from 'styled-components';
import { Link, RouteComponentProps } from 'react-router-dom';
import { userStore } from '../../store';
import { RegisterModal, LoginModal } from '../Modals';
import { api } from '../../api';

type Props = {
  handleLogout: () => void;
} & RouteComponentProps;

type State = {
  isRegistering: boolean;
  isLogging: boolean;
  storeLink: string;
};

export default class Footer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLogging: false,
      isRegistering: false,
      storeLink: '',
    };
  }

  componentDidMount() {
    this.fetchStoreLink();
  }

  render() {
    const { isLogging, isRegistering, storeLink } = this.state;
    const { id } = userStore.getState();

    return (
      <>
        <footer className="footer">
          <div className="container">
            <div className="mobile-container">
              <div className="footer__top">
                <p className="footer__title">E&B Pawnbrokers</p>
                <div className="footer__link-list">
                  <Link className="footer__link" to="/contact-us">
                    <span>
                      Contact
                    </span>
                  </Link>
                  <Link className="footer__link" to="/about">
                    <span>
                      About
                    </span>
                  </Link>
                  <Link className="footer__link" to="/faq">
                    <span>
                      FAQ
                    </span>
                  </Link>
                  <Button
                    border={false}
                    className="footer__link"
                    onClick={() => storeLink && window.open(storeLink, '_blank')}
                  >
                    <span>
                      Store
                    </span>
                  </Button>
                </div>
                <div className="footer__btn-list">
                  {
                    id ? (
                      <Button
                        border
                        type="submit"
                        className="footer__btn btn btn--outline-white footer__btn--mobile-half"
                        onClick={this.props.handleLogout}
                      >
                        LOGOUT
                      </Button>
                    ) : (
                      <Button
                        border
                        type="submit"
                        className="footer__btn btn btn--outline-white footer__btn--mobile-half"
                        onClick={() => this.handleIsLoggingChange(true)}
                      >
                        LOGIN
                      </Button>
                    )
                  }
                  <Link
                    to="/categories"
                    className="footer__btn btn btn--white footer__btn--mobile-full"
                  >
                    Get an Offer
                  </Link>
                </div>
              </div>
              <div className="footer__bottom">
                <div className="row">
                  <div className="col-xl-2 col-lg-3 col-sm-12 col-6">
                    <p className="footer__caption">
                      Call: &#160;
                      <a href="tel:8562352463">856-235-2463</a>
                    </p>
                  </div>
                  <div className="col-xl-2 col-lg-3 col-sm-12 col-6">
                    <p className="footer__caption">
                      Write: &#160;
                      <LinkButton
                        type="button"
                        onClick={() => window.smartsupp('chat:open')}
                      >
                        CHAT WITH US
                      </LinkButton>
                    </p>
                  </div>
                  <div className="col-xl-8 col-lg-6 text-lg-right col-12">
                    <p className="footer__caption">
                      Come: &#160;
                      <Link to="/">110 W Camden Ave, Moorestown, NJ 08057</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {
          isRegistering && (
            <RegisterModal
              isVisible={isRegistering}
              handleLoginModal={this.handleIsLoggingChange}
              handleRegisterModal={this.handleIsRegisteringChange}
            />
          )
        }
        {
          isLogging && (
            <LoginModal
              isVisible={isLogging}
              handleLoginModal={this.handleIsLoggingChange}
              handleRegisterModal={this.handleIsRegisteringChange}
            />
          )
        }
      </>
    );
  }

  handleIsLoggingChange = (isLogging: boolean) => this.setState({ isLogging });

  handleIsRegisteringChange = (isRegistering: boolean) => this.setState({ isRegistering });

  fetchStoreLink = () => {
    api
      .get('/configuration/store-link')
      .then(res => res.data)
      .then(({ content }) => this.setState({ storeLink: content }))
      .catch(() => this.setState({ storeLink: '' }));
  };
}

type ButtonProps = {
  border: boolean
};

const Button = styled.button<ButtonProps>`
  background: none;
  border: ${props => (props.border ? '' : 'none')};
`;

const LinkButton = styled.button`
  text-decoration: underline;
  background-color: unset;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
`;
