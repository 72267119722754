type Props = {
  loanData?: Loan;
  quoteValue: number;
  loanDelayed?: { isDelayed: boolean, delayDays: number };
};

const AdminLoanIsActive = ({ loanData, quoteValue, loanDelayed }: Props) => (
  <div className="dashboard-columns__right">
    <div className="dashboard-infocard">
      <div className="popup__header">
        <div className="popup__title">
          Loan
          {loanDelayed?.isDelayed}
          {
            loanDelayed?.isDelayed
              ? <span style={{ color: '#d87d7d' }}> Delayed</span>
              : ' in Progress'
          }
        </div>
      </div>
      <div className="popup__content">
        <div className="attachments-card__info">
          Rate:
          <span>
            {` ${((loanData?.monthlyPercentageInterestRate || 0) * 100).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}
            % per month (
            {((loanData?.yearlyPercentageInterestRate || 0) * 100).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            % APR)
          </span>
        </div>
        <div className="attachments-card__info">
          Total interests:
          <span>
            {' $'}
            {(quoteValue * (loanData?.monthlyPercentageInterestRate || 0) * 3).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
        <div className="popup__price popup__price--small popup__price--green">
          $
          {loanData?.loanAmount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="popup__footer" />
    </div>
  </div>
);

export default AdminLoanIsActive;
