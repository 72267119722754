/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash';
import { Pagination } from 'antd';
import { api } from '../api';
import { Button, Spinner, Input } from '../components/common';
import ResourceTable from '../components/ResourceTable';
import { userStore } from '../store';
import ShowNotificationBox from '../components/Notifications/TableItems/ShowNotificationsBox';

const { ActionButton } = Button;
const { SearchInput } = Input;

interface Props extends RouteComponentProps { }

type State = {
  quotes: Quote[],
  isLoading: boolean,
  category: string,
  filter: string,
  current: number,
  limit: number,
  total: number,
};

export default class RequestsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      quotes: [],
      category: '',
      isLoading: true,
      filter: '',
      current: 1,
      limit: 5,
      total: 0,
    };
  }

  componentDidMount() {
    const category = this.handleGetFilterFromPathName();
    this.setState({ category }, () => this.fetchRequest());
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const category = this.handleGetFilterFromPathName();
      this.setState({ category }, () => this.fetchRequest());
    }
  }

  render() {
    const {
      quotes,
      isLoading,
      filter,
      limit,
      current,
      total,
    } = this.state;

    if (isLoading) return <Spinner />;

    return (
      <div className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <form>
              <Filter>
                <SearchInput
                  value={filter}
                  onChange={e => this.handleFilterChange(e)}
                  placeholder="Search by name or number"
                />
                <ActionButton
                  type="submit"
                  className="search-button"
                  onClick={() => this.fetchRequest()}
                >
                  Search
                </ActionButton>
              </Filter>
            </form>
            <ResourceTable
              customColumns={this.handleGetColumnsByRole()}
              dataSource={quotes}
              callback={this.navigateToDetailsView}
            />
            <CPagination
              showSizeChanger
              onShowSizeChange={this.onShowSizeChange}
              onChange={this.onChange}
              current={current}
              pageSizeOptions={['2', '5', '10', '20']}
              pageSize={limit}
              total={total}
            />
          </div>
        </div>
      </div>
    );
  }

  handleGetColumnsByRole = () => {
    const { role } = userStore.getState();
    return role === 'admin' ? this.customAdminColumns : this.customColumns;
  };

  onShowSizeChange = (_: number, limit: number) => this.setState({ limit }, () => this.fetchRequest());

  onChange = (page: number) => this.setState({ current: page }, () => this.fetchRequest());

  handleFilterChange = (value: string) => this.setState({ filter: value });

  handleGetFilterFromPathName = (): string => {
    const {
      location: { pathname },
    } = this.props;

    if (!pathname || typeof pathname !== 'string') return 'all';

    const paths = pathname.split('/');
    const lastPath = paths.pop();

    if (lastPath === 'requests' || lastPath === undefined) return 'all';

    return lastPath;
  };

  navigateToDetailsView = (id: String): void => this.props.history.push(`/request-details/${id}`);

  fetchRequest = () => {
    const {
      filter,
      limit,
      current,
      category,
    } = this.state;

    const offset = (current - 1) * (limit || 0);

    this.setState({ isLoading: true });

    api.get('/quotes', {
      params: {
        category,
        filter,
        limit,
        offset,
      },
    })
      .then(res => res.data)
      .then(({ quotes, total }) => this.setState({ quotes, total }))
      .finally(() => this.setState({ isLoading: false }));
  };

  customColumns = [
    {
      title: 'Number',
      key: 'id',
      dataIndex: 'custom_id',
    },
    {
      title: 'Name', key: 'name', render: ({ name }: Quote) => <>{upperFirst(name)}</>,
    },
    {
      title: 'Items', key: 'items', dataIndex: 'items', render: (items: number) => <>{items > 0 ? items : 1}</>,
    },
    {
      title: 'Last proposal',
      render: ({ context: { quoteValue } }: Quote) => (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(quoteValue)}
        </>
      ),
    },
    {
      title: 'Updated',
      key: 'updated_at',
      render: ({ updatedAt }: Quote) => <>{dayjs(updatedAt).format('MM.DD.YYYY h:mm A')}</>,
    },
    {
      title: 'Status',
      key: 'state',
      render: ({ context: { status }, state }: any) => <ShowNotificationBox type={state} notificationText={status} />,
    },
    {
      title: 'User action',
      key: 'action',
      render: ({ context: { isAdminAction }, state }: Quote) => (
        <>
          {
            !isAdminAction && state !== 'rejected'
              ? <div className="status status--yellow">Required</div>
              : '-'
          }
        </>
      )
      ,
    },
    {
      title: '',
      key: 'actions',
      render: ({ groupId }: Quote) => (
        <>
          <a
            className=" btn btn--alt-font btn--bordered btn--bordered-dark table__btn"
            onClick={() => this.navigateToDetailsView(groupId)}
          >Details
          </a>
        </>
      ),
    },
  ];

  customAdminColumns = [
    {
      title: 'Number',
      key: 'id',
      dataIndex: 'custom_id',
    },
    {
      title: 'Name', key: 'name', render: ({ name }: Quote) => <>{upperFirst(name)}</>,
    },
    {
      title: 'Customer Name',
      key: 'customer',
      render: (({ firstName, lastName }: any) => (
        <>
          {`${firstName} ${lastName}`}
        </>
      )),
    },
    {
      title: 'Items', key: 'items', dataIndex: 'items', render: (items: number) => <>{items > 0 ? items : 1}</>,
    },
    {
      title: 'Last proposal',
      render: ({ context: { quoteValue } }: Quote) => (
        <>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(quoteValue)}
        </>
      ),
    },
    {
      title: 'Updated',
      key: 'updated_at',
      render: ({ updatedAt }: Quote) => <>{dayjs(updatedAt).format('MM.DD.YYYY h:mm A')}</>,
    },
    {
      title: 'Status',
      key: 'state',
      render: ({ context: { status }, state }: any) => <ShowNotificationBox type={state} notificationText={status} />,
    },
    {
      title: 'Admin action',
      key: 'action',
      render: ({ context: { isAdminAction }, state }: Quote) => (
        <>
          {
            isAdminAction && state !== 'rejected'
              ? <div className="status status--yellow">Required</div>
              : '-'
          }
        </>
      )
      ,
    },
    {
      title: '',
      key: 'actions',
      render: ({ groupId }: Quote) => (
        <a
          className="btn btn--alt-font btn--bordered btn--bordered-dark table__btn"
          onClick={() => this.navigateToDetailsView(groupId)}
        >Details
        </a>
      ),
    },
  ];
}

const Filter = styled.div`
              display: flex;
              flex-direction: row;
              align-items: center;
              .search-button {
                height: 60px;
              margin-left: 1%;
  }
              `;

const CPagination = styled(Pagination)`
              margin-bottom: 6%;
              margin-top: 1%;
              `;
