import Categories from '../Categories';

const Offer = () => (
  <div className="section section-offer">
    <img
      className="section-offer__line section-offer__line--top"
      src="images/line-1-gold.svg"
      alt="line"
      data-aos="line-clip-patch"
      data-aos-delay="000"
      data-aos-duration={1500}
      data-aos-easing="ease-in-out"
      data-aos-once="true"
      data-aos-anchor-placement="center-bottom"
    />
    <img
      className="section-offer__line"
      src="images/line-2-gold.svg"
      alt="line"
      data-aos="line-clip-patch"
      data-aos-delay={500}
      data-aos-duration={1500}
      data-aos-easing="ease-in-out"
      data-aos-once="true"
      data-aos-anchor-placement="center-bottom"
    />
    <div className="container">
      <div className="mobile-container">
        <div className="section__header section-offer__header">
          <h2 className="section__title" style={{ color: '#4f6470' }}>
            Get &#160;
            <span className="gradient-color">
              the best offer
              <br />
            </span>
            in your item’s category
          </h2>
          <img
            className="section-offer__header-icon"
            data-aos="fade-up"
            data-aos-delay={0}
            data-aos-duration={800}
            data-aos-easing="ease-in-out"
            data-aos-once="true"
            data-aos-mirror="true"
            data-aos-anchor-placement="center-bottom"
            src="images/sprites/main.stack.svg#image-diamons-gold"
            alt="diamond"
          />
        </div>
        <Categories />
      </div>
    </div>
  </div>

);

export default Offer;
