import { Row, Col, RowProps } from 'antd';
import InfoTile from './InfoTile';
import offerImage from '../assets/images/whatsnew-icons/offer.png';
import paidImage from '../assets/images/whatsnew-icons/paid.png';
import reviewImage from '../assets/images/whatsnew-icons/review.png';
import shipImage from '../assets/images/whatsnew-icons/ship.png';
import signImage from '../assets/images/whatsnew-icons/sign.png';

const HowItWorks = (props: RowProps) => (
  <div style={{ marginBottom: '5%' }}>
    <Row {...props}>
      <Col>
        <InfoTile
          leftHeaderText="1."
          imageSrc={offerImage}
          title="Get the Final Offer"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, ..."
        />
      </Col>
      <Col>
        <InfoTile
          leftHeaderText="2."
          imageSrc={shipImage}
          title="Ship the Item"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, ..."
        />
      </Col>
      <Col>
        <InfoTile
          leftHeaderText="3."
          imageSrc={reviewImage}
          title="Pass Quality Review"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, ..."
        />
      </Col>
      <Col>
        <InfoTile
          leftHeaderText="4."
          imageSrc={signImage}
          title="Sign Agreement"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, ..."
        />
      </Col>
      <Col>
        <InfoTile
          leftHeaderText="5."
          imageSrc={paidImage}
          title="Get Paid"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, ..."
        />
      </Col>
    </Row>
  </div>
);

export default HowItWorks;
