const text = {
  dark: '#4F6470',
  white: '#FFFFFF',
  light: '#707F89',
  body: '#4F6470',
  2: '#575C60',
  // selected: '#9D904E',
  selected: '#c0a725',
  green: '#7EC099',
  red: '#D87D7D',
};

const button = {
  primary: '#526B7C',
};
const icon = '#99ADBA';
const background = {
  widget: 'linear-gradient(180deg, #99ADBA 0%, #ECF2F8 100%)',
  'widget-white': '#FFFFFF',
  selected: 'rgba(253, 226, 85, 0.1);',
  main: '#faf9f7',
  inputNumber: '#E8F1F7',
};

const table = {
  tr: '#faf9f7',
  hover: 'rgba(0, 0, 0, 0.05)',
};

const textFields = '#E8F1F6';
const selected = '#FDE255';

const metal = {
  gold: `radial-gradient(60.36% 60.36% at 54.5% 37.39%,
            rgba(255, 255, 255, 0) 59.65%,
            rgba(255, 255, 255, 0.05) 74.25%,
            rgba(255, 255, 255, 0.05) 83.23%,
            rgba(255, 255, 255, 0) 100%),
            radial-gradient(39.19% 39.19% at 64.41% 30.18%, #FFFBF0 0%, #FFE6AD 100%);`,
  sterling: `radial-gradient(60.36% 60.36% at 54.5% 37.39%,
                rgba(255, 255, 255, 0) 59.65%,
                rgba(255, 255, 255, 0.05) 74.25%,
                rgba(255, 255, 255, 0.05) 83.23%,
                rgba(255, 255, 255, 0) 100%),
                radial-gradient(39.19% 39.19% at 64.41% 30.18%, #FFFFFF 0%, #D7FDEF 100%);`,
  platinium: `radial-gradient(60.36% 60.36% at 54.5% 37.39%,
                    rgba(255, 255, 255, 0) 59.65%,
                    rgba(255, 255, 255, 0.05) 74.25%,
                    rgba(255, 255, 255, 0.05) 83.23%,
                    rgba(255, 255, 255, 0) 100%),
                    radial-gradient(39.19% 39.19% at 64.41% 30.18%, #FFFFFF 0%, #D1DDE4 100%);`,
  other: ` radial-gradient(60.36% 60.36% at 54.5% 37.39%,
                rgba(255, 255, 255, 0) 59.65%,
                rgba(255, 255, 255, 0.05) 74.25%,
                rgba(255, 255, 255, 0.05) 83.23%,
                rgba(255, 255, 255, 0) 100%),
                radial-gradient(39.19% 39.19% at 64.41% 30.18%, #FFFFFF 0%, #DBD2F7 100%);`,
};

export {
  text, button, background, icon, table, textFields, selected, metal,
};
