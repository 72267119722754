/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PlaidLink, Spinner } from 'components/common';
import dayjs from 'dayjs';
import { MANUAL_PAYMENT_OPTIONS } from '../../../../constants/data';
import { userStore } from '../../../../store';

type Props = {
  loanData?: Loan | any;
  quoteValue: number;
  signedAutoWithdraw?: string;
  errorRecurringPayment: boolean;
  isPaymentSuccess: boolean;
  showPaymentNotification: boolean;
  loanClosed?: boolean;
  isConnectButtonVisible?: boolean;
  onAuthorize: () => void;
  onAuthorizeChangeBankAccount: () => void;
  onContactUs: () => void;
  onOptOut: () => void;
  onChangeBankAccount: () => void;
  onUpdatePaymentDetails: () => void;
  payWithCreditCard?: () => void;
  payWithBankAccount?: () => void;
  onClosePaymentNotification?: () => void;
  onMakeManualPayment: (payWith: string, option: string, otherAmount?: string) => void;
};

const LoanBalance = ({
  loanData, errorRecurringPayment, isPaymentSuccess, showPaymentNotification, loanClosed, isConnectButtonVisible, onAuthorize, onAuthorizeChangeBankAccount, onContactUs, onUpdatePaymentDetails, onOptOut, onClosePaymentNotification, onMakeManualPayment,
}: Props) => {
  const [otherAmountValue, setOtherAmountValue] = useState('$0');
  const [isActiveInterest, setIsActiveInterest] = useState([true, false, false, false]);
  const [manualPaymentOption, setManualPaymentOption] = useState(MANUAL_PAYMENT_OPTIONS[0]);
  const [isChangingBankAccount, setIsChangingBankAccount] = useState(false);

  const signedAutoWithdraw = userStore.getState().bankAccount && !isChangingBankAccount;
  const bankAccountId = userStore.getState().bankAccount;

  const handleSelectedInterest = (activeInterest: number) => {
    const temporaryInterest = [false, false, false, false];
    temporaryInterest[activeInterest] = true;
    setIsActiveInterest(temporaryInterest);
    setManualPaymentOption(MANUAL_PAYMENT_OPTIONS[activeInterest]);
    setOtherAmountValue('$0');
  };

  const handleOtheAmountValue = (e: any) => {
    const maxAmountValue = (loanData?.loanAmount || 0) + (rate || 0);
    const currentValue = e.target.value;
    if (Number(currentValue) > maxAmountValue) {
      return setOtherAmountValue(maxAmountValue);
    }
    setOtherAmountValue(currentValue);
  };

  const paymentDate = new Date();
  const diff = dayjs(paymentDate).diff(loanData?.startDate, 'd');
  const rate = parseFloat(((loanData?.monthlyPercentageInterestRate || 0) * (loanData?.loanAmount / 30) * diff)
    .toFixed(2));

  const isPaymentProcessing = loanData?.paymentProcessing;
  const payCardButtonDisabled = isActiveInterest[0] || showPaymentNotification || isPaymentProcessing;
  const payBankAccountButtonDisabled = !signedAutoWithdraw || showPaymentNotification || isPaymentProcessing;

  const typeConnection = loanData?.isOptOut ? '' : 'CHANGE_BANK_ACCOUNT';

  return (
    <div style={{ fontFamily: 'Poppins' }}>
      {
        errorRecurringPayment && (
          <div className="dashboard-status dashboard-status--error">
            <div className="dashboard-status__title">The recurring payment has not been completed</div>
            <div className="dashboard-status__text">
              It seems that there was a problem with your payment method when collecting the automatic interest payment.
              <span onClick={onUpdatePaymentDetails}> Click here to update your payment details </span>
              or contact E&B to resolve the issue.
            </div>
          </div>
        )
      }
      {
        showPaymentNotification && isPaymentSuccess && !loanClosed && (
          <div className="dashboard-status dashboard-status--success" style={{ position: 'relative' }}>
            <span className="popup__close agreement-popup__close" style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClosePaymentNotification} />
            <div className="dashboard-status__title">Your payment was successfull</div>
            <div className="dashboard-status__text">
              You have paid $200 of the principal and $10 of the interest. Your loan has been renewed. You can check the
              <Link to="/"><span className="link-btn"> loan history here</span></Link>
              .
            </div>
          </div>
        )
      }
      {
        showPaymentNotification && isPaymentSuccess && loanClosed && (
          <>
            <div className="dashboard-status dashboard-status--success">
              <div className="dashboard-status__title">Your payment was successfull</div>
              <div className="dashboard-status__text">
                Your loan has been redeemed. Thank you for your business.
                <br />
                You can create
                <Link to="/new-request"><span className="link-btn"> a new request here</span></Link>
                .
              </div>
            </div>
            <div className="dashboard-payment">
              <div className="dashboard-payment-method">
                <div className="dashboard-payment-method__title">Your item(s) will be shipped back within 3 business days.</div>
              </div>
            </div>
          </>
        )
      }
      {
        showPaymentNotification && !isPaymentSuccess && (
          <div className="dashboard-status dashboard-status--error" style={{ position: 'relative' }}>
            <span className="popup__close agreement-popup__close" style={{ position: 'absolute', right: 5, top: 5 }} onClick={onClosePaymentNotification} />
            <div className="dashboard-status__title">The transaction was not authorized</div>
            <div className="dashboard-status__text">
              Please try again. If this problem persists, please
              <span className="link-btn" onClick={onContactUs}> contact us.</span>
            </div>
          </div>
        )
      }
      {
        !loanClosed && (
          <div className="dashboard-columns">
            <div className="dashboard-columns__left dashboard-columns__left--stretch-cards">
              <div className="dashboard-card dashboard-loan-card">
                <div className="dashboard-loan-card__unit dashboard-loan-card__unit--big">
                  Remaining Principal:
                  <span>
                    $
                    {loanData?.loanAmount.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
                <div className="dashboard-loan-card__unit">
                  Rate:
                  <span>
                    {` ${((loanData?.monthlyPercentageInterestRate || 0) * 100).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                    % per month (
                    {((loanData?.yearlyPercentageInterestRate || 0) * 100).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    % APR)
                  </span>
                </div>
                <div className="dashboard-loan-card__unit">
                  Current interests:
                  <span>
                    {' $'}
                    {rate.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
                <div className="dashboard-loan-card__unit">
                  Due date:
                  <span>
                    <span>{` ${dayjs(loanData?.charges[loanData?.charges.length - 1].date).format('MM.DD.YYYY')}`}</span>
                  </span>
                </div>
              </div>
              {
                signedAutoWithdraw
                  ? (
                    <div className="dashboard-card dashboard-card--white">
                      <div className="dashboard-card__title">Auto-Withdraw</div>
                      <div className="dashboard-card__text">
                        <span>account: </span>
                        {bankAccountId}
                      </div>
                      <div className="popup__buttons popup__buttons--horizontal">
                        <span className="popup__btn btn btn--red" onClick={onOptOut}>Opt-out</span>
                        <span className="popup__btn btn btn--bordered btn--bordered-dark" onClick={() => setIsChangingBankAccount(true)}>Change Bank Account</span>
                      </div>
                    </div>
                  )
                  : (
                    <div className="dashboard-card dashboard-card--white dashboard-card--bordered">
                      {isChangingBankAccount && <span className="popup__close" onClick={() => setIsChangingBankAccount(false)} />}
                      <div className="dashboard-card__text">
                        {
                          isChangingBankAccount
                            ? 'Connect your new bank account'
                            : 'You are currently not signed up for auto withdraw. Please authorize your account if you would like to opt-in to auto withdraw'
                        }

                      </div>
                      <div className="popup__buttons popup__buttons--horizontal">
                        {
                          (isConnectButtonVisible || isChangingBankAccount)
                            ? <PlaidLink typeConnection={typeConnection} onFinish={onAuthorizeChangeBankAccount} loanId={loanData?.id} />
                            : <a className="popup__btn btn btn--bordered btn--bordered-dark" onClick={onAuthorize}>Authorize</a>
                        }

                      </div>
                    </div>
                  )
              }
            </div>
            <div className="dashboard-columns__right">
              <div className="dashboard-infocard">
                <div className="popup__header">
                  <div className="popup__title">Make a Manual Payment</div>
                </div>
                <div className="popup__content">
                  <div className="shipping-label-options shipping-label-options--wide">
                    <div className="shipping-label-option shipping-label-option--manual-payment">
                      <label className="checkbox shipping-label-option__checkbox">
                        <input type="radio" name="js-manual-payment" checked={isActiveInterest[0]} onChange={() => handleSelectedInterest(0)} />
                        <span>
                          Interest Only
                          (
                          {' $'}
                          {rate.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          )
                        </span>
                      </label>
                    </div>
                    <div className="shipping-label-option shipping-label-option--manual-payment">
                      <label className="checkbox shipping-label-option__checkbox">
                        <input type="radio" name="js-manual-payment" checked={isActiveInterest[1]} onChange={() => handleSelectedInterest(1)} />
                        <span>
                          Interest + 10% Principal
                          (
                          {' $'}
                          {((rate || 0) + ((loanData?.loanAmount || 0) * 0.1)).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          )
                        </span>
                      </label>
                    </div>
                    <div className="shipping-label-option shipping-label-option--manual-payment">
                      <label className="checkbox shipping-label-option__checkbox">
                        <input type="radio" name="js-manual-payment" checked={isActiveInterest[2]} onChange={() => handleSelectedInterest(2)} />
                        <span>Interest + Other Amount</span>
                      </label>
                      <div className={`${!isActiveInterest[2] && 'shipping-label-option__body'}`}>
                        <div className="form-input">
                          <input type="number" placeholder="$0" value={otherAmountValue} onChange={handleOtheAmountValue} min={parseFloat(rate.toFixed(1)) * 0.1} max={(loanData?.loanAmount || 0) + (rate || 0)} />
                          <div className="form-input__comment">Must be minimum 10% of the principal + interests</div>
                        </div>
                      </div>
                    </div>
                    <div className="shipping-label-option shipping-label-option--manual-payment">
                      <label className="checkbox shipping-label-option__checkbox">
                        <input type="radio" name="js-manual-payment" checked={isActiveInterest[3]} onChange={() => handleSelectedInterest(3)} />
                        <span>
                          Full Amount
                          (
                          $
                          {((loanData?.loanAmount || 0) + (rate || 0)).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          )
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="popup__comment">You will receive your item(s) back with a full payment.</div>
                </div>
                <div className="popup__footer">
                  <div className="popup__buttons popup__buttons--wide">
                    <span
                      className={`popup__btn btn btn--dark ${payCardButtonDisabled && 'btn--disabled'}`}
                      onClick={() => onMakeManualPayment('CARD', manualPaymentOption, otherAmountValue)}
                    >
                      Pay with credit card
                    </span>
                    <span
                      className={`popup__btn btn btn--dark ${payBankAccountButtonDisabled && 'btn--disabled'}`}
                      onClick={() => onMakeManualPayment('BANK', manualPaymentOption, otherAmountValue)}
                    >
                      Pay with bank account
                    </span>
                    {isPaymentProcessing && <span><Spinner tip={`Payment ${loanData?.interestRepaymentType} processing`} loaderSize={{ width: 20, height: 20 }} /></span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default LoanBalance;
