import { useEffect, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type Props = {
  children: JSX.Element | JSX.Element[];
} & RouteComponentProps;

const ScrollToTop = ({ children, history }: Props) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
};

export default withRouter(ScrollToTop);
