import styled from 'styled-components';
import { QuoteSection } from '../components/sections';

export default function NewRequestView() {
  return (
    <Container>
      <body className="body--dashboard">
        <div className="main">
          <div className="dashboard">
            <div style={{ padding: 30 }}>
              <QuoteSection />
            </div>
          </div>
        </div>
      </body>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
`;
