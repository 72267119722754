/* eslint-disable max-len */
import fonts from '../../../constants/fonts';

const { mainFont } = fonts.fonts;

type Props = {
  typeRequest?: string;
};

const RequestSentEstimate = ({ typeRequest }: Props) => (
  <div style={{ fontFamily: mainFont }}>
    <div className="dashboard-columns">
      <div className="dashboard-columns__left">
        <div className="dashboard-card dashboard-card--blue">
          <div className="dashboard-card__title">Notification</div>
          <div className="dashboard-card__text">{`Thank you for requesting to ${typeRequest?.toLocaleLowerCase()} your item(s) with us. Our experts are now reviewing your request details and we will send you our offer. You will be notified via email once your offer is ready.`}</div>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 36.6668C18.1591 36.6668 16.6667 35.1744 16.6667 33.3335H23.3334C23.3334 35.1744 21.841 36.6668 20 36.6668ZM33.3334 31.6668H6.66669V28.3335L10 26.6668V17.5002C10 11.7302 12.3684 7.9885 16.6667 6.96683V3.3335H21.6667C20.5799 4.77339 19.9946 6.52953 20 8.3335C20 8.75246 20.0312 9.17084 20.0934 9.58516H20C17.9664 9.45345 16.005 10.3629 14.7917 12.0002C13.7543 13.6411 13.2452 15.5608 13.3334 17.5002V28.3335H26.6667V17.5002C26.6667 17.1485 26.655 16.8102 26.6317 16.5002C27.7341 16.7279 28.871 16.7319 29.975 16.5118C29.9934 16.8635 30 17.1985 30 17.5118V26.6668L33.3334 28.3335V31.6668ZM28.3334 13.3335C27.3263 13.3347 26.3426 13.0308 25.5117 12.4618C23.548 11.1195 22.8018 8.5795 23.7275 6.38834C24.6531 4.19717 26.9943 2.96147 29.3257 3.43356C31.657 3.90565 33.3331 5.95483 33.3334 8.3335C33.3334 11.0949 31.0948 13.3335 28.3334 13.3335Z" />
          </svg>
        </div>
        <div className="dashboard-card dashboard-card--smaller-text">
          <div className="dashboard-card__title">What’s Next?</div>
          <div className="dashboard-card__text">Please wait while our experts review your request’s details. We will notify you once your offer is ready. Our experts usually get back to you in 2 hours.</div>
        </div>
      </div>
      <div className="dashboard-columns__right">
        <div className="dashboard-infocard">
          <div className="popup__header">
            <div className="popup__title">Our Estimate</div>
          </div>
          <div className="popup__content">
            <div className="popup__image"><img src="/images/icons/file_blank_outline.svg" alt="" /></div>
          </div>
          <div className="popup__footer">
            <div className="popup__text">
              Our experts are reviewing
              <br />
              your request details
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RequestSentEstimate;
