import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { QuoteBreadcrumb } from './common/Breadcrumb';
import { text, colors } from '../constants';
import { quoteStore } from '../store';
import Edit from '../assets/images/Edit';
import { ChangeButton } from './common/Button';

export function withCategories<P>(WrappedComponent: React.ComponentType<P & RouteComponentProps>) {
  const ComponentWithExtraInfo = (props: P & RouteComponentProps) => {
    const [categories, setCategories] = useState<string[]>([]);
    const [subCategory, setSubCategory] = useState<string>('');
    const {
      location: { pathname },
      history,
    } = props;

    useEffect(() => {
      const canAccessChild = (value: string[]) => {
        const store = quoteStore.getState();
        if (value[1] === 'description' && !store.generalInfo) {
          const url = [...value].splice(2).join('/');
          history.push(`/general-information/${url}`);
        } else if (value[1] === 'attachments' && !store.description) {
          const url = [...value].splice(2).join('/');
          history.push(`/description/${url}`);
        }
      };

      const handleGetFilterFromPathName = (): void => {
        if (!pathname || typeof pathname !== 'string') return;

        const paths = pathname.split('/');

        canAccessChild(paths);

        const categories = paths.slice(2);

        if (categories.length === 0) return;

        setCategories(categories);
        setSubCategory(categories.length > 1 ? categories[1] : '');
      };
      handleGetFilterFromPathName();
    }, [pathname, history]);

    const handleDisplayMainCategoryString = () => {
      if (!(categories.length > 0)) return '';
      if (categories[0].includes('&')) return categories[0].split('&').join(' / ');
      return categories[0];
    };

    const handleGetSubCategoriesString = () => {
      const subCategories = [...categories].splice(1);
      if (subCategories.length === 0) return '';
      return ` /${subCategories.map(c => ` ${c} `).join('/')}`;
    };

    const shouldDisplayAddidtionalSteps = () => ['bullion', 'coins'].every(category => category !== categories[0]);
    return (
      <Container>
        <GeneralInfoTitleSection className="title-section container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <QuoteBreadcrumb
                hasAttachments={shouldDisplayAddidtionalSteps()}
                hasDescription={shouldDisplayAddidtionalSteps()}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Category>
                {handleDisplayMainCategoryString()}
                {handleGetSubCategoriesString()}
                <Link to="/categories">
                  <ChangeButton icon={<Edit />}>
                    Change
                  </ChangeButton>
                </Link>
              </Category>
            </Col>
          </Row>
        </GeneralInfoTitleSection>
        <Container className="step-body">
          <WrappedComponent
            categories={categories}
            subCategory={subCategory}
            subCategoriesString={handleGetSubCategoriesString()}
            {...props}
          />
        </Container>
      </Container>
    );
  };
  return ComponentWithExtraInfo;
}

const Container = styled.div`
  flex: 1;
`;

const GeneralInfoTitleSection = styled.div`
  display: flex;
  flex-direction: column;

  .ant-row {
    width: 100%;
  }
`;

const Category = styled.p`
  ${text['title-20']}
  color: ${colors.text.dark};
  text-transform: capitalize;
  padding: 30px 0;
  font-family: Poppins;
`;

export default withCategories;
