/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Form } from 'antd';
import { Spinner } from 'components/common';
import { Component } from 'react';
import { RouteChildrenProps } from 'react-router-dom';

type ChangePasswordValues = {
  email: string;
  newPassword: string;
  confirmNewPassword: string;
};

type Props = RouteChildrenProps & {
  match: {
    params: {
      id?: string;
    }
  },
  isVisible: boolean;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (values: ChangePasswordValues) => void;
};

type State = {
  isReseting: boolean;
};

export default class ResetPasswordSetNewView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isReseting: false,
    };
  }

  render(): JSX.Element {
    const { isVisible, isLoading, onSubmit } = this.props;

    return (
      <div className={`popup ${isVisible && 'popup--shown'}`}>
        <div className="popup__wrap popup__wrap--wide">
          <a className="popup__close" href="#/" />
          <div className="popup__inner">
            <div className="popup__header">
              <div className="popup__title">Recover Password</div>
            </div>
            <div className="popup__content">
              <Form
                onFinish={onSubmit}
              >
                <div className="login-form">
                  <div className="login-form__body">
                    <div className="form-input">
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: 'Set valid email',
                          },
                          {
                            required: true,
                            message: 'Email is required',
                          },
                        ]}
                      >
                        <input type="email" placeholder="Your Email" />
                      </Form.Item>
                    </div>
                    <div className="form-input">
                      <Form.Item
                        name="newPassword"
                        rules={[
                          {
                            required: true,
                            message: 'New password is required',
                          },
                        ]}
                      >
                        <input type="password" placeholder="New Password" />
                      </Form.Item>
                    </div>
                    <div className="form-input">
                      <Form.Item
                        name="confirmNewPassword"
                        dependencies={['password']}
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject('Confirm password is not valid');
                            },
                          }),
                        ]}
                      >
                        <input type="password" placeholder="Confirm New Password" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="login-form__footer">
                    <button
                      className="btn btn--dark popup__btn popup__btn--wide login-form__submit"
                      type="submit"
                    >
                      {
                        isLoading
                          ? <Spinner loaderSize={{ width: 20, height: 20 }} tip={' '} style={{ marginTop: 20 }} />
                          : 'Recover Password'
                      }
                    </button>
                    <div className="form-input__footer">
                      <span>Have a questions, problems?</span>
                      <a>Contact Us</a>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
