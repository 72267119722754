import React, { Component } from 'react';
import {
  Form, Row, Col, Modal,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { api } from '../api';
import { SecondaryButton } from '../components/common/Button';
import { FormInput, FormInputPassword } from '../components/common/Input';
import { Spinner } from '../components/common';

type ProfileInfo = {
  firstName: string;
  lastName: string;
  currentEmail: string;
  newEmail: string;
  repeatNewEmail: string;
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  address: string;
  country: string;
  bankAccount: string;
};

type IProps = {};
type IState = {
  initialValues: ProfileInfo,
  isLoading: boolean,
};
class ProfileView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      initialValues: {
        firstName: '',
        lastName: '',
        currentEmail: '',
        newEmail: '',
        repeatNewEmail: '',
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: '',
        address: '',
        country: '',
        bankAccount: '',
      },
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchProfileInfo();
  }

  formRef = React.createRef<FormInstance>();

  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }

    return (
      <body className="body--dashboard">
        <div className="main main-dashboard">
          <div className="dashboard">
            <FormArea>
              <Form onFinish={this.onFinish} initialValues={this.state.initialValues} ref={this.formRef}>
                <InputsArea>
                  <Header>User Name</Header>
                  <Row gutter={[32, 0]}>
                    <Col xs={24} sm={18} md={14} lg={8} xl={8}>
                      <Form.Item
                        name="firstName"
                        rules={[{ required: true, message: 'Please input your first name.' }]}
                      >
                        <FormInput placeholder="First Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={18} md={14} lg={8} xl={8}>
                      <Form.Item
                        name="lastName"
                        rules={[{ required: true, message: 'Please input your last name.' }]}
                      >
                        <FormInput placeholder="Last Name" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[32, 16]}>
                    <Col xs={24} sm={18} md={14} lg={8} xl={8}>
                      <Header>Email</Header>
                      <Form.Item name="currentEmail" style={{ marginBottom: 16 }}>
                        <FormInput placeholder="Current Email" disabled />
                      </Form.Item>
                      <Form.Item name="newEmail" style={{ marginBottom: 16 }}>
                        <FormInput placeholder="New Email" />
                      </Form.Item>
                      <Form.Item name="repeatNewEmail">
                        <FormInput placeholder="Repeat New Email" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={18} md={14} lg={8} xl={8}>
                      <Header>Password</Header>
                      <Form.Item name="currentPassword" style={{ marginBottom: 16 }}>
                        <FormInputPassword placeholder="Current Password" />
                      </Form.Item>
                      <Form.Item name="newPassword" style={{ marginBottom: 16 }}>
                        <FormInputPassword placeholder="New Password" />
                      </Form.Item>
                      <Form.Item name="repeatNewPassword">
                        <FormInputPassword placeholder="Repeat New Password" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Header>Company Delivery Address</Header>
                  <Row gutter={[32, 32]}>
                    <Col xs={24} sm={18} md={14} lg={8} xl={8}>
                      <Form.Item name="address" style={{ marginBottom: 16 }}>
                        <FormInput placeholder="Address" />
                      </Form.Item>
                      <Form.Item name="country">
                        <FormInput placeholder="Country" />
                      </Form.Item>
                    </Col>
                  </Row>
                </InputsArea>
                <ButtonArea>
                  <Form.Item>
                    <SecondaryButton htmlType="submit">Save changes</SecondaryButton>
                  </Form.Item>
                </ButtonArea>
              </Form>
            </FormArea>
          </div>
        </div>
      </body>
    );
  }

  onFinish = (values: ProfileInfo) => {
    api
      .post('/user/profile', values)
      .then(() => this.showSuccessModal('Success', 'Your profile information has been changed.'))
      .then(() => this.fetchProfileInfo())
      .catch(err => this.showErrorModal('Error occured', err.response.data));
  };

  fetchProfileInfo = () => {
    api
      .post('/user')
      .then(res => res.data)
      .then(data => this.setState((prevState: IState) => ({
        initialValues: {
          ...prevState.initialValues,
          firstName: data.firstName,
          lastName: data.lastName,
          currentEmail: data.email,
          address: data.address,
          country: data.country,
          bankAccount: data.bankAccount,
        },
        isLoading: false,
      }), () => this.formRef.current!.resetFields()))
      .finally(() => this.setState({ isLoading: false }));
  };

  showErrorModal = (title: string, content: string) => {
    Modal.error({
      title,
      content,
    });
  };

  showSuccessModal = (title: string, content: string) => {
    Modal.success({
      title,
      content,
    });
  };
}

const FormArea = styled.div`
    width: 100%;

    input, .ant-input-password {
      width: 100%;
    }
`;

const InputsArea = styled.div`
    padding-left: 40px;
    background-color: #f6f4f0;
    padding: 40px;

    .ant-input-password {
        background-color: white;
    }
    .ant-input {
        background-color: white;
        color: #31393e;
        &::placeholder {
            color: #b7bfc5;
        }
    }
`;

const ButtonArea = styled.div`
    height: 100%;
    width: 20%;
    padding: 20px 0px 10px 40px;
`;

const Header = styled.div`
    font-family: Cambon;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #575c60;
    margin-bottom: 10px;
`;

export default ProfileView;
