import { Component } from 'react';
import { Row, Col, message } from 'antd';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SecondaryButton } from '../common/Button';
import { colors, text as textStyle } from '../../constants';
import { quoteStore, userStore, actionTypes } from '../../store';
import { LoginModal, RegisterModal, SummaryModal } from '../Modals';
import { Spinner } from '../common';

type Props = {
  beforeSubmit?: () => void;
  text?: string;
  isSummaryModalVisible?: boolean;
  handleSummaryModalVisibleChange?: (state: boolean) => void;
  fileList?: any[];
  customHandleFinish?: () => void;
  handleValidateForm?: () => boolean;
} & RouteComponentProps;

type State = {
  isLoginModalVisible: boolean;
  isRegisterModalVisible: boolean;
  isLoading: boolean;
};

class SubmitSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoginModalVisible: false,
      isRegisterModalVisible: false,
      isLoading: false,
    };
  }

  render() {
    const {
      isSummaryModalVisible,
      handleSummaryModalVisibleChange,
      fileList,
    } = this.props;

    const { isLoginModalVisible, isRegisterModalVisible, isLoading } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        <SubmitContainer className="container">
          <Row gutter={[8, 16]} justify="center">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 10 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              xl={{ span: 9 }}
            >
              <Button onClick={() => {
                if (this.props.handleValidateForm) {
                  if (!this.props.handleValidateForm()) return false;
                }
                this.handleSubmitClick();
              }}
              >
                Submit & Send Request
              </Button>
            </Col>
            <Col
              xs={{ offset: 2, span: 24 }}
              sm={{ span: 10 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              xl={{ span: 9 }}
            >
              <SButton onClick={() => {
                if (this.props.handleValidateForm) {
                  if (!this.props.handleValidateForm()) return false;
                }
                this.handleNextItem();
              }}
              >
                Submit & Add Next Item
              </SButton>
            </Col>
          </Row>
        </SubmitContainer>
        {
          isLoginModalVisible && (
            <LoginModal
              handleLoginModal={this.handleLoginModalVisibilityChange}
              handleRegisterModal={this.handleRegisterModalVisibilityChange}
              isVisible={isLoginModalVisible}
              onFinish={this.handleFinish}
            />
          )
        }
        {
          isRegisterModalVisible && (
            <RegisterModal
              handleLoginModal={this.handleLoginModalVisibilityChange}
              handleRegisterModal={this.handleRegisterModalVisibilityChange}
              isVisible={isRegisterModalVisible}
              onFinish={this.handleFinish}
            />
          )
        }
        <SummaryModal
          fileList={fileList}
          handleVisibileChange={handleSummaryModalVisibleChange}
          onConfirm={this.handleConfirm}
          isVisible={!!isSummaryModalVisible}
        />
      </>
    );
  }

  handleNextItem = () => {
    const { fileList, beforeSubmit } = this.props;

    if (beforeSubmit) {
      beforeSubmit();
    }

    this.setState({ isLoading: true });

    sessionStorage.removeItem('isSummaryModalVisible');
    sessionStorage.removeItem('isAlertModalVisible');

    if (fileList && fileList.length > 0)
      quoteStore.dispatch({ type: actionTypes.ADD_ATTACHMENTS, attachments: fileList });
    quoteStore.dispatch({ type: actionTypes.ADD_NEXT_ITEM });

    message.success('Item added');
    this.props.history.push('/categories');
  };

  handleFinish = () => {
    const isSummaryModalVisible = sessionStorage.getItem('isSummaryModalVisible');
    const isAlertModalVisible = sessionStorage.getItem('isAlertModalVisible');

    if (isSummaryModalVisible === 'true' || isAlertModalVisible === 'true') {
      return null;
    }

    sessionStorage.removeItem('isSummaryModalVisible');
    sessionStorage.removeItem('isAlertModalVisible');

    this.setState({ isLoading: true });

    const { fileList, customHandleFinish } = this.props;

    if (customHandleFinish) {
      return customHandleFinish();
    }

    const {
      generalInfo, description, quotes,
    } = quoteStore.getState();

    const category = this.props.location.pathname.split('/').pop();

    const quotesArray = [...quotes, { generalInfo, description, attachments: fileList }];

    this.props.history.push({
      pathname: '/payment-information',
      state: {
        quote: quotesArray,
        category,
      },
    });
  };

  handleSubmitClick = () => {
    const { beforeSubmit, fileList } = this.props;
    const { id } = userStore.getState();

    if (beforeSubmit)
      beforeSubmit();

    if (!fileList && !id) {
      return this.handleLoginModalVisibilityChange(true);
    }

    return this.handleFinish();
  };

  handleConfirm = () => {
    const { handleSummaryModalVisibleChange } = this.props;

    if (handleSummaryModalVisibleChange) {
      handleSummaryModalVisibleChange(false);
    }

    sessionStorage.setItem('isSummaryModalVisible', 'false');

    const { id } = userStore.getState();

    if (!id) return this.handleLoginModalVisibilityChange(true);
    this.handleFinish();
  };

  handleRegisterModalVisibilityChange = (isVisible: boolean) => this.setState({ isRegisterModalVisible: isVisible });

  handleLoginModalVisibilityChange = (isVisible: boolean) => this.setState({ isLoginModalVisible: isVisible });
}

const SubmitContainer = styled.div`
    background-color: ${colors.background.main};
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ant-row {
        height: 100%;
        margin: 0 !important;
    }

    .ant-col {
        align-self: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
            padding: 19.5px 30px;
            ${textStyle.text};
            margin-bottom: 0;
        }
    }
`;

const Button = styled(SecondaryButton)`
    ${textStyle['buttom-caps-24']}
    padding: 10px 35px;
    min-height: 60px;
   
    @media(max-width: 768px) {
      margin: 5px 0;
      font-size: 11px;
      padding: 10px 15px;
    }

    @media(max-width: 1024px) {
      margin: 5px 0;
      font-size: 12px;
      padding: 10px 20px;
    }
`;

const SButton = styled(SecondaryButton)`
    ${textStyle['buttom-caps-24']}
    background-color:white;
    color: ${colors.text.body};
    padding: 10px 35px;
    border: dashed 1px ${colors.text.dark};
    outline: none;
    min-height: 60px;
    
    @media(max-width: 768px) {
      margin: 5px 0;
      font-size: 11px;
      padding: 10px 15px;
    }

    @media(max-width: 1024px) {
      margin: 5px 0;
      font-size: 12px;
      padding: 10px 20px;
    }
`;

export default withRouter(SubmitSection);
